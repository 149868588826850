import published from "../../../../../../../assets/images/file_check2.svg";
import not_ready_to_publish from "../../../../../../../assets/images/file.svg";
import ready_to_publish from "../../../../../../../assets/images/file_check.svg";
import copyIcon from "../../../../../../../assets/images/copy-icon.svg";
import pasteIcon from "../../../../../../../assets/images/paste.svg";
import trim_svg from "../../../../../../../assets/images/image-trim.svg";
import expandIcon from "../../../../../../../assets/images/expand-icon.svg";
import publishedEdit from "../../../../../../../assets/images/publishedEdit.svg"

export const DateActions = ({
    item,
    confirmPublishWithDate,
    copyData,
    pasteDataFunc,
    copyDataFun,
    trim_programs_space
}) => {
    return (
        <div className="d-flex justify-content-between">
            <span className="text-white">
                <span style={{ fontSize: "16px", color: "#999999" }}>
                    {new Date(item?.date).toLocaleDateString("en-US", { weekday: "short" })}
                </span>
                {" "}
                <span style={{ fontSize: "20px"}}>
                    {new Date(item?.date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    })}
                </span>
            </span>
            <div className="d-flex justify-content-between">
                {
                    item?.published?
                        item?.isEdit?
                            <a className="btn mx-1 p-1" style={{height: '28px', background: "#ff6464"}} >
                                <img src={publishedEdit} width= {20}/>
                            </a>
                        :
                            <a className="btn mx-1 p-1" style={{height: '28px', background: "#73DF5C"}} >
                                <img src={published} width= {20}/>
                            </a>
                        
                    :
                        (item?.scheduleItems?.reduce((total, data) => total + (data?.totalDuration || 0), 0)) >= 86400000 ?
                            // <a className="btn mx-1 p-1" style={{height: '28px', background: "#FB923C"}} onClick={() => confirmPublishWithDate(item?.date)}>
                            //     <img src={ready_to_publish} width= {20}/>
                            // </a>
                            <a className="btn mx-1 p-1" style={{height: '28px', background: "#FB923C"}}>
                                <img src={ready_to_publish} width= {20}/>
                            </a>
                        :
                            <a className="btn mx-1 p-1 bg-secondary" style={{height: '28px'}} >
                                <img src={not_ready_to_publish} width= {20}/>
                            </a>
                }
                
                
                {
                    copyData?.length && item?.scheduleItems?.length === 0 ?
                    <a className="btn mx-1 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {pasteDataFunc(item?.date)}}>
                        <img src={pasteIcon} width= {20}/>
                    </a>
                    :
                    <a className="btn mx-1 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {copyDataFun(item?.date)}}>
                        <img src={copyIcon} width= {20}/>
                    </a>
                }
                <a className="btn mx-1 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {trim_programs_space(item?.date)}}>
                    <img src={trim_svg} width= {20}/>
                </a>
                {/* <a className="btn mx-1 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {expandPanelView(item?.date)}}>
                    <img src={expandIcon} width={20}/>
                </a> */}
            </div>    
        </div>
    )
}