
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./LoginHeader.scss";
import Drawer from "../Drawer";

export default function LoginHeader() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  return (
    <div className="header-section">
      <div className="logo">
        <a href="">
          <img src={''} />
        </a>
      </div>
      <div className="right-side">
        <div className="need-help">
          <a href="">Need help?</a>
        </div>

        <div className="contact-us">
          <button onClick={openDrawer} className="btn">
            Contact us
          </button>
        </div>


        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer} />

      </div>
    </div>
  );
}
