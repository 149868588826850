import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import content_icon from "../../../assets/images/content-icon.svg";
import room from "../../../assets/images/room.svg";
import DropdownArrow from "../../../assets/svg/graph/dropdownArrow.svg";
import closeIcon from "../../../assets/svg/contact/close-circle-o.svg";
import { ChannelDataAction, MapDataAction } from "../../../Redux/slices";
import { ViewershipMap_URL } from "../../../service/API_URL";
import "./GraphAccordionFilter.scss";

function GraphAccordionFilter({ handleChange, Durations, setSortOrders, channelData, handleChannelChange }) {
  const [view, setView] = useState("Channel");
  const [duration, setDuration] = useState("day");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const channelOptions = ["Channel 1", "Channel 2", "Channel 3", "Channel 4", "Channel 5", "Channel 6", "Channel 7", "Channel 8", "Channel 9"];
  const locationOptions = ["Location 1", "Location 2", "Location 3", "Location 4", "Location 5", "Location 6", "Location 7", "Location 8", "Location 9"];

  const colors = [
    { border: '#F39F53', background: 'rgba(255, 99, 132, 0.2)' },
    { border: '#FFEE50', background: 'rgba(54, 162, 235, 0.2)' },
    { border: '#73DB32', background: 'rgba(75, 192, 192, 0.2)' },
    { border: '#AD55F3', background: 'rgba(153, 102, 255, 0.2)' },
    { border: '#1BE3E4', background: 'rgba(255, 159, 64, 0.2)' },
  ];


  const fetchDropdownOptions = async (viewType) => {
    try {
      const response = await axios.get(ViewershipMap_URL, {
        params: { type: viewType.toLowerCase() },
      });
      if (viewType === "Channel") {
        dispatch(ChannelDataAction(response.data?.data || []));
      } else if (viewType === "Location") {
        dispatch(MapDataAction(response.data?.data || []));
      }

      setSelectedItems([]); // Reset selection when the view type changes
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    // Prevent selecting more than 5 items
    if (checked && selectedItems.length >= 5) {
      alert("You can only select up to 5 channels.");
      return; // Do not add more items
    }

    setSelectedItems((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );

  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filterChange = (e) => {
    const { name, value } = e.target;
    if (name === "view_by") {
      setView(value);
      setSortOrders({ viewKey: "asc" });
      handleChange(value, duration, { sort: "asc", key: "viewKey" });
      fetchDropdownOptions(value);
    } else if (name === "Duration") {
      setDuration(value);
      setSortOrders({ viewKey: "asc" });
      handleChange(view, value, { sort: "asc", key: "viewKey" });
    }
  };

  useEffect(() => {
    setSelectedItems([]); // Reset selection when the view changes
    fetchDropdownOptions(view);
  }, [view]);

  const filteredOptions = (view === "Channel" ? channelData : locationOptions).filter(option =>
    option?.viewKey?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const removeSelectedItem = (item) => {
    setSelectedItems((prev) => prev.filter((selectedItem) => selectedItem !== item));
  };


  useEffect(() => {
    if (selectedItems) {
      handleChannelChange(selectedItems)
    }
  }, [selectedItems])
  return (
    <div className="view-duration">
      <div className="view-block " >
        <h5>View by</h5>
        <div className="radio-list">
          <div className="radio-box">
            <input
              id="content"
              value="Channel"
              name="view_by"
              type="radio"
              onChange={filterChange}
              checked={view === "Channel"}
            />
            <label htmlFor="content" className={view === "Channel" ? "active" : ""}>
              <img src={content_icon} alt="Channel Icon " />
              All Channels
              <img
                src={DropdownArrow}
                className="dropdown-arrow"
                alt="Dropdown Arrow"
                style={{ marginLeft: 10, cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
              />
            </label>
          </div>
          <div className="radio-box ">
            <input
              id="Location"
              value="Location"
              name="view_by"
              type="radio"
              onChange={filterChange}
              checked={view === "Location"}
            />
            <label htmlFor="Location" className={view === "Location" ? "active" : ""}>
              <img src={room} alt="Location Icon" />
              Location
              <img
                src={DropdownArrow}
                className="dropdown-arrow"
                alt="Dropdown Arrow"
                style={{ marginLeft: 10, cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="duration-block" style={{marginLeft:30}}>
        <h5>Duration</h5>
        <div className="radio-list">
          {["day", "Week", "Month", "Year"].map((item) => (
            <div key={item} className="radio-box">
              <input
                id={item}
                value={item}
                name="Duration"
                type="radio"
                onChange={filterChange}
                checked={duration === item}
              />
              <label htmlFor={item}>{item === "day" ? "Today" : `This ${item}`}</label>
            </div>
          ))}
        </div>
      </div>

      {/* Dropdown options with search */}
      {isDropdownOpen && (
        <div ref={dropdownRef} className="dropdown-options" style={{backgroundColor:'#131313', marginTop:75}}>
          <img
            src={closeIcon}
            alt="Close"
            className="close-icon"
            onClick={() => setIsDropdownOpen(false)}
            style={{ marginTop: "10px" }}
          />
          <input
            type="text"
            className="search-input"
            placeholder={`Search ${view.toLowerCase()}...`}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          {filteredOptions.map((option) => (
            <div key={option?.viewKey} className="dropdown-option">
               <label>
              <input
                type="checkbox"
                value={option?.viewKey}
                checked={selectedItems.includes(option?.viewKey)}
                onChange={handleCheckboxChange}
                disabled={selectedItems.length >= 5 && !selectedItems.includes(option?.viewKey)} 
              />
                 <span style={{ marginLeft: '8px' }}>{option?.viewKey}</span>
             {/* {option?.viewKey} */}
             </label>
            </div>
          ))}
        </div>
      )}

      {/* Selected items display */}
      {selectedItems.length > 0 && (
        <div className="selected-items" style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
          <strong style={{ color: "#fff", fontSize: 20, marginTop: 10 }}>{view} :</strong>
          {selectedItems.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: colors[index % colors.length].background,
                color: "#FFFFFF",
                margin: "5px",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                padding: "5px",
                fontSize: 15,
                border: `0.5px solid ${colors[index % colors.length].border}`,
                backgroundColor: colors[index % colors.length].background,
              }}

            >

              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: colors[index % colors.length].border,
                  marginRight: "8px",
                }}
              ></span>
              {item}
              <img
                src={closeIcon}
                alt="Close"
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  width: 25,
                  height: 25,
                }}
                onClick={() => removeSelectedItem(item)}
              />
            </div>
          ))}
        </div>
      )}
   

    </div>
  );
}

export default GraphAccordionFilter;
