const AssetDuration = ({ label, duration }) => (
    <h5 className="ms-1"><span>{label}</span> - {duration}</h5>
);

const AssetInfoCard = ({ 
    assetInfo,
    millisecondsToHuman,
    appTheme,
    categoryTab
 }) => {
    const formatDuration = (type) => {
        return assetInfo ? millisecondsToHuman(
            assetInfo?.adBreaks?.filter(data => data?.assetType === type)
                ?.reduce((total, ad) => total + ad.durationInMillis, 0)
        ) : "00:00:00";
    };

    return (
        <div className={`top-content-right ${appTheme === "light" ? "rounded shadow-sm" : ""}`}>
            <div className="left-side">
                <h5 className="py-3 px-3">{assetInfo?.title}</h5>
            </div>
            <div className="asset-duration-cont row px-1 pb-3">
                <div className="col-5 start-end-time">
                    <h5 className="px-2">
                        {assetInfo ? `${millisecondsToHuman(assetInfo?.scheduleStartTime)} - ${millisecondsToHuman(assetInfo?.scheduleEndTime)}` : "00:00:00 - 00:00:00"}
                    </h5>
                </div>
                <div className="col-7 d-flex video-ads-time justify-content-end">
                    <h5 className="me-1"><span>Video</span> - {assetInfo ? millisecondsToHuman(assetInfo?.durationInMillis) : "00:00:00"}</h5>
                    {categoryTab === "Ads" && <AssetDuration label="Ads" duration={formatDuration("AD")} />}
                    {categoryTab === "Promos" && <AssetDuration label="Promos" duration={formatDuration("PROMO")} />}
                    {categoryTab === "Secondary" && <AssetDuration label="Sec" duration={formatDuration("FILLER")} />}
                </div>
            </div>
        </div>
    );
};

export default AssetInfoCard;
