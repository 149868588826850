import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"; // Assuming you're using React Router for routing
import BrowsePopup from "./BrowsePopup";
import "./CmsChannelDetail.scss";
import icroundsearch from "../../../assets/svg/Assetmanagemant/search.svg";
import video_icon from "../../../assets/images/video-icon.png";
import uploadicon from "../../../assets/svg/POSTER/upload .svg";
import browseicon from "../../../assets/images/Browse.png";
import deleteicon from "../../../assets/svg//Assetmanagemant/delete_forever.svg";
import Groupicon from "../../../assets/images/Group.png";
import metafile from "../../../assets/images/metafile.png";
import Groupiconblue from "../../../assets/svg/partitioncontent/blueVideoImg.svg";
import archiveicon from "../../../assets/images/archiveicon.png";
// import option from "../../../assets/svg/Assetmanagemant/optionIcon.svg";
import option from "../../../assets/images/option.png";
import {
  CMS_Multipart_upload,
  CMS_Multipart_upload_URL,
  CMS_Multipart_Complete_URL,
  CMS_Multipart_Fillers,
  Metaupdate_url,
  CMS_Multipart_PROMOS,
} from "../../../service/API_URL";
import instance from "../../../service/axiosConfig";
import {
  Asset_Delete,
  Asset_search,
  DeleteallPoster,
  FillerList,
  Filler_delete,
  Language_Lists,
  Language_list,
  MetafileList_By_Partner,
  Metafile_delete,
  ProgramsList_By_Partner,
  Promo_delete,
  PromosList_By_Partner,
  GetPocketFilms,
} from "../../../api/api";
import { getInstantData, postInstantData } from "../../../api/apiMethod";
import Partitioncontent from "../../../component/content-update/Partitioncontent";
import UploadPosters from "../../../component/content-update/uploadposter";
import { image_url } from "../../../service/Constant";
import MetaDataView from "../../../component/metaData/MetaDataView";
import VideoBlock from "../../../component/video-block/VideoBlock";
import SearchData from "./SearchData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPopup from "../../../component/popup/ErrorPopup";
import {
  capitalizeFirstLetter,
  isLogin,
  posterimage,
} from "../../../service/commonFunc";
import axios from "axios";
import Videoplayer from "../../../component/player-block/Videoplayer";
import { permissionDetails } from "../../../utility/localStoageData";
import refresh from "../../../assets/images/refresh.png"
import Hls from "hls.js";
import VideoPlayerPktFlm from "../../../component/player-block/VideoPlayerPktFlm";

function CmsChannelDetail() {
  const navigate = useNavigate();
  // const someValue = useSelector(state => state);
  const assetData = JSON.parse(localStorage.getItem("AssetDetail"));
  const AssetPartnerDetail = JSON.parse(
    localStorage.getItem("AssetPartnerDetail")
  );
  const assetCode = JSON.parse(localStorage.getItem("tenantInfo"));

  const PART_SIZE = 50 * 1024 * 1024; // 50 MB in bytes
  const [contentArray, setContentArray] = useState(assetData.content);
  const [activeTab, setActiveTab] = useState("PROGRAM");
  const [categoryTab, setCategoryTab] = useState("VIDEO");
  const handleClose = () => setShowModal(false);
  const [partition, setpartition] = useState(false);
  const [posterpartion, setposterpartion] = useState(false);
  const [postermeta, setpostermeta] = useState(true);
  const [assetSignledata, setAssetSignledata] = useState(null);
  const [partProgress, setPartProgress] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFile, setUploadFile] = useState([]);
  const uploadFileRef = useRef(uploadFile);
  const [numberOfParts, setNumberOfParts] = useState(null);
  const [newUpload, setNewUpload] = useState(null);
  const [uploadurl, setuploadURL] = useState(CMS_Multipart_upload);
  const [filler, setfiller] = useState([]);
  const [promos, setPromos] = useState([]);
  const [Metafile, setMetafile] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [errorbox, setErrorbox] = useState(false);
  const [errorMssage, setErrorMessage] = useState("");
  const [btntext, setbtntext] = useState(null);
  const [eventdelete, seteventdelete] = useState(null);
  const [actTab, setactTab] = useState(activeTab);
  const [showplayer, setshowplayer] = useState(false);
  const [showHlsplayer, setShowHlsplayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenPath, setTokenPath] = useState(null);
  const [expires, setExpires] = useState(null);
  const [permission, setpermission] = useState(null);
  const videoRef = useRef(null);

  const {
    appThemeReducer: { appTheme },
  } = useSelector((state) => state);

  const handleRetryUpload = (selectedFile) => {
    setTimeout(() => {
      if (selectedFile) {
        // Set overrideExisting flag to true

        // Retry the upload with the selected file
        handleFileChange({ target: { files: [selectedFile] } }, true); // Pass true directly
      }
    }, 1000);

    // errorboxclose()
  };
  const VideoRetryUpload = () => {
    setTimeout(() => {
      if (selectedFile) {
        // Set overrideExisting flag to true

        // Retry the upload with the selected file
        handleMultipartFileChange({ target: { files: [selectedFile] } }, true); // Pass true directly
      }
    }, 1000);

    errorboxclose();
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (newUpload != null) {
      (async () => {
        try {
          let response = {};
          const formdata = new FormData();
          formdata.append("filename", newUpload?.fileName);
          // let filename = {filename:newUpload?.fileName}
          if (activeTab == "PROGRAM") {
            let params = newUpload?.params;

            response = await postInstantData(newUpload.URL, formdata, {
              params,
            });
          } else if (activeTab == "FILLER") {
            let params = newUpload?.params;

            response = await postInstantData(newUpload.URL, formdata, {
              params,
            });
          } else if (activeTab == "PROMO") {
            let params = newUpload?.params;

            response = await postInstantData(newUpload.URL, formdata, {
              params,
            });
          }

          const { uploadId, objectKey } = response.data.data;
          const preSignedUrls = await getPreSignedUrls(
            uploadId,
            objectKey,
            newUpload.numberOfParts
          );
          await uploadPart(
            preSignedUrls,
            newUpload.parts,
            uploadId,
            objectKey,
            newUpload.fileName
          );
        } catch (error) {
          if (error.response.status == 400) {
            if (error.response.data.message.includes("filename=[")) {
              setErrorbox(true);
              setbtntext("Override");
              seteventdelete(() => () => VideoRetryUpload());
              setErrorMessage(error.response.data.message);
            } else if (
              error.response.data.message.includes("Invalid filename")
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
              });
              setUploadFile([]);
            }
          } else {
            toast.error("Something went wrong, please try after sometime", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
            setUploadFile([]);
          }
        }
      })();
    }
  }, [newUpload]);

  const splitFormData = (formData, partSize) => {
    const totalSize = formData.size; // Get the total file size in bytes
    const numberOfParts = Math.ceil(totalSize / partSize);
    setNumberOfParts(numberOfParts);

    const parts = [];

    let start = 0;
    let end = partSize;

    for (let i = 0; i < numberOfParts; i++) {
      if (i === numberOfParts - 1) {
        end = totalSize; // Last part might be smaller
      }

      // Create a new Blob for each part
      const partBlob = formData.slice(start, end);
      parts.push(partBlob);

      start = end;
      end += partSize;
    }

    return parts;
  };
  const uplaodassetposter = async (e, id) => {
    const file = e.target.files[0];

    const params = {
      assetId: id,
      partnerId: AssetPartnerDetail?.id,
      promoType: "PROGRAM_PROMO",

      fileSize: file.size,
    };
    // Condition to use either params1 or params2

    const URL = CMS_Multipart_PROMOS;
    const totalSize = file.size;
    const numberOfParts = Math.ceil(totalSize / PART_SIZE);
    const parts = splitFormData(file, PART_SIZE);
    const formData = new FormData();
    const newUpload = {
      newUpload: {
        name: file.name,
        percentage: 0,
        totalSize,
      },
    };
    setSelectedFile(file);
    setUploadFile([...uploadFile, newUpload]);
    const fileName = file?.name;
    setNewUpload({
      URL,
      numberOfParts,
      params,
      parts,
      fileName,
    });
    setactTab("PROMO");
  };

  const handleMultipartFileChange = async (e, overrider) => {
    setactTab(activeTab);
    const file = e.target.files[0];
    const programs = {
      partnerId: AssetPartnerDetail?.id,
      assetType: categoryTab,
      videoType: categoryTab === "VIDEO" ? activeTab : null,
      metadataType: "MOVIES",
      overrideExisting: overrider,

      fileSize: file?.size,
    };

    // Second set of parameters
    const fillers = {
      partnerId: AssetPartnerDetail?.id,

      fillerType: "PARTNER_FILLER",

      fileSize: file?.size,
    };
    const promo = {
      partnerId: AssetPartnerDetail?.id,

      promoType: "PARTNER_PROMO",

      fileSize: file.size,
    };
    const assetpromo = {
      assetId: AssetPartnerDetail?.id,

      promoType: "PROGRAM_PROMO",

      fileSize: file.size,
    };
    // Condition to use either params1 or params2
    let params;
    if (activeTab == "PROGRAM") {
      params = programs;
    } else if (activeTab == "FILLER") {
      params = fillers;
    } else if (activeTab == "PROMO") {
      params = promo;
    }

    const URL = uploadurl;
    const totalSize = file.size;
    const numberOfParts = Math.ceil(totalSize / PART_SIZE);
    const parts = splitFormData(file, PART_SIZE);
    const formData = new FormData();
    const newUpload = {
      newUpload: {
        name: file.name,
        percentage: 0,
        totalSize,
      },
    };
    setSelectedFile(file);
    setUploadFile([...uploadFile, newUpload]);
    const fileName = file?.name;
    setNewUpload({
      URL,
      numberOfParts,
      params,
      parts,
      fileName,
    });
  };

  const getPreSignedUrls = async (uploadId, objectKey, numberOfParts) => {
    const URL = encodeURI(
      `${CMS_Multipart_upload_URL}?uploadId=${uploadId}&objectKey=${objectKey}&numberOfParts=${numberOfParts}`
    );
    try {
      const response = await getInstantData(URL);
      return response.data;
    } catch (error) { }
  };

  const uploadPart = async (
    preSignedUrls,
    parts,
    uploadId,
    objectKey,
    name
  ) => {
    let uploadCount = 0;

    // Initialize part progress array
    const partProgressArray = Array(preSignedUrls.length).fill(0);

    const uploadPromises = preSignedUrls.map(async (preSignedUrl, i) => {
      const url = preSignedUrl.url;

      const formData = new FormData();
      formData.append("file", parts[i]);

      try {
        const resp = await axios.put(url, parts[i], {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          //  cancelToken: source.token,
          onUploadProgress: (progressEvent) => {
            parts[i].progress =
              (progressEvent.loaded / progressEvent.total) * 100;
            const overallProgress =
              parts.reduce(
                (acc, current) =>
                  parseInt(acc || 0) + parseInt(current?.progress || 0)
              ) / parts.length;
            const newUploadFile = uploadFileRef.current.map((d) => {
              if (d.newUpload.name === name) {
                d.newUpload.percentage = parseInt(overallProgress);
              }
              return d;
            });
            setUploadFile([...newUploadFile]);
          },
        });
        uploadCount += 1;
        const totalProgress = Math.round(
          (100 * uploadCount) / preSignedUrls.length
        );
        let uploadLength = uploadFile.length;
        for (let i = 0; i < uploadLength; i++) {
          if (uploadFile[i].newUpload.name === name) {
            uploadFile[i].newUpload.percentage = totalProgress;
            break;
          }
        }
        return { partNumber: i + 1, eTag: resp.headers["etag"] };
      } catch (error) {
        console.error("Error uploading part", i, error);
        return { partNumber: i + 1, eTag: null };
      }
    });
    const eTags = await Promise.all(uploadPromises);

    completeMultipartUpload(uploadId, objectKey, eTags, name);
  };

  useEffect(() => {
    uploadFileRef.current = uploadFile;
  }, [uploadFile]);

  const completeMultipartUpload = async (
    uploadId,
    objectKey,
    partETags,
    name
  ) => {
    try {
      const response = await instance.post(CMS_Multipart_Complete_URL, {
        uploadId,
        objectKey,
        partETags,
        videoType: actTab,
      });
      // const newUploadFile = uploadFileRef.filter((d) => d.newUpload.name !== name);
      // if(uploadFile.length == 1) {
      //   setUploadFile([])
      // } else {
      //   setUploadFile(newUploadFile);
      // }
      setTimeout(() => {
        assetdetail(AssetPartnerDetail);
        const newUploadFile = uploadFileRef.current.filter(
          (d) => d.newUpload.name !== name
        );
        setUploadFile(newUploadFile);
      }, 1000);

      toast.success(
        capitalizeFirstLetter(categoryTab) + " uploaded successfully",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        }
      );

      return response.data?.data;
    } catch (error) {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleFileChange = async (e, override) => {
    const params = {
      partnerId: AssetPartnerDetail?.id,
      assetType: categoryTab,
      videoType: categoryTab === "VIDEO" ? activeTab : null,
      metadataType: "MOVIES",
      overrideExisting: override,
      name: e.currentTarget?.files?.[0]?.name,
      fileSize: 0,
    };

    const URL =
      categoryTab == "METAFILE" ? Metaupdate_url : CMS_Multipart_upload;
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const uploadfilename = {
      name: e.currentTarget?.files?.[0]?.name,
      percentage: 0,
    };
    setUploadFile([...uploadFile, uploadfilename]);
    setSelectedFile(file);
    try {
      const response = await instance.post(URL, formData, {
        params,
      });

      toast.success(
        capitalizeFirstLetter(categoryTab) + " uploaded successfully",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        }
      );
      setUploadFile(
        uploadFile.filter((e) => e !== e.currentTarget?.files?.[0]?.name)
      );

      assetdetail(AssetPartnerDetail);
    } catch (error) {
      if (error.response.status != 500) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
          setbtntext("Override");
          seteventdelete(() => () => handleRetryUpload(file));
          setErrorMessage(error.response.data.message);
          setErrorbox(true);
        }
      } else {
        toast.error("Something went wrong, please try after sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }
  };

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("ASSET_MANAGEMENT_EDIT")) setpermission("edit");
    else if (findEdit.includes("ASSET_MANAGEMENT_READ")) setpermission("read");
    else setpermission(null);
  }

  useEffect(() => {
    getPermission();
    isLogin();

    assetdetail(AssetPartnerDetail); // Call the fetchData function
  }, []);

  const programVideos = contentArray;
  const promosVideos = promos;
  const fillersVideos = contentArray.filter(
    (item) => item.assetType === "VIDEO"
  );
  const PostersData = contentArray.filter(
    (item) => item.assetType === "POSTERS"
  );
  const SecondaryData = contentArray.filter(
    (item) => item.assetType === "SECONDARY"
  );
  const MetafileData = Metafile;

  const [programVid, setprogramVid] = useState(programVideos);

  const handlecategoryTab = (tab) => {
    setpartition(false);
    setCategoryTab(tab);

    if (tab == "POSTERS") {
      setposterpartion(true);
    } else {
      setposterpartion(false);
    }
    setAssetSignledata(null);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab == "PROGRAM") {
      setprogramVid(programVideos);
      setuploadURL(CMS_Multipart_upload);
    } else if (tab == "FILLER") {
      setprogramVid(filler);
      setuploadURL(CMS_Multipart_Fillers);
    } else if (tab == "PROMO") {
      setuploadURL(CMS_Multipart_PROMOS);
      setprogramVid(promosVideos);
    }
  };

  const opendata = (data, bool, e) => {
    e.stopPropagation();
    setpartition(true);
    setAssetSignledata(data);
    setpostermeta(bool);
  };

  const onArchiveClick = async (e) => {
    e.stopPropagation();
  };

  const assetDeleteConfirm = (assetid, e) => {
    e.stopPropagation();
    deletepopup(e, assetid, 'asset');
  }
  const assetdelete = async (assetid, e) => {
    e.stopPropagation();
    setpartition(false);
    const deleteasset = await Asset_Delete(assetid);

    if (deleteasset == true) {
      assetdetail(AssetPartnerDetail);
      toast.success("Video deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const fillerDeleteConfirm = (assetid, e) => {
    e.stopPropagation();
    deletepopup(e, assetid, 'filler');
  }
  const fillerdelete = async (assetid) => {
    setpartition(false);
    const deleteasset = await Filler_delete(assetid);

    if (deleteasset.status == 200) {
      assetdetail(AssetPartnerDetail);
      toast.success("Filler deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const promoDeleteConfirm = (assetid, e) => {
    e.stopPropagation();
    deletepopup(e, assetid, 'promo');
  }
  const promodelete = async (assetid) => {
    setpartition(false);
    const deleteasset = await Promo_delete(assetid);

    if (deleteasset.status == 200) {
      assetdetail(AssetPartnerDetail);
      toast.success("Promo deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const assetdetail = async (AssetPartnerDetail) => {
    const params = {
      partnerId: AssetPartnerDetail?.id,
    };
    const partid = {
      partnerId: AssetPartnerDetail?.id,
      pageSize: "1000",
    };

    const AssetDetail = await ProgramsList_By_Partner(partid);
    const Fillerlisting = await FillerList(partid);
    const Promolisting = await PromosList_By_Partner(partid);
    const MetaDatalisting = await MetafileList_By_Partner(params);

    if (AssetDetail.status == true) {
      localStorage.setItem("AssetDetail", JSON.stringify(AssetDetail?.data));

      setContentArray(JSON.parse(localStorage.getItem("AssetDetail"))?.content);
    }
    if (Fillerlisting?.status == true) {
      setfiller(Fillerlisting?.data.content);
    }
    if (Promolisting?.status == true) {
      setPromos(Promolisting?.data.content);
    }
    if (MetaDatalisting?.status == true) {
      setMetafile(MetaDatalisting?.data);
    }
    // dispatch(AssetDetailAction(AssetDetail?.data))
    if (assetSignledata != null) {
      const filteredItems = AssetDetail?.data?.content.filter(
        (item) => item.id == assetSignledata?.id
      );

      setAssetSignledata(filteredItems[0]);
    }
    // setFilteredData(filteredItems);
  };
  const [showModal, setShowModal] = useState(false);
  const [assetsingledata, setAssetsingledata] = useState(null);

  const handleShow = (data) => {
    setAssetsingledata(data);
    if (assetsingledata !== null) {
      setShowModal(true);
    }
  };
  const bulkEdit = () => {
    navigate("/meta-edit", {
      state: {
        id: AssetPartnerDetail.id,
        name: AssetPartnerDetail.name,
      },
    });
  };

  const metaEditView = () => {
    navigate("/meta-edit");
  };

  const postertab = (data) => {
    setAssetSignledata(data);
  };
  const deleteallposter = async (event, id) => {
    errorboxclose();
    event.stopPropagation();
    const response = await DeleteallPoster(id);
    if (response.data.status == true) {
      setTimeout(() => {
        assetdetail(AssetPartnerDetail);
      }, 1000);

      toast.success("Posters deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else {
      toast.error(
        response.data.message
          ? response.data.message
          : "Something went wrong, please try after sometime",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        }
      );
    }
  };

  const databymeta = (data) => {
    setAssetSignledata(data);
  };
  const [expandMeta, setExpandMeta] = useState(true);
  const [ismetadata, setIsmetadata] = useState(true);
  const expandMetaView = () => {
    setExpandMeta(false);
    setIsmetadata(true);
  };

  // let clickTimeout;

  // const handleDoubleClick = () => {
  //     // Add your double-click functionality here
  //     // For example:
  //     console.log('Button double-clicked');
  // };

  // function handleClick() {
  //     if (!clickTimeout) {
  //       // This executes on single click
  //       clickTimeout = setTimeout(() => {
  //         clickTimeout = null;
  //         closepartition();
  //       }, 200); // Adjust the delay as needed
  //     } else {
  //       // This executes on double click
  //       clearTimeout(clickTimeout);
  //       clickTimeout = null;
  //     //   handleDoubleClick();
  //     }
  //   }

  const minmizemeta = () => {
    setExpandMeta(true);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState(null);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      AssetSearch(searchQuery);
    }
  };
  const AssetSearch = async (searchQuery) => {
    const data = {
      id: AssetPartnerDetail?.id,
      query: searchQuery,
    };
    setSearchQuery(data.query);
    const partnerDataSearch = await Asset_search(data);

    setSearchData(partnerDataSearch.data);

    setExpandMeta(false);
    setIsmetadata(true);
    setIsmetadata(false);
  };

  const errorboxclose = () => {
    setUploadFile([]);
    assetdetail(AssetPartnerDetail);
    setErrorMessage("");
    setErrorbox(false);
  };
  // const [errorbox, setErrorbox] = useState(false);
  // const [errorMssage, setErrorMssage] = useState("");

  const deleteMetafile = async (event, id) => {
    errorboxclose();
    event.stopPropagation();

    const response = await Metafile_delete(id);

    if (response.data.status == true) {
      setTimeout(() => {
        assetdetail(AssetPartnerDetail);
      }, 1000);
      toast.success("Metafile deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } else if (response.data.status == false) {
      toast.error(
        response.data.message
          ? response.data.message
          : "Something went wrong, please try after sometime",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        }
      );
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const deletepopup = (event, id, method = '') => {
    event.stopPropagation();
    setbtntext("Delete");
    setErrorMessage("Do you want to delete ?");
    setErrorbox(true);
    switch (method) {
      case 'asset':
        seteventdelete(() => () => assetdelete(id, event));
        break;
      case 'fillerdelete':
        seteventdelete(() => () => fillerdelete(id));
        break;
      case 'promodelete':
        seteventdelete(() => () => promodelete(id));
        break;
      default:
        seteventdelete(() => () => deleteallposter(event, id));
    }
  };
  const deletemetafilepopup = (event, id) => {
    event.stopPropagation();
    setbtntext("Delete");
    setErrorMessage("Do you want to delete ?");
    setErrorbox(true);
    seteventdelete(() => () => deleteMetafile(event, id));
  };

  const openplayerdata = (data) => {
    if (assetCode?.code === "pktflm") {
      const url = data?.metadata?.contentPlaybackUrl;
      const videoUrl = url.split('?token=');
      const token = videoUrl[1].split("&token_path=%");
      const tokenPath = token[1].split("&expires=");
      const expires = tokenPath[1];
      function hex_to_ascii(str1) {
        var hex = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
          str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
      }
      setAssetSignledata(data);
      setVideoUrl(videoUrl[0]);
      setToken(token[0]);
      setTokenPath(hex_to_ascii(tokenPath[0]));
      setExpires(expires);
      setShowHlsplayer(true);
    } else {
      if (data?.isPlaceHolder) {
        setAssetSignledata(data);
        setshowplayer(true);
      }
    }
  };

  const closeplayer = () => {
    setShowHlsplayer(false);
    setshowplayer(false);
  };

  const syncPocketFilms = async () => {
    const res = await GetPocketFilms(AssetPartnerDetail?.id);
    if (res?.status === 200) {
      toast.success("Sync Completed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      handleTabClick("PROGRAM");
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  }

  return (
    permission !== null ?
      <div className={`content-body channel-detail ${appTheme}`}>
        {showplayer ? (
          <Videoplayer
            closepartition={() => closeplayer()}
            videodata={assetSignledata}
            assetdetail={() => assetdetail(AssetPartnerDetail)}
            programVideos={programVid}
            databymeta={databymeta}
          />
        ) : showHlsplayer ? (
          <VideoPlayerPktFlm
            closepartition={() => closeplayer()}
            videodata={assetSignledata}
            programVideos={programVid}
            src={videoUrl}
            token={token}
            tokenPath={tokenPath}
            expires={expires}
            databymeta={openplayerdata}
          />
        ) : expandMeta ? (
          <div className="dashboard-content">
            <div className="top-content">
              <div className="left-side">
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <Link to="/asset">Asset Management</Link>
                    </li>
                    <li className="active">{AssetPartnerDetail?.name}</li>
                  </ul>
                </div>
              </div>
              <div className="right-side">
                <div className="icon-list">
                  <div className="search-box " >
                    <input
                      className="border-0 p-2 pr-2"
                      type="text"
                      placeholder="Search...."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyPress={handleKeyPress}
                      style={{
                        flex: 1, 
                     
                      }}
                    />
                    <img
                      src={icroundsearch}
                      alt="Search"
                      style={{
                        height: '27px',
                        width: '27px',
                        cursor: 'pointer',
                        filter: 'brightness(0) saturate(100%) invert(21%) sepia(90%) saturate(5497%) hue-rotate(0deg) brightness(95%) contrast(108%)',

                      }}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div className="channel-category">
              <ul className="channel-category-tab">
                <li className={categoryTab === "VIDEO" ? "active" : ""}>
                  <a onClick={() => handlecategoryTab("VIDEO")}>Video</a>
                </li>
                <li className={categoryTab === "POSTERS" ? "active" : ""}>
                  <a onClick={() => handlecategoryTab("POSTERS")}>Posters</a>
                </li>
                <li className={categoryTab === "TRAILER" ? "active" : ""}>
                  <a onClick={() => { handlecategoryTab("TRAILER"); setActiveTab("TEASERS"); }}>Trailer</a>
                </li>
                <li className={categoryTab === "METAFILE" ? "active" : ""}>
                  <a onClick={() => handlecategoryTab("METAFILE")}>Metafile</a>
                </li>
              </ul>
              <ul>
                <li className="bulk-edit">
                  <button
                    onClick={bulkEdit}
                    className="default-odd border-0 btn-light-red-ml-auto mr-0"
                  >
                    Meta Edit
                  </button>

                </li>
                <Link
                  to="/channel-actions"
                  className="default-odd border-0 btn-light-red-ml-auto mr-0"
                >
                  Actions
                </Link>
              </ul>
            </div>
            <div className="row  mt-2 " >
              <div className={partition || posterpartion ? "col-8" : "col-12"}>
                {categoryTab === "VIDEO" && (
                  <div className="channel-content" >
                    <div className="channel-top">
                      <div className="left-side">
                        <ul className="tabs">
                          <li
                            className={`tab-a ${activeTab === "PROGRAM" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("PROGRAM")}
                          >
                            Program
                          </li>
                          <li
                            className={`tab-a ${activeTab === "PROMO" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("PROMO")}
                          >
                            Promos
                          </li>
                          <li
                            className={`tab-a ${activeTab === "FILLER" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("FILLER")}
                          >
                            Fillers
                          </li>
                        </ul>
                      </div>
                      {
                        permission === "edit" ?
                          <div className="right-side browse-btns">
                            {
                              assetCode?.code === "pktflm" || assetCode?.code === "nhstdz" ?
                                <>
                                  {
                                    assetCode?.code === "pktflm" ?
                                      <a
                                        className="mx-2"
                                        onClick={() =>
                                          syncPocketFilms(true)
                                        }
                                      >
                                        <img src={refresh} height={25} width={25} />
                                      </a>
                                      :
                                      ""
                                  }
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    accept=".mp4,.mov"
                                    multiple
                                    onChange={(e) => handleMultipartFileChange(e, false)}
                                  />
                                  <a
                                    className="mx-2"
                                    onClick={() =>
                                      document.getElementById("fileInput").click()
                                    }
                                  >
                                    <img src={uploadicon} />
                                  </a>
                                </>
                                :
                                <>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    accept=".mp4,.mov"
                                    multiple
                                    onChange={(e) => handleMultipartFileChange(e, false)}
                                  />
                                  {/* <a
                                className="mx-2"
                                onClick={() =>
                                  setShowPopup(true)
                                }
                              >
                                <img src={browseicon} />
                              </a> */}
                                  <a
                                    className="mx-2"
                                    onClick={() =>
                                      document.getElementById("fileInput").click()
                                    }
                                  >
                                    <img src={uploadicon} />
                                  </a>
                                </>
                            }
                          </div>
                          :
                          ''
                      }
                    </div>
                    <div
                    
                    className="content-wrapper"
                    >
                      <div
                        id="tab-1"
                        className={`tab-content ${activeTab === "PROGRAM" ? "active" : ""
                          }`}
                      >
                        <div
                         className="video-channel-list part-heights">
                          {programVideos &&
                            programVideos.map((data) => (
                              <VideoBlock
                            
                                assetSignledata={assetSignledata}
                                key={data.id} // Add a unique key
                                data={data}
                                onVideoClick={opendata}
                                onUploadPosterClick={opendata}
                                onDeleteClick={assetDeleteConfirm}
                                onArchiveClick={onArchiveClick}
                                onUploadPromos={uplaodassetposter}
                                image_url={image_url}
                                openplayercomp={openplayerdata}
                                permission={permission}
                              />
                            ))}
                          {uploadFile?.length > 0 &&
                            uploadFile.map((data) => (
                              <div
                              
                              className="block upload" key={data.name}>
                                <div className="uploading">
                                  <svg
                                    width="100"
                                    height="80"
                                    viewBox="0 -10 40 60"
                                  >
                                    <circle
                                      cx="20"
                                      cy="20"
                                      r="18"
                                      fill="none"
                                      stroke="#e6e6e6"
                                      stroke-width="2"
                                    />
                                    <circle
                                      className="percent fifty"
                                      cx="20"
                                      cy="20"
                                      r="18"
                                      fill="none"
                                      stroke="#D22F26"
                                      stroke-width="2"
                                      pathLength="100"
                                      style={{
                                        transition: "all 0.3s",
                                        strokeDasharray: 100,
                                        strokeDashoffset: `calc(100 - ${data?.newUpload?.percentage})`,
                                      }}
                                    />
                                  </svg>
                                  <div
                                  
                                  className="percentage">
                                    {data?.newUpload?.percentage}
                                    <span>%</span>
                                  </div>
                                </div>
                                <div className="img-block ">
                                  <img src={Groupicon} alt="" />
                                </div>
                                <div className="text-block">
                                  <label>{data?.newUpload?.name}</label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div
                      
                        id="tab-2"
                        className={`tab-content ${activeTab === "PROMO" ? "active" : ""
                          }`}
                      >
                        <div
                         className="video-channel-list part-heights">
                          {promosVideos &&
                            promosVideos.map((data, index) => {
                              return (
                                <div
                                style={{
                                  border: "1px solid",
                                  borderImage: "linear-gradient(56deg,#FFFFFF29,#FFFFFF14,#FFFFFF29) 1",
  
                                  borderRadius: "6px",
                                }}
                                  className="block"
                                  onDoubleClick={() => {
                                    openplayerdata(data);
                                  }}
                                  key={index}
                                >
                                  <div className="img-block ">
                                    <img src={Groupicon} alt="" />
                                  </div>

                                  <div className="text-block">
                                    <label>{data?.filename}</label>
                                  </div>
                                  <div className="dropdown">
                                    <a className="dropdown-button">
                                      <img src={option} />
                                    </a>
                                    <div className="dropdown-content">
                                      <a
                                        className="delete-button"
                                        onClick={(e) => promoDeleteConfirm(data.id, e)}
                                      >
                                        <span className="img-box">
                                          <img src={deleteicon} />
                                        </span>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          {uploadFile?.length > 0 &&
                            uploadFile.map((data) => {
                              return (
                                <div className="block upload" key={data.name}>
                                  <div className="uploading">
                                    <svg
                                      width="100"
                                      height="80"
                                      viewBox="0 -10 40 60"
                                    >
                                      <circle
                                        cx="20"
                                        cy="20"
                                        r="18"
                                        fill="none"
                                        stroke="#e6e6e6"
                                        stroke-width="2"
                                      />
                                      <circle
                                        className="percent fifty"
                                        cx="20"
                                        cy="20"
                                        r="18"
                                        fill="none"
                                        stroke="#D22F26"
                                        stroke-width="2"
                                        pathLength="100"
                                        style={{
                                          transition: "all 0.3s",
                                          strokeDasharray: 100,
                                          strokeDashoffset: `calc(100 - ${data?.newUpload?.percentage})`,
                                        }}
                                      />
                                    </svg>
                                    <div className="percentage">
                                      {data?.newUpload?.percentage}
                                      <span>%</span>
                                    </div>
                                  </div>
                                  <div className="img-block ">
                                    <img src={Groupicon} alt="" />
                                  </div>
                                  <div className="text-block">
                                    <label>{data?.newUpload?.name}</label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div
                        id="tab-3"
                        className={` tab-content ${activeTab === "FILLER" ? "active" : ""
                          }`}
                      >
                        <div className="video-channel-list part-heights">
                          {filler &&
                            filler?.map((data, index) => {
                              return (
                                <div
                                style={{
                                  background: `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #0F3B47, #36D2FF, #0F3B47) border-box`,
                                  borderRadius: "6px",
                                  border: "1px solid transparent",
                                  padding: "5px",
                                  transition: " all 0.3s ",
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box`;
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right,  #0F3B47, #36D2FF, #0F3B47) border-box`;
                                }}
                                  key={index}
                                  className="block"
                                  onDoubleClick={() => openplayerdata(data)}
                                >
                                  <div className="img-block ">
                                    <img src={Groupiconblue} alt="" />
                                  </div>

                                  <div className="text-block">
                                    <label>{data.filename}</label>
                                  </div>
                                  {
                                    permission === "edit" ?
                                      <div className="dropdown">
                                        <a className="dropdown-button">
                                          <img src={option} />
                                        </a>
                                        <div className="dropdown-content">
                                          <a
                                            className="delete-button"
                                            onClick={(e) => fillerDeleteConfirm(data.id, e)}
                                          >
                                            <span className="img-box">
                                              <img src={deleteicon} />
                                            </span>
                                            Delete
                                          </a>
                                        </div>
                                      </div>
                                      :
                                      ''
                                  }
                                </div>
                              );
                            })}
                          {uploadFile?.length > 0 &&
                            uploadFile.map((data) => (
                              <div className="block upload" key={data.name}>
                                <div className="uploading">
                                  <svg
                                    width="100"
                                    height="80"
                                    viewBox="0 -10 40 60"
                                  >
                                    <circle
                                      cx="20"
                                      cy="20"
                                      r="18"
                                      fill="none"
                                      stroke="#e6e6e6"
                                      stroke-width="2"
                                    />
                                    <circle
                                      className="percent fifty"
                                      cx="20"
                                      cy="20"
                                      r="18"
                                      fill="none"
                                      stroke="#D22F26"
                                      stroke-width="2"
                                      pathLength="100"
                                      style={{
                                        transition: "all 0.3s",
                                        strokeDasharray: 100,
                                        strokeDashoffset: `calc(100 - ${data?.newUpload?.percentage})`,
                                      }}
                                    />
                                  </svg>
                                  <div className="percentage">
                                    {data?.newUpload?.percentage}
                                    <span>%</span>
                                  </div>
                                </div>
                                <div className="img-block ">
                                  <img src={Groupicon} alt="" />
                                </div>
                                <div className="text-block">
                                  <label>{data?.newUpload?.name}</label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {categoryTab === "POSTERS" && (
                  <div className="channel-content">
                    <div className="channel-top">
                      <div className="left-side"></div>
                      {
                        permission === "edit" ?
                          <div className="right-side">
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={(e) => handleFileChange(e, false)}
                            />
                            <a
                              className="mx-2"
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                            >
                              <img src={uploadicon} />
                            </a>
                          </div>
                          :
                          ''
                      }
                    </div>
                    <div className="channel-content">
                      <div className="video-channel-list part-heights">
                        {programVideos &&
                          programVideos.map((data) => {
                            let image = posterimage(data);
                            return (
                              <div
                              
                                style={{
                                  background: `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #0F3B47, #36D2FF, #0F3B47) border-box`,
                                  borderRadius: "6px",
                                  border: "1px solid transparent",
                                  padding: "5px",
                                  transition: " all 0.3s ",
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box`;
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right,  #0F3B47, #36D2FF, #0F3B47) border-box`;
                                }}
                                className={`block ${data?.id == assetSignledata?.id ? "active" : ""
                                  }`}
                                onClick={() => postertab(data)}
                              >
                                {image == "" ? (
                                  <div className="img-block ">
                                    <img src={Groupiconblue} alt="" />
                                  </div>
                                ) : (
                                  <div
                                    className="img-block p-0"
                                    style={{ overflow: "hidden" }}

                                  >
                                    <img
                                      style={{ width: "100%", height: "auto" }}
                                      src={image}
                                      alt=""
                                    />
                                  </div>
                                )}
                                <div className="text-block">
                                  <label>
                                    {data?.metadata == null
                                      ? data.filename
                                      : data?.metadata?.title == null
                                        ? data.filename
                                        : data?.metadata?.title}
                                  </label>
                                </div>
                                {
                                  permission === "edit" ?
                                    <div className="dropdown">
                                      <a
                                        onClick={(e) => deletepopup(e, data.id)}
                                        className="delete-poster "
                                      >
                                       
                                        <img src={deleteicon} />
                                        
                                        
                                      </a>
                                    </div>
                                    :
                                    ''
                                }
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
                {categoryTab === "TRAILER" && (
                  <div className="channel-content">
                    <div className="channel-top">
                      <div className="left-side">
                        <ul className="tabs">
                          <li
                            className={`tab-a ${activeTab === "TEASERS" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("TEASERS")}
                          >
                            Teasers
                          </li>
                          <li
                            className={`tab-a ${activeTab === "SONGS" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("SONGS")}
                          >
                            Songs
                          </li>
                          <li
                            className={`tab-a ${activeTab === "BehindTheScene" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("BehindTheScene")}
                          >
                            Behind The Scene
                          </li>
                          <li
                            className={`tab-a ${activeTab === "PROMOTIONS" ? "active" : ""
                              }`}
                            onClick={() => handleTabClick("PROMOTIONS")}
                          >
                            Promotions
                          </li>
                        </ul>
                      </div>
                      {
                        permission === "edit" ?
                          <div className="right-side">
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              accept=".mp4,.mov"
                              multiple
                              onChange={(e) => handleMultipartFileChange(e, false)}
                            />
                            <a
                              className="mx-2"
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                            >
                              <img src={uploadicon} />
                            </a>
                          </div>
                          :
                          ''
                      }
                    </div>
                  </div>
                )}
                {categoryTab === "SECONDARY" && (
                  <div className="channel-content">
                    <div className="channel-top">
                      <div className="left-side"></div>
                      {
                        permission === "edit" ?
                          <div className="right-side">
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={(e) => handleFileChange(e, false)}
                            />
                            <a
                              className="mx-2"
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                            >
                              <img src={uploadicon} />
                            </a>
                          </div>
                          :
                          ''
                      }
                    </div>
                    <div
                

                    className="content-wrapper">
                      <div className="video-channel-list part-heights">
                        {SecondaryData &&
                          SecondaryData.map(() => {
                            return (
                              <div
                              style={{
                                border: "1px solid",
                                borderImage: "linear-gradient(56deg,#FFFFFF29,#FFFFFF14,#FFFFFF29) 1",
          
                                borderRadius: "6px",
                              }}
                              className="block">
                                <img src={video_icon} alt="" />
                                <label>Video 1</label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
                {categoryTab === "METAFILE" && (
                  <div className="channel-content">
                    <div className="channel-top">
                      <div className="left-side"></div>
                      {
                        permission === "edit" ?
                          <div className="right-side">
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              accept=".xls, .xlsx, .csv"
                              onChange={(e) => handleFileChange(e, false)}
                            />
                            <a
                              className="mx-2"
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                            >
                              <img src={uploadicon} />
                            </a>
                          </div>
                          :
                          ""
                      }
                    </div>
                    <div className="video-channel-list part-heights">
  {MetafileData &&
    MetafileData.map((data) => {
      return (
        <div
          style={{
            background: `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #0F3B47, #36D2FF, #0F3B47) border-box`,
            borderRadius: "6px",
            border: "1px solid transparent",
            padding: "5px",
            transition: "all 0.3s",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box`;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #0F3B47, #36D2FF, #0F3B47) border-box`;
          }}
          className="block"
          onClick={metaEditView}
        >
          <div className="img-block">
            <img src={metafile} alt="" />
          </div>

          <div className="text-block">
            <label className="text-label">
              {data?.metadata == null
                ? data.filename
                : data?.metadata?.title == null
                ? data.filename
                : data?.metadata?.title}
            </label>
          </div>

          {permission === "edit" ? (
            <div className="dropdown">
              <a
                onClick={(e) => deletemetafilepopup(e, data.id)}
                className="delete-poster"
              >
                <img src={deleteicon} />
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    })}
</div>

                  </div>
                )}
              </div>
              {errorbox && (
                <ErrorPopup
                  btntext={btntext}
                  message={errorMssage}
                  closebox={() => errorboxclose()}
                  overrides={() => eventdelete()}
                />
              )}
              {partition &&
                (postermeta ? (
                  <UploadPosters
                    videodata={assetSignledata}
                    assetdetail={() => assetdetail(AssetPartnerDetail)}
                    closepartition={() => setpartition(false)}
                    permission={permission}
                  />
                ) : (
                  <Partitioncontent
                    assetdetail={() => assetdetail(AssetPartnerDetail)}
                    expandMetaView={() => expandMetaView()}
                    partition={partition}
                    videodata={assetSignledata}
                    closepartition={() => setpartition(false)}
                    permission={permission}
                  />
                ))}

              {posterpartion && (
                <UploadPosters
                  videodata={assetSignledata}
                  assetdetail={() => assetdetail(AssetPartnerDetail)}
                  permission={permission}
                />
              )}
            </div>
          </div>
        ) : ismetadata ? (
          <MetaDataView
            closepartition={() => minmizemeta()}
            videodata={assetSignledata}
            assetdetail={() => assetdetail(AssetPartnerDetail)}
            programVideos={programVideos}
            databymeta={databymeta}
          />
        ) : (
          <SearchData
            videodata={assetSignledata}
            searchdata={searchData}
            AssetSearch={AssetSearch}
            assetdetail={assetdetail}
            searchQuerys={searchQuery}
          />
        )}
        <ToastContainer />
        <BrowsePopup
          show={showPopup}
          handleClose={closePopup}
          data={'data'}
          parentData={'data'}
          updateData={'data'}
        />
      </div>
      :
      ''
  );
}

export default CmsChannelDetail;
