export const addadsSqId = (
    data,
    millisecondsToHuman,
    setGridList,
) => {
    let startTime = 0;

    // Sort the programs based on startOffsetInMillis
    const sortedData = data?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis);

    const updatedGridList = sortedData?.map((program) => {
        // Calculate total duration from adBreaks
        const totalAdBreakDuration = program?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0;

        const endTime = startTime + program.durationInMillis + totalAdBreakDuration; // Add adBreaks duration

        // Sort the adBreaks based on startOffsetInMillis (if needed) and reassign adSqId
        let totalDuration = 0;
        let updatedAdBreaks = program?.adBreaks
            ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
            .map((ad, index) => ({
                ...ad,
                adSqId: index + 1, // Reassign incremental adSqId after sorting
            }));
            updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
                totalDuration += index === 0 ? 0 : updatedAdBreaks[index - 1]?.durationInMillis || 0;
                const startOffsetInMillis = ad.adInsertionPoint + totalDuration;
                const endOffsetInMillis = startOffsetInMillis + (ad.durationInMillis || 0);
    
                return {
                    ...ad,
                    startOffsetInMillis,
                    endOffsetInMillis,
                    startAt: millisecondsToHuman(startOffsetInMillis),
                    endAt: millisecondsToHuman(endOffsetInMillis),
                };
            });

        const updatedProgram = {
            ...program,
            adBreaks: updatedAdBreaks,
            scheduleStartTime: startTime,
            scheduleEndTime: endTime,
        };

        startTime = endTime; // Update startTime for the next program

        return updatedProgram;
    });

    setGridList(updatedGridList); // This will overwrite the gridList with updated values
};

export function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const addToDragabbleList = (data, gridList, setGridList) => {
    let listSize = gridList.length;
    const obj = { ...data, sequence: listSize + 1 };
    setGridList([...gridList, obj]);
};