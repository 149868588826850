import zoomInIcon from "../../../../assets/images/zoom-in.svg";
import zoomOutIcon from "../../../../assets/images/zoom-out.svg";

export const ZoomControls = ({ handleZoomIn, handleZoomOut }) => {
    return (
        <div class="d-flex flex-column align-items-center zoom col-2">
            <a class="btn btn-secondary btn-circle" onClick={handleZoomIn}>
                <img src={zoomInIcon} width={30}/>
            </a>
            <div class="vertical-line my-2"></div>
            <a class="btn btn-secondary btn-circle" onClick={handleZoomOut}>
                <img src={zoomOutIcon} width={30}/>
            </a>
        </div>
    )
}