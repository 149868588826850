
import React, { useState } from 'react';
import './activeUser.scss';
import plusicon from '../../../assets/svg/viewership/plusIcon.svg';
import minusicon from '../../../assets/svg/viewership/miusIcon.svg';
import AreaSelector from '../../../pages/viewership/dashboard-Map/AreaSelector';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../service/commonFunc';

function ActiveUser() {
  const [zoom, setZoom] = useState(1);
  const state = useSelector((state) => state);
  const MapDatastate = state?.MapData?.data?.data?.stateWiseActiveUsers;
  const channelName = state?.ChannelData?.data?.viewKey;

  const formatData = (data) => {
    const formattedData = [];
    for (const key in data) {
      const state = key.replace(':', '').trim();
      const population = parseFloat(data[key]) || 'N/A';
      formattedData.push({ state, population });
    }
    return formattedData;
  };

  // Sort the formatted data by population in descending order
  const sortedData = formatData(MapDatastate).sort(
    (a, b) => b.population - a.population
  );

  // Get the top 6 states
  const top6States = sortedData.slice(0, 6);
  // Get the remaining states after top 6
  const remainingStates = sortedData.slice(6, 12); // Next 6 states for 2 columns and 3 rows

  return (
    <>
      <div className="active-user-block">
        <div className="top">
          <div className="left-side">
            <h4>Active Users</h4>
            <p  style={{color:'#D9D9D9' , paddingTop:3}}>{channelName && channelName === "Total" ? "All Channels" : channelName}</p>
          </div>

          {/* <div className="zoom-map">
            <a onClick={() => setZoom(zoom + 0.2)} className="pulse">
              <img src={plusicon} alt="" />
            </a>
            <a onClick={() => setZoom(zoom - 0.2)} className="minus">
              <img src={minusicon} alt="" />
            </a>
          </div> */}
         <div className="zoom-map">
  <div >
    <a onClick={() => setZoom(zoom + 0.2)} className="pulse">
      <img src={plusicon} alt="Zoom In" />
    </a>
  </div>
  <div>
    <a onClick={() => setZoom(zoom - 0.2)} className="minus">
      <img src={minusicon} alt="Zoom Out" />
    </a>
  </div>
</div>


        </div>

        <div className="map-block">
          <AreaSelector zooms={zoom} />
        </div>

        <div className="top-state">
          <h6>Top 6 States Users</h6>
          <ul>
            {top6States.map(({ state, population }) => (
              <li key={state}>
                <span>{state}</span>
                <span>{formatNumber(population)}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Grid for the remaining users (2 columns, 3 rows) */}
        {/* <div className="remaining-states">
          <h6>Other Active States</h6>
          <div className="remaining-grid">
            {remainingStates.map(({ state, population }) => (
              <div className="grid-item" key={state}>
                <span>{state}</span>
                <span>{formatNumber(population)}</span>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ActiveUser;

