import React, { useEffect, useState } from 'react';
import "./sidebar.scss";
import stream from "../../assets/images/stream.svg";

import { useSelector } from 'react-redux';

import ReactHlsPlayer from 'react-hls-player';
const MonitorSidebar = () => {
  const [hlsUrl1, setHlsUrl1] = useState("");
  const [hlsUrl2, setHlsUrl2] = useState("");
  const [video1, setVideo1] = useState("");
  const [video2, setVideo2] = useState("");
  const [video3, setVideo3] = useState("");
  const getFileTypeFromUrl = (url) => {
    console.log('video1',video1?.assetInformation);
    
    if(url){
        const extension = url.split('.').pop().toLowerCase();
        if (extension === 'm3u8') {
            return 'HLS';
        } else {
            return 'OTHER';
        }

    }
  
  };
  
  const { CategoryDuration } = useSelector(state => ({ CategoryDuration: state.CategoryDuration?.data}));

  useEffect(() => {
    if(!CategoryDuration?.scheduleItems) return;
    // const video1 = CategoryDuration?.scheduleItems[0]?.assetPlaybackUrl || '';
    const video1st = CategoryDuration?.playbackUrl || '';
    const video2 = CategoryDuration?.scheduleItems[1]?.assetPlaybackUrl || '';
    setHlsUrl1(video1st);
    setHlsUrl2(video2);
    setVideo1(CategoryDuration?.scheduleItems[0]);
    setVideo2(CategoryDuration?.scheduleItems[1]);
    setVideo3(CategoryDuration?.scheduleItems[2]);
  }, [CategoryDuration]);

  return (
    <div className="sidebar-menu monitor-sidebar">
      <h3>Channel Name</h3>
      <div>
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Asset</th>
              <th>Played</th>
              <th>Yet to play</th>
            </tr>
          </thead>
          <tbody>
            {CategoryDuration?.playbackProgressData?.map((data, index) => (
              <tr key={index}>
                <td>{data?.assetTypeDisplayName}</td>
                <td>{data?.elapsedDuration}</td>
                <td>{data?.remainingDuration}</td>
              </tr>
            ))}
           
          </tbody>
        </table>
      </div>
      {hlsUrl1 && (
      <div className='monitor-video-player'>
        <div className='monitor-video-title div-flex-space-between'>
          <div><span className='text-danger'>Streaming Now</span> - {video1?.assetInformation}</div>
          {/* <div>Name of the asset playing</div> */}
        </div>  
        {getFileTypeFromUrl(hlsUrl1) === 'HLS' ? (
          <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
            <ReactHlsPlayer
              autoPlay={true}
              src={hlsUrl1}
              controls={true}
              // poster={video_not_available}
              width="100%"
              height="auto"
              style={{ maxWidth: "100%", maxHeight: "auto" }} // 16:9 aspect ratio
            />
            <div
              style={{
                position: "absolute",
                top: "-1px",
                right: "-3px",
                // backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "red",
                padding: "5px 10px",
                borderRadius: "5px",
                fontWeight: "bold",
                fontSize: "14px"
              }}
            >
              <img src={stream} width={20} />
            </div>
          </div>

          ) : ( 
            <video className='mp4player' id='video'controls 
//            autoPlay 
            src={hlsUrl1} style={{height:"auto"}}>
              
            </video>
          )}
          <div className='monitor-video-info'>
            <div className='div-flex-space-between'>
              <div>Time Elapsed</div>
              <div>{video1?.elapsedTime} / {video1?.duration}</div>
            </div>
            <div className='div-flex-space-between'>
              <div>Next in</div>
              <div>{(video2?.assetInformation) ? video2?.assetInformation : 'NA'}</div>
            </div>
            <div className='div-flex-space-between'>
              <div>Coming up in</div>
              <div>00:35:25</div>
            </div>
          </div>
      </div>)}
      {hlsUrl2 && (
      <div className='monitor-video-player'>
        <div className='monitor-video-title div-flex-space-between'>
          <div><span className='text-white'>Upcoming -</span> {video2?.assetInformation}</div>
          {/* <div>Name of the asset playing</div> */}
        </div>  
        {getFileTypeFromUrl(hlsUrl2) === 'HLS' ? (
          <ReactHlsPlayer
        //  autoPlay={true}
          src={hlsUrl2}
          controls={true}
          // poster={video_not_available}
          width="100%"
          height="auto"
          style={{ maxWidth: "100%", maxHeight: "auto" }} // 16:9 aspect ratio
        />

          ) : ( 
            <video className='mp4player' id='video'controls 
//            autoPlay
             src={hlsUrl2} style={{height:"auto"}}>
              
            </video>
          )}
          <div className='monitor-video-info'>
            <div className='div-flex-space-between'>
              <div>Time Elapsed</div>
              <div>{video2?.elapsedTime} / {video2?.duration}</div>
            </div>
            <div className='div-flex-space-between'>
              <div>Next in</div>
              <div>{(video3?.assetInformation) ? video3?.assetInformation : 'NA'}</div>
            </div>
            <div className='div-flex-space-between'>
              <div>Coming up in</div>
              <div>00:35:25</div>
            </div>
          </div>
      </div>
      )}
    </div>        
  );
};

export default MonitorSidebar;