import axios from "axios";
import {
  Devicesupdate_URL,
  Devicescreate_URL,
  AssetDelete_URL,
  AssetPartner_URL,
  Themecreate_URL,
  GetChannelSchedule,
  Themeupdate_URL,
  ThemeDelete_URL,
  AssetPoster_URL,
  Gettheme_URL,
  ScheduleSync_URL,
  AssetPublish_URL,
  AssetLogoAction_URL,
  AssetTranscode_URL,
  AssetValidate_URL,
  Assetoverride_URL,
  AssetsSearch_URL,
  DeletePoster_URL,
  DeleteallPoster_URL,
  Logout_URL,
  Metadataupdate_URL,
  Partnercreate_URL,
  Partnerdelete_URL,
  Partnerlist_URL,
  Partnersearch_URL,
  Partnerupdate_URL,
  ViewershipMap_URL,
  ViewershipTableChild_URL,
  ViewershipTablehead_URL,
  GetChannelList_URL,
  ActiveInactiveChannel_URL,
  Categories_URL,
  Get_Roles,
  GetChannelTypeList_URL,
  GetLangList_URL,
  AllCountries_URL,
  Genres_URL,
  MatuarityRatings_ListData_URL,
  Languages_list_URL,
  Devices_list_URL,
  Categoriecreate_URL,
  CategoriesDelete_URL,
  Categoriesupdate_URL,
  Genresupdate_URL,
  GenresDelete_URL,
  Genrescreate_URL,
  Languagesupdate_URL,
  Languagescreate_URL,
  LanguagesDelete_URL,
  MatuarityRatingscreate_URL,
  MatuarityRatingsupdate_URL,
  MatuarityRatingsDelete_URL,
  DevicesDelete_URL,
  FillerList_URL,
  Devicetype_URL,
  CreateChannel_URL,
  DeleteChannel_URL,
  Programs_listing_url,
  PromosList_URL,
  FillerDelete_URL,
  FillerListfilter_URL,
  PROMODelete_URL,
  Metadfile_listing_url,
  GetChannelById_URL,
  UpdateChannel_URL,
  GET_METADATA,
  language_List_URL,
  TerritoriesList_URL,
  GetScheduleChannelList,
  GetMonitorChannelList,
  GetDataSchedule,
  PutDataSchedule,
  GetThemeSequenceList_URL,
  GetGenreSequenceList_URL,
  GetCategorySequenceList_URL,
  GetLanguageSequenceList_URL,
  SetThemeRepositon_URL,
  SetGenreRepositon_URL,
  SetCategoryRepositon_URL,
  SetLanguageRepositon_URL,
  GetChannelSequenceList_URL,
  GetAvailableChannelList_URL,
  UpdateChannelSequenceList_URL,
  GetFeaturedChannelList_URL,
  UpdateFeaturedChannelList_URL,
  GetBannerList_URL,
  CreateBanner_URL,
  UpdateChannelFilter_URL,
  GetChannelFilterList_URL,
  GetSpotlightContentList_URL,
  GetAvailableSpotlightContentList_URL,
  UpdateSpotlightContentList_URL,
  browseS3,
  Timezone_URL,
  GetEpgHandlers,
  getDeviceType_URL,
  SetLangPriority,
  GetPocketFilms_URL,
  GetPeerGroups,
  GetUserGroup,
  CreateAvatar,
  GetAvatarList_URL,
  DeleteAvatar_URL,
  UpdateAvatar_URL,
  GetAvatarGender_URL,
  GetAuditLogs_URL,
  GeneratePartnerUrl_URL,
  GetPartnerUrls_URL,
  DeletePartnerUrl_URL,
  ProgramValidate_URL,
  AdValidate_URL,
  AllFillers_URL,
} from "../service/API_URL";

import {
  deleteInstantData,
  getInstantData,
  postData,
  postInstantData,
  putInstantData,
  postFormData,
} from "./apiMethod";
import { UUID_CODE, deviceType } from "../utility/deviceid";
import { logoutfunc } from "../service/commonFunc";
const refreshToken = localStorage.getItem("refreshToken");

// export const refreshTokenAPI = async () => {
//     let URL = RefreshToken_URL;
//     const data = {
//         "refreshToken": refreshToken
//     }
//     try {
//         const { data: { success, data: { accessToken }
//         } } = await axios.post(URL, data, {
//             headers: {
//                 "Access-Control-Allow-Origin": "*",
//             }
//         });

//         if (success) {
//             localStorage.setItem("accessToken", accessToken)
//             // await profileApi();
//         }
//     } catch (err) {
//         const { response: { data: { message } } } = err;
//         if (message == "Refresh token expired") {
//             window.location.href = "/login"
//         }
//     }

// };
export const refreshTokenAPI = async () => {
  logoutfunc();
};

export const ViewershipTablehead = async (data, setshowLoader) => {
  let URL = ViewershipTablehead_URL;
  setshowLoader(true);
  try {
    const response = await postInstantData(URL, data);
    //   console.log('POST response:', response);
    setshowLoader(false);
    return response;
  } catch (error) {
    setshowLoader(false);
    // alert("Something wents wrong, Please try again.");
  }
};

export const ViewershipTableChild = async (data, setshowLoader) => {
  let URL = ViewershipTableChild_URL;
  setshowLoader(true);
  try {
    const response = await postInstantData(URL, data);
    setshowLoader(false);
    return response;
  } catch (error) {
    setshowLoader(false);
    // alert("Something wents wrong, Please try again.");
  }
};

export const ViewershipMap = async (data) => {
  let URL = ViewershipMap_URL;

  try {
    const response = await postInstantData(URL, data);
    //   console.log('POST response:', response);
    return response;
  } catch (error) {
    // alert("Something wents wrong, Please try again.");
  }
};

export const Logout_Api = async () => {
  let URL = Logout_URL;
  const data = {
    deviceInfo: {
      deviceId: UUID_CODE(),
      deviceType: deviceType,
    },
  };

  try {
    const response = await postData(URL, data);
    setTimeout(() => {}, 1500);
    // return response;
  } catch (error) {
    window.location.href = "/login";
    // alert("Something wents wrong, Please try again.");
  }
};
export const Country_list = async () => {
  let URL = AllCountries_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};
export const TerritoriesList = async () => {
  let URL = TerritoriesList_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

export const All_Theme_list = async () => {
  let URL = Gettheme_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

export const FillerList_By_Partner = async (param) => {
  let URL = `${FillerList_URL}?partnerIds=${param}`;
  try {
    const response = await getInstantData(URL, {});
    return response;
  } catch (error) {}
};
export const FillerList = async (param) => {
  let URL = FillerListfilter_URL;
  let params = param;
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) {}
};
export const ProgramsList_By_Partner = async (param) => {
  let URL = Programs_listing_url;
  let params = param;
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) {
    return error;
  }
};
export const PromosList_By_Partner = async (param) => {
  let URL = PromosList_URL;
  let params = param;
  try {
    const response = await getInstantData(URL, { params });
    console.log("response",JSON.stringify(response))
    return response;
  } catch (error) {}
};

//asset Management
export const Theme_list = async (data) => {
  let URL = Gettheme_URL;
  // const data = {

  //   deviceInfo: {
  //     deviceId: UUID_CODE(),
  //     deviceType: deviceType,
  //   },
  //   "searchText": ""
  // };
  const params = {
    searchText: data?.searchText,
    pageSize: data?.pageSize,
    sortBy: data.sortBy,
    sortOrder: data.sortOrder,
    pageNumber: data?.pageNumber,
  };

  // console.log(params);
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {}
};

// Channel List
export const ListApiSync = async (id) => {
  let URL = ScheduleSync_URL + id + '/schedule/external/sync';

  try {
    const response = await postInstantData(URL);
    return response;
  } catch (error) {
    return false;
  }
}

// Channel List
export const GetScheduleList = async (queryParams, fetchDate = null) => {
  if(Array.isArray(fetchDate)) {
    return;
  }
  const date = new Date();
  let setDate = queryParams?.daysList - 1;
  let todayDate = new Date().toISOString().slice(0, 10);
  let endDate = new Date(date.setDate(date.getDate() + setDate)).toISOString().slice(0, 10);
  if(fetchDate){
    setDate = 2;
    todayDate = fetchDate.toISOString().slice(0, 10);
    endDate = fetchDate.toISOString().slice(0, 10);
  }
  const params = {
    startDate: todayDate,
    endDate: endDate,
    sortBy: queryParams?.sortBy,
    sortOrder: queryParams?.sortOrder,
    pageNumber: queryParams?.pageNumber,
    pageSize: queryParams?.pageSize,
    searchQuery: queryParams?.searchText,
  };
  const URL = `${GetScheduleChannelList}?startDate=2023-12-10&endDate=2023-12-16&sortBy=scheduleDate&sortOrder=asc&pageNumber=0&pageSize=15`;

  try {
    const response = await getInstantData(GetScheduleChannelList, { params });
    // const response = await getInstantData(URL)
    return response;
  } catch (error) {}
};

// Channel List
export const GetMonitorScheduleList = async (fetchDate = null) => {
  if(!fetchDate || Array.isArray(fetchDate)) {
    return;
  }
  const date = fetchDate.toISOString().slice(0, 10);
  
  const URL = `${GetMonitorChannelList}?query=${date}`;

  try {
    const response = await getInstantData(GetMonitorChannelList);
    // const response = await getInstantData(URL)
    return response;
  } catch (error) {}
};

// Channel Type List
export const ChannelType_list = async () => {
  let URL = GetChannelTypeList_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

// Get Schedule List
export const Schedules_list = async (id, type) => {
  let URL = `${GetChannelSchedule}?channelId=${id}&assetType=${type}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

// Get Schedule List
export const Schedules_Datalist = async (id, startDate, endDate) => {
  let URL = `${GetDataSchedule}/${id}/schedule/linear?startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

// Get Schedule List by Post Method
export const Schedules_Datalist_Ist = async (id, data) => {
  let URL = `${GetDataSchedule}/${id}/schedule/linear`;
  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Get Schedule List (old method) 
export const Schedules_Datalist_Linear_Old = async (id, startDate, endDate) => {
  let URL = `${GetDataSchedule}/${id}/schedule?startDate=${startDate}&endDate=${endDate}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};
// Get Schedule List Loop
export const Schedules_Datalist_Loop = async (id) => {
  let URL = `${GetDataSchedule}/${id}/schedule/loop`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

// Get Monitor List
export const Monitor_Datalist = async (id, date) => {
  let URL = `${GetMonitorChannelList}/${id}/schedule?date=${date}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

// Get Language List
export const Language_list = async () => {
  let URL = `${GetLangList_URL}?searchText`;
  const data = {
    deviceInfo: {
      deviceId: UUID_CODE(),
      deviceType: deviceType,
    },
  };

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

//asset Management
export const Partner_list = async () => {
  let URL = Partnerlist_URL;
  const data = {
    deviceInfo: {
      deviceId: UUID_CODE(),
      deviceType: deviceType,
    },
  };

  try {
    const response = await getInstantData(URL, data);

    return response;
  } catch (error) {
    // window.location.href = "/login"
    // alert("Something wents wrong, Please try again.");
  }
};
export const Partner_search = async (search) => {
  let URL = Partnersearch_URL;
  const data = {
    deviceInfo: {
      deviceId: UUID_CODE(),
      deviceType: deviceType,
    },
  };
  const params = {
    query: search,
  };

  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {
    // window.location.href = "/login"
    // alert("Something wents wrong, Please try again.");
  }
};

export const Partner_delete = async (param) => {
  let URL = Partnerdelete_URL + "/" + param;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const Partner_update = async (data, param) => {
  let URL = Partnerupdate_URL + "/" + param;

  try {
    const response = await postInstantData(URL, data);
    //   console.log('POST response:', response);
    return response;
  } catch (error) {
    // alert("Something wents wrong, Please try again.");
  }
};

export const Partner_create = async (data) => {
  let URL = Partnercreate_URL;

  try {
    const response = await postInstantData(URL, data);
    // console.log('POST response:', response);
    return response;
  } catch (error) {
    alert("Something wents wrong, Please try again.");
  }
};

export const Partner_archive = async (param) => {
  let URL = Partnerdelete_URL + "/" + param + "/archive";

  try {
    const response = await postInstantData(URL);
    return response;
  } catch (error) {
    return error;
    // alert("Something wents wrong, Please try again.");
  }
};
export const Asset_Detail = async (param) => {
  let URL = AssetPartner_URL;
  // console.log(param);

  let params = param;
  try {
    const response = await getInstantData(URL, { params });
    // console.log("response",response);
    return response;
  } catch (error) {
    // window.location.href = "/login"
    // alert("Something wents wrong, Please try again.");
  }
};

export const Asset_Delete = async (param) => {
  let URL = AssetDelete_URL + "/" + param;
  // console.log("response.status")
  try {
    const response = await deleteInstantData(URL);
    if (response.data.status == true) {
      // console.log("response.status",response.data.status == true)
      return response.data.status;
    } else {
      return "Something wents wrong, Please try again.";
    }
  } catch (error) {
    return "Something wents wrong, Please try again.";
    // alert("Something wents wrong, Please try again.");
  }
};

export const AssetTranscode = async (data) => {
  let URL = AssetTranscode_URL;

  try {
    const response = await postInstantData(URL, data);
    // console.log('POST response:', response);
    return response;
  } catch (error) {
    alert("Something wents wrong, Please try again.");
  }
};
export const AssetValidate = async (data) => {
  let URL = AssetValidate_URL;

  try {
    const response = await postInstantData(URL, data);
    // console.log('POST response:', response);
    return response;
  } catch (error) {
    alert("Something wents wrong, Please try again.");
  }
};
export const AssetPublish = async (data) => {
  let URL = AssetPublish_URL;

  try {
    const response = await postInstantData(URL, data);
    // console.log('POST response:', response);
    return response;
  } catch (error) {
    alert("Something wents wrong, Please try again.");
  }
};

export const AssetLogoAction = async (data) => {
  let URL = AssetLogoAction_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    alert("Asset Logo Action failed for all provided assets.");
    throw error;
  }
};

export const Assetoverride = async (data) => {
  let URL = Assetoverride_URL + "/" + data + "/override";

  try {
    const response = await postInstantData(URL);
    // console.log('POST response:', response);
    return response;
  } catch (error) {
    alert("Something wents wrong, Please try again.");
  }
};

export const Asset_Poster = async (id, param) => {
  let URL = AssetPoster_URL + id;
  // console.log(param);

  let params = param;
  try {
    const response = await postInstantData(URL, { params });
    return response;
  } catch (error) {
    // window.location.href = "/login"
    // alert("Something wents wrong, Please try again.");
  }
};
export const Metadataupdate = async (id, data) => {
  let URL = Metadataupdate_URL + "/" + id;
  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    // window.location.href = "/login"
    // alert("Something wents wrong, Please try again.");
  }
};
export const MetadataAdd = async (data) => {
  let URL = Metadataupdate_URL;
  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const DeletePoster = async (id, data) => {
  let URL = DeletePoster_URL + "/" + id;
  let params = {
    posterType: data,
  };
  try {
    const response = await deleteInstantData(URL, { params });
    return response;
  } catch (error) {
    return error;
    // alert("Something went wrong, Please try again.");
  }
};

export const DeleteallPoster = async (id) => {
  let URL = DeleteallPoster_URL + "/" + id + "/posters";

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
    // alert("Something wents wrong, Please try again.");
  }
};
export const Asset_search = async (search) => {
  let URL = AssetsSearch_URL;
  const data = {
    deviceInfo: {
      deviceId: UUID_CODE(),
      deviceType: deviceType,
    },
  };
  const params = {
    partnerId: search.id,
    searchQuery: search.query,
  };

  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {
    return error;
    // window.location.href = "/login"
    // alert("Something wents wrong, Please try again.");
  }
};

export const ViewChannelList = async (setshowLoader, queryParam) => {
  let URL = `${GetChannelList_URL}?searchText=${queryParam.searchText}&pageNumber=${queryParam.pageNumber}&pageSize=${queryParam.pageSize}&sortBy=${queryParam.sortBy}&sortOrder=${queryParam.sortOrder}`;
  setshowLoader(true);
  try {
    const response = await getInstantData(URL, { queryParam });
    setshowLoader(false);
    return response;
  } catch (error) {
    setshowLoader(false);
  }
};

export const GetChannelById = async (id) => {
  let URL = `${GetChannelById_URL}/${id}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return false;
    // setshowLoader(false);
  }
};

export const Channel_create = async (data) => {
  let URL = CreateChannel_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return error;
    //alert("Something wents wrong, Please try again.");
  }
};

export const Channel_update = async (data) => {
  let URL = UpdateChannel_URL;

  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const Schedule_update = async (data, channelId) => {
  const URL = `${GetDataSchedule}/${channelId}/schedule`;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
  }
};

export const Schedule_update_weekly = async (data, channelId) => {
  const URL = `${GetDataSchedule}/${channelId}/weekly-schedule`;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
  }
};

export const ScheduleLoop_update = async (data, channelId) => {
  const URL = `${GetDataSchedule}/${channelId}/schedule/loop`;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
  }
};

export const ChannelDelete = async (param) => {
  let URL = DeleteChannel_URL + "/" + param;
  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const SetActiveInactiveChanel = async (channelId, value) => {
  let URL = `${ActiveInactiveChannel_URL}?channelId=${channelId}&activeValue=${value}`;
  let data = {};
  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const Theme_create = async (data) => {
  let URL = Themecreate_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};

export const Theme_update = async (data, param) => {
  let URL = Themeupdate_URL;
  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};

export const Theme_delete = async (param) => {
  let URL = ThemeDelete_URL + "/" + param;
  const response = await deleteInstantData(URL);
  return response;
};

export const Categories_list = async (data) => {
  let URL = Categories_URL;

  const params = {
    searchText: data?.searchText,
    sortBy: data.sortBy,
    sortOrder: data.sortOrder,
    pageSize: data?.pageSize,
    pageNumber: data?.pageNumber,
  };
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {}
};
export const All_Categories_list = async () => {
  let URL = Categories_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};
export const Roles_list = async () => {
  const URL = Get_Roles;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

export const Categories_create = async (data) => {
  let URL = Categoriecreate_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const Categories_delete = async (param) => {
  let URL = CategoriesDelete_URL + "/" + param;
  const response = await deleteInstantData(URL);
  return response;
};
export const Categories_update = async (data) => {
  let URL = Categoriesupdate_URL;
  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};

export const Timezone_list = async () => {
  let URL = Timezone_URL;
  try {
    const response = await getInstantData(URL);

    return response;
  } catch (error) {}
};
export const Genres_list = async (data) => {
  let URL = Genres_URL;
  // const data = {

  //   deviceInfo: {
  //     deviceId: UUID_CODE(),
  //     deviceType: deviceType,
  //   },
  //   "searchText": ""
  // };
  const params = {
    searchText: data?.searchText,
    pageSize: data?.pageSize,
    sortBy: data.sortBy,
    sortOrder: data.sortOrder,
    pageNumber: data?.pageNumber,
  };
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {}
};
export const Genres_create = async (data) => {
  let URL = Genrescreate_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const Genres_delete = async (param) => {
  let URL = GenresDelete_URL + "/" + param;
  const response = await deleteInstantData(URL);
  return response;
};
export const Genres_update = async (data) => {
  let URL = Genresupdate_URL;
  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};

export const Languages_list = async (data) => {
  let URL = Languages_list_URL;
  // const data = {

  //   deviceInfo: {
  //     deviceId: UUID_CODE(),
  //     deviceType: deviceType,
  //   },
  //   "searchText": ""
  // };
  const params = {
    searchText: data?.searchText,
    pageSize: data?.pageSize,
    sortBy: data.sortBy,
    sortOrder: data.sortOrder,
    pageNumber: data?.pageNumber,
  };
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {}
};
export const Languages_create = async (data) => {
  let URL = Languagescreate_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const Languages_delete = async (param) => {
  let URL = LanguagesDelete_URL + "/" + param;
  const response = await deleteInstantData(URL);
  return response;
};
export const Languages_update = async (data) => {
  let URL = Languagesupdate_URL;
  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;

    alert("Something wents wrong, Please try again.");
  }
};

export const MatuarityRatings_ListData_list = async (data) => {
  let URL = MatuarityRatings_ListData_URL;
  // const data = {

  //   deviceInfo: {
  //     deviceId: UUID_CODE(),
  //     deviceType: deviceType,
  //   },
  //   "searchText": ""
  // };
  const params = {
    searchText: data?.searchText,
    pageSize: data?.pageSize,
    sortBy: data.sortBy,
    sortOrder: data.sortOrder,
    pageNumber: data?.pageNumber,
  };
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {
    return false;
  }
};
export const MatuarityRatings_create = async (data) => {
  let URL = MatuarityRatingscreate_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const MatuarityRatings_update = async (data) => {
  let URL = MatuarityRatingsupdate_URL;

  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const MatuarityRatings_delete = async (param) => {
  let URL = MatuarityRatingsDelete_URL + "/" + param;
  const response = await deleteInstantData(URL);
  return response;
};

export const Devices_list = async (data) => {
  let URL = Devices_list_URL;

  const params = {
    searchText: data?.searchText,
    pageSize: data?.pageSize,
    sortBy: data.sortBy,
    sortOrder: data.sortOrder,
    pageNumber: data?.pageNumber,
  };
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {}
};
export const Devices_create = async (data) => {
  let URL = Devicescreate_URL;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const Devices_update = async (data) => {
  let URL = Devicesupdate_URL;

  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};
export const Devices_delete = async (param) => {
  let URL = DevicesDelete_URL + "/" + param;
  const response = await deleteInstantData(URL);
  return response;
};

export const Devicetype = async () => {
  let URL = Devicetype_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const Filler_delete = async (param) => {
  let URL = FillerDelete_URL + "/" + param;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
    // alert("Something wents wrong, Please try again.");
  }
};
export const Promo_delete = async (param) => {
  let URL = PROMODelete_URL + "/" + param;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
    // alert("Something wents wrong, Please try again.");
  }
};

export const MetafileList_By_Partner = async (param) => {
  let URL = Metadfile_listing_url;
  let params = param;
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) {
    return error;
  }
};
export const Language_Lists = async (param) => {
  let URL = language_List_URL;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const Metafile_delete = async (param) => {
  let URL = GET_METADATA + "/file/" + param;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const themeSequenceList = async (param) => {
  let URL = `${GetThemeSequenceList_URL}?deviceType=${param}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTheme = async (param) => {
  let URL = SetThemeRepositon_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const genreSequenceList = async (param) => {
  let URL = `${GetGenreSequenceList_URL}?deviceType=${param}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateGenre = async (param) => {
  let URL = SetGenreRepositon_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};
export const categorySequenceList = async (param) => {
  let URL = `${GetCategorySequenceList_URL}?deviceType=${param}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCategory = async (param) => {
  let URL = SetCategoryRepositon_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const languageSequenceList = async (param) => {
  let URL = `${GetLanguageSequenceList_URL}?deviceType=${param}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateLanguage = async (param) => {
  let URL = SetLanguageRepositon_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const channelSequenceList = async (deviceType) => {
  let URL = `${GetChannelSequenceList_URL}?deviceType=${deviceType}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const allAvailableChannelList = async (param) => {
  let URL = `${GetAvailableChannelList_URL}?query=`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateChannelSequenceList = async (param) => {
  let URL = UpdateChannelSequenceList_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const featureChannelList = async (deviceType) => {
  let URL = `${GetFeaturedChannelList_URL}?deviceType=${deviceType}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateFeatureList = async (param) => {
  let URL = UpdateFeaturedChannelList_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const bannerlList = async (devideType) => {
  let URL = GetBannerList_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const createBanner = async (param) => {
  let URL = CreateBanner_URL;
  try {
    const response = await postFormData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateBanner = async (params, id) => {
  let URL = `${CreateBanner_URL}/${id}`;
  try {
    const response = await postFormData(URL, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateBannerList = async (params) => {
  let URL = `${CreateBanner_URL}/update-sequence`;
  try {
    const response = await postFormData(URL, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteBannerData = async (bannerId) => {
  let URL = `${CreateBanner_URL}/${bannerId}`;
  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const channelFilterlList = async (deviceType) => {
  let URL = `${GetChannelFilterList_URL}?deviceType=${deviceType}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateChannelFilter = async (param) => {
  let URL = UpdateChannelFilter_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const spotlightContentList = async (deviceType) => {
  let URL = `${GetSpotlightContentList_URL}?deviceType=${deviceType}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const allAvailableSpotlightContentList = async (param) => {
  let URL = `${GetAvailableSpotlightContentList_URL}?query=`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateSpotlightContentList = async (param) => {
  let URL = UpdateSpotlightContentList_URL;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
};

export const BrowseFiles = async (path = '/') => {
  let URL = `${browseS3}/49/s3-browse?path=${path}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {}
};

export const epgHandlersList = async () => {
  let URL = GetEpgHandlers;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

// get all type of devices
export const getDeviceType = async () => {
  let URL = getDeviceType_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const setLangPriority = async (data, id) => {
  let URL = `${SetLangPriority}${id}/priority/${data}`;
  try {
    const response = await putInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const GetPocketFilms = async (id) => {
  let URL = `${GetPocketFilms_URL}/${id}/sync`;

  try {
    const response = await postInstantData(URL);
    return response;
  } catch (error) {
    return false;
  }
}
export const getPeerGroups = async () => {
  let URL = GetPeerGroups;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getUserGroup = async () => {
  let URL = GetUserGroup;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const Avatar_create = async (param) => {
  let URL = CreateAvatar;
  try {
    const response = await postInstantData(URL, param);
    return response;
  } catch (error) {
    return error;
  }
}

export const Avatar_List = async (data) => {
  let URL = GetAvatarList_URL;

  const params = {
    searchText: data?.searchText,
    pageSize: data?.pageSize,
    sortBy: data?.sortBy,
    sortOrder: data?.sortOrder,
    pageNumber: data?.pageNumber,
  };
  try {
    const response = await getInstantData(URL, { params });

    return response;
  } catch (error) {}
};

export const Avatar_delete = async (param) => {
  let URL = DeleteAvatar_URL + "/" + param;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
};

export const Avatar_update = async (data, id) => {
  let URL = `${UpdateAvatar_URL}/${id}`;
  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) {
    return false;
    alert("Something wents wrong, Please try again.");
  }
};

export const getAvatarGender = async () => {
  let URL = GetAvatarGender_URL;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getAuditLogs = async (data) => {
  let URL = GetAuditLogs_URL;
  if (data?.duration !== "ALL") {
    var params = {
      pageNumber: data?.pageNumber,
      pageSize: data?.pageSize,
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
      duration: data?.duration,
    }
  } else {
    var params = {
      pageNumber: data?.pageNumber,
      pageSize: data?.pageSize,
      sortBy: data?.sortBy,
      sortOrder: data?.sortOrder,
    }
  }
  try {
    const response = await getInstantData(URL, {params});
    return response;
  } catch (error) {
    return error;
  }
}

export const generatePartnerUrl = async (data, id) => {
  let URL = `${GeneratePartnerUrl_URL}/${id}/partner-urls`;
  try {
    const response = await postInstantData(URL, data);
    return response
  } catch (error) {
    return error;
  }
}

export const getPartnerUrls = async (id) => {
  let URL = `${GetPartnerUrls_URL}/${id}/partner-urls`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const deletePartnerUrl = async (channelId, partnerId) => {
  let URL = `${DeletePartnerUrl_URL}/${channelId}/partner-urls/${partnerId}`;
  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    return error
  }
}

export const programValidate = async (id) => {
  let URL = `${ProgramValidate_URL}/${id}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const adValidate = async (id) => {
  let URL = `${AdValidate_URL}/${id}`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const getAllFillers = async (ids) => {
  let URL = `${AllFillers_URL}?partnerIds=${ids}&pageNumber=0&pageSize=100000`;
  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) {
    return error;
  }
}

export const saveLinerPrograms = async (id, data) => {
  let URL = `${ScheduleSync_URL}${id}/draft-schedule`;
  try {
    const response = await postInstantData(URL, data);
    return response
  } catch (error) {
    return error;
  }
}