import React, { useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../channel-actions/ChannelActions.scss";

import donecheck from "../../../assets/svg/Action/compltedIcon.svg";
import downarrow from "../../../assets/images/down-arrow.svg";
import pending from "../../../assets/svg/Action/pending.svg";
import error from "../../../assets/images/error.png";
import processing from "../../../assets/svg/Action/proccesing.svg";
import filter_alt from "../../../assets/images/sortarrow.png";
import desc from "../../../assets/images/desc.png";
import rightArrow from "../../../assets/images/rightvector.png";
import leftArrow from "../../../assets/images/Vector.png";
import filterimg from "../../../assets/images/sort.png";
import override from "../../../assets/images/override.png";
import refresh from "../../../assets/svg/Action/refresh.svg";
import deleteicon from "../../../assets/svg/Action/deleteIcon.svg";
import uploadicon from "../../../assets/svg/Assetmanagemant/uil_upload.svg";

import {
    DateStamp,

    capitalizeFirstLetter,

    convertTimestampToTime,
    isLogin,
} from "../../../service/commonFunc";
import axios from "axios";
import instance from "../../../service/axiosConfig";
import {
    AssetPublish,
    AssetTranscode,
    AssetValidate,
    Asset_Detail,
    FillerList,
    Filler_delete,
    ProgramsList_By_Partner,
    PromosList_By_Partner,
} from "../../../api/api";
import Validatepopup from "../../../component/popup/asset-action-popup/Validatepopup";
import { useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReactTooltip from "react-tooltip";
import { CMS_Multipart_Complete_URL, CMS_Multipart_Fillers, CMS_Multipart_upload_URL } from "../../../service/API_URL";
import { getInstantData, postInstantData } from "../../../api/apiMethod";
import ErrorPopup from "../../../component/popup/ErrorPopup";
import Tooltip from "../../../component/Tooltip";
// import ReactTooltip from 'react-tooltip';

export default function Filler({permission}) {
    const AssetActionDetail = JSON.parse(localStorage.getItem("AssetDetail"));
    const AssetPartnerDetail = JSON.parse(
        localStorage.getItem("AssetPartnerDetail")
    );
    const [actionDetail, setActionDetail] = useState([]);
    const [actionData, setActionData] = useState([]);

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuerys, setSearchQuery] = useState("");
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    const [rotation, setRotation] = useState(0);
    const typeOptions = ["FILLER"];
    const [selectedType, setSelectedType] = useState("FILLER");
    const [currentSortOrder, setCurrentSortOrder] = useState({
        sortBy: "filename", // Default sort order for 'filename' column
        sortOrder: "asc", // Default sort order: ascending
    });


    async function fetchData() {
     
        const params = {
            // partnerId: AssetPartnerDetail?.id,
            pageSize: itemsPerPage,
            sortBy: currentSortOrder.sortBy,
            sortOrder: currentSortOrder.sortOrder,
            pageNumber: currentPage - 1,
            ...(searchQuerys ? { searchQuery: searchQuerys } : null),
            fillerType: "PLATFORM_FILLER"
        };

        try {
            //   const assetDetail = await ProgramsList_By_Partner(params);
            const fillerDetail = await FillerList(params);
          
            if (selectedType == "FILLER") {
                if (fillerDetail.status === true) {
                    localStorage.setItem(
                        "PlatformfillerDetail",
                        JSON.stringify(fillerDetail.data)
                    );
                    setActionDetail(fillerDetail.data?.content);
                    setActionData(fillerDetail.data);
                }
            }
          
            setSearchButtonClicked(false);
        } catch (error) {
            console.error(error);
        }
    }

   // Function to check if any object has transcodingStatus as "IN_PROGRESS"
   const checkTranscodingInProgress = (data) => {

    for (let i = 0; i < data.length; i++) {

      if (data[i].transcodingStatus === "SUBMITTED" || data[i].transcodingStatus === "IN_PROGRESS") {
        return true; // Found IN_PROGRESS status
        
      }
    }
    return false; // Did not find IN_PROGRESS status
  }


    let intervalId;
    useEffect(() => {
        setCheckedIds([]);

        fetchData();
        console.log("AWSEDRFTYUIOP",checkTranscodingInProgress(actionDetail))
        intervalId = setInterval(() => {

            if (checkTranscodingInProgress(actionDetail)) {
                fetchData();
            }


        }, 30000);

        return () => {
            clearInterval(intervalId);
        };

    }, [currentPage, searchButtonClicked, selectedType, currentSortOrder]);

    const blankValue = (
        <>
            <img src={pending} alt="Error Image" /> pending
        </>
    );
    const processingValue = (
        <>
            <img src={processing} alt="Error Image" /> processing..
        </>
    );
    const errorValue = (
        <>
            <img src={error} alt="Error Image" /> Error Summary
        </>
    );
    const OVERRIDDEN = (
        <>
            <img src={override} /> Over Riden
        </>
    );

    const [validatepopup, setvalidatepopup] = useState(false);

    const [transcodeActive, setTranscodeActive] = useState(false);
    const [validateActive, setValidateActive] = useState(false);
    const [publishActive, setPublishActive] = useState(false);
    const [validatedata, setValidate] = useState(null);
    const [assetSignledata, setAssetSignledata] = useState(null);

    const actionStatus = (status) => {
        if (status === "NOT_DONE") {
            return "pending";
        }
        if (status === "ERROR") {
            return "error";
        }

        if (status == "IN_PROGRESS" || status == "SUBMITTED") {
            return "processing ";
        }
        if (status === "DONE") {
            return "complete";
        }
        if (status == null) {
            return "pending ";
        }
        if (status === "OVERRIDDEN") {
            return "OverRiden";
        }
    };

    const errorstatus = (status) => {
        if (status === "NOT_DONE") {
            return blankValue;
        }
        if (status === "OVERRIDDEN") {
            return OVERRIDDEN;
        }
        if (status === "ERROR") {
            return errorValue;
        }

        if (status == "IN_PROGRESS" || status == "SUBMITTED") {
            return processingValue;
        }
        if (status === "DONE") {
            return blankValue;
        }
        if (status == null) {
            return blankValue;
        }
    };

    const [checkedIds, setCheckedIds] = useState([]);


    const AssetActions = async (actionType, state) => {
        

        const assemblyType = state ? "AWS" : "RUNN_MEDIA";
    
        const actionData = {
            actionType: actionType,
            assetIds: checkedIds,
            videoType: selectedType,
            transcodingProvider: state ? "AWS_MEDIA_CONVERT" : "R_MEDIA_ENCODER",
            assemblyType: assemblyType, // Updated to use mapped values
        };
    
        if (actionType === "TRANSCODE") {
            const transcode = await AssetTranscode(actionData);
            if (transcode?.data?.status === true) {
                toast.success("Transcode operation initiated successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                });
                fetchData();
            } else {
                toast.error("Something went wrong, please try after sometime", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                });
            }
        } else if (actionType === "PUBLISH") {
            const publish = await AssetPublish(actionData);
    
            if (publish?.data?.status === true) {
                toast.success("Content published successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                });
                fetchData();
            } else {
                toast.error("Something went wrong, please try after sometime", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                });
            }
        }
    
        setCheckedIds([]);
        setChecked(false);
    };
    

    const [sortOrders, setSortOrders] = useState({});
    useEffect(() => {
        isLogin();
        const initialSortOrders = {
            filename: "asc", // Default sort order for 'filename' column
            duration: "", // Default sort order for 'duration' column
            dateUploaded: "", // Default sort order for 'dateUploaded' column
            //   dateValidated: "", // Default sort order for 'dateUploaded' column
            datePublished: "", // Default sort order for 'dateUploaded' column
            dateTranscoded: "", // Default sort order for 'dateUploaded' column
        };
        setSortOrders(initialSortOrders);
    }, [selectedType]);

    const handleSort = async (field) => {
        let currentSortOrder = "desc"; // Default sort order
        let sortField = field
        // Check if the field was previously sorted and toggle the sort order
        if (sortOrders[field]) {
            currentSortOrder = sortOrders[field] === "asc" ? "desc" : "asc";
        }

        const params = {
            // partnerId: AssetPartnerDetail?.id,
            sortBy: field,
            sortOrder: currentSortOrder,
            pageNumber: currentPage - 1,
            pageSize: itemsPerPage,
        };

        setCurrentSortOrder({
            sortBy: sortField,
            sortOrder: currentSortOrder
        })

        let updatedData = {};

        // if (selectedType === "PROGRAM") {
        //   updatedData = await ProgramsList_By_Partner(params);
        // } else 
        if (selectedType === "FILLER") {
            updatedData = await FillerList(params);
        }
        // else if (selectedType === "PROMO") {
        //   updatedData = await PromosList_By_Partner(params);
        // }

        if (updatedData.status === true) {
            const newData = updatedData.data;
            const newDataContent = newData?.content || [];

            localStorage.setItem("PlatformfillerDetail",
                JSON.stringify(newData)
            );
            setActionDetail(newDataContent);

            const newSortOrders = Object.keys(sortOrders).reduce((acc, key) => {
                if (key === field) {
                    acc[key] = currentSortOrder;
                } else {
                    acc[key] = ""; // Reset other sort orders
                }
                return acc;
            }, {});

            setSortOrders(newSortOrders);
        }
    };

    const getSortIcon = (field) => {
        if (sortOrders[field] == "") {
            return <img src={filterimg} alt="filterimg" />;
        }
        if (sortOrders[field] == "desc") {
            return <img src={desc} alt="desc" />; // Image for ascending order
        } else {
            return <img src={filter_alt} alt="Ascending" />; // Image for ascending order
        }
    };
    //   const handleSearch = () => {
    // if(searchQuerys !== ""){
    //     setSearchButtonClicked(true);

    // }
    //   };
    const handleKeyPress = (e) => {

        if (e.key === "Enter") {
            if (searchQuerys !== "") {
                setSearchButtonClicked(true);
            }
        }
    };

    const [disabledFiled, setDisabledFiled] = useState(false);

    const [publishvalidation, setPublishvalidation] = useState(true);

    const handleCheckboxChange = (e, data, index) => {
        const checked = e.target.checked;

        // Update checkedIds based on checkbox status
        let updatedCheckedIds = [...checkedIds];

        if (checked) {
            if (data.transcoded == false) {
                if (
                    data.transcodingStatus == "SUBMITTED" ||
                    data.transcodingStatus == "IN_PROGRESS"
                ) {
                    toast.error("Transcoding is in progress", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                    });
                } else {
                    setTranscodeActive(true);
                    setValidateActive(false);
                    setPublishActive(false);
                    setDisabledFiled("transcode");
                }
            } else {
                if (data.validated == false) {
                    setTranscodeActive(false);
                    if (data.validationStatus == "error") {
                        setPublishvalidation(false);
                        setDisabledFiled("novalidation");
                        setValidateActive(true);
                        setPublishActive(false);
                    }
                    if (data.validationStatus == "OVERRIDDEN") {
                        if (publishvalidation) {
                            setPublishActive(true);
                        }
                        setValidateActive(true);

                        setDisabledFiled("novalidation");
                    } else {
                        setPublishvalidation(false);
                        setDisabledFiled("novalidation");
                        setValidateActive(true);
                        setPublishActive(false);
                    }
                } else if (data.validated == true) {
                    if (data.published == false) {
                        setDisabledFiled("publish");
                        setTranscodeActive(false);
                        setValidateActive(false);
                        setPublishActive(true);
                    } else if (data.published == true) {
                        setTranscodeActive(false);
                        setValidateActive(false);
                        setPublishActive(false);
                    }
                }
            }
            updatedCheckedIds.push(data.id);
        } else {
            updatedCheckedIds = updatedCheckedIds.filter((id) => id !== data.id);
        }

        setCheckedIds(updatedCheckedIds);
        if (updatedCheckedIds.length == 0) {
            setChecked(false);
            setDisabledFiled(false);
            setPublishActive(false);
        } else {
            setChecked(true);


            if (updatedCheckedIds.length === actionDetail.length) {
                setAllcheck(false)
            } else {
                setAllcheck(true)
            }
        }
    };

    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1);
    };
    const [isChecked, setChecked] = useState(false);
    const [allcheck, setAllcheck] = useState(false);
    const handleSelectAllClick = () => {

        let updatedCheckedIds = [...checkedIds];

        actionDetail?.map((data, index) => {

            if ((disabledFiled == "publish" && data.validated == false && data.transcoded == false
            ) || (data.validationStatus == "IN_PROGRESS" &&
                true) ||
                (disabledFiled == false && false) ||
                (disabledFiled == "transcode" &&
                    data.transcoded == true) ||
                data.transcodingStatus == "IN_PROGRESS" ||
                data.transcodingStatus == "SUBMITTED" ||
                (disabledFiled == "novalidation" &&
                    ((data.transcoded == false &&
                        data.validated == false) ||
                        (data.transcoded == true &&
                            data.validated == true &&
                            data.published == true))) ||
                (disabledFiled == "publish_overriden" &&
                    data.validated == false &&
                    data.validationStatus != "OVERRIDDEN")) {



            } else {
                setChecked(true);
                if (checkedIds.length == 1) {
                    updatedCheckedIds.push(data.id);

                    setCheckedIds(updatedCheckedIds)

                } else {
                    setChecked(false);
                    setCheckedIds([])
                    setChecked(false);
                    setDisabledFiled(false);
                    setPublishActive(false);
                }
            }



        })

        if (isChecked) {
            setAllcheck(false)
        }


    };
    const [disabled, setDisabled] = useState(false);
    const rotations = () => {
        setRotation(rotation + 360)
        if (!disabled) {
            setDisabled(true);

            // Add your logic for fetching data here
            // You can also update the rotation state if needed
            setRotation(rotation + 360);

            // Enable the button after 5 seconds
            setTimeout(() => {
                setDisabled(false);
            }, 5000);
        }
    }

    const [activeTab, setActiveTab] = useState("FILLER");
    const [errorbox, setErrorbox] = useState(false);
    const [errorMssage, setErrorMessage] = useState("");
    const [btntext, setbtntext] = useState(null);
    const [eventdelete, seteventdelete] = useState(null);
    const [actTab, setactTab] = useState(activeTab);

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFile, setUploadFile] = useState([]);
    const uploadFileRef = useRef(uploadFile)
    const [newUpload, setNewUpload] = useState(null);
    const PART_SIZE = 50 * 1024 * 1024; // 50 MB in bytes

    const [numberOfParts, setNumberOfParts] = useState(null);
    const [categoryTab, setCategoryTab] = useState("VIDEO");










    const splitFormData = (formData, partSize) => {
        const totalSize = formData.size; // Get the total file size in bytes
        const numberOfParts = Math.ceil(totalSize / partSize);
        setNumberOfParts(numberOfParts);

        const parts = [];

        let start = 0;
        let end = partSize;

        for (let i = 0; i < numberOfParts; i++) {
            if (i === numberOfParts - 1) {
                end = totalSize; // Last part might be smaller
            }

            // Create a new Blob for each part
            const partBlob = formData.slice(start, end);
            parts.push(partBlob);

            start = end;
            end += partSize;
        }

        return parts;
    }
    const handleMultipartFileChange = async (e, overrider) => {
        //     setactTab(activeTab)
        const file = e.target.files[0];
        //     const programs = {
        //       partnerId: AssetPartnerDetail?.id,
        //       assetType: categoryTab,
        //       videoType: categoryTab === "VIDEO" ? activeTab : null,
        //       metadataType: "MOVIES",
        //       overrideExisting: overrider,
        //       filename: file?.name,
        //       fileSize: file?.size
        //     };

        //     // Second set of parameters
        const fillers = {
            // partnerId: AssetPartnerDetail?.id,

            fillerType: "PLATFORM_FILLER",

            fileSize: file?.size
        };
        //     const promo = {
        //       partnerId: AssetPartnerDetail?.id,

        //       promoType: "PARTNER_PROMO",

        //       filename: file.name,
        //       fileSize: file.size
        //     };
        //     const assetpromo = {
        //       assetId: AssetPartnerDetail?.id,

        //       promoType: "PROGRAM_PROMO",

        //       filename: file.name,
        //       fileSize: file.size
        //     };
        //     // Condition to use either params1 or params2
        let params = fillers
        //     if (activeTab == "PROGRAM") {
        //       params = programs;
        //     }
        //     else
        //  if (activeTab == "FILLER") {
        //       params = fillers;
        //     }
        //     else if (activeTab == "PROMO") {
        //       params = promo;
        //     }

        const URL = CMS_Multipart_Fillers;
        const totalSize = file.size;
        const numberOfParts = Math.ceil(totalSize / PART_SIZE);
        const parts = splitFormData(file, PART_SIZE);
        const formData = new FormData();
        const newUpload = {
            'newUpload': {
                name: file.name,
                percentage: 0,
                totalSize
            }
        }
        setSelectedFile(file);
        setUploadFile([...uploadFile, newUpload])
        const fileName = file?.name;
        setNewUpload({
            URL, numberOfParts, params, parts, fileName
        })

    }

    const getPreSignedUrls = async (uploadId, objectKey, numberOfParts) => {

        const URL = encodeURI(`${CMS_Multipart_upload_URL}?uploadId=${uploadId}&objectKey=${objectKey}&numberOfParts=${numberOfParts}`);
        try {
            const response = await getInstantData(URL)
            //   console.log("multipart",response.data);
            return response.data;
        } catch (error) {
        }
    }
    const VideoRetryUpload = () => {

        setTimeout(() => {
            if (selectedFile) {
                // Set overrideExisting flag to true


                // Retry the upload with the selected file
                handleMultipartFileChange({ target: { files: [selectedFile] } }, true); // Pass true directly
            }
        }, 1000);

        errorboxclose()
    };

    useEffect(() => {

        if (newUpload != null) {
            (async () => {

                try {
                    let response = {}
                    //   if (activeTab == "PROGRAM") {
                    //     let params = newUpload?.params;
                    //     response = await postInstantData(newUpload.URL, {},
                    //       { params }
                    //     );
                    //   } else
                    //    if (activeTab == "FILLER") {
                    let params = newUpload?.params;
                    const formdata = new FormData()
                    formdata.append('filename', newUpload?.fileName)

                    response = await postInstantData(newUpload.URL, formdata,
                        { params }
                    );

                    //   } 
                    //   else if (activeTab == "PROMO") {
                    //     let params = newUpload?.params;

                    //     response = await postInstantData(newUpload.URL, {},
                    //       { params }
                    //     );
                    //   }
                    // console.log("asasasas", response.data.data);
                    const { uploadId, objectKey } = response.data.data;


                    const preSignedUrls = await getPreSignedUrls(uploadId, objectKey, newUpload.numberOfParts);



                    await uploadPart(preSignedUrls, newUpload.parts, uploadId, objectKey, newUpload.fileName)
                } catch (error) {

                    if (error.response.status == 400) {


                        if (error.response.data.message.includes("filename=[")) {
                            setErrorbox(true)
                            setbtntext("Override")
                            seteventdelete(() => () => VideoRetryUpload())
                            setErrorMessage(error.response.data.message)
                        }
                        else if (error.response.data.message.includes("Invalid filename")) {
                            toast.error(error.response.data.message, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 5000
                            });
                        }




                    } else {

                        toast.error("Something went wrong, please try after sometime", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 5000
                        });
                    }

                }

            })();
        }
    }, [newUpload]);
    const uploadPart = async (preSignedUrls, parts, uploadId, objectKey, name) => {
        let uploadCount = 0;

        // Initialize part progress array
        const partProgressArray = Array(preSignedUrls.length).fill(0);

        const uploadPromises = preSignedUrls.map(async (preSignedUrl, i) => {
            const url = preSignedUrl.url;

            const formData = new FormData();
            formData.append("file", parts[i]);

            try {
                const resp = await axios.put(url, parts[i], {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    //  cancelToken: source.token,
                    onUploadProgress: (progressEvent) => {
                        parts[i].progress = (progressEvent.loaded / progressEvent.total) * 100;
                        const overallProgress = parts.reduce((acc, current) => parseInt(acc || 0) + parseInt(current?.progress || 0)) / parts.length;
                        const newUploadFile = uploadFileRef.current.map((d) => {
                            if (d.newUpload.name === name) {
                                d.newUpload.percentage = parseInt(overallProgress)
                            }
                            return d;
                        });
                        setUploadFile([...newUploadFile]);
                    },
                });
                uploadCount += 1;
                const totalProgress = Math.round((100 * uploadCount) / preSignedUrls.length);
                let uploadLength = uploadFile.length;
                for (let i = 0; i < uploadLength; i++) {
                    if (uploadFile[i].newUpload.name === name) {
                        uploadFile[i].newUpload.percentage = totalProgress;
                        break;
                    }
                }
                return { partNumber: i + 1, eTag: resp.headers["etag"] };
            } catch (error) {
                console.error("Error uploading part", i, error);
                return { partNumber: i + 1, eTag: null };
            }

        });
        const eTags = await Promise.all(uploadPromises);

        completeMultipartUpload(uploadId, objectKey, eTags, name);
    }


    const completeMultipartUpload = async (uploadId, objectKey, partETags, name) => {
        try {
            const response = await instance.post(CMS_Multipart_Complete_URL,
                {
                    uploadId,
                    objectKey,
                    partETags,
                    videoType: actTab
                }
            );
            // const newUploadFile = uploadFileRef.filter((d) => d.newUpload.name !== name);
            // if(uploadFile.length == 1) {
            //   setUploadFile([])
            // } else {
            //   setUploadFile(newUploadFile);
            // }
            setTimeout(() => {
                // assetdetail(AssetPartnerDetail)
                const newUploadFile = uploadFileRef.current.filter((d) => d.newUpload.name !== name);
                setUploadFile(newUploadFile);
            }, 1000);
            fetchData()

            toast.success(capitalizeFirstLetter(categoryTab) + " uploaded successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });


            return response.data?.data;
        } catch (error) {

            toast.error("Something went wrong, please try after sometime", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });

        }

    };


    const fillerdelete = async (assetid) => {

        errorboxclose()
        const deleteasset = await Filler_delete(assetid);

        if (deleteasset.status == 200) {
            fetchData();
            toast.success("Filler deleted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });
        } else {

            toast.error("Something went wrong, please try after sometime", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });
        }
    }

    const deletemetafilepopup = (id) => {

        setbtntext("Delete")
        setErrorMessage("Do you want to delete ?")
        setErrorbox(true)
        seteventdelete(() => () => fillerdelete(id))

    }

    const errorboxclose = () => {
        setUploadFile([])
        // assetdetail(AssetPartnerDetail)
        setErrorMessage("")
        setErrorbox(false)
    }

    // console.log("checkedIds", checkedIds);
    return (
        <div className="w-100">
            <div className="dashboard-content">
              

            <div 
    className="action-category" 
    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}
>
    {/* Left Section: Transcode and Publish */}
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {permission === "edit" && (
            <>
                <ul style={{ listStyle: "none", padding: 0, margin: 0, display: "flex", gap: "10px" }}> 
                    <li
                        className={
                            transcodeActive && checkedIds.length > 0 ? "active" : "disable"
                        }
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div className="dropdown action-dropdown" style={{ position: "relative" ,border: "2px solid #808080", borderRadius:6}}>
                            <a
                                className="border-0"
                                style={{
                                    cursor: transcodeActive && checkedIds.length > 0 ? "pointer" : "not-allowed",
                                    padding: "12px 16px",
                                    fontSize: "16px",
                                    borderRadius: "8px", 
                                    
                                }}
                                onClick={() =>
                                    transcodeActive && checkedIds.length > 0
                                        ? AssetActions("TRANSCODE", true)
                                        : null
                                }
                            >
                                Transcode
                                <span style={{ marginLeft: "5px" }}>&#9660;</span>
                            </a>

                            {transcodeActive && checkedIds.length > 0 && (
                                <div
                                    className="dropdown-content w-auto"
                                    style={{
                                        position: "absolute",
                                        top: "100%",
                                        left: 0,
                                        background: "#fff",
                                        border: "1px solid #ccc",
                                        zIndex: 1,
                                        padding: "5px",
                                    }}
                                >
                                    <button
                                        className="transoption"
                                        style={{
                                            display: "block",
                                            marginBottom: "5px",
                                            cursor: "pointer",
                                            padding: "12px 16px", /* Increased padding */
                                            fontSize: "16px", /* Larger font size */
                                            borderRadius: "8px", /* Larger border radius */
                                        }}
                                        onClick={() =>
                                            AssetActions("TRANSCODE", false)
                                        }
                                    >
                                        Runn Media Encoder
                                    </button>
                                    <button
                                        className="transoption"
                                        style={{
                                            display: "block",
                                            cursor: "pointer",
                                            padding: "12px 16px", /* Increased padding */
                                            fontSize: "16px", /* Larger font size */
                                            borderRadius: "8px", /* Larger border radius */
                                        }}
                                        onClick={() =>
                                            AssetActions("TRANSCODE", true)
                                        }
                                    >
                                        AWS MediaConvert
                                    </button>
                                </div>
                            )}
                        </div>
                    </li>
                    <li
                        className={
                            publishActive && checkedIds.length > 0 ? "active" : "disable"
                        }
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <a
                            style={{
                                cursor: publishActive && checkedIds.length > 0 ? "pointer" : "not-allowed",
                                padding: "12px 16px", /* Increased padding */
                                fontSize: "16px", /* Larger font size */
                                borderRadius: "8px", /* Larger border radius */
                                border: "2px solid #808080",
                            }}
                            onClick={() =>
                                publishActive && checkedIds.length > 0
                                    ? AssetActions("PUBLISH")
                                    : null
                            }
                        >
                            Publish
                        </a>
                    </li>
                </ul>
            </>
        )}
    </div>

    {/* Right Section: Refresh and Upload */}
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <button
            className="refresh-button"
            style={{
                margin: "0 5px",
                border: "1px solid #444649",
                background: "#444649",
                cursor: disabled ? "not-allowed" : "pointer",
                padding: "5px 2px",
                borderRadius: "8px", 
                fontSize: "16px", 
            }}
            onClick={() => { fetchData(); rotations(); }}
            disabled={disabled}
        >
            <img
                className={rotation !== 0 ? "rotate" : ""}
                style={{ transform: `rotate(${rotation}deg)`, height: 27, width:35 }} /* Increased icon size */
                src={refresh}
                alt="refresh"
            />
        </button>

        {permission === "edit" && (
            <>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept=".mp4,.mov"
                    multiple
                    onChange={(e) => handleMultipartFileChange(e, false)}
                />
                <button
                    className="upload-button"
                    style={{
                        margin: "0 5px",
                        border: "1px solid #444649cc",
                        background: "#444649",
                        cursor: "pointer",
                        padding: "5px 7px", 
                        borderRadius: "8px", 
                        fontSize: "10px", 
                    }}
                    onClick={() => document.getElementById("fileInput").click()}
                >
                    <img src={uploadicon} alt="upload" style={{height: 27, width:27}} /> {/* Increased icon size */}
                </button>
            </>
        )}

      
    </div>
</div>







      
                <div className="table-checkbox"
                >
                    <div className="table">
                        <table className="striped-table"
                         style={{
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    width: "100%", 
  }}>
                            <thead>
                                <tr>
                                    {
                                        permission === "edit" ?
                                        <th  style={{paddingLeft:10}}>
                                            <input
                                                type="checkbox"
                                                id="selectAllCheckbox"
                                                checked={isChecked}
                                                onChange={() => handleSelectAllClick()}
                                           
                                            />
                                            <label className={allcheck ? "check" : null} htmlFor="selectAllCheckbox">&nbsp;</label>
                                            {/* <label for="1">&nbsp;</label> */}
                                        </th>
                                        :
                                        ''
                                    }
                                    <th style={{paddingLeft:20,textAlign:"left",}}>
                                        {selectedType} Name{" "}
                                        <a
                                            className="filter-icon mx-2"
                                            onClick={() => handleSort("filename")}
                                        >
                                            {getSortIcon("filename")}
                                        </a>
                                    </th>
                                    {/* <th>
               

                    <div
                      className="dropdown p-0"
                      style={{
                        position: "relative",
                        top: 0,
                        cursor: "pointer",
                      }}
                    >
                      <span>Type </span> <img src={downarrow} />
                      <a className="dropdown-button"></a>
                      <div className="dropdown-content w-auto h-auto p-0 m-0">
                        {typeOptions.map((option, index) => (
                          <a
                            key={index}
                            value={option}
                            className={
                              selectedType == option
                                ? " dropdownfilter active px-2"
                                : "dropdownfilter px-2"
                            }
                            onClick={() => setSelectedType(option)}
                          >
                            {option}
                          </a>
                        ))}
                      </div>
                    </div>
                  </th> */}
                                    <th>
                                        Duration{" "}
                                        <a
                                            className="filter-icon mx-2"
                                            onClick={() => handleSort("duration")}
                                        >
                                            {getSortIcon("duration")}
                                        </a>
                                        <br /> hh:mm:ss
                                    </th>
                                    <th>
                                        Date Uploaded{" "}
                                        <a
                                            className="filter-icon mx-2"
                                            onClick={() => handleSort("dateUploaded")}
                                        >
                                            {getSortIcon("dateUploaded")}
                                        </a>
                                        <br />
                                        dd/mm/yy{" "}
                                    </th>
                                    <th>
                                        Transcode{" "}
                                        <a
                                            className="filter-icon mx-2"
                                            onClick={() => handleSort("dateTranscoded")}
                                        >
                                            {getSortIcon("dateTranscoded")}
                                        </a>
                                    </th>
                                    {/* <th>
                    VALIDATE{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("dateValidated")}
                    >
                      {getSortIcon("dateValidated")}
                    </a>
                  </th> */}
                                    <th>
                                        PUBLISH{" "}
                                        <a
                                            className="filter-icon mx-2"
                                            onClick={() => handleSort("datePublished")}
                                        >
                                            {getSortIcon("datePublished")}
                                        </a>
                                    </th>
                                    {
                                        permission === "edit" ?
                                        <th>
                                            Delete
                                        </th>
                                        :
                                        ''
                                    }
                                </tr>
                            </thead>
                            <tbody >
                                {actionDetail &&
                                    actionDetail?.map((data, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className={index % 2 === 0 ? "even" : "odd"}
                                            >
                                                {
                                                    permission === "edit" ?
                                                    <td style={{backgroundColor: "#121212",}}>
                                                        <input
                                                            id={data.id}
                                                            type="checkbox"
                                                            value=""
                                                            onChange={(e) =>
                                                                handleCheckboxChange(e, data, index)
                                                            }
                                                            checked={checkedIds.includes(data.id)}
                                                            disabled={
                                                                (disabledFiled == "publish" && data.validated == false && data.transcoded == false
                                                                ) ||
                                                                (
                                                                    data.validationStatus == "IN_PROGRESS" &&
                                                                    true) ||
                                                                (disabledFiled == false && false) ||
                                                                (disabledFiled == "transcode" &&
                                                                    data.transcoded == true) ||
                                                                data.transcodingStatus == "IN_PROGRESS" ||
                                                                data.transcodingStatus == "SUBMITTED" ||
                                                                (disabledFiled == "novalidation" &&
                                                                    ((data.transcoded == false &&
                                                                        data.validated == false) ||
                                                                        (data.transcoded == true &&
                                                                            data.validated == true &&
                                                                            data.published == true)))
                                                                ||
                                                                (disabledFiled == "publish_overriden" &&
                                                                    data.validated == false &&
                                                                    data.validationStatus != "OVERRIDDEN")
                                                            }
                                                        // disabled={
                                                        //   data.validated == false && data.validationStatus != "OVERRIDDEN" || data.published == false
                                                        // }
                                                        />
                                                        <label for={data.id}>&nbsp;</label>
                                                    </td>
                                                    :
                                                    ''
                                                }

                                                <td style={{textAlign:"left",backgroundColor: "#121212",}}>
                                                    {data?.metadata == null
                                                        ? data.filename
                                                        : data?.metadata?.title == null
                                                            ? data.filename
                                                            : data?.metadata?.title}
                                                </td>
                                                {/* 
                        <td>
                          {data.videoType ? data.videoType : selectedType}
                        </td> */}
                                                <td style={{backgroundColor: "#121212", }}>
                                                    {data.duration != null
                                                        ? convertTimestampToTime(data.duration)
                                                        : data.metadata != null
                                                            ? data.metadata.duration != null
                                                                ? data.metadata.duration
                                                                : "N/A"
                                                            : "N/A"
                                                    }
                                                </td>
                                                <td style={{backgroundColor: "#121212", }}>{DateStamp(data.dateUploaded)}</td>
                                                <td  style={{backgroundColor: "#121212", }}>
                                                    <div
                                                        data-tip={data?.jobPercentComplete == null || data.transcoded == true ? "" : data?.jobPercentComplete + "%"}
                                                        className={
                                                            data?.transcoded == true
                                                                ? "complete"
                                                                : actionStatus(data?.transcodingStatus)
                                                        }
                                                    >
                                                        {data.transcoded !== true ? (
                                                            errorstatus(data?.transcodingStatus)
                                                        ) : (
                                                            <>
                                                                <img src={donecheck} />
                                                                {DateStamp(data.dateTranscoded)}
                                                            </>
                                                        )}
                                                    </div>
                                                </td>


                                                {/* <td>
                          <div
                            onClick={() => {
                              validationation(data);
                            }}
                            className={
                              data?.transcoded === true
                                ? data?.validated === true
                                  ? "complete"
                                  : actionStatus(data?.validationStatus)
                                : "pending"
                            }
                          >
                            {data?.validated !== true ? (
                              errorstatus(data?.validationStatus)
                            ) : (
                              < >
                                <img src={donecheck} />
                                {DateStamp(data?.dateValidated)}
                              </>
                            )}
                          </div>
                        </td> */}
                                                <td style={{backgroundColor: "#121212", }}>
                                                    <div
                                                        className={data?.published === true ? 'complete' : 'pending'}

                                                    >
                                                        {data.datePublished === null ? (
                                                            blankValue
                                                        ) : (
                                                            <>
                                                                <img src={donecheck} />
                                                                {DateStamp(data.datePublished)}
                                                            </>
                                                        )}

                                                    </div>


                                                </td>

                                                {
                                                    permission === "edit" ?
                                                    <td style={{backgroundColor: "#121212", }}>
                                                        <a
                                                            onClick={() => deletemetafilepopup(data.id)}
                                                        >
                                                            <img src={deleteicon} alt="delete" />
                                                        </a>
                                                    </td>
                                                    :
                                                    ''
                                                }
                                                <Tooltip type="error" />
                                            </tr>

                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {actionDetail.length !== 0 && (
  <div className="table-pagination">
    <div className="pagination-count">
      <div className="count">
        {`${Math.min(
          (currentPage - 1) * itemsPerPage + 1,
          actionData.totalElements
        )}-${Math.min(
          currentPage * itemsPerPage,
          actionData.totalElements
        )} of ${actionData.totalElements}`}
      </div>
      <div className="pagination-arrow">
        {/* Previous Button */}
        <button
          className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
          disabled={currentPage === 1}
        >
          <img src={leftArrow} alt="Previous" />
        </button>

        {/* Editable Current Page Number */}
        <input
          className="page-number-box"
          type="number"
          value={currentPage}
          min="1"
          max={actionData.totalPages}
          onChange={(e) => {
            const value = e.target.value;
            // Only update if it's a valid number
            if (/^\d*$/.test(value)) {
              setCurrentPage(value ? Number(value) : '');
            }
          }}
          onBlur={(e) => {
            let value = Number(e.target.value);
            if (!value || value < 1) value = 1;
            if (value > actionData.totalPages) value = actionData.totalPages;
            setCurrentPage(value);
          }}
        />

        {/* Next Button */}
        <button
          className={`next ${
            currentPage === actionData.totalPages ? 'disabled' : ''
          }`}
          onClick={() => {
            if (currentPage < actionData.totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
          disabled={currentPage === actionData.totalPages}
        >
          <img src={rightArrow} alt="Next" />
        </button>
      </div>
    </div>
  </div>
)}


                </div>
            </div>
            {errorbox && <ErrorPopup btntext={btntext} message={errorMssage} closebox={() => errorboxclose()} overrides={() => eventdelete()} />}


            <ToastContainer />
            {/* {validatepopup && (
        <Validatepopup
          handleClosePopup={() => setvalidatepopup(false)}
          validateinfo={validatedata}
          assetsingledata={assetSignledata}
        />
      )} */}
        </div>
    );
}
