import icroundsearch from "../../../../../../assets/images/ic_round-search.svg";

export const SearchBar = ({
    appTheme,
    searchedText,
    searchPrograms
}) => {
    return (
        <div className="search-container" style={{height: '44px'}}>
            <input
                id="search-input"
                className="search-box"
                type="text"
                placeholder="Search program"
                style={{
                width: '100%',
                height: '40px',
                color: appTheme === "dark" ? '#ffffff' : 'black',
                borderRadius: '6px',
                padding: '0 10px',
                }}
                value={searchedText}
                onChange={(e)=>{searchPrograms(e.target.value)}}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        searchPrograms(e.target.value); // Trigger the button's onClick function
                    }
                }}
            />
            <button
                className="search-button"
                aria-label="Search"
                style={{
                border: 'none',
                cursor: 'pointer',
                padding: '0',
                backgroundColor: 'transparent',
                color: appTheme === "dark" ? '#ffffff' : 'black',
                }}
            >
                <img
                src={icroundsearch}
                alt="Search"
                className="search-icon"
                style={{
                    filter: appTheme === "dark" ? 'invert(100%) brightness(150%)' : 'none',
                }}
                />
            </button>
        </div>
    )
}