// import React, { useState, useEffect } from "react";
// import { ViewershipMap, ViewershipTableChild } from "../../../api/api";
// import { useDispatch } from "react-redux";
// import { ChannelDataAction, MapDataAction } from "../../../Redux/slices";
// import { TimeConverter, formatNumber } from "../../../service/commonFunc";
// import rightarrow  from "../../../assets/images/caret-small-right.svg"
// import leftarrow  from "../../../assets/images/caret-small-left.svg"
// import Loader from "../../../component/Loader/Loader";
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';
// // import { CartesianGrid, LineChart, XAxis, Line, Tooltip } from "recharts";

// function AccordionChild({ data, filter, clickedData, graphView}) {
//   const [programData, setProgramData] = useState([]);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [totalShowLength, setTotalShowLength] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [options, setOptions] = useState([]);
//   const [showLoaders, setshowLoaders] = useState(false);
//   const [xAxis, setXAxis] = useState([]);
//   const [yAxis, setYAxis] = useState([]);
//   const dispatch = useDispatch();
//   const [chartData, setChartData] = useState({});
//   const [chartOptions, setChartOptions] = useState({
//     plugins: {
//       tooltip: {
//         enabled: true, // Enable tooltips
//         mode: 'index',
//         intersect: false,
//         callbacks: {
//           label: function(context) {
//             let label = context.dataset.label || '';

//             if (label) {
//               label += ': ';
//             }
//             if (context.parsed.y !== null) {
//               label += context.parsed.y;
//             }
//             return label;
//           }
//         }
//       },
//       legend: {
//         display: true,
//       },
//     },
//     hover: {
//       mode: 'nearest',
//       intersect: true
//     },
//     scales: {
//       x: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Program Name'
//         }
//       },
//       y: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Information'
//         }
//       }
//     }
//   });

//   useEffect(() => {
//     const xdata = [];
//     const ydata = [];
//     const ydata2 = [];
//     const ydata3 = [];
//     const ydata4 = [];
//     const ydata5 = [];
//     programData.map((data) => {
//       xdata.push(data.showName);
//       ydata.push(data.numberOfActiveUsers);
//       ydata2.push(data.totalWatchHours);
//       // ydata3.push(data.averageWatchTimePerSession);
//       // ydata4.push(data.averageWatchTimePerUser);
//       ydata3.push(data.totalAdImpression);
//     });
//     setXAxis(xdata);
//     setYAxis(ydata);
//     setChartData({
//       labels: xdata,
//       datasets: [
//         {
//           label: 'Number of Active Users',
//           data: ydata,
//           fill: false,
//           backgroundColor: 'rgb(0, 0, 0)',
//           borderColor: '#ed5c0d ',
//         }, {
//           label: 'Total watch hours',
//           data: ydata2,
//           fill: false,
//           backgroundColor: 'rgb(0, 50, 200)',
//           borderColor: '#900C3F',
//         },
//         //  {
//         //   label: 'Average watch time per session',
//         //   data: ydata3,
//         //   fill: false,
//         //   backgroundColor: 'rgb(0, 0, 0)',
//         //   borderColor: 'rgba(0, 0, 0, 0.7)',
//         // }, {
//         //   label: 'Average watch time per user',
//         //   data: ydata4,
//         //   fill: false,
//         //   backgroundColor: 'rgb(0, 0, 0)',
//         //   borderColor: 'rgba(0, 0, 0, 0.7)',#67ed0d 

//         // }, 
//         {
//           label: 'Total ad impression',
//           data: ydata3,
//           fill: false,
//           backgroundColor: 'rgb(0, 0, 0)',
//           borderColor: '#67ed0d ',
//         },
//       ],
//       options: {
//         scales: {
//           y: {
//             beginAtZero: true, // This ensures the y-axis starts at 0
//             ticks: {
//               precision: 0 // This can be used to avoid decimal values on the y-axis
//             }
//           }
//         }
//       }
//     })
//     // xAxis={[{ scaleType: 'point', data: xAxis }]}
//     // series={[
//     //   {
//     //     data: yAxis,
//     //   },
//     // ]}
//   }, [programData]);
 
//   // Update the API call parameters when currentPage or itemsPerPage change
//   useEffect(() => {
//     const offset = (currentPage - 1) * itemsPerPage;
//     const limit = itemsPerPage;
//     const ViewershipTableChildData = {
//       viewType: filter?.viewType,
//       viewKey: data?.viewKey,
//       duration: filter?.duration,
//       offset,
//       limit,
//       sortBy: clickedData?.key,
//       sortOrder: clickedData?.sort
//     };
//     // console.log("hello", clickedData );
//     (async () => {
//       const childdata = await ViewershipTableChild(ViewershipTableChildData,setshowLoaders);
//       setProgramData(childdata?.data.data.rows);
//       setTotalShowLength(childdata?.data.data.totalResults);
//     })();
 
//     if(filter?.viewType == "CHANNEL" && filter?.duration == "DAY"){
//       // console.log("SAS",filter?.viewType);
//       mapRenderData()

//     }

//   }, [currentPage, itemsPerPage, data, filter, clickedData]);
  
//   const mapRenderData = async () => {
//     const ViewershipMapData = {
//       channelName: data?.viewKey
//     };
    
//     const MapData = await ViewershipMap(ViewershipMapData);
//     if(MapData.data.success == true){
//       dispatch(ChannelDataAction(data));
//    dispatch(MapDataAction(MapData?.data));
//    }
//   };

//   useEffect(() => {

//     // Calculate the maximum selectable value in intervals of 10
//     const maxSelectableValue = Math.ceil(totalShowLength / itemsPerPage) * itemsPerPage;

//     // Generate the dynamic options array based on maxSelectableValue
//     const newOptions = [...Array(maxSelectableValue / itemsPerPage)].map(
//       (_, index) => (index + 1) * itemsPerPage
//     );
   
    
//     setOptions(newOptions.length >= 3 ? newOptions.slice(0, 3) : newOptions);
//   }, [totalShowLength]);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleItemsPerPageChange = (event) => {
//     const newItemsPerPage = parseInt(event.target.value, 10);
//     // Ensure the selected value is not greater than 30
//     const clampedItemsPerPage = Math.min(newItemsPerPage, 30);
//     setItemsPerPage(clampedItemsPerPage);
//     setCurrentPage(1); // Reset currentPage to 1 when changing the number of items per page
//   };

//   const totalPages = Math.ceil(totalShowLength / itemsPerPage);

//   const renderPaginationButtons = () => {
//     const buttons = [];
//     const maxVisibleButtons = 3; // Maximum number of visible buttons

//     if (totalPages <= maxVisibleButtons) {
//       for (let i = 1; i <= totalPages; i++) {
//         buttons.push(
//           <button
//             key={i}
//             onClick={() => handlePageChange(i)}
//             className={currentPage === i ? "active" : ""}
//           >
//             {i}
//           </button>
//         );
//       }
//     } else {
//       if (currentPage <= maxVisibleButtons - 1) {
//         for (let i = 1; i <= maxVisibleButtons; i++) {
//           buttons.push(
//             <button
//               key={i}
//               onClick={() => handlePageChange(i)}
//               className={currentPage === i ? "active" : ""}
//             >
//               {i}
//             </button>
//           );
//         }
//         buttons.push(<span key="ellipsis1">...</span>);
//         buttons.push(
//           <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
//             {totalPages}
//           </button>
//         );
//       } else if (currentPage >= totalPages - (maxVisibleButtons - 2)) {
//         buttons.push(
//           <button key={1} onClick={() => handlePageChange(1)}>
//             1
//           </button>
//         );
//         buttons.push(<span key="ellipsis1">...</span>);
//         for (let i = totalPages - (maxVisibleButtons - 2); i <= totalPages; i++) {
//           buttons.push(
//             <button
//               key={i}
//               onClick={() => handlePageChange(i)}
//               className={currentPage === i ? "active" : ""}
//             >
//               {i}
//             </button>
//           );
//         }
//       } else {
//         buttons.push(
//           <button key={1} onClick={() => handlePageChange(1)}>
//             1
//           </button>
//         );
//         buttons.push(<span key="ellipsis1">...</span>);
//         for (let i = currentPage - 1; i <= currentPage + 1; i++) {
//           buttons.push(
//             <button
//               key={i}
//               onClick={() => handlePageChange(i)}
//               className={currentPage === i ? "active" : ""}
//             >
//               {i}
//             </button>
//           );
//         }
//         buttons.push(<span key="ellipsis2">...</span>);
//         buttons.push(
//           <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
//             {totalPages}
//           </button>
//         );
//       }
//     }

//     return buttons;
//   };

//   const showPagination = true
//   console.log('programData',programData);
//   return (

//     <div className="inner">
//       { showLoaders && <Loader />}
//       {graphView ?
//       <>
//         {xAxis.length > 0 && 
//         <Line data={chartData} options={chartOptions} />
//         // <LineChart
//         //   xAxis={[{ scaleType: 'point', data: xAxis, 
//         //     valueFormatter: (value) => {
//         //       // Insert a line break after a certain number of characters
//         //       // This is a simple example; you might need a more complex logic
//         //       return value.length > 5 ? value.slice(0, 5) + '\n' + value.slice(6,10) + '...' : value;
//         //     }
//         //   }]}
//         //   series={[
//         //     {
//         //       data: yAxis,
//         //       color: '#fdb462',
//         //       className: 'white',
//         //       valueFormatter: (v, { dataIndex }) => {
//         //         const value = xAxis[dataIndex];
//         //         return `${value}.`;
//         //       },
//         //     },
//         //   ]}
//         //   width={700}
//         //   height={300}
//         // >
//         //   <XAxis dataKey={xAxis}/>
//         //   <Tooltip />
//         //   <CartesianGrid stroke="#f5f5f5" />
//         //   <Line type="monotone" dataKey="uv" stroke="#ff7300" yAxisId={0} />
//         //   <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} />
//         // </LineChart> 
//         }
//       </> :
//       <div className="table-content">
//         {programData.map((data, index) => (
//           <div key={index} className="table-row">
//             <div className="table-col name">{data.showName}</div>
//             <div className="table-col">{formatNumber(data.numberOfActiveUsers)}</div>
//             <div className="table-col">{formatNumber(data.totalWatchHours?.toFixed(2))}</div>
//             <div className="table-col">{TimeConverter(data.averageWatchTimePerUser)}</div>
//             <div className="table-col">{TimeConverter(data.averageWatchTimePerSession)}</div>
//             {/* <div className="table-col">{data.totalAdImpression}</div> */}
//           </div>
//         ))}
//       </div>}
      
//       {/* */}
      
//       {showPagination && !graphView && (
//         <div className="pagination pagination-table"  >
//           <div className="items-per-page">
//             <label >Items per page:</label>
//             <select className="selectboxpage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
//               {options.map((option) => (
//                 <option key={option} value={option}>
//                   {option}
//                 </option>
//               ))}
//               {/* <option value={totalShowLength}>All</option> */}
//             </select>

            
//           </div>
//           <button
//             onClick={() => handlePageChange(currentPage - 1)}
//             disabled={currentPage === 1} 
//           >
//             <img src={leftarrow} alt="rightarrow" />
//           </button>
//           {renderPaginationButtons()}
//           <button
//             onClick={() => handlePageChange(currentPage + 1)}
//             disabled={currentPage === totalPages} 
//           >
//              <img src={rightarrow} alt="rightarrow" />
//           </button>
          
//         </div>
//       )}

//     </div>
  
//   );
// }

// export default AccordionChild;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { ViewershipMap, ViewershipTableChild } from "../../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { ChannelDataAction, MapDataAction } from "../../../Redux/slices";
import { TimeConverter, formatNumber } from "../../../service/commonFunc";
import rightarrow from "../../../assets/svg/viewership/rightArrow.svg";
import leftarrow from "../../../assets/svg/viewership/leftArrow.svg";
import Loader from "../../../component/Loader/Loader";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { throttle } from "lodash";

function AccordionChild({ data, filter, clickedData, graphView }) {
  // State variables
  const [programData, setProgramData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalShowLength, setTotalShowLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [showLoaders, setShowLoaders] = useState(false);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [yAxis2, setYAxis2] = useState([]);
  const [yAxis3, setYAxis3] = useState([]);
  const [gradientsApplied, setGradientsApplied] = useState(false);
  const dispatch = useDispatch();

  // References for the chart and gradients
  const chartRef = useRef(null);
  const gradient1Ref = useRef(null);
  const gradient2Ref = useRef(null);
  const gradient3Ref = useRef(null);

  // Define gradient colors
  const gradientColors1 = ['#92F134', '#138800', '#008826', '#5AF134', '#DEF8D5'];
  const gradientColors2 = ['#F7E2D3', '#F18F34', '#885200', '#884900', '#F19A34', '#F8ECD5'];
  const gradientColors3 = ['#FFA07A', '#FF8C00', '#8B4513'];

  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);

  // Initial Chart Data with placeholders
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Number of Active Users",
        data: [],
        fill: false,
        borderColor: '#A52A2A', 
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: '#000',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
      },
      {
        label: "Total Watch Hours",
        data: [],
        fill: false,
        borderColor: "#0e6def", 
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: '#000',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
      },
      {
        label: "Total Ad Impression",
        data: [],
        fill: false,
        borderColor: "#67ed0d", 
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: '#000',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
      },
    ],
  });

  // Chart Options
  const chartOptions = {
    // plugins: {
    //   tooltip: {
    //     enabled: true,
    //     mode: "index",
    //     intersect: false,
    //     callbacks: {
    //       label: function (context) {
    //         let label = context.dataset.label || "";
    //         if (label) {
    //           label += ": ";
    //         }
    //         if (context.parsed.y !== null) {
    //           label += context.parsed.y;
    //         }
    //         return label;
    //       },
    //     },
    //   },
    //   legend: {
    //     display: true,
    //   },
    // },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Program Name",
        },
        // grid: {
        //   color: appTheme === "dark" ? '#606264' : '#FCFCFD',
        // },
        grid: {
          lineWidth: 40,
          color: appTheme === "dark" ? '#606264' : '#FCFCFD', 
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Information",
        },
        border: { dash: [4, 4] },
        grid: {
          color: "#C9CBCD",
          lineWidth: 1,
        },
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 300, // Faster animation
      easing: 'linear',
    },
  };

  // Fetch and set program data
  useEffect(() => {
    const fetchData = async () => {
      const offset = (currentPage - 1) * itemsPerPage;
      const limit = itemsPerPage;
      const ViewershipTableChildData = {
        viewType: filter?.viewType,
        viewKey: data?.viewKey,
        duration: filter?.duration,
        offset,
        limit,
        sortBy: clickedData?.key,
        sortOrder: clickedData?.sort,
      };

      try {
        const childData = await ViewershipTableChild(ViewershipTableChildData, setShowLoaders);
        const rows = childData?.data.data.rows || [];
        setProgramData(rows);
        setTotalShowLength(childData?.data.data.totalResults || 0);
      } catch (error) {
        console.error("Error fetching viewership table child data:", error);
      }
    };

    fetchData();

    if (filter?.viewType === "CHANNEL" && filter?.duration === "DAY") {
      mapRenderData();
    }
  }, [currentPage, itemsPerPage, data, filter, clickedData, dispatch]);

  // Fetch map data
  const mapRenderData = async () => {
    const ViewershipMapData = {
      channelName: data?.viewKey,
    };

    try {
      const MapData = await ViewershipMap(ViewershipMapData);
      if (MapData.data.success) {
        dispatch(ChannelDataAction(data));
        dispatch(MapDataAction(MapData?.data));
      }
    } catch (error) {
      console.error("Error fetching viewership map data:", error);
    }
  };

  // Process program data to extract chart data
  useEffect(() => {
    const xdata = programData.map(item => item.showName);
    const ydata = programData.map(item => item.numberOfActiveUsers);
    const ydata2 = programData.map(item => item.totalWatchHours);
    const ydata3 = programData.map(item => item.totalAdImpression);

    setXAxis(xdata);
    setYAxis(ydata);
    setYAxis2(ydata2);
    setYAxis3(ydata3);

    setChartData(prevData => ({
      ...prevData,
      labels: xdata,
      datasets: [
        {
          ...prevData.datasets[0],
          data: ydata,
        },
        {
          ...prevData.datasets[1],
          data: ydata2,
        },
        {
          ...prevData.datasets[2],
          data: ydata3,
        },
      ],
    }));
  }, [programData]);

  // Generate pagination options
  useEffect(() => {
    const maxSelectableValue = Math.ceil(totalShowLength / itemsPerPage) * itemsPerPage;
    const newOptions = [...Array(maxSelectableValue / itemsPerPage)].map(
      (_, index) => (index + 1) * itemsPerPage
    );

    setOptions(newOptions.length >= 3 ? newOptions.slice(0, 3) : newOptions);
  }, [totalShowLength, itemsPerPage]);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > Math.ceil(totalShowLength / itemsPerPage)) return;
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    const clampedItemsPerPage = Math.min(newItemsPerPage, 30);
    setItemsPerPage(clampedItemsPerPage);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalShowLength / itemsPerPage);

  // Render pagination buttons
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 3;

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === i ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      if (currentPage <= maxVisibleButtons - 1) {
        for (let i = 1; i <= maxVisibleButtons; i++) {
          buttons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              {i}
            </button>
          );
        }
        buttons.push(<span key="ellipsis">...</span>);
        buttons.push(
          <button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === totalPages ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            {totalPages}
          </button>
        );
      } else if (currentPage >= totalPages - (maxVisibleButtons - 2)) {
        buttons.push(
          <button
            key={1}
            onClick={() => handlePageChange(1)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            1
          </button>
        );
        buttons.push(<span key="ellipsis1">...</span>);
        for (let i = totalPages - (maxVisibleButtons - 2); i <= totalPages; i++) {
          buttons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              {i}
            </button>
          );
        }
      } else {
        buttons.push(
          <button
            key={1}
            onClick={() => handlePageChange(1)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === 1 ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            1
          </button>
        );
        buttons.push(<span key="ellipsis1">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          buttons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === i ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              {i}
            </button>
          );
        }
        buttons.push(<span key="ellipsis2">...</span>);
        buttons.push(
          <button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === totalPages ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
          >
            {totalPages}
          </button>
        );
      }
    }
    return buttons;
  };

  const showPagination = true;

  // Function to create gradient
  const createFixedGradient = (ctx, chartArea, colors) => {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    colors.forEach((color, index) => {
      gradient.addColorStop(index / (colors.length - 1), color);
    });
    return gradient;
  };

  // Apply gradients to the chart after rendering
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const applyGradients = () => {
      const { ctx, chartArea } = chart;

      if (!chartArea) return;

      // Create gradients if they haven't been created yet
      if (!gradient1Ref.current) {
        gradient1Ref.current = createFixedGradient(ctx, chartArea, gradientColors1);
      }
      if (!gradient2Ref.current) {
        gradient2Ref.current = createFixedGradient(ctx, chartArea, gradientColors2);
      }
      if (!gradient3Ref.current) {
        gradient3Ref.current = createFixedGradient(ctx, chartArea, gradientColors3);
      }

      // Update chart data with gradients only once
      if (!gradientsApplied) {
        setChartData(prevData => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              borderColor: gradient1Ref.current,
            },
            {
              ...prevData.datasets[1],
              borderColor: gradient2Ref.current,
            },
            {
              ...prevData.datasets[2],
              borderColor: gradient3Ref.current,
            },
          ],
        }));
        setGradientsApplied(true);
      }
    };

    // Apply gradients after the chart is rendered
    applyGradients();

    // Throttled resize handler to recreate gradients
    const handleResize = throttle(() => {
      setGradientsApplied(false); // Allow gradients to be reapplied
      applyGradients();
    }, 300);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [xAxis, yAxis, yAxis2, yAxis3, gradientColors1, gradientColors2, gradientColors3, gradientsApplied]);

  return (
    <div className="inner">
      {showLoaders && <Loader />}
      {graphView ? (
        xAxis.length > 0 && (
          <div style={{ position: 'relative', height: '400px' }}>
            <Line ref={chartRef} data={chartData} options={chartOptions} />
          </div>
        )
      ) : (
        <div className="table-content" style={{paddingRight:13}}>
          {programData.map((data, index) => (
            <div key={index} className="table-row">
              <div className="table-col name">{data.showName}</div>
              <div className="table-col" >{formatNumber(data.numberOfActiveUsers)}</div>
              <div className="table-col" style={{color: appTheme === "dark" ? '#ffffff' : 'black'}}>{formatNumber(data.totalWatchHours?.toFixed(2))}</div>
              <div className="table-col">{TimeConverter(data.averageWatchTimePerUser)}</div>
              <div className="table-col">{TimeConverter(data.averageWatchTimePerSession)}</div>
            </div>
          ))}
        </div>
      )}
      {showPagination && !graphView && (
        <div className="pagination pagination-table flex items-center mt-4" style={{backgroundColor:"#363636"}}>
          <div className="items-per-page mr-4">
            <label htmlFor="itemsPerPage" className="mr-2">Items per page:</label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="selectboxpage p-1"
              style={{border:'none'}}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`mx-1  rounded bg-gray-300 ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-600"}`}
          >
            <img src={leftarrow} alt="Previous" />
          </button>
          <div className="page-buttons flex">
            {renderPaginationButtons()}
          </div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`mx-1 rounded bg-gray-300 ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-600"}`}
          >
            <img src={rightarrow} alt="Next" />
          </button>
        </div>
      )}
    </div>
  );
}

export default AccordionChild;









