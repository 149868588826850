import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import "./monitorGridView.scss";
import ReactHlsPlayer from 'react-hls-player';

const MonitorGridView = ({ allChannelData }) => {
    const maxRows = 3;
    const maxColumns = 4;
    const maxChannels = maxRows * maxColumns; 

    const [currentChannels, setCurrentChannels] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    

    const getCurrentOrUpcomingVideo = (scheduleItems) => {
        const currentTime = new Date();
        let liveVideo = null;
        let upcomingVideo = null;

        scheduleItems.forEach(item => {
            const startDateTime = new Date(item.startTime);
            const endDateTime = new Date(item.endTime);

            if (currentTime >= startDateTime && currentTime <= endDateTime) {
                liveVideo = item;
            }

            if (startDateTime > currentTime) {
                if (!upcomingVideo || startDateTime < new Date(upcomingVideo.startTime)) {
                    upcomingVideo = item;
                }
            }
        });

        return liveVideo || upcomingVideo;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setStartIndex((prevIndex) => (prevIndex + maxChannels) % allChannelData.length);
        }, 30000); 

        return () => clearInterval(interval);
    }, [allChannelData.length]);

    useEffect(() => {
        setCurrentChannels(allChannelData.slice(startIndex, startIndex + maxChannels));
    }, [startIndex, allChannelData]);

    return (
        <div className="monitor-grid-view">
            <div className="monitor-video-grid">
                {currentChannels.map(channel => {
                    let currentVideo = null;
                    if (channel.scheduleItems && channel.scheduleItems.length > 0) {
                        currentVideo = getCurrentOrUpcomingVideo(channel.scheduleItems);
                    }

                    const fallbackVideo = !currentVideo && channel.playbackUrl ? {
                        assetPlaybackUrl: channel.playbackUrl
                    } : null;

                    const videoToShow = currentVideo || fallbackVideo;

                    return (
                        <div key={channel.channelId} className="channel-section">
                            <h4 className="channel-name">{channel.channelName}</h4>

                            {videoToShow && (
                                <div className='monitor-video-player'>
                                    {videoToShow.assetPlaybackUrl.endsWith('m3u8') ? (
                                        <ReactHlsPlayer
                                            src={videoToShow.assetPlaybackUrl}
                                            autoPlay={true}
                                            controls={true}
                                            muted={true}
                                            width="100%"
                                            height="auto"
                                        />
                                    ) : (
                                        <video
                                            className='mp4player'
                                            controls
                                            src={videoToShow.assetPlaybackUrl}
                                            style={{ height: "auto", width: "100%" }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

MonitorGridView.propTypes = {
    allChannelData: PropTypes.array.isRequired
};

export default MonitorGridView;
