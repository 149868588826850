import ellipse from "../../../../../../assets/images/Ellipse.png";

export const ChannelTab = ({ 
    tab, 
    selectedTab, 
    setSelectedTab, 
    list, 
    dataType, 
    locationState, 
    handleDoubleClick
}) => {
    return (
        <div className={`item-wrapper ${tab === selectedTab ? "active" : ""}`}>
            <div
                className="list-item"
                onClick={() => setSelectedTab(tab === selectedTab ? "" : tab)}
            >
                <img src={ellipse} width={10} />
                <div className="channel-name">{tab}</div>
                <span className="channel-arrow"></span>
            </div>
            <ul className="nexted-list">
                {list?.length ? (
                    list.map((data, index) => (
                        <li
                            key={index}
                            className="nexted-list-item"
                            data-type={dataType}
                            draggable={locationState?.state?.permission === "edit"}
                            data-value={JSON.stringify(data)}
                            data-key={index}
                            onDoubleClick={() => handleDoubleClick(data, index)}
                            title={data?.metadata?.title || data?.filename}
                        >
                            <span className="program-name">{data?.metadata?.title || data?.filename}</span>
                        </li>
                    ))
                ) : (
                    <span className="program-name text-center no-list-data">No {tab.toLowerCase()} available.</span>
                )}
            </ul>
        </div>
    );
};