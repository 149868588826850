import { addadsSqId } from "./utilityCommonMethods";

export const handleProgramDelete = (
    index,
    gridList,
    setGridList,
    millisecondsToHuman
) => {
    const updatedGridList = gridList
        .filter((_, i) => i !== index) // Filter out the item at the specified index
        .map((item, i) => ({
            ...item,
            sequence: i + 1, // Reset sequence starting from 1
        }));

    // Now you can use the updatedGridList variable or set it later
    addadsSqId(updatedGridList, millisecondsToHuman, setGridList);
}

export const deleteEvent = (
    seqId, 
    sequenceId,
    gridList,
    setGridList,
    millisecondsToHuman
) => {
    const updatedGridList = gridList.map(item => {
        if (item.sequence === sequenceId) {
            // Filter out the object in adBreaks where adSqId matches seqId
            return {
                ...item,
                adBreaks: item.adBreaks.filter(ad => ad.adSqId !== seqId)
            };
        }
        return item;
    });
    addadsSqId(updatedGridList, millisecondsToHuman, setGridList);
}