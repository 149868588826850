import adMarkers from "../../../../assets/images/adMarkers.svg";

export const AdBreaksContainer = ({
    calculateMinutes,
    data,
    zoomInOut,
    linePosition,
    hoveredId,
    draggableType,
    hoverTime
}) => {
    return (
        <div
          style={{
            height: `${calculateMinutes(
              data?.durationInMillis +
                data?.adBreaks?.reduce((sum, ad) => sum + ad?.durationInMillis, 0) || 0
            ) * zoomInOut}px`,
            width: "36px",
            position: "relative",
          }}
        >
          {linePosition !== null &&
            hoveredId === data?.sequence &&
            (draggableType === "fillers" || draggableType === "ads") && (
              <div
                style={{
                  position: "absolute",
                  pointerEvents: "none",
                  top: `${linePosition}px`,
                  zIndex: "9999",
                  left: "-8",
                  width: "460px",
                  height: "2px",
                  backgroundColor: "blue",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    zIndex: "99999999",
                    top: "-22px",
                    left: "0px",
                    backgroundColor: "white",
                    padding: "2px 5px",
                    fontSize: "12px",
                  }}
                >
                  {hoverTime} min
                </span>
              </div>
            )}
    
          {data?.adBreaks?.length !== 0 &&
            data?.adBreaks?.map((ad) => {
              const isPromo = ad?.assetType === "PROMO";
              const style = isPromo
                ? {
                    position: "absolute",
                    top: `${calculateMinutes(ad?.startOffsetInMillis) * zoomInOut}px`,
                    height: "0px",
                    filter: "invert(30%) sepia(90%) saturate(400%) hue-rotate(75deg) brightness(90%) contrast(90%)",
                  }
                : {
                    position: "absolute",
                    top: `${calculateMinutes(ad?.startOffsetInMillis) * zoomInOut}px`,
                    height: "0px",
                  };
    
              return (
                <span key={ad?.adSqId} style={{ position: "absolute", zIndex: `${ad?.adSqId}`, ...style }}>
                  <img
                    src={adMarkers}
                    width={35}
                    style={{ position: "absolute", top: "-4px" }}
                    alt="ad marker"
                  />
                </span>
              );
            })}
        </div>
    );
}