export const NavigationButtons = ({
    appTheme,
    programDelete,
    assetInfo,
    toGetAssetInfo,
    gridList
}) => {
    return (
        <div className={`bottom-container-buttons`}>
            <div className="buttons-container p-2 d-flex justify-content-between">
                <a 
                    className={`btn btn-secondary w-50 mx-3 ${appTheme === "dark"? 'text-white': ''}`} 
                    onClick={() => {programDelete(assetInfo?.sequence - 1)}}
                >Delete</a>
                <a 
                    className="btn btn-danger w-50 mx-3"
                    onClick={(e) => { 
                        e.preventDefault();
                        if ((assetInfo?.sequence - 1) > 0) {
                            toGetAssetInfo(assetInfo?.sequence - 1);
                            document
                            .getElementById(`sequenceId${assetInfo?.sequence - 1}`)
                            ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                        }
                    }}
                >Previous</a>
                <a 
                    className="btn btn-primary w-50 mx-3"
                    onClick={(e) => {
                        e.preventDefault();
                        if (assetInfo?.sequence < gridList.length) {
                            toGetAssetInfo(assetInfo?.sequence + 1);
                            document
                            .getElementById(`sequenceId${assetInfo?.sequence + 1}`)
                            ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                        }
                    }}
                >Next</a>
            </div>
        </div>
    )
}