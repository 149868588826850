import { addadsSqId } from "./utilityCommonMethods";

export const searchProgram = (
    text,
    originalProgramList,
    setProgramList,
    setSearchedText,
) => {
    if (text) {
        const filterPrograms = originalProgramList?.filter((data) => {
            return data?.metadata?.title?.toLowerCase().includes(text.toLowerCase());
        });
        setProgramList(filterPrograms);
    } else {
        // If text is empty or null, show the original program list
        setProgramList(originalProgramList);
    }
    setSearchedText(text);
};

export const addAdsOnClick = async(
    sequenceId, 
    panelDate,
    locationState,
    adValidate,
    toast,
    gridList,
    setAssetInfo,
    setGridList,
    millisecondsToHuman,
) => { 
    let value = locationState?.state?.defaultFiller;

    // const assetValidate = await adValidate(value["id"]);
    //     if (!assetValidate?.status) {
    //         toast.error(assetValidate?.message, {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             autoClose: 3000
    //         });
    //         return;
    //     } 

    // Function to check if the new ad break overlaps with existing ad breaks
    function isOverlapping(adBreaks, newAd) {
        return adBreaks.some(ad => {
            const adStart = ad.startOffsetInMillis;
            const adEnd = ad.startOffsetInMillis + ad.duration;
            const newAdStart = newAd.startOffsetInMillis;
            const newAdEnd = newAd.startOffsetInMillis + newAd.duration;

            const isOverlap = 
                (newAdStart >= adStart && newAdStart < adEnd) || 
                (newAdEnd > adStart && newAdEnd <= adEnd) || 
                (newAdStart <= adStart && newAdEnd >= adEnd);

            if (isOverlap) {
                toast.error("New Ad cannot overlap with an existing ad.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                });
            }
            return isOverlap;
        });
    }
    
    let updatedGridList = gridList.map(item => {
        // Match the grid item by panelDate
        if (item.date === panelDate) {
            let isGridEdited = false;
            // Match scheduleItems by sequenceId
            const updatedScheduleItems = item.scheduleItems.map((schedule, index) => {
                if (schedule.sequence === Number(sequenceId)) {
                    const lastAdBreak = schedule.adBreaks[schedule.adBreaks.length - 1];
                    const lastStartOffset = lastAdBreak ? lastAdBreak?.startOffsetInMillis + lastAdBreak?.duration: 0;
                    const newStartOffset = lastStartOffset; // Adjust as needed for increment

                    const totalAdBreakDuration = schedule?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0);
                    const NewhoverTime = newStartOffset + verifyMilliSeconds("00:00:30") - totalAdBreakDuration;

                    let obj = {
                        adSqId: lastAdBreak?.adSqId + 1,
                        assetId: value["id"],
                        type: "filler",
                        adInsertionPoint: NewhoverTime,
                        adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
                        duration: value["duration"],
                        startOffsetInMillis: newStartOffset,
                        title: value["filename"],
                        date: null,
                    };    
                    const isValid = !isOverlapping(schedule.adBreaks, obj);

                    if (isValid) {
                        isGridEdited = true; // Mark grid as edited
                    }
                    // Add the new adBreak to the schedule item
                    // return { 
                    //     ...schedule, 
                    //     adBreaks: !isOverlapping(schedule.adBreaks, obj) 
                    //     // && isValidAdBreakAddition(item.scheduleItems, index, obj)
                    //     ?[...schedule.adBreaks, obj] 
                    //     :schedule.adBreaks
                    // };
                    return {
                        ...schedule,
                        adBreaks: isValid ? [...schedule.adBreaks, obj] : schedule.adBreaks
                    };
                }
                return schedule;
            });

            // Return the updated item with modified scheduleItems
            // return { ...item, scheduleItems: updatedScheduleItems };
            return {
                ...item,
                scheduleItems: updatedScheduleItems,
                isEdit: isGridEdited ? true : item.isEdit, // Set isEdit only if edited
            };
        }
        return item;
    });

    // Update the gridList with the new data
    // setGridList(updatedGridList);
    addadsSqId(updatedGridList, panelDate, setGridList, millisecondsToHuman);

    // Find the updated matching schedule to pass the panelDate and set asset info
    const updatedMatchingSchedule = updatedGridList.find(
        item => item.date === panelDate
    );
    const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
        item => item.sequence === sequenceId
    );
    if (matchedAsset) {
        setAssetInfo({ ...matchedAsset, panelDate: panelDate });
    }
};

export const formatTime = (epoch) => {
    if (!epoch) return "Invalid Time";
    const date = new Date(epoch);
    // return date.toISOString().split('T')[1].split('.')[0]; // This will return time in 24-hour format (HH:mm:ss) in UTC
    return date.toLocaleTimeString('en-GB', { hour12: false }); // Returns time in HH:mm:ss format
};

export const generateTimeSlots = () => {
    let timeSlotsArray = [];
    let currentHour = 0; // Start at 00:00

    while (currentHour < 24) {
        let hours = Math.floor(currentHour).toString().padStart(2, '0'); // Convert to 2-digit format
        let minutes = (currentHour % 1 === 0) ? '00' : '30'; // Set minutes to 00 or 30
        
        // Format the time as HH:MM
        let time = `${hours}:${minutes}`;
        timeSlotsArray.push(time);

        // Increment by 30 minutes
        currentHour += 0.5;
    }
    return timeSlotsArray;
};

const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;
export const verifyMilliSeconds = (duration) => {
    const len = duration?.toString()?.split(":");
    if(len?.length > 1) {
        return parseInt(toMilliseconds(len[0], len[1], len[2]))
    } else {
        return parseInt(duration);
    }
};

export const millisecondsToHuman = (duration) => {
    // if(duration === 0) return '00:00:00';
    let milliseconds = parseInt((duration % 1000) / 100),
    seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)));

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds ;
};

export const calculateMinutes = (durationInMillis) => {
    const durationInMinutes = durationInMillis / 60000;
    // const roundedMinutes = Math.round(durationInMinutes); // Rounds to nearest minute
    return durationInMinutes;
};

export const millisecondsSinceMidnightUTC = (epochTime) => {
    const date = new Date(epochTime);

    // Extract UTC hours, minutes, seconds, and milliseconds
    // const utcHours = date.getUTCHours();
    // const utcMinutes = date.getUTCMinutes();
    // const utcSeconds = date.getUTCSeconds();
    // const utcMilliseconds = date.getUTCMilliseconds();
    const utcHours = date.getHours();
    const utcMinutes = date.getMinutes();
    const utcSeconds = date.getSeconds();
    const utcMilliseconds = date.getMilliseconds();

    // Calculate milliseconds since midnight UTC
    const millisecondsSinceMidnight =
        (utcHours * 60 * 60 * 1000) + // Hours to milliseconds
        (utcMinutes * 60 * 1000) +   // Minutes to milliseconds
        (utcSeconds * 1000) +        // Seconds to milliseconds
        utcMilliseconds;             // Add remaining milliseconds
    return millisecondsSinceMidnight;
};

export const getAssetInfo = (
    sequenceId, 
    panelDate, 
    isSplit,
    gridList,
    setAssetInfo,
    setSeqId,
    setShowPopup,
) => {
    if (isSplit) {
        return;
    }
    // Find the schedule for the matching panelDate
    const matchingSchedule = gridList.find(item => item.date === panelDate);
    if (matchingSchedule && matchingSchedule.scheduleItems?.length > 0) {
        // Find the schedule item with the matching sequenceId
        const matchedAsset = matchingSchedule.scheduleItems.find(
            item => item.sequence === Number(sequenceId)
        );
        
        if (matchedAsset) {
            setAssetInfo({...matchedAsset, panelDate: panelDate, panelLength: matchingSchedule?.scheduleItems.length});
            setSeqId(sequenceId);
            setShowPopup(true);
        } else {
            // Handle case where sequenceId doesn't match any item
            const lastItem = matchingSchedule.scheduleItems[matchingSchedule.scheduleItems.length - 1];
            setAssetInfo({...matchedAsset, panelDate: panelDate, panelLength: matchingSchedule?.scheduleItems.length} || null);
            setSeqId(lastItem?.sequence || null);
            setShowPopup(true);
        }
    } else {
        // Handle case where no matching panelDate or empty scheduleItems
        setAssetInfo(null);
        setSeqId(null);
        setShowPopup(false);
    }
};

export const selectedFiller = (
    id, 
    adSqId, 
    sequenceId, 
    panelDate,
    fillerList,
    gridList,
    setGridList,
    setAssetInfo,
) => {
    const matchedFiller = fillerList.find(item => item.id === Number(id));
    const updatedGridList = gridList.map(dateItem => {
        if (dateItem.date === panelDate) {
            return {
                ...dateItem,
                scheduleItems: dateItem.scheduleItems.map(scheduleItem => {
                    if (scheduleItem.sequence === sequenceId) {
                        return {
                            ...scheduleItem,
                            adBreaks: scheduleItem.adBreaks.map(ad => {
                                if (ad.adSqId === adSqId) {
                                    const assetId = matchedFiller["id"];
                                    const duration = matchedFiller["duration"];
                                    const title = matchedFiller["filename"];
                                    const assetType = "filler";
                                    const date = null;
                                    return {
                                        ...ad,
                                        assetId,
                                        duration,
                                        title,
                                        assetType,
                                        date,
                                    };
                                }
                                return ad;
                            }),
                        };
                    }
                    return scheduleItem;
                }),
            };
        }
        return dateItem;
    });
    setGridList(updatedGridList);
    const updatedMatchingSchedule = updatedGridList.find(
        item => item.date === panelDate
    );
    const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
        item => item.sequence === sequenceId
    );
    if (matchedAsset) {
        setAssetInfo({ ...matchedAsset, panelDate: panelDate });
    }
};

// old setAdsTime without isEdit functioanlity
// export const setAdsTime = (
//     e,
//     adSqId,
//     sequenceId,
//     panelDate,
//     gridList,
//     setGridList,
//     setAssetInfo,
//     toast
// ) => {
//     let convertTime = verifyMilliSeconds(e);

//     const updatedGridList = gridList.map(item => {
//         if (item.date === panelDate) { // Match panelDate with the date in gridList
//             return {
//                 ...item,
//                 scheduleItems: item.scheduleItems.map(schedule => {
//                     if (schedule.sequence === sequenceId) { // Match the sequence inside scheduleItems
//                         let totalDuration = 0;
//                         let updatedAdBreaks = schedule.adBreaks.map(ad => {
//                             if (schedule.duration < convertTime) {
//                                 toast.error("Ads cannot be set beyond the video's duration.", {
//                                     position: toast.POSITION.BOTTOM_RIGHT,
//                                     autoClose: 3000
//                                 });
//                                 return ad;
//                             }

//                             const isOverlapping = schedule.adBreaks.some(
//                                 a => a.adInsertionPoint === convertTime && a.adSqId !== ad.adSqId
//                             );

//                             if (isOverlapping) {
//                                 toast.error("Ad cannot overlap with an existing ad.", {
//                                     position: toast.POSITION.BOTTOM_RIGHT,
//                                     autoClose: 3000
//                                 });
//                                 return ad;
//                             }

//                             if (ad.adSqId === adSqId) {
//                                 return {
//                                     ...ad,
//                                     adInsertionPoint: convertTime,
//                                     adInsertionPointFormat: e
//                                 };
//                             }

//                             return ad;
//                         });

//                         // **Sort & Reassign `adSqId`**
//                         updatedAdBreaks = updatedAdBreaks
//                             .sort((a, b) => a.adInsertionPoint - b.adInsertionPoint)
//                             .map((ad, index) => ({ ...ad, adSqId: index + 1 }));

//                         // **Recalculate Offsets**
//                         totalDuration = 0;
//                         updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
//                             if (index > 0) {
//                                 totalDuration += updatedAdBreaks[index - 1]?.duration || 0;
//                             }
//                             const startOffsetInMillis = ad.adInsertionPoint + totalDuration;

//                             return {
//                                 ...ad,
//                                 startOffsetInMillis,
//                                 startAt: millisecondsToHuman(startOffsetInMillis),
//                             };
//                         });

//                         return { ...schedule, adBreaks: updatedAdBreaks };
//                     }
//                     return schedule;
//                 })
//             };
//         }
//         return item;
//     });

//     setGridList(updatedGridList);

//     const updatedMatchingSchedule = updatedGridList.find(item => item.date === panelDate);
//     const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(item => item.sequence === sequenceId);

//     if (matchedAsset) {
//         setAssetInfo({ ...matchedAsset, panelDate });
//     }
// };

export const setAdsTime = (
    e,
    adSqId,
    sequenceId,
    panelDate,
    gridList,
    setGridList,
    setAssetInfo,
    toast
) => {
    let convertTime = verifyMilliSeconds(e);

    const updatedGridList = gridList.map(item => {
        if (item.date === panelDate) { // Match panelDate with the date in gridList
            let isGridEdited = false; // Track if this grid is edited

            const updatedScheduleItems = item.scheduleItems.map(schedule => {
                if (schedule.sequence === sequenceId) { // Match the sequence inside scheduleItems
                    let totalDuration = 0;
                    let updatedAdBreaks = schedule.adBreaks.map(ad => {
                        if (schedule.duration < convertTime) {
                            toast.error("Ads cannot be set beyond the video's duration.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 3000
                            });
                            return ad;
                        }

                        const isOverlapping = schedule.adBreaks.some(
                            a => a.adInsertionPoint === convertTime && a.adSqId !== ad.adSqId
                        );

                        if (isOverlapping) {
                            toast.error("Ad cannot overlap with an existing ad.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 3000
                            });
                            return ad;
                        }

                        if (ad.adSqId === adSqId) {
                            isGridEdited = true; // Mark this grid as edited
                            return {
                                ...ad,
                                adInsertionPoint: convertTime,
                                adInsertionPointFormat: e
                            };
                        }

                        return ad;
                    });

                    // **Sort & Reassign `adSqId`**
                    updatedAdBreaks = updatedAdBreaks
                        .sort((a, b) => a.adInsertionPoint - b.adInsertionPoint)
                        .map((ad, index) => ({ ...ad, adSqId: index + 1 }));

                    // **Recalculate Offsets**
                    totalDuration = 0;
                    updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
                        if (index > 0) {
                            totalDuration += updatedAdBreaks[index - 1]?.duration || 0;
                        }
                        const startOffsetInMillis = ad.adInsertionPoint + totalDuration;

                        return {
                            ...ad,
                            startOffsetInMillis,
                            startAt: millisecondsToHuman(startOffsetInMillis),
                        };
                    });

                    return { ...schedule, adBreaks: updatedAdBreaks };
                }
                return schedule;
            });

            return {
                ...item,
                scheduleItems: updatedScheduleItems,
                isEdit: isGridEdited ? true : item.isEdit, // Set isEdit only if edited
            };
        }
        return item;
    });

    setGridList(updatedGridList);

    const updatedMatchingSchedule = updatedGridList.find(item => item.date === panelDate);
    const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(item => item.sequence === sequenceId);

    if (matchedAsset) {
        setAssetInfo({ ...matchedAsset, panelDate });
    }
};

export const setProgramTime = (
    e, 
    programUuid, 
    sequenceId, 
    panelDate,
    gridList,
    toast,
    setPanelHoverTime,
    setPanelLinePosition,
    setPanelHoverDate,
    setGridList,
    setAssetInfo,
) => {
    let combinedTimeInMillis = panelDate + verifyMilliSeconds(e);
    let isOverlap = false;
    let updatedGridList = [...gridList];
    
    updatedGridList = updatedGridList.map((grid) => {
        let isGridEdited = false;
        const updatedScheduleItems = grid.scheduleItems.map((scheduleItem) => {
            if (scheduleItem.uuid === programUuid) {
                const updatedStartAt = combinedTimeInMillis;
                const updatedEndAt = updatedStartAt + scheduleItem.totalDuration;

                // Check for overlap with other schedule items in the entire gridList
                const isConflict = updatedGridList.some((grid) => {
                    return grid.scheduleItems.some((item) => {
                        if (item.uuid !== scheduleItem.uuid) {
                            return (
                                (updatedStartAt > item.startAt && updatedStartAt < item.endAt) || // Starts within another program
                                (updatedEndAt > item.startAt && updatedEndAt < item.endAt) ||   // Ends within another program
                                (updatedStartAt < item.startAt && updatedEndAt > item.endAt)  // Envelops another program
                            );
                        }
                        return false;
                    });
                });

                if (isConflict) {
                    isOverlap = true;
                }

                isGridEdited = true;

                return {
                    ...scheduleItem,
                    startAt: updatedStartAt,
                    endAt: updatedEndAt,
                };
            }
            return scheduleItem;
        });

        return {
            ...grid,
            scheduleItems: updatedScheduleItems,
            isEdit: isGridEdited ? true : grid.isEdit
        };
    });

    // If overlap detected, show error and exit early
    if (isOverlap) {
        toast.error("Program cannot overlap with an existing program.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
        });
        setPanelHoverTime(null);
        setPanelLinePosition(null);
        setPanelHoverDate(null);
        return;
    }

    // Sort scheduleItems by startAt and update sequence numbers
    updatedGridList = updatedGridList.map((grid) => {
        const sortedScheduleItems = grid.scheduleItems.sort((a, b) => a.startAt - b.startAt);
        return {
            ...grid,
            scheduleItems: sortedScheduleItems.map((item, index) => ({
                ...item,
                sequence: index + 1,
            })),
        };
    });
    // Update state
    setGridList(updatedGridList);
    const updatedMatchingSchedule = updatedGridList.find(
        item => item.date === panelDate
    );
    const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
        item => item.sequence === sequenceId
    );
    if (matchedAsset) {
        setAssetInfo({ ...matchedAsset, panelDate: panelDate });
    }
}

export const splitScheduleItems = (
    gridList,
    _, 
    setNewGridData,
) => {
    // Deep clone gridList to avoid mutating the original data
    const clonedGridList = _.cloneDeep(gridList);

    for (let i = 0; i < clonedGridList?.length; i++) {
        let currentDay = clonedGridList[i];
        let nextDay = clonedGridList[i + 1];
        let lastItem = currentDay.scheduleItems[currentDay.scheduleItems.length - 1]; // Last item of current day's scheduleItems

        if (lastItem && lastItem.endAt > currentDay.date + 86400000) { // Check if the item overflows the day boundary
            // Define 23:59:59 for the current day
            const dayBoundary = currentDay.date + 86400000; // 24:00:00 in milliseconds

            // Calculate overflow time (endAt - 24:00:00)
            let overflow = lastItem.endAt - dayBoundary;

            // Adjust last item's endAt time to 24:00:00
            let adjustedEndAt = dayBoundary;
            let adjustedDuration = lastItem.totalDuration - overflow;

            // Split adBreaks for the first and second parts
            const splitAdBreaks = (adBreaks, duration, newStartAt) => {
                let firstPartAdBreaks = [];
                let secondPartAdBreaks = [];

                for (const adBreak of adBreaks) {
                    if (adBreak.startOffsetInMillis < duration) {
                        // AdBreak belongs to the first part
                        firstPartAdBreaks.push(adBreak);
                    } else {
                        // Adjust the offset for the second part
                        secondPartAdBreaks.push({
                            ...adBreak,
                            startOffsetInMillis: adBreak.startOffsetInMillis - duration
                        });
                    }
                }

                return { firstPartAdBreaks, secondPartAdBreaks };
            };

            const { firstPartAdBreaks, secondPartAdBreaks } = splitAdBreaks(
                lastItem.adBreaks || [],
                adjustedDuration,
                adjustedEndAt
            );

            // Create a new object for the overflow
            let newObject = {
                ...lastItem,
                startAt: dayBoundary + 1, // New start time for the overflow
                endAt: lastItem.endAt,   // Original end time remains
                totalDuration: overflow,
                adBreaks: secondPartAdBreaks, // Assign second part adBreaks
                isSplit: true,
            };

            // Update the last item's properties
            lastItem.endAt = adjustedEndAt; // Set endAt to 24:00:00
            lastItem.totalDuration = adjustedDuration;
            lastItem.lastSplitItem = true;
            lastItem.adBreaks = firstPartAdBreaks;

            // Add the new object to the next day's scheduleItems
            if (nextDay) {
                nextDay.scheduleItems.unshift(newObject);
            } else {
                // If no next day exists, create a new day
                clonedGridList.push({
                    date: currentDay.date + 86400000,
                    scheduleItems: [newObject]
                });
            }
        }
    }
    // let arr = clonedGridList?.slice(1);
    setNewGridData(clonedGridList);
};

export const pasteDataFun = (
    panelDate,
    gridList,
    copyData,
    setGridList,
    setCopyData,
) => {
    let previousEndAt = panelDate; // Initialize with panelDate (12 AM)
    const allExistingUUIDs = new Set(
        gridList.flatMap(item => item.scheduleItems.map(schedule => schedule.uuid))
    );
    const newUUIDs = new Set();

    const adjustedCopyData = copyData.map((item, index) => {
        const startAt = index === 0 ? panelDate : previousEndAt + 1000; // Add 1 second for subsequent items
        const endAt = startAt + item.totalDuration;

        // Update previousEndAt for the next iteration
        previousEndAt = endAt;

        // Generate a unique UUID
        let newUUID;
        do {
            newUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0;
                const v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        } while (allExistingUUIDs.has(newUUID) || newUUIDs.has(newUUID));

        // Add the new UUID to the set
        newUUIDs.add(newUUID);

        return { ...item, startAt, endAt, uuid: newUUID };
    });

    // Merge adjusted data into gridList
    const updatedGridList = gridList.map((gridItem) => {
        if (gridItem.date === panelDate) {
            return {
                ...gridItem,
                scheduleItems: [...gridItem.scheduleItems, ...adjustedCopyData],
            };
        }
        return gridItem;
    });

    // Update the state
    setGridList(updatedGridList);
    setCopyData([]);
};

export const trimPrograms = (
    panelDate,
    gridList,
    setGridList,
) => {
    if (panelDate) {
        const updatedGridList = gridList.map((entry) => {
            if (entry.date === panelDate) {
                let previousEndAt = 0; // To keep track of the end time of the previous program
                
                entry.scheduleItems = entry.scheduleItems.map((program, index) => {
                    const programStartAt = new Date(entry.date); // Start at midnight of the matching date
                    
                    programStartAt.setHours(0, 0, 0, 0); // Set to 00:00:00 UTC
                    const previousDayIndex = gridList.findIndex(item => item.date === (entry.date));
                    if (index === 0) {
                        if (previousDayIndex - 1 !== -1) {
                            let previousDaySchedules = gridList[previousDayIndex - 1]?.scheduleItems || [];
                            previousDaySchedules = previousDaySchedules[previousDaySchedules.length - 1];
                            if (previousDaySchedules?.endAt >= panelDate) {
                                programStartAt.setTime(previousDaySchedules?.endAt);
                            }
                        }
                    }
                    
                    // If it's not the first program, update the start time based on previous program's end time
                    if (index !== 0) {
                        programStartAt.setTime(previousEndAt);
                    }
    
                    const programEndAt = new Date(programStartAt).getTime() + program.totalDuration; // Calculate end time by adding total duration
                    previousEndAt = programEndAt; // Update previousEndAt for the next iteration
    
                    // Update program with new startAt and endAt
                    return {
                        ...program,
                        startAt: programStartAt.getTime(),
                        endAt: programEndAt
                    };
                });
            }
    
            return entry;
        });
        setGridList(updatedGridList);
    } else {
        const updatedGridList = gridList.map((entry) => {
            let previousEndAt = 0; // To keep track of the end time of the previous program
            
            entry.scheduleItems = entry.scheduleItems.map((program, index) => {
                const programStartAt = new Date(entry.date); // Start at midnight of the current entry's date
                programStartAt.setHours(0, 0, 0, 0); // Set to 00:00:00 UTC
                
                const previousDayIndex = gridList.findIndex(item => item.date === (entry.date));
                
                if (index === 0) {
                    if (previousDayIndex - 1 !== -1) {
                        let previousDaySchedules = gridList[previousDayIndex - 1]?.scheduleItems || [];
                        previousDaySchedules = previousDaySchedules[previousDaySchedules.length - 1];
                        if (previousDaySchedules?.endAt >= entry?.date) {
                            programStartAt.setTime(previousDaySchedules?.endAt);
                        }
                    }
                }
                
                // If it's not the first program, update the start time based on previous program's end time
                if (index !== 0) {
                    programStartAt.setTime(previousEndAt);
                }
    
                const programEndAt = new Date(programStartAt).getTime() + program.totalDuration; // Calculate end time by adding total duration
                previousEndAt = programEndAt; // Update previousEndAt for the next iteration
    
                // Update program with new startAt and endAt
                return {
                    ...program,
                    startAt: programStartAt.getTime(),
                    endAt: programEndAt
                };
            });
    
            return entry;
        });
    
        setGridList(updatedGridList);
    }
};