import { addadsSqId, getFormattedDate, addToDragabbleList } from "./utilityCommonMethods";

function generateUniqueUUID(gridList) {
    // Generate a UUID and ensure it is unique within gridList
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let uuid;
        do {
            const r = Math.random() * 16 | 0; 
            const v = c === 'x' ? r : (r & 0x3 | 0x8); 
            uuid = v.toString(16); 
        } while (gridList.some(item => item.uuid === uuid));
        return uuid;
    });
}

export const handleDrop = async (
    event,
    setHoveredId,
    setLinePosition,
    draggedItem,
    setSeqId,
    gridList,
    setHoverTime,
    adValidate,
    verifyMilliSeconds,
    hoverTime,
    millisecondsToHuman,
    toast,
    setCategoryTab,
    setGridList,
    programValidate
) => {
    event.preventDefault();
    setHoveredId(null);
    setLinePosition(null);
    // setHoverTime(null);
    let { type, value, key } = draggedItem.current.dataset;    
    let { id } = event.target.dataset;
    setSeqId(id);
    try {
        value = JSON.parse(value);
    } catch (error) {
        // If JSON.parse fails, leave value as-is (e.g., time string "00:00:30") for ads
    }
    // value = value ? JSON.parse(value) : value;
    let ads = [];
    let obj = {};
    if (type == "programs" && value) {

        const assetValidate = await programValidate(value["id"]);
        if (!assetValidate?.status) {
            toast.error(assetValidate?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            return;
        }

        if (value?.defaultAdMarkers.length) {
            let totalPrevDuration = 0;
            const updatedAdBreaks = value?.defaultAdMarkers
                ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
                .map((ad, index) => {
                    const updatedAd = {
                        ...ad,
                        adSqId: index + 1, // Add adSqId
                        adInsertionPoint: ad.startOffsetInMillis - totalPrevDuration
                    };
                    totalPrevDuration += ad.durationInMillis; // Update total duration
                    return updatedAd; // Return updated ad object
                });

            console.log('updatedAdBreaks', updatedAdBreaks);
            ads = updatedAdBreaks;
        }
        const lastItem = gridList[gridList.length - 1];
        const uuid = generateUniqueUUID(gridList);
            
        obj = {
            assetId: value["id"],
            assetType: "PROGRAM",
            title: value["metadata"]["title"],
            durationInMillis: value["duration"],
            scheduleStartTime: lastItem?.scheduleEndTime? lastItem?.scheduleEndTime : 0,
            scheduleEndTime : lastItem?.scheduleEndTime? lastItem?.scheduleEndTime + value["duration"] : 0 + value["duration"],
            adBreaks: ads,
            new: true,
            // program: {
            //     partner: {
            //     id: key,
            //     },
            // },
            uuid: uuid,
        };
        // Find the last matching item in gridList with the same assetId
        const latestIndex = gridList?.findLastIndex(item => item.assetId === value["id"]);
        if (latestIndex !== -1) {
            // Copy adBreaks from the latest matching item in gridList to obj
            obj.adBreaks = [...gridList[latestIndex].adBreaks];
        }
        // Add obj to the draggable list with adBreaks populated
        addToDragabbleList(obj, gridList, setGridList);
        setHoverTime(null);
        return;
    }
    if (type == "fillers" && value) {  
        const assetValidate = await adValidate(value["id"]);
        if (!assetValidate?.status) {
            toast.error(assetValidate?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            return;
        }        
        
        const gridItem = gridList.find((item) => item.sequence === Number.parseInt(id))
        // const totalAdBreakDuration = gridItem?.adBreaks?.reduce((sum, ad) => sum + (ad.durationInMillis || 0), 0);
        const totalAdBreakDuration = (gridItem?.adBreaks ?? []).reduce((sum, ad) => {
            return ad.startOffsetInMillis < verifyMilliSeconds(hoverTime) 
                ? sum + (ad.durationInMillis ?? 0) 
                : sum;
        }, 0);
        const NewhoverTime = verifyMilliSeconds(hoverTime) - totalAdBreakDuration;
        // console.log(`NewhoverTime: ${millisecondsToHuman(NewhoverTime)}, totalAdBreakDuration: ${millisecondsToHuman(totalAdBreakDuration)}`);
        
        obj = {
            assetId: value["id"],
            assetType: "FILLER",
            adInsertionPoint: NewhoverTime,
            adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
            duration: value["duration"],
            durationInMillis: value["duration"],
            startAt: hoverTime,
            startOffsetInMillis: verifyMilliSeconds(hoverTime),
            endAt: millisecondsToHuman(verifyMilliSeconds(hoverTime) + value["duration"]),
            endOffsetInMillis: verifyMilliSeconds(hoverTime) + value["duration"],
            title: value["filename"],
            scheduledDate: getFormattedDate(),
            date: getFormattedDate(),
        };

        function isOverlapping(adBreaks, newAd) {
            return adBreaks.some((ad, index) => {
                const adStart = ad.startOffsetInMillis;
                const adEnd = ad.startOffsetInMillis + ad.duration;
                const newAdStart = newAd.startOffsetInMillis;
                const newAdEnd = newAd.startOffsetInMillis + newAd.duration;
                const isOverlap = 
                    (newAdStart >= adStart && newAdStart < adEnd) ||
                    (newAdEnd > adStart && newAdEnd <= adEnd) ||
                    (newAdStart <= adStart && newAdEnd >= adEnd);
        
                if (isOverlap) {
                    toast.error("New Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000
                    });
                }
                return isOverlap;
            });
        }

        const updatedGridList = gridList.map(item => 
            item.sequence === Number(id) 
                ? {
                    ...item, 
                    adBreaks: isOverlapping(item.adBreaks, obj) 
                        ? item.adBreaks // Keep existing adBreaks if there's overlap
                        : [...item.adBreaks, obj], // Add new obj if no overlap
                    } 
                : item
        );
        addadsSqId(updatedGridList, millisecondsToHuman, setGridList);
        setCategoryTab("Ads");
        setHoverTime(null);
        return;
    }
    if (type == "ads" && value) {   
        const assetValidate = await adValidate(value["id"]);
        if (!assetValidate?.status) {
            toast.error(assetValidate?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            return;
        }    
        const gridItem = gridList.find((item) => item.sequence === Number.parseInt(id))
        const totalAdBreakDuration = gridItem?.adBreaks?.reduce((sum, ad) => sum + (ad.durationInMillis || 0), 0);
        const NewhoverTime = verifyMilliSeconds(hoverTime) - totalAdBreakDuration;
            
        obj = {
            assetId: value["id"],
            assetType: "AD",
            adInsertionPoint: NewhoverTime,
            adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
            duration: value["duration"],
            durationInMillis: value["duration"],
            startAt: hoverTime,
            startOffsetInMillis: verifyMilliSeconds(hoverTime),
            endAt: millisecondsToHuman(verifyMilliSeconds(hoverTime) + value["duration"]),
            endOffsetInMillis: verifyMilliSeconds(hoverTime) + value["duration"],
            title: value["filename"],
            scheduledDate: getFormattedDate(),
            date: getFormattedDate(),
        }
        function isOverlapping(adBreaks, newAd) {
            return adBreaks.some((ad, index) => {
                const adStart = ad.startOffsetInMillis;
                const adEnd = ad.startOffsetInMillis + ad.duration;
                const newAdStart = newAd.startOffsetInMillis;
                const newAdEnd = newAd.startOffsetInMillis + newAd.duration;
                const isOverlap = 
                    (newAdStart >= adStart && newAdStart < adEnd) ||
                    (newAdEnd > adStart && newAdEnd <= adEnd) ||
                    (newAdStart <= adStart && newAdEnd >= adEnd);
        
                if (isOverlap) {
                    toast.error("New Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000
                    });
                }
                return isOverlap;
            });
        }

        const updatedGridList = gridList.map(item => 
            item.sequence === Number(id) 
                ? {
                    ...item, 
                    adBreaks: isOverlapping(item.adBreaks, obj) 
                        ? item.adBreaks // Keep existing adBreaks if there's overlap
                        : [...item.adBreaks, obj], // Add new obj if no overlap
                    } 
                : item
        );
        addadsSqId(updatedGridList, millisecondsToHuman, setGridList);
        setCategoryTab("Ads");
        setHoverTime(null);
        return;
    }
}

export const handelProgramDoubleClick = (
    data, 
    index,
    gridList,
    setGridList
) => {
    let ads = [];
    if (data?.defaultAdMarkers.length) {
        const updatedAdBreaks = data?.defaultAdMarkers
        ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
        .map((ad, index) => ({
            ...ad,
            adSqId: index + 1, // Reassign incremental adSqId after sorting
        }));
        ads = updatedAdBreaks;
    }
    const lastItem = gridList[gridList.length - 1];
    const uuid = generateUniqueUUID(gridList);
    let obj = {
        assetId: data["id"],
        assetType: "PROGRAM",
        title: data["metadata"]["title"],
        durationInMillis: data["duration"],
        scheduleStartTime: lastItem?.scheduleEndTime? lastItem?.scheduleEndTime : 0,
        scheduleEndTime : lastItem?.scheduleEndTime? lastItem?.scheduleEndTime + data["duration"] : 0 + data["duration"],
        adBreaks: ads,
        new: true,
        // program: {
        //     partner: {
        //     id: index,
        //     },
        // },
        uuid: uuid,
    };
     // Find the last matching item in gridList with the same assetId
    const latestIndex = gridList?.findLastIndex(item => item.assetId === data["id"]);
    if (latestIndex !== -1) {
        // Copy adBreaks from the latest matching item in gridList to obj
        obj.adBreaks = [...gridList[latestIndex].adBreaks];
    }
    // Add obj to the draggable list with adBreaks populated
    addToDragabbleList(obj, gridList, setGridList);
}