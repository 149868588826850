export const handleDragStart = (event, draggedItem) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
};

export const handleDragOver = (
    event, 
    date,
    draggedItem,
    setDraggableType,
    setPanelHoverDate,
    setPanelLinePosition,
    setPanelHoverTime,
) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    let { type, value, key } = draggedItem.current.dataset; 
    setDraggableType(type);
    setPanelHoverDate(date)
    const containerTop = event.currentTarget.getBoundingClientRect().top;
    const containerHeight = event.currentTarget.getBoundingClientRect().height;

    const yPos = event.clientY - containerTop;
    const durationPerPixel = 1440 / containerHeight; // 1440 minutes in 24 hours divided by the panel's height
    const timeAtPosition = yPos * durationPerPixel;

    // Convert time from minutes to hh:mm:ss format
    let totalSeconds = Math.floor(timeAtPosition * 60);
    if (totalSeconds < 0) {
        totalSeconds = 0;
    }
    const hours = Math.floor(totalSeconds / 3600) % 24; // Ensure hours wrap around at 24
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    setPanelLinePosition(yPos);
    setPanelHoverTime(formattedTime);
};

export const handleDragOver2 = (
    event, 
    id,
    draggedItem,
    setDraggableType,
    setHoveredId,
    zoomInOut,
    setLinePosition,
    setHoverTime,
) => {
    event.preventDefault();
    let { type, value, key } = draggedItem.current.dataset;         
    setDraggableType(type);
    setHoveredId(id); // Set the currently hovered asset ID
    const containerTop = event.currentTarget.getBoundingClientRect().top;
    const yPos = event.clientY - containerTop;
    const durationPerPixel = 1/zoomInOut; // Adjust this as needed
    const timeAtPosition = yPos * durationPerPixel;

    // Convert time from minutes to hh:mm:ss format
    const totalSeconds = Math.floor(timeAtPosition * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    
    setLinePosition(yPos);
    setHoverTime(formattedTime);
};

export const channelDragStart = (event, draggedItem) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
};