import React, { useState } from "react";
import "./CustomTooltip.scss";

const CustomTooltip = ({ children, text }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.pageX, y: e.pageY });
  };

  return (
    <span
      className="tooltip-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {children}
      {visible && (
        <span
          className="custom-tooltip"
          style={{ top: position.y + 15, left: position.x + 15 }}
        >
          {text}
        </span>
      )}
    </span>
  );
};

export default CustomTooltip;
