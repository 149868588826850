import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CategoryDurationAction } from "../../Redux/slices";
import { GetMonitorScheduleList, Monitor_Datalist } from "../../api/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SlideBar from "../../component/sidebar/sidebar";
import MonitorGridView from "../../component/girdMonitorView/monitoringGridView";

const GridMonitor = () => {
    const [allChannelData, setAllChannelData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const { MonitorDate } = useSelector((state) => ({ MonitorDate: state.MonitorDate?.data }));

    useEffect(() => {
        if (MonitorDate) {
            fetchSchedule(MonitorDate);
        }
    }, [MonitorDate]);

    const fetchSchedule = async (MonitorDate) => {
        setShowLoader(true);
        try {
            const scheduleData = await GetMonitorScheduleList(MonitorDate);
            if (!scheduleData?.data?.length) {
                setAllChannelData([]);
                dispatch(CategoryDurationAction([]));
                return;
            }
            
            const monitordate = new Date(MonitorDate).toISOString().substring(0, 10);
            const allDataPromises = scheduleData.data.map(channel => Monitor_Datalist(channel.id, monitordate));
            const allDataResponses = await Promise.all(allDataPromises);

            const aggregatedData = scheduleData.data.map((channel, index) => {
                const gridData = allDataResponses[index];
                if (!gridData?.data) return null;
                
                return {
                    channelId: channel.id,
                    channelName: channel.name,
                    channelLogo: channel.channelLogo[0]?.thumbnailSourcePath || "",
                    scheduleItems: gridData.data.scheduleItems || [],
                    playbackUrl: channel.playbackUrl || ""
                };
            }).filter(item => item !== null);

            setAllChannelData(aggregatedData);
            dispatch(CategoryDurationAction(aggregatedData));
        } catch (error) {
            console.error("Error fetching schedule data:", error);
            setAllChannelData([]);
            dispatch(CategoryDurationAction([]));
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <div style={{ padding: 10, background: "#121212", color: "#ffffff", minHeight: "100vh" }}>
            {showLoader ? (
                <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "16px", height: "80vh" }}>
                    {[...Array(12)].map((_, index) => (
                        <div key={index} style={{ padding: "10px", background: "#1e1e1e", borderRadius: "8px", }}>
                             <Skeleton count={1} height={35} baseColor="#333" highlightColor="#555" style={{ marginBottom: "5px" }} />
                            <Skeleton height={180} style={{ marginBottom: "10px" }} baseColor="#333" highlightColor="#555" />
                        </div>
                    ))}
                </div>
            ) : (
                <MonitorGridView allChannelData={allChannelData} />
            )}
        </div>
    );
};

export default GridMonitor;