const gmtOffSetFunc = () => {
    const date = new Date();
    const offset = -date.getTimezoneOffset();
    const sign = offset >= 0 ? '+' : '-';
    const absOffset = Math.abs(offset);
    const hours = String(Math.floor(absOffset / 60)).padStart(2, '0');
    const minutes = String(absOffset % 60).padStart(2, '0');
    
    // const gmtOffset = `GMT${sign}${hours}:${minutes}`;
    const gmtOffset = `${sign}${hours}:${minutes}`;
    return gmtOffset
}

const formattedGridList = (newGridList) => {
    return newGridList.map(item => {
        const dateObj = new Date(item.date);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        
        return {
            ...item,
            date: `${year}-${month}-${day}`
        };
    });
};

export const fetchSchedule = async (
    setLoaderStatus, 
    locationState, 
    Schedules_list, 
    setProgramList,
    setOriginalProgramList,
    setFillerist,
    setPromoList,
    Schedules_Datalist,
    Schedules_Datalist_Ist,
    setGridList,
    startDateUTC,
    endDateUTC,
    setDate
) => {
    setLoaderStatus(true);
    if(!locationState?.state?.channelId) {
        return;
    }
    const scheduleData = await Schedules_list(locationState?.state?.channelId, 'PROGRAM');
    setProgramList(scheduleData?.data);
    setOriginalProgramList(scheduleData?.data);

    const fillerData = await Schedules_list(locationState?.state?.channelId, 'FILLER');
    setFillerist(fillerData?.data);

    const promoData = await Schedules_list(locationState?.state?.channelId, 'PROMO');
    setPromoList(promoData?.data);

    let dataPayload = {
        "startDate": startDateUTC,
        "endDate" : endDateUTC,
        "timeOffset": gmtOffSetFunc()
    }

    const gridData = await Schedules_Datalist_Ist(locationState?.state?.channelId, dataPayload);
    
    const updatedGridList = gridData?.data?.data?.map((item) => {
        // let sequenceCounter = 1;
        let sequenceCounter = 1; // Initialize sequence counter
        const updatedScheduleItems = item?.scheduleItems?.map((scheduleItem, index) => {
            let totalPrevDuration = 0; // Tracks sum of all previous durations

            const updatedAdBreaks = scheduleItem?.adBreaks?.map((adBreak, adIndex) => {
                const updatedAd = {
                    ...adBreak,
                    adSqId: adIndex + 1, // Add adSqId
                    adInsertionPoint: adBreak.startOffsetInMillis - totalPrevDuration
                };
                totalPrevDuration += adBreak.duration; // Update total duration
                return updatedAd;
            });

            return {
                id: scheduleItem.assetId, // Add id field
                ...scheduleItem,
                sequence: sequenceCounter++, // Remove sequence
                adBreaks: updatedAdBreaks,
            };
        });
        
        return {
            // date: item.date,
            // date: Math.floor(new Date(item.date).getTime()),
            date: new Date(item.date).setHours(0, 0, 0, 0),
            published: item?.published,
            // date: new Date(item.date).getTime(),
            scheduleItems: updatedScheduleItems,
            isEdit: false
        };
    });
    
    // Add empty dates for the range
    for (let i = 0; i <= setDate; i++) {
        const currentDate = new Date(startDateUTC);
        // currentDate.setUTCDate(currentDate.getUTCDate() + i);
        currentDate.setDate(currentDate.getDate() + i);

        // Set the time to 12 AM (midnight)
        // currentDate.setUTCHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const epochTimestamp = currentDate.getTime();
        // const formattedDate = currentDate.toISOString().slice(0, 10);
        
        // Check if date already exists in updatedGridList
        // if (!updatedGridList?.some((entry) => entry.date === epochTimestamp)) {
        if (!updatedGridList?.some((entry) => Math.floor(new Date(entry.date).getTime()) === epochTimestamp)) {
            updatedGridList?.push({
                date: epochTimestamp,
                published: false,
                // date: new Date(formattedDate).getTime(),
                scheduleItems: [], // Add empty scheduleItems
                isEdit: false
            });
        }
    }
    
    // Sort dates to maintain order
    updatedGridList?.sort((a, b) => new Date(a.date) - new Date(b.date));
    setGridList(updatedGridList);
    setLoaderStatus(false);
};

export const update_schedule = async (
    panelDate,
    gridList,
    Schedule_update,
    locationState,
    toast,
    today,
    setLoaderStatus,
    newGridData,
    Schedule_update_weekly
) => {
    // let newGridList = gridList.slice(1);
    let originalGridList = gridList.slice(1);
    let newGridList = newGridData.slice(1);

    if (panelDate) {
        const gridListByDate = newGridList.map((item) => ({
            ...item,
            scheduleItems: item.date === panelDate || item.published ? item.scheduleItems : []
        }));
        const formattedGridList = gridListByDate.map(item => {
            const dateObj = new Date(item.date);
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it's 0-indexed
            const day = dateObj.getDate().toString().padStart(2, '0'); // Padding single digit days with zero
            
            return {
                ...item,
                date: `${year}-${month}-${day}` // Format as yyyy-mm-dd
            };
        });
        const response = await Schedule_update(formattedGridList, locationState?.state?.channelId);
        if (response) {
            toast.success("Channel Updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            today();
            setLoaderStatus(false);
        } else {
            toast.error("Channel Update failed", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            setLoaderStatus(false);
        }
    } else {
        setLoaderStatus(true);
        const datesArray = [];
        const updatedGridList = newGridData.map((item) => {
            // Saare scheduleItems ke totalDuration ka sum nikalna
            const totalDurationInMillis = item.scheduleItems.reduce((acc, schedule) => acc + schedule.totalDuration, 0);
            // 23:55:00 => 86100000 ms
            const isValid = totalDurationInMillis >= 86100000 || item.published;

            if (isValid) {
                datesArray.push(item?.date);
            }
        });

        // const filteredGridList = newGridList.map(item => ({
        //     ...item,
        //     scheduleItems: datesArray.includes(item.date) ? item.scheduleItems : []
        // }));
        
        // const formattedGridList = filteredGridList.map(item => {
        //     const dateObj = new Date(item.date);
        //     const year = dateObj.getFullYear();
        //     const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it's 0-indexed
        //     const day = dateObj.getDate().toString().padStart(2, '0'); // Padding single digit days with zero
            
        //     return {
        //         ...item,
        //         date: `${year}-${month}-${day}` // Format as yyyy-mm-dd
        //     };
        // });

        let formattedList = formattedGridList(newGridList);
        let originalFormattedGridList = formattedGridList(originalGridList);
    
        const draft = [];
        const publish = [];
        const MILLISECONDS_IN_24_HOURS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        formattedList.forEach((schedule) => {
            const totalDuration = schedule.scheduleItems.reduce(
                (sum, item) => sum + item.totalDuration,
                0
            );

            if (totalDuration >= MILLISECONDS_IN_24_HOURS) {
                publish.push(schedule?.date);
            } else {
                draft.push(schedule?.date);
            }
        });

        let draftData = originalFormattedGridList.filter(entry => 
            draft.some(data => data === entry.date)
        );
        let publishData = originalFormattedGridList.filter(entry => 
            publish.some(data => data === entry.date)
        );

        let payload = {
            timeOffset: gmtOffSetFunc(),
            draft: draftData,
            publish: publishData
        }

        // const response = await Schedule_update(formattedGridList, locationState?.state?.channelId);
        const response = await Schedule_update_weekly(payload, locationState?.state?.channelId);
        console.log('response',response);
        
        if (response) {
            toast.success("Channel Updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            today();
            setLoaderStatus(false);
        } else {
            toast.error("Channel Update failed", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            setLoaderStatus(false);
        }
    }

};

export const confirmSaveAndUpdate = async (
    btnType,
    setLoaderStatus,
    gridList,
    saveLinerPrograms,
    locationState,
    toast,
    today,
    confirmPublishWithDate,
) => {
    if (btnType === "Save") {
        setLoaderStatus(true);
        let newGridList = gridList.slice(1);
        // newGridList = newGridList.map(item => {
        //     const localDate = new Date(item.date); // Convert to Date object
        //     localDate.setHours(0, 0, 0, 0); // Set to local 12 AM
        
        //     const utcDate = new Date(Date.UTC(
        //         localDate.getFullYear(),
        //         localDate.getMonth(),
        //         localDate.getDate(),
        //         0, 0, 0, 0
        //     )); // Convert to UTC 12 AM
        
        //     return { ...item, date: utcDate.getTime() };
        // });

        // for (let i = 1; i < newGridList.length; i++) {
        //     let currentObj = newGridList[i];
        //     let previousObj = newGridList[i - 1];
        
        //     if (currentObj.scheduleItems.length > 0) {
        //         let remainingItems = [];
        
        //         currentObj.scheduleItems.forEach(schedule => {
        //             if (schedule.startAt < currentObj.date) {
        //                 previousObj.scheduleItems.push(schedule);
        //             } else {
        //                 remainingItems.push(schedule);
        //             }
        //         });
        
        //         currentObj.scheduleItems = remainingItems;
        //     }
        // }
        
        const formattedGridList = newGridList.map(item => {
            const dateObj = new Date(item.date);
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it's 0-indexed
            const day = dateObj.getDate().toString().padStart(2, '0'); // Padding single digit days with zero
            
            return {
                ...item,
                date: `${year}-${month}-${day}` // Format as yyyy-mm-dd
            };
        });

        const filteredGridList = formattedGridList.filter((item) => item?.published === false);            
        const response = await saveLinerPrograms(locationState?.state?.channelId, filteredGridList);
        if (response) {
            toast.success("Programs successfully saved.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            today();
            setLoaderStatus(false);
        } else {
            toast.error("Failed to save programs.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            setLoaderStatus(false);
        }
    } else if (btnType === "Save & Publish") {
        confirmPublishWithDate();
    }
}