export const TableBody = ({
    children,
    item,
    handleDragStart,
    draggedItem,
    handleDragOvers,
    handleAdsAndProgramDrop
}) => {
    return (
        <table className="scheduling-table tbl-50-per">
            <tbody
                data-type="table-body"
                role="none"
                onDragStart={(e) => handleDragStart(e, draggedItem)}
                onDragOver={(e) => handleDragOvers(e, item?.date)}
                onDrop={(e) => {handleAdsAndProgramDrop(e, item?.date)}}
            >
                <div className="row w-100 m-0 h-100">
                    {children}
                </div>
            </tbody>
        </table>
    )
}