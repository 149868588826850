// generate react function base component    
import React from "react";
import { useState, useEffect } from "react";
import zee from "../../assets/images/zee2.png";
import editIcon from '../../assets/images/edit-outline.svg';
import viewIcon from '../../assets/images/carbon_view.svg';
import { cloudFront_URL } from '../../service/API_URL';
import Loader from "../../component/Loader/Loader";

import { useSelector, useDispatch } from 'react-redux';
import { CategoryDurationAction } from "../../Redux/slices";

import "./monitor.scss";

import {
    GetMonitorScheduleList,
    Monitor_Datalist
} from "../../api/api";

function Monitor() {
    const [channels, setChannels] = useState([]);
    const [filteredChannels, setFilteredChannels] = useState([]);
    const [upcomingChannels, setUpcomingChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState();
    const [channelData, setChannelData] = useState([]);
    const [allowedEdit, setAllowedEdit] = useState([]);
    const [channelDuration, setChannelDuration] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [categoryDuration, setCategoryDuration] = useState([]);
    const [progressPercent, setProgressPercent] = useState([]);
    const dispatch = useDispatch(); 
    const fetchResumeData = () => {
       document.getElementsByTagName('body')[0].classList.add('dark-theme');
    };
    const getHMS = (dt) => {
        const date = new Date(dt);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const getElapsedTime = (data , type = true) => {
        const currentDate = getHMS(new Date());
        const currentTime = verifyMilliSeconds(currentDate);
        const startTime = verifyMilliSeconds(data);
        let elapsedTime
        if(type) {
            elapsedTime = millisecondsToHuman(currentTime - startTime);
        } else {
            elapsedTime = startTime - currentTime;
        }
        return elapsedTime; 
    }
    
    const getDuration = (data) => {
        const today = new Date();
        const monitor = new Date(MonitorDate);
        let comingUp = '00:00:00';
        // selected date is past date or current date
        if(data?.scheduleItems.length === 0) return data;
        if(monitor <= today && getHMS(new Date()) > data?.scheduleItems?.[0].startTime) {
            if(data?.scheduleItems?.[0]) {
                data.scheduleItems[0].elapsedTime = getElapsedTime(data.scheduleItems[0].startTime);
            }
            if(data?.scheduleItems?.[1]) {
                data.scheduleItems[1].elapsedTime = getElapsedTime(data.scheduleItems[1].startTime);
            }
          //  data.scheduleData[0]
        } else {
            if(data?.scheduleItems?.[0]) {
                data.scheduleItems[0].elapsedTime = '00:00:00';
                data.scheduleItems[0].remainingTime = data.scheduleItems[0].duration;
            }
            if(data?.scheduleItems?.[1]) {
                comingUp = data.scheduleItems[0].duration;
            }
        }
        if(data?.scheduleItems?.[1]) {
            data.scheduleItems[1].elapsedTime = '00:00:00';
            data.scheduleItems[1].remainingTime = data.scheduleItems[1].duration;
            data.scheduleItems[1].remainingTime = comingUp;
        }
        return data;
        // const duration = data.duration;
        // const elapsedDuration = data.elapsedDuration;
        // return duration - elapsedDuration;
    }
    
    const millisecondsToHuman = (duration) => {
        let milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds ;
    }

    
    useEffect(() => {
        const today = new Date();
        const monitor = new Date(MonitorDate);
        if(monitor > today) {
            updatePlayData(0);
        } else {
            updatePlayData()
        }
     //   dispatch(CategoryDurationAction(categoryDuration));
    }, [categoryDuration])

    useEffect(() => {
        fetchResumeData();
    }, []); 
    const { MonitorDate } = useSelector(state => ({ MonitorDate: state?.MonitorDate?.data}));
    const { SearchChannel } = useSelector(state => ({ SearchChannel: state?.SearchChannel?.data}));
    useEffect(() => {
        if (SearchChannel) {
            // filtered filteredChannels.name if containing SearchChannel
            const result = channels?.filter(channel => channel?.name?.toLowerCase().includes(SearchChannel?.toLowerCase()));
            setFilteredChannels(result);
        }
    }, [SearchChannel])
    useEffect(() => {
        fetchSchedule(MonitorDate);
    }, [MonitorDate]);
    const fetchSchedule = async (MonitorDate) => {        
        const scheduleData = await GetMonitorScheduleList(MonitorDate);
        if(!scheduleData?.data?.length || scheduleData?.data?.length === 0) return;
        setChannels(scheduleData.data);
        setFilteredChannels(scheduleData.data);
        (selectedChannel) ?
            fetchChannelData(selectedChannel) :
            setSelectedChannel(scheduleData.data[0].id);
        (scheduleData.data[0].epgType === 'XLSInput' || scheduleData.data[0].epgType === 'APIDriven') ? setAllowedEdit(false) : setAllowedEdit(true);
    }

    const getPercentage = (duration, totalDuration) => {
        const percentage = (verifyMilliSeconds(duration) / verifyMilliSeconds(totalDuration)) * 100;
        setProgressPercent(percentage+'%');
    }
    useEffect(() => {
        if (selectedChannel) {
            const timer = setTimeout(() => {
                fetchChannelData(selectedChannel);
            }, 1000); // 1000 milliseconds = 1 second
        
            // Cleanup function to clear the timer if the component unmounts or `selectedChannel` changes
            return () => clearTimeout(timer);
        }
    }, [selectedChannel]);

    const fetchChannelData = async (channelId) => {
        setShowLoader(true);
        if(!MonitorDate || channelId?.length === 0 || MonitorDate?.length === 0) return;
        const monitordate = new Date(MonitorDate).toISOString().substring(0,10);
        const gridData = await Monitor_Datalist(channelId, monitordate);
        if(!gridData?.data) return gridData;
        const today = new Date();
        const monitor = new Date(MonitorDate);
        if(monitor <= today) {
            gridData.data = filterData(gridData?.data);
        }
        const data = gridData?.data?.scheduleItems || [];
        setChannelData(data);
        setChannelDuration({
            elapsedDuration: gridData?.data?.elapsedDuration,
            remainingDuration: gridData?.data?.remainingDuration,
            totalScheduledDuration: gridData?.data?.totalScheduledDuration
        })
        //uncomment below line to get remaining time
        if(gridData?.data) {
            gridData.data = getDuration(gridData?.data);
        }
        parseData(gridData?.data);
        setShowLoader(false);
        getPercentage(gridData?.data?.elapsedDuration, gridData?.data?.totalScheduledDuration);
       
    }

    const filterData = (data) => {
        if(!data?.scheduleItems) return data;
        const filter = data.scheduleItems.filter(element => {
            const past = getElapsedTime(element.endTime, false);
            return (past > 0) ? true : false
        });
        data.scheduleItems = filter;
        return data;
    }

    
    const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;

    const verifyMilliSeconds = (duration) => {
        const len = duration?.toString()?.split(":");
        if(len?.length > 1) {
            return parseInt(toMilliseconds(len[0], len[1], len[2]))
        } else {
            return parseInt(duration);
        }
    }

    const updatePlayData = (index =null) => {
        if(!categoryDuration?.scheduleItems) return;
        if(index === null || !categoryDuration?.scheduleItems || categoryDuration?.scheduleItems?.length === 0) {
            dispatch(CategoryDurationAction(categoryDuration));
        } else {
            if(categoryDuration.scheduleItems.length === 0) return;
            const data = categoryDuration?.scheduleItems.filter((item, i) => i === index);
            const updatedData = {
                ...categoryDuration,
                scheduleItems: data
            };
            dispatch(CategoryDurationAction(updatedData));
        }   
    }

    const parseData = (data) => {
        if(!data || data?.length === 0) {
            setCategoryDuration([]);   
            return;
        };
        setCategoryDuration(data);
    }
    return (
        <div className="content-body">
            <div className="channel-list">
                <div className="list-header">Channel list</div>
                <ul>
                    {filteredChannels?.map((channel, index) => (
                        <li key={index} onClick={() => setSelectedChannel(channel?.id)} title={channel?.channelName} className={`channel-item ${(channel?.id === selectedChannel) ? 'selected' : ''}`}>
                            <img src={cloudFront_URL + channel?.channelLogo[0]?.thumbnailSourcePath} alt={channel?.channelName} />
                            <p>{channel?.name}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="schedule-info">
                <div className="total-schedule">
                    Total Scheduled
                    <span> {channelDuration?.totalScheduledDuration}</span>
                </div>
                <div className="schedule-progress">
                    <div className="progress" style={{width: progressPercent}}></div>
                    <div className="progress-status">
                        <div className="progress-played">
                            {channelDuration?.elapsedDuration} <span>played</span>
                        </div>
                        <div className="progress-remaining">
                            <span>Remaining </span>
                            {channelDuration?.remainingDuration}
                        </div>
                    </div>
                </div>
            </div>

            <div className="schedule-list">
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>        
                            <th>Start Time</th>
                            <th>Duration Time</th>
                            <th>End Time</th>
                            <th>Asset Information</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showLoader && <tr><td colSpan="7"><Loader /></td></tr>}
                        {channelData && !showLoader && channelData.map((item, index) => (
                            <tr key={index} onClick={() => updatePlayData(index)}>
                                <td>{item.startTime}</td>
                                <td>
                                    <span className="show-duration">{item.duration}</span></td>
                                <td>{item.endTime}</td>
                                <td>{item.assetInformation}</td>
                                <td><span className="monitor-type">{item.assetTypeDisplayName}</span></td>
                                <td><span className="monitor-status">{item.playbackStatusDisplayName}</span></td>
                                <td>
                                    <img src={viewIcon} alt="view" />
                                    {allowedEdit && <img src={editIcon} alt="edit" />}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Monitor