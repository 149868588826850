import arrow_next from "../../../../../../assets/images/arrow-next.svg"
import arrow_prev from "../../../../../../assets/images/arrow-prev.svg"

export const DateNavigator = ({
    locationState,
    prevWeekFunc,
    startDate,
    endDate,
    nextWeekFunc,
    today
}) => {
    return (
        <div className={`left-side ${locationState?.state?.channelAssembly === "EXTERNAL"? "d-flex justify-content-between align-items-center w-100": ""}`}>
            <h3>{locationState?.state?.channelName}</h3>
            <div class="d-flex gap-3">
                <div class="d-flex align-items-center text-white py-2 px-3 rounded" style={{backgroundColor: "#222"}}>
                    <a className="btn mx-1 py-1 px-3 bg-secondary" onClick={prevWeekFunc}><img src={arrow_prev} width={8} style={{ filter: "invert(1)" }}/></a>
                    <span>
                        {
                            `
                            ${new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))
                                .toLocaleDateString("en-GB")} 
                            - 
                            ${new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 1))
                                .toLocaleDateString("en-GB")}
                            `
                        }
                    </span>
                    <a className="btn mx-1 py-1 px-3 bg-secondary" onClick={nextWeekFunc}><img src={arrow_next} width={8} style={{ filter: "invert(1)" }}/></a>
                </div>
                <button class="btn btn-secondary my-auto text-white px-5" onClick={today}>Today</button>
            </div>
        </div>
    )
}