import deleteIcon from '../../../../../../../assets/images/delete_forever.png';

export const DraggableEvent = ({
    data,
    toGetAssetInfo,
    item,
    locationState,
    index,
    calculateMinutes,
    zoomInOut,
    newGridData,
    key,
    formatTime,
    programDelete
}) => {
    return (
        <tr
            className="dragabble"
            onClick={() => {toGetAssetInfo(data?.sequence, item?.date, data?.isSplit)}}
            draggable={locationState?.state?.permission === "edit"? true : false}
            // draggable={true}
            data-type="draggable-list"
            key={`${index}`}
            data-value={JSON.stringify({date:item.date, ...data})}
            data-id={data?.sequence}
            style={{
                height: `${calculateMinutes(
                    data?.totalDuration
                    ? data?.totalDuration
                    : data?.durationInMillis +
                        (data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)
                ) * zoomInOut}px`,
                width: "13vw", // Set width relative to the viewport width
                position: "absolute",
                left: "1.5vw", // Adjust left position relative to the viewport width
                overflow: "hidden",
                }}
            title={data?.title}
        >
            <td data-id={data?.sequence} data-ifdate={data?.isSplit && newGridData[key - 1]?.date} style={{ background: index % 4 === 0 ? "#FFC374" : index % 4 === 1 ? "#4AD2C9" : index % 4 === 2 ? "#8E73EF" : "#536EFF" }}>
                {
                    data?.isSplit &&
                    <div class="dots position-absolute d-flex align-items-end mx-2 pe-none" style={{height: `15px`}}>
                        {[...Array(10)].map((_, index) => (
                            <div key={index} className="dot"></div>
                        ))}
                    </div>
                }
                <div className="table-content2" style={{ background: "rgba(255, 255, 255, 0.65)", pointerEvents: "none"}}>
                    <div className="title-schedule-container" style={{width: "70%"}}> 
                        <p className="px-2 fw-bold m-0 program-title" style={{color: "#212529"}}>
                            {data?.title}
                        </p>
                        <p className="px-2 fw-bold schedule-time" style={{color: "#212529"}}>
                            {`${formatTime(data?.startAt)} -  ${formatTime(data?.endAt)}`}
                        </p>
                    </div>
                    <div className="position-relative">
                        {
                            data?.isSplit === true ?
                            ''
                            :
                            <a className="p-2 table-icon position-absolute" onClick={(e) => {programDelete(e, data?.uuid, item?.date)}} style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                <img src={deleteIcon} width={18} />
                            </a>
                        }
                        <a className="p-2 table-adbreaks-count position-absolute" style={{filter: "invert(0)", pointerEvents: "auto"}}>
                            <span>{data?.adBreaks.length}</span>
                        </a>
                    </div>
                </div>
                {
                    data?.lastSplitItem &&
                    <div class="dots position-relative d-flex align-items-end mx-2 pe-none" style={{height: `${calculateMinutes(
                    data?.totalDuration
                        ? data?.totalDuration
                        : data?.durationInMillis +
                        (data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)
                    ) * zoomInOut - 27}px`}}>
                        {[...Array(10)].map((_, index) => (
                            <div key={index} className="dot"></div>
                        ))}
                    </div>
                }
            </td>
        </tr>
    )
}