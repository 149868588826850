export const channelDragStart = (event, draggedItem) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
};

export const handleDragStart = (event, draggedItem) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
};

export const handleDragOver = (
    event, 
    draggedItem, 
    setDraggableType
) => {
    event.preventDefault();
    let { type, value, key } = draggedItem.current.dataset; 
    setDraggableType(type);
    event.dataTransfer.dropEffect = "move";
};

export const handleDragOver2 = (
    event,
    id,
    setHoveredId,
    zoomInOut,
    setLinePosition,
    setHoverTime
) => {
    event.preventDefault();
    setHoveredId(id); // Set the currently hovered asset ID
    const containerTop = event.currentTarget.getBoundingClientRect().top;
    const yPos = event.clientY - containerTop;
    const durationPerPixel = 1/zoomInOut; // Adjust this as needed
    const timeAtPosition = yPos * durationPerPixel;

    // Convert time from minutes to hh:mm:ss format
    const totalSeconds = Math.floor(timeAtPosition * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    setLinePosition(yPos);
    setHoverTime(formattedTime);
};