import { addadsSqId } from "./utilityCommonMethods";

function generateUniqueUUID(gridList) {
    const allUUIDs = gridList.flatMap(item => item.scheduleItems.map(schedule => schedule.uuid));        
    let uuid;
    do {
        uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    } while (allUUIDs.includes(uuid));
    return uuid;
};

export const addToDragabbleList = (
    data, 
    panelDate,
    gridList,
    setGridList,
) => {
    // Find the index of the date in gridList that matches the panelDate
    const dateIndex = gridList.findIndex(item => item.date === panelDate);

    if (dateIndex !== -1) {
        // Get the scheduleItems array for the matching date
        const scheduleItems = gridList[dateIndex].scheduleItems;

        // Add the new data to the scheduleItems array
        const updatedItems = [...scheduleItems, data];

        // Sort the updatedItems by startAt time
        updatedItems.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));

        // Update the sequence based on the sorted order
        const sortedItems = updatedItems.map((item, index) => ({
            ...item,
            sequence: index + 1
        }));

        // Update the gridList with the sorted and updated schedule items
        const updatedGridList = [...gridList];
        // updatedGridList[dateIndex].scheduleItems = sortedItems;
        updatedGridList[dateIndex] = {
            ...updatedGridList[dateIndex],
            scheduleItems: sortedItems,
            isEdit: true, // Mark it as edited
        };

        // Set the updated gridList
        // adjustScheduleItems(updatedGridList);
        setGridList(updatedGridList);
    } else {
        // If panelDate does not exist, create a new entry for it
        const newEntry = {
            date: panelDate,
            scheduleItems: [{ ...data, sequence: 1 }],
            isEdit: true, // Mark new entry as edited
        };
        setGridList([...gridList, newEntry]);
    }
};

export const handleDrop = async(
    event,
    panelDate,
    setHoveredId,
    setLinePosition,
    draggedItem,
    setPanelHoverTime,
    setPanelLinePosition,
    setPanelHoverDate,
    panelHoverTime,
    gridList,
    toast,
    setGridList,
    adValidate,
    hoverTime,
    newGridData,
    millisecondsToHuman,
    verifyMilliSeconds,
    setHoverTime,
    programValidate,
) => {        
    event.preventDefault();
    setHoveredId(null);
    setLinePosition(null);
    let { type, value, key } = draggedItem.current.dataset; 
    let { id,  ifdate} = event.target.dataset;
    try {
        value = JSON.parse(value);
    } catch (error) {
        // If JSON.parse fails, leave value as-is (e.g., time string "00:00:30") for ads
    }
    let ads = [];
    let obj = {};
    let totalAdsDuration = 0;
    if (type === "draggable-list") {
        if (value?.isSplit) {
            setPanelHoverTime(null);
            setPanelLinePosition(null);
            setPanelHoverDate(null);
            return;
        }
        // Convert panelHoverTime (HH:MM:SS) to milliseconds
        const [hours, minutes, seconds] = panelHoverTime.split(":").map(Number);
        const hoverTimeInMillis = (hours * 3600 + minutes * 60 + seconds) * 1000;
        let combinedTimeInMillis = panelDate + hoverTimeInMillis;
    
        let updatedGridList = [...gridList];
        const valueOfItem = gridList
                .flatMap((grid) => grid?.scheduleItems)
                .find((item) => item?.uuid === value?.uuid); 
        value = valueOfItem;
        
        const gridListToCheck = gridList.map((data) => {
            return {
                ...data,
                scheduleItems:data?.scheduleItems.filter(item => item.uuid !== value?.uuid)
            } 
        })
        
        // Find overlapping item across all gridList scheduleItems
        const overlappingItem = gridListToCheck.find(item => 
            item.scheduleItems.some(schedule => {
                const itemStart = schedule.startAt;
                const itemEnd = schedule.endAt;
                const objStart = combinedTimeInMillis;
                const objEnd = combinedTimeInMillis + value["totalDuration"];
                return (
                    (objStart >= itemStart && objStart <= itemEnd) || // Starts within an existing item's time
                    (objEnd >= itemStart && objEnd <= itemEnd) ||     // Ends within an existing item's time
                    (objStart <= itemStart && objEnd >= itemEnd)   // Fully covers an existing item's time
                );
            })
        );
        
        if (overlappingItem) {
            const overlappingSchedule = overlappingItem.scheduleItems.find(schedule => {
                const itemStart = schedule.startAt;
                const itemEnd = schedule.endAt;
                const objStart = combinedTimeInMillis;
                const objEnd = combinedTimeInMillis + value["totalDuration"];
            
                return (
                    (objStart >= itemStart && objStart <= itemEnd) || 
                    (objEnd >= itemStart && objEnd <= itemEnd) ||     
                    (objStart <= itemStart && objEnd >= itemEnd)     
                );
            });
                
            if (overlappingSchedule && overlappingSchedule?.uuid !== value?.uuid) {
                if (overlappingItem.scheduleItems.indexOf(overlappingSchedule) === overlappingItem.scheduleItems.length - 1) {
                    if (overlappingSchedule.endAt >= overlappingItem.date + 24 * 3600 * 1000) {
                        if (overlappingItem.date === panelDate) {
                            toast.error("Overlap exceeds 24 hours within the same panel date.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 3000
                            });
                            setPanelHoverTime(null);
                            setPanelLinePosition(null);
                            setPanelHoverDate(null);
                            return;
                        } else {
                            // Adjust startAt for different date
                            combinedTimeInMillis = overlappingSchedule.endAt;
                            // Check next day's scheduleItems for overlap
                            const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date + 24 * 3600 * 1000));
                            if (nextDayIndex !== -1) {
                                const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                let nextItem = nextDaySchedules[0]; // First item of the next day
                                if (nextItem?.uuid === value?.uuid) {
                                    nextItem = undefined;
                                }
                                
                                if (nextItem) {
                                    const nextItemStart = nextItem.startAt;
                                    const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                                    
                                    if (availableSpace < (value["totalDuration"])) {
                                        toast.error("Not enough space to adjust the program.", {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                            autoClose: 3000
                                        });
                                        setPanelHoverTime(null);
                                        setPanelLinePosition(null);
                                        setPanelHoverDate(null);
                                        return;
                                    }
                                }
                            }
                        }
                    } else {
                        combinedTimeInMillis = overlappingSchedule.endAt;
                    }
                } else {
                    // Check if enough space exists between overlapping item and the next item
                    const nextItemIndex = overlappingItem.scheduleItems.indexOf(overlappingSchedule) + 1;
                    let nextItem = overlappingItem.scheduleItems[nextItemIndex];
                    if(value?.uuid === nextItem?.uuid) {
                        nextItem = overlappingItem.scheduleItems[nextItemIndex + 1];
                    } else {
                        nextItem = overlappingItem.scheduleItems[nextItemIndex]
                    }
                    if (nextItem) {
                        const spaceAvailable = nextItem.startAt - (overlappingSchedule.endAt); // Time gap in ms
                        if (spaceAvailable >= value["totalDuration"]) {
                            combinedTimeInMillis = overlappingSchedule.endAt;
                        } else {
                            toast.error("Not enough space to adjust the program.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                autoClose: 3000
                            });
                            setPanelHoverTime(null);
                            setPanelLinePosition(null);
                            setPanelHoverDate(null);
                            return;
                        }
                    } else {
                        combinedTimeInMillis = overlappingSchedule.endAt;
                    }
                }
            }
        }

        // Update and sort scheduleItems within each grid
        updatedGridList = updatedGridList.map((grid) => {
            // Check if any scheduleItem was updated
            const isEdited = grid.scheduleItems.some(
                (scheduleItem) =>
                    scheduleItem?.uuid === value?.uuid && scheduleItem?.startAt !== combinedTimeInMillis
            );
            // Update scheduleItems and sort them directly
            const updatedScheduleItems = grid.scheduleItems
                .map((scheduleItem) => {
                    if (scheduleItem.uuid === value?.uuid) {
                        const updatedStartAt = combinedTimeInMillis;
                        const updatedEndAt = updatedStartAt + scheduleItem.totalDuration;

                        return {
                            ...scheduleItem,
                            startAt: updatedStartAt,
                            endAt: updatedEndAt,
                        };
                    }
                    return scheduleItem;
                })
                .sort((a, b) => a.startAt - b.startAt) // Sort by startAt

                .map((item, index) => ({
                    ...item,
                    sequence: index + 1, // Update sequence
                }));

            return {
                ...grid,
                scheduleItems: updatedScheduleItems,
                isEdit: isEdited ? true : grid?.isEdit
            };
        });

        // Update state after confirming no overlap
        setGridList(updatedGridList);
        setPanelHoverTime(null);
        setPanelLinePosition(null);
        setPanelHoverDate(null);
    }
    if (type === "programs" && value) {

        // const assetValidate = await programValidate(value["id"]);
        // if (!assetValidate?.status) {
        //     toast.error(assetValidate?.message, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 3000
        //     });
        //     return;
        // }

        const dateIndex = gridList.findIndex(item => item.date === panelDate);
        
        // to check the default ad markers
        if (value?.defaultAdMarkers?.length) {
            // const updatedAdBreaks = value?.defaultAdMarkers
            //     .sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
            //     .map((ad, index) => {
            //         totalAdsDuration += ad.durationInMillis
            //         return {
            //             assetId: ad?.assetId,
            //             date: null,
            //             duration: ad?.durationInMillis,
            //             startOffsetInMillis: ad?.startOffsetInMillis,
            //             title: ad?.title,
            //             type: "filler",
            //             adSqId: index + 1,
            //             adInsertionPoint: ad.startOffsetInMillis - totalPrevDuration,
            //         };
            //     });
            // ads = updatedAdBreaks;
            
            let totalPrevDuration = 0;
            const updatedAdBreaks = value?.defaultAdMarkers
                ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
                .map((ad, index) => {
                    totalAdsDuration += ad.durationInMillis;

                    const updatedAd = {
                        assetId: ad?.assetId,
                        date: null,
                        duration: ad?.durationInMillis,
                        startOffsetInMillis: ad?.startOffsetInMillis,
                        title: ad?.title,
                        type: "filler",
                        adSqId: index + 1,
                        adInsertionPoint: ad.startOffsetInMillis - totalPrevDuration,
                    };

                    totalPrevDuration += ad.durationInMillis; // Update total duration correctly

                    return updatedAd;
                });

            ads = updatedAdBreaks;

        }
        if (dateIndex !== -1) {
            const uuid = generateUniqueUUID(gridList);
        
            // Convert panelHoverTime (HH:MM:SS) to milliseconds
            const [hours, minutes, seconds] = panelHoverTime.split(":").map(Number);
            const hoverTimeInMillis = (hours * 3600 + minutes * 60 + seconds) * 1000;
        
            // Add hoverTimeInMillis to panelDate
            let combinedTimeInMillis = panelDate + hoverTimeInMillis;
        
            const scheduleItems = gridList[dateIndex]?.scheduleItems || [];
        
            // Find overlapping item across all gridList scheduleItems
            const overlappingItem = gridList.find(item => 
                item.scheduleItems.some(schedule => {
                    const itemStart = schedule.startAt;
                    const itemEnd = schedule.endAt;
                    const objStart = combinedTimeInMillis;
                    const objEnd = combinedTimeInMillis + totalAdsDuration + value["duration"];

                    return (
                        (objStart >= itemStart && objStart <= itemEnd) || // Starts within an existing item's time
                        (objEnd >= itemStart && objEnd <= itemEnd) ||     // Ends within an existing item's time
                        (objStart <= itemStart && objEnd >= itemEnd)     // Fully covers an existing item's time
                    );
                })
            );
            
            if (overlappingItem) {
                // Find the overlapping schedule within the gridList
                const overlappingSchedule = overlappingItem.scheduleItems.find(schedule => {
                    const itemStart = schedule.startAt;
                    const itemEnd = schedule.endAt;
                    const objStart = combinedTimeInMillis;
                    const objEnd = combinedTimeInMillis + totalAdsDuration + value["duration"];

                    return (
                        (objStart >= itemStart && objStart <= itemEnd) || 
                        (objEnd >= itemStart && objEnd <= itemEnd) ||     
                        (objStart <= itemStart && objEnd >= itemEnd)     
                    );
                });

                if (overlappingSchedule) {
                    console.error(`Overlap found with schedule ID: ${overlappingSchedule.id}`);
                    
                    // If overlapping is the last item
                    if (overlappingItem.scheduleItems.indexOf(overlappingSchedule) === overlappingItem.scheduleItems.length - 1) {
                        if (overlappingSchedule.endAt >= overlappingItem.date + 24 * 3600 * 1000) {
                            if (overlappingItem.date === panelDate) {
                                toast.error("Overlap exceeds 24 hours within the same panel date.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 3000
                                });
                                setPanelHoverTime(null);
                                setPanelLinePosition(null);
                                setPanelHoverDate(null);
                                return;
                            } else {
                                // Adjust startAt for different date
                                combinedTimeInMillis = overlappingSchedule.endAt;
                                // Check next day's scheduleItems for overlap
                                const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date + 24 * 3600 * 1000));
                                if (nextDayIndex !== -1) {
                                    const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                    const nextItem = nextDaySchedules[0]; // First item of the next day
                        
                                    if (nextItem) {
                                        const nextItemStart = nextItem.startAt;
                                        const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                        
                                        if (availableSpace < (value["duration"] + totalAdsDuration)) {
                                            toast.error("Not enough space to adjust the program", {
                                                position: toast.POSITION.BOTTOM_RIGHT,
                                                autoClose: 3000
                                            });
                                            setPanelHoverTime(null);
                                            setPanelLinePosition(null);
                                            setPanelHoverDate(null);
                                            return;
                                        }
                                    }
                                }
                            }
                        } else {
                            if (overlappingItem.date !== panelDate) {
                                if (overlappingSchedule.endAt >= overlappingItem.date) {
                                    const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date));
                                    if (nextDayIndex !== -1) {
                                        const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                        const nextItem = nextDaySchedules[0]; // First item of the next day

                                        if (nextItem) {
                                            const nextItemStart = nextItem.startAt;
                                            const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                            
                                            if (availableSpace < (value["duration"] + totalAdsDuration)) {
                                                toast.error("Not enough space to adjust the program", {
                                                    position: toast.POSITION.BOTTOM_RIGHT,
                                                    autoClose: 3000
                                                });
                                                setPanelHoverTime(null);
                                                setPanelLinePosition(null);
                                                setPanelHoverDate(null);
                                                return;
                                            }
                                        }
                                    }
                                }
                            }else {
                                combinedTimeInMillis = overlappingSchedule.endAt; // 1 second after last item's endAt
                            }
                        }
                    } else {
                        // Check if enough space exists between overlapping item and the next item
                        const nextItemIndex = overlappingItem.scheduleItems.indexOf(overlappingSchedule) + 1;
                        const nextItem = overlappingItem.scheduleItems[nextItemIndex];
                        const spaceAvailable = nextItem.startAt - (overlappingSchedule.endAt); // Time gap in ms
                        if (spaceAvailable >= value["duration"] + totalAdsDuration) {
                            combinedTimeInMillis = overlappingSchedule.endAt; // Adjust startAt
                        } else {
                            if (overlappingItem.date === panelDate) {
                                toast.error("Not enough space to adjust the program.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 3000
                                });
                                setPanelHoverTime(null);
                                setPanelLinePosition(null);
                                setPanelHoverDate(null);
                                return;
                            } else {
                                if (overlappingItem.date !== panelDate) {
                                    if (overlappingSchedule.endAt >= overlappingItem.date) {
                                        const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date));
                                        if (nextDayIndex !== -1) {
                                            const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                            const nextItem = nextDaySchedules[0]; // First item of the next day
    
                                            if (nextItem) {
                                                const nextItemStart = nextItem.startAt;
                                                const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                                
                                                if (availableSpace < (value["duration"] + totalAdsDuration)) {
                                                    toast.error("Not enough space to adjust the program", {
                                                        position: toast.POSITION.BOTTOM_RIGHT,
                                                        autoClose: 3000
                                                    });
                                                    setPanelHoverTime(null);
                                                    setPanelLinePosition(null);
                                                    setPanelHoverDate(null);
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }else {
                                    combinedTimeInMillis = overlappingSchedule.endAt; // 1 second after last item's endAt
                                }
                            }
                        }
                    }
                }
            }
        
            // Create the new object
            const obj = {
                adBreaks: ads,
                adBreaksCount: value?.defaultAdMarkers?.length,
                assetId: value["id"],
                assetType: "PROGRAM",
                duration: value["duration"],
                endAt: combinedTimeInMillis + totalAdsDuration + value["duration"],
                id: value["id"],
                scheduledDate: panelDate,
                startAt: combinedTimeInMillis,
                title: value["metadata"]["title"],
                totalDuration: totalAdsDuration + value["duration"],
                uuid: uuid,
            };
        
            // Copy adBreaks if the assetId matches
            const latestIndex = scheduleItems.findLastIndex(item => item.assetId === value["id"]);
            if (latestIndex !== -1) {
                obj.adBreaks = [...scheduleItems[latestIndex]?.adBreaks];
            }
        
            addToDragabbleList(obj, panelDate, gridList, setGridList);
            setPanelHoverTime(null);
            setPanelLinePosition(null);
            setPanelHoverDate(null);
        } else {
            console.error(`Date ${panelDate} not found in gridList.`);
            setPanelHoverTime(null);
            setPanelLinePosition(null);
            setPanelHoverDate(null);
        }
        return;
    }
    if (type == "fillers" && value) {
        // const assetValidate = await adValidate(value["id"]);
        // if (!assetValidate?.status) {
        //     toast.error(assetValidate?.message, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 3000
        //     });
        //     return;
        // } 

        let updatedHoverTime = hoverTime;
        let updatedPanelDate = panelDate
        if (ifdate) {
            const firstDatePanel = newGridData.find((item) => item.date === Number.parseInt(ifdate));
            if (firstDatePanel?.scheduleItems?.length) {
                const lastScheduleItem = firstDatePanel.scheduleItems[firstDatePanel.scheduleItems.length - 1];
                updatedHoverTime = millisecondsToHuman((lastScheduleItem?.totalDuration || 0) + verifyMilliSeconds(hoverTime));
                updatedPanelDate = Number.parseInt(ifdate);
            }
        }

        const schedulingItems = gridList.find((item) => item?.date === updatedPanelDate);
        const gridItem = schedulingItems?.scheduleItems.find((item) => item?.sequence === Number(id))
        // const totalAdBreakDuration = gridItem?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0);
        const totalAdBreakDuration = (gridItem?.adBreaks ?? []).reduce((sum, ad) => {
            return ad.startOffsetInMillis < verifyMilliSeconds(updatedHoverTime) 
                ? sum + (ad.duration ?? 0) 
                : sum;
        }, 0);        
        const NewhoverTime = verifyMilliSeconds(updatedHoverTime) - totalAdBreakDuration;
        
        obj = {
            assetId: value["id"],
            type: "filler",
            adInsertionPoint: NewhoverTime,
            adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
            duration: value["duration"],
            startAt: updatedHoverTime,
            startOffsetInMillis: verifyMilliSeconds(updatedHoverTime),
            title: value["filename"],
            date: null,
        };
        
        // Function to check if the new ad break overlaps with existing ad breaks
        function isOverlapping(adBreaks, newAd) {
            return adBreaks.some(ad => {
                const adStart = ad.startOffsetInMillis;
                const adEnd = ad.startOffsetInMillis + ad.duration;
                const newAdStart = newAd.startOffsetInMillis;
                const newAdEnd = newAd.startOffsetInMillis + newAd.duration;

                const isOverlap = 
                    (newAdStart >= adStart && newAdStart < adEnd) || 
                    (newAdEnd > adStart && newAdEnd <= adEnd) || 
                    (newAdStart <= adStart && newAdEnd >= adEnd);

                if (isOverlap) {
                    toast.error("New Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    });
                }
                return isOverlap;
            });
        }

        // function isValidAdBreakAddition(scheduleItems, currentProgramIndex, newAdBreak) {
        //     const currentProgram = scheduleItems[currentProgramIndex];

        //     // Calculate total adBreaks duration including the new one
        //     const newEndAt = currentProgram.startAt + currentProgram.totalDuration + newAdBreak.duration;

        //     const overlappingItem = gridList.find(item => 
        //         item.scheduleItems.some(schedule => {
        //             const itemStart = schedule.startAt;
        //             const itemEnd = schedule.endAt;
        //             const objStart = currentProgram.startAt;
        //             const objEnd = newEndAt;

        //             return (
        //                 // Starts within an existing item's time
        //                 (objEnd >= itemStart && objEnd <= itemEnd)    // Ends within an existing item's time
        //                    // Fully covers an existing item's time
        //             );
        //         })
        //     );
            
        //     // Check if the new endAt exceeds the next program's startAt
        //     if (overlappingItem) {
        //         toast.error(
        //             `Ad break overlaps with the next program.`,
        //             { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000 }
        //         );
        //         return false;
        //     }

        //     return true;
        // }

        // Adding the new ad break with both checks
        const updatedGridList = gridList.map(item =>
            item.date === updatedPanelDate
                ? {
                    ...item,
                    scheduleItems: item.scheduleItems.map((scheduleItem, index) =>
                        scheduleItem.sequence === Number(id)
                            ? {
                                ...scheduleItem,
                                adBreaks: !isOverlapping(scheduleItem.adBreaks, obj) 
                                // && isValidAdBreakAddition(item.scheduleItems, index, obj)
                                    ? [...scheduleItem.adBreaks, obj] // Add if valid
                                    : scheduleItem.adBreaks, // Keep original if invalid
                            }
                            : scheduleItem
                    ),
                    isEdit: true,
                }
                : item
        );
        
        addadsSqId(updatedGridList, updatedPanelDate, setGridList, millisecondsToHuman);
        setHoverTime(null);
        return;
    }
    if (type == "ads" && value) {
        // const assetValidate = await adValidate(value["id"]);
        // if (!assetValidate?.status) {
        //     toast.error(assetValidate?.message, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 3000
        //     });
        //     return;
        // }

        let updatedHoverTime = hoverTime;
        let updatedPanelDate = panelDate
        if (ifdate) {
            const firstDatePanel = newGridData.find((item) => item.date === Number.parseInt(ifdate));
            if (firstDatePanel?.scheduleItems?.length) {
                const lastScheduleItem = firstDatePanel.scheduleItems[firstDatePanel.scheduleItems.length - 1];
                updatedHoverTime = millisecondsToHuman((lastScheduleItem?.totalDuration || 0) + verifyMilliSeconds(hoverTime));
                updatedPanelDate = Number.parseInt(ifdate);
            }
        }

        const schedulingItems = gridList.find((item) => item?.date === updatedPanelDate);
        const gridItem = schedulingItems?.scheduleItems.find((item) => item?.sequence === Number(id))
        // const totalAdBreakDuration = gridItem?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0);
        const totalAdBreakDuration = (gridItem?.adBreaks ?? []).reduce((sum, ad) => {
            return ad.startOffsetInMillis < verifyMilliSeconds(updatedHoverTime) 
                ? sum + (ad.duration ?? 0) 
                : sum;
        }, 0);        
        const NewhoverTime = verifyMilliSeconds(updatedHoverTime) - totalAdBreakDuration;
        
        obj = {
            assetId: value["id"],
            type: "filler",
            adInsertionPoint: NewhoverTime,
            adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
            duration: value["duration"],
            startAt: updatedHoverTime,
            startOffsetInMillis: verifyMilliSeconds(updatedHoverTime),
            title: value["filename"],
            date: null,
        };

        // Function to check if the new ad break overlaps with existing ad breaks
        function isOverlapping(adBreaks, newAd) {
            return adBreaks.some(ad => {
                const adStart = ad.startOffsetInMillis;
                const adEnd = ad.startOffsetInMillis + ad.duration;
                const newAdStart = newAd.startOffsetInMillis;
                const newAdEnd = newAd.startOffsetInMillis + newAd.duration;

                const isOverlap = 
                    (newAdStart >= adStart && newAdStart < adEnd) || 
                    (newAdEnd > adStart && newAdEnd <= adEnd) || 
                    (newAdStart <= adStart && newAdEnd >= adEnd);

                if (isOverlap) {
                    toast.error("New Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    });
                }
                return isOverlap;
            });
        }

        // Function to check if the new ad break causes the program to exceed its allowed end time
        // function isValidAdBreakAddition(scheduleItems, currentProgramIndex, newAdBreak) {
        //     const currentProgram = scheduleItems[currentProgramIndex];

        //     // Calculate total adBreaks duration including the new one
        //     const newEndAt = currentProgram.startAt + currentProgram.totalDuration + newAdBreak.duration;
            
        //     const overlappingItem = gridList.find(item => 
        //         item.scheduleItems.some(schedule => {
        //             const itemStart = schedule.startAt;
        //             const itemEnd = schedule.endAt;
        //             const objStart = currentProgram.startAt;
        //             const objEnd = newEndAt;                        
        //             return (
        //                 // Starts within an existing item's time
        //                 (objEnd >= itemStart && objEnd <= itemEnd)    // Ends within an existing item's time
        //                    // Fully covers an existing item's time
        //             );
        //         })
        //     );
        //     // Check if the new endAt exceeds the next program's startAt
        //     if (overlappingItem) {
        //         toast.error(
        //             `Ad break overlaps with the next program.`,
        //             { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000 }
        //         );
        //         return false;
        //     }

        //     return true;
        // }

        // Adding the new ad break with both checks
        const updatedGridList = gridList.map(item =>
            item.date === updatedPanelDate
                ? {
                    ...item,
                    scheduleItems: item.scheduleItems.map((scheduleItem, index) =>
                        scheduleItem.sequence === Number(id)
                            ? {
                                ...scheduleItem,
                                adBreaks: !isOverlapping(scheduleItem.adBreaks, obj) 
                                // && isValidAdBreakAddition(item.scheduleItems, index, obj)
                                    ? [...scheduleItem.adBreaks, obj] // Add if valid
                                    : scheduleItem.adBreaks, // Keep original if invalid
                            }
                            : scheduleItem
                    ),
                    isEdit: true,
                }
                : item
        );

        addadsSqId(updatedGridList, updatedPanelDate, setGridList, millisecondsToHuman);
        setHoverTime(null);
        return;
    }
};