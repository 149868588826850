import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { 
    Schedules_list, 
    Schedules_Datalist,
    Schedule_update,
    programValidate,
    adValidate,
    saveLinerPrograms,
    Schedules_Datalist_Ist,
    Schedule_update_weekly
} from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import AdsInfo from "./components/adsInfo";
import _ from "lodash";
import ExpandView from "../scheduling-details/expand-view";
import ErrorPopup from "../../../component/popup/ErrorPopup";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { 
    fetchSchedule,
    update_schedule,
    confirmSaveAndUpdate,
} from "./utils/fetchAndUpdateScheduleAPI";
import { 
    searchProgram,
    addAdsOnClick,
    formatTime,
    generateTimeSlots,
    verifyMilliSeconds,
    millisecondsToHuman,
    calculateMinutes,
    millisecondsSinceMidnightUTC,
    getAssetInfo,
    selectedFiller,
    setAdsTime,
    setProgramTime,
    splitScheduleItems,
    pasteDataFun,
    trimPrograms,
} from "./utils/utils";
import { 
    handleDrop,
    addToDragabbleList,
} from "./utils/handleDrop";
import { 
    handleDragStart,
    handleDragOver,
    handleDragOver2,
    channelDragStart,
} from "./utils/handleDrag";
import { 
    handleProgramDelete,
    deleteEvent,
} from "./utils/handleDelete";
import { Left } from "./components/left/left";
import { SearchBar } from "./components/left/child-component/SearchBar";
import { ChannelListing } from "./components/left/child-component/ChannelListing";
import { AdsButton } from "./components/left/child-component/AdsButton";
import { Right } from "./components/right/Right";
import { DateNavigator } from "./components/right/child-component/DateNavigator";
import { ActionToolbar } from "./components/right/child-component/ActionToolbar";
import { ZoomableSchedule } from "./components/right/child-component/ZoomableSchedule";
import { ScheduleDetails } from "./components/right/child-component/ScheduleDetails";
import { SchedulingTable } from "./components/right/child-component/SchedulingTable";
import { DateActions } from "./components/right/child-component/child-component/DateActions ";
import { TableDetails } from "./components/right/child-component/child-component/TableDetails";
import { TableBody } from "./components/right/child-component/child-component/TableBody";
import { PanelLine } from "./components/right/child-component/child-component/PanelLine";
import { DraggableItem } from "./components/right/child-component/child-component/DraggableItem";
import { HoverLine } from "./components/right/child-component/child-component/HoverLine";
import { DraggableEvent } from "./components/right/child-component/child-component/DraggableEvent";
import { AdMarker } from "./components/right/child-component/child-component/AdMarker";

function SchedulingDetailsNew () {

    const [programList, setProgramList] = useState();
    const [originalProgramList, setOriginalProgramList] = useState([]);
    const [promoList, setPromoList] = useState();
    const [fillerList, setFillerist] = useState();
    const [searchedText, setSearchedText] = useState("");
    const [gridList, setGridList] = useState();
    console.log('gridList',gridList);
    const [linePosition, setLinePosition] = useState(null);
    const [panelLinePosition, setPanelLinePosition] = useState(null);
    const [hoverTime, setHoverTime] = useState(null);
    const [panelHoverTime, setPanelHoverTime] = useState(null);
    const [panelHoverDate, setPanelHoverDate] = useState(null);
    const [draggableType, setDraggableType] = useState(null);
    const [zoomInOut, setZoomInOut] = useState(1);
    const [hoveredId, setHoveredId] = useState(null); 
    const [timeSlots, setTimeSlots] = useState([]);
    const [singleTimeSlots, setSingleTimeSlots] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [assetInfo, setAssetInfo] = useState(null);
    const [categoryTab, setCategoryTab] = useState("Ads");
    const [seqId, setSeqId] = useState(1);
    const [newGridData, setNewGridData] = useState([]);
    const [expandViewData, setExpandViewData] = useState([]);
    const [expendedViewDate, setExpendedViewDate] = useState('');
    const [copyData, setCopyData] = useState([]);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [errorbox, setErrorbox] = useState(false);
    const [eventTrim, seteventTrim] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [saveBtnValue, setSaveBtnValue] = useState("Save & Publish");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const locationState = useLocation();
    
    const {
        appThemeReducer: { appTheme },
    } = useSelector((state) => state);
    const draggedItem = useRef();
    
    const SaveAndUpdate = (btnType) => {
        confirmSaveAndUpdate (
            btnType,
            setLoaderStatus,
            gridList,
            saveLinerPrograms,
            locationState,
            toast,
            today,
            confirmPublishWithDate,
        )
    }

    const trimProgramsBlankSpace = (panelDate) => {
        trimPrograms(
            panelDate,
            gridList,
            setGridList,
        )
    }

    const pasteDataFunc = (panelDate) => {
        pasteDataFun (
            panelDate,
            gridList,
            copyData,
            setGridList,
            setCopyData,
        )
    }

    const updateProgramTime = (e, programUuid, sequenceId, panelDate) => {
        setProgramTime(
            e, 
            programUuid, 
            sequenceId, 
            panelDate,
            gridList,
            toast,
            setPanelHoverTime,
            setPanelLinePosition,
            setPanelHoverDate,
            setGridList,
            setAssetInfo,
        )
    }

    const updateAdsTime = (e, adSqId, sequenceId, panelDate) => {
        setAdsTime(
            e, 
            adSqId, 
            sequenceId, 
            panelDate,
            gridList,
            setGridList,
            setAssetInfo,
            toast,
        )
    }

    const selectedFillers = (id, adSqId, sequenceId, panelDate) => {
        selectedFiller(
            id, 
            adSqId, 
            sequenceId, 
            panelDate,
            fillerList,
            gridList,
            setGridList,
            setAssetInfo,
        )
    }

    const toGetAssetInfo = (sequenceId, panelDate, isSplit) => {
        getAssetInfo(
            sequenceId, 
            panelDate, 
            isSplit,
            gridList,
            setAssetInfo,
            setSeqId,
            setShowPopup,
        )
    }

    const deleteAdEvent = (seqId, sequenceId, panelDate) => {
        deleteEvent(
            seqId,
            sequenceId,
            panelDate,
            gridList,
            setGridList,
            setAssetInfo,
        )
    }

    const programDelete = (event, uuid, panelDate) => {
        handleProgramDelete(
            event, 
            uuid,
            panelDate,
            gridList,
            setGridList,
            setShowPopup,
        )
    }

    const handleDragOvers = (event, date) => {
        handleDragOver(
            event, 
            date,
            draggedItem,
            setDraggableType,
            setPanelHoverDate,
            setPanelLinePosition,
            setPanelHoverTime,
            setHoveredId,
            zoomInOut,
            setLinePosition,
            setHoverTime,
        )
    }

    const handleDragOvers2 = (event, id) => {
        handleDragOver2(
            event, 
            id,
            draggedItem,
            setDraggableType,
            setHoveredId,
            zoomInOut,
            setLinePosition,
            setHoverTime,
        ) 
    }

    const handleZoomIn = () => {
        setZoomInOut((prevZoom) => Math.min(prevZoom + 1, 25));
    };
    
    const handleZoomOut = () => {
        setZoomInOut((prevZoom) => Math.max(prevZoom - 1, 1));
    };

    const adAdsOnClick = (sequenceId, panelDate) => {
        addAdsOnClick(
            sequenceId, 
            panelDate,
            locationState,
            adValidate,
            toast,
            gridList,
            setAssetInfo,
            setGridList,
            millisecondsToHuman,
        )
    }

    const handleAdsAndProgramDrop = (event, panelDate) => {
        handleDrop(
            event,
            panelDate,
            setHoveredId,
            setLinePosition,
            draggedItem,
            setPanelHoverTime,
            setPanelLinePosition,
            setPanelHoverDate,
            panelHoverTime,
            gridList,
            toast,
            setGridList,
            adValidate,
            hoverTime,
            newGridData,
            millisecondsToHuman,
            verifyMilliSeconds,
            setHoverTime,
            programValidate,
        )
    }

    const searchPrograms = (text) => {
        searchProgram(
            text,
            originalProgramList,
            setProgramList,
            setSearchedText,
        )
    }

    const updateLinearData = (panelDate) => {
        update_schedule(
            panelDate,
            gridList,
            Schedule_update,
            locationState,
            toast,
            today,
            setLoaderStatus,
            newGridData,
            Schedule_update_weekly
        );
    }

    const fetchLinearData = (startDateUTC, endDateUTC, setDate) => {
        fetchSchedule(
            setLoaderStatus, 
            locationState, 
            Schedules_list, 
            setProgramList,
            setOriginalProgramList,
            setFillerist,
            setPromoList,
            Schedules_Datalist,
            Schedules_Datalist_Ist,
            setGridList,
            startDateUTC,
            endDateUTC,
            setDate
        );
        setStartDate(startDateUTC);
        setEndDate(endDateUTC);
    }

    const today = () => {
        const date = new Date();        
        const setDate = 7;
        
        // Set the start date to 12 AM UTC
        // const startDateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0, 0);
        const startDateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0, 0))
        .toISOString()
        .split('T')[0];
        
        // Calculate the end date by adding 'setDate' days and setting 12 AM UTC
        // const endDateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + setDate, 0, 0, 0, 0);
        const endDateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + setDate, 0, 0, 0, 0))
        .toISOString()
        .split('T')[0];
        fetchLinearData(startDateUTC, endDateUTC, setDate);
    }
    
    useEffect(()=> {
        today();
    },[locationState])

    useEffect(() => {
        const observer = new MutationObserver((mutationsList, observer) => {
            const targetElement = document.getElementById('targetOnTime');
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
                observer.disconnect(); // Stop observing once element is found
            }
        });

        // Start observing the body for child list changes
        observer.observe(document.body, {
            childList: true, // Observe direct children
            subtree: true,  // Observe all descendants
        });

        // Cleanup the observer on component unmount
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const slots = generateTimeSlots();
        setTimeSlots(slots);
        setSingleTimeSlots(slots);
    }, []);

    useEffect(() => {
        splitScheduleItems(
            gridList,
            _, 
            setNewGridData,
        );
    },[gridList])
    
    const handlecategoryTab = (tab) => {
        setCategoryTab(tab);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const errorboxclose = () => {
        setErrorbox(false);
    }

    const copyDataFun = (panelDate) => {
        const copyObject = gridList.find(item => item.date === panelDate);
        if (copyObject) {
            setCopyData(copyObject?.scheduleItems);
        } else {
            toast.error("No match found.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
        }
    }

    const trim_programs_space = ((date) => {
        if (date) {
            setErrorMessage(`Are you sure you want to trim the spaces in '${new Date(date).toLocaleDateString("en-US", { weekday: "short",month: "short",day: "2-digit", })}'`)
        } else {
            setErrorMessage(`Are you sure you want to trim the spaces between all the scheduled programs?`)
        }
        setErrorbox(true);
        seteventTrim(() => () => trimProgramsBlankSpace(date))
    })

    const confirmPublishWithDate = (date) => {        
        if (date) {
            setErrorMessage(`Are you sure you want to publish the programs for '${new Date(date).toLocaleDateString("en-US", { weekday: "short",month: "short",day: "2-digit", })}' date?'`)
        } else {
            setErrorMessage(`Are you sure you want to publish all ready programs for their dates?`)
        } 
        setErrorbox(true);
        seteventTrim(() => async() => {
            // await SaveAndUpdate("Save")
            updateLinearData(date)
        })
    }

    const handelProgramDoubleClick = () => {

    }

    const prevWeekFunc = () => {
        const newStartDate = new Date(startDate);
        newStartDate.setUTCDate(newStartDate.getUTCDate() - 6);

        const newEndDate = new Date(endDate);
        newEndDate.setUTCDate(newEndDate.getUTCDate() - 6);

        // const startEpoch = newStartDate.getTime(); // UTC epoch time in milliseconds
        const startEpoch = new Date(newStartDate.getTime()).toISOString().split('T')[0];
        // const endEpoch = newEndDate.getTime(); // UTC epoch time in milliseconds
        const endEpoch = new Date(newEndDate.getTime()).toISOString().split('T')[0];

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        fetchLinearData(startEpoch, endEpoch, 7);
    }

    const nextWeekFunc = () => {
        const newStartDate = new Date(startDate);
        newStartDate.setUTCDate(newStartDate.getUTCDate() + 6);

        const newEndDate = new Date(endDate);
        newEndDate.setUTCDate(newEndDate.getUTCDate() + 6);

        // const startEpoch = newStartDate.getTime(); // UTC epoch time in milliseconds
        const startEpoch = new Date(newStartDate.getTime()).toISOString().split('T')[0];
        // const endEpoch = newEndDate.getTime(); // UTC epoch time in milliseconds
        const endEpoch = new Date(newEndDate.getTime()).toISOString().split('T')[0];

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        fetchLinearData(startEpoch, endEpoch, 7);
    }
    
    return (
        <>
            <div className={`content-body SchedulingDetail SchedulingLoop scheduling-loop-content-body ${appTheme}`}>
                <div className="dashboard-content flex schedule-loop-view">
                {
                    locationState?.state?.channelAssembly === "EXTERNAL"?
                    ''
                    :
                    <Left>
                        <SearchBar appTheme={appTheme} searchedText={searchedText} searchPrograms={searchPrograms} />
                        <ChannelListing programList={programList} promoList={promoList} fillerList={fillerList} locationState={locationState} channelDragStart={channelDragStart} handelProgramDoubleClick={handelProgramDoubleClick} draggedItem={draggedItem} />
                        <AdsButton locationState={locationState} draggedItem={draggedItem} channelDragStart={channelDragStart} />
                    </Left>
                }
                    <Right customStyle={{width: locationState?.state?.channelAssembly === "EXTERNAL"? "100%": "83%"}}>
                        <div className="top-content">
                            <DateNavigator locationState={locationState} prevWeekFunc={prevWeekFunc} startDate={startDate} endDate={endDate} nextWeekFunc={nextWeekFunc} today={today} />
                            {
                                locationState?.state?.channelAssembly === "EXTERNAL"?
                                ""
                                :
                                <ActionToolbar locationState={locationState} trim_programs_space={trim_programs_space} SaveAndUpdate={SaveAndUpdate} saveBtnValue={saveBtnValue} setSaveBtnValue={setSaveBtnValue} />
                            }
                        </div>
                        <div className="bottom-content mt-2 d-flex schedule-detail-container-scroll" style={{height: "90vh"}}>
                            <div className="middle-content col-1" style={{height: `${(30*timeSlots.length*zoomInOut + 220)}px`}}>
                                <ZoomableSchedule handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} timeSlots={timeSlots} zoomInOut={zoomInOut} />
                            </div>
                            {gridList?.length !== 0 &&
                            newGridData
                                ?.map((item, key) => {
                                    if (key !== 0) {
                                        return (
                                        <div className="middle-content col-3 px-1" style={{height: `${(30*timeSlots.length*zoomInOut + 220)}px`, width: locationState?.state?.channelAssembly === "EXTERNAL"? "20%": "25%"}}>
                                            <div className="border-middle-container rounded">
                                                <ScheduleDetails>
                                                    <DateActions item={item} confirmPublishWithDate={confirmPublishWithDate} copyData={copyData} pasteDataFunc={pasteDataFunc} copyDataFun={copyDataFun} trim_programs_space={trim_programs_space} />
                                                    <TableDetails appTheme={appTheme} item={item} millisecondsToHuman={millisecondsToHuman} />
                                                </ScheduleDetails>
                                                <SchedulingTable>
                                                    <TableBody item={item} handleDragStart={handleDragStart} draggedItem={draggedItem} handleDragOvers={handleDragOvers} handleAdsAndProgramDrop={handleAdsAndProgramDrop}>
                                                        <div className="grid-list-container col-12 p-2" style={{height: `${(30*timeSlots.length*zoomInOut + 10)}px`, position: 'relative'}}>
                                                            {panelLinePosition !== null && panelHoverDate === item?.date && (draggableType === "programs" || draggableType === "draggable-list") && (
                                                                <PanelLine panelLinePosition={panelLinePosition} panelHoverTime={panelHoverTime} />
                                                            )}
                                                            {item?.length !== 0 ?
                                                                item?.scheduleItems?.map((data, index) => {
                                                                    return (
                                                                        <DraggableItem handleDragOvers2={handleDragOvers2} data={data} calculateMinutes={calculateMinutes} millisecondsSinceMidnightUTC={millisecondsSinceMidnightUTC} zoomInOut={zoomInOut}>
                                                                            {linePosition !== null && hoveredId === data?.uuid && (draggableType === "fillers" || draggableType === "ads") && (
                                                                                <HoverLine linePosition={linePosition} hoverTime={hoverTime} />
                                                                            )}      
                                                                            <AdMarker data={data} calculateMinutes={calculateMinutes} zoomInOut={zoomInOut} />
                                                                            <DraggableEvent data={data} toGetAssetInfo={toGetAssetInfo} item={item} locationState={locationState} index={index} calculateMinutes={calculateMinutes} zoomInOut={zoomInOut} newGridData={newGridData} key={key} formatTime={formatTime} programDelete={programDelete} />
                                                                        </DraggableItem>  
                                                                    )
                                                                })
                                                                :
                                                                <td colspan="5" class="text-center no-data">
                                                                    No data has been scheduled.
                                                                    {/* Nothing scheduled yet */}
                                                                </td>
                                                            }
                                                        </div>
                                                    </TableBody>
                                                </SchedulingTable>  
                                            </div>
                                        </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </Right>
                </div>
                <AdsInfo
                    show={showPopup}
                    handleClose={closePopup}
                    assetInfo={assetInfo}
                    millisecondsToHuman={millisecondsToHuman}
                    formatTime={formatTime}
                    appTheme={appTheme}
                    categoryTab={categoryTab}
                    handlecategoryTab={handlecategoryTab}
                    permission={locationState?.state?.permission}
                    selectedFillers={selectedFillers}
                    fillerList={fillerList}
                    updateAdsTime={updateAdsTime}
                    deleteAdEvent={deleteAdEvent}
                    toGetAssetInfo={toGetAssetInfo}
                    adAdsOnClick={adAdsOnClick}
                    programDelete={programDelete}
                    updateProgramTime={updateProgramTime}
                />
            </div>
            <ToastContainer />
            {
                loaderStatus &&
                <div className="loader-container">
                    <div className="loader"></div>
                    <p></p>
                </div>
            }
            { errorbox &&
                <ErrorPopup
                    btntext={"Yes"}
                    message={errorMessage}
                    closebox={() => errorboxclose()}
                    overrides={() => eventTrim()}
                />
            }
        </>    
    )
}

export default SchedulingDetailsNew;