import { addadsSqId, getFormattedDate } from "./utilityCommonMethods";

export const addAdsOnClick = async (
    sequenceId,
    locationState,
    adValidate,
    gridList,
    verifyMilliSeconds,
    millisecondsToHuman,
    setGridList,
    toast
) => {
    let value = locationState?.state?.defaultFiller;        
    const assetValidate = await adValidate(value["id"]);
    if (!assetValidate?.status) {
        toast.error(assetValidate?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
        });
        return;
    }

    let updatedGridList = gridList.map(item => {
        if (item.sequence === Number(sequenceId)) {
            const lastAdBreak = item.adBreaks[item.adBreaks.length - 1];
            const lastStartOffset = lastAdBreak ? lastAdBreak.endOffsetInMillis : 0;
            const newStartOffset = lastStartOffset; // Adjust as needed for increment
            
            const totalAdBreakDuration = item?.adBreaks?.reduce((sum, ad) => sum + (ad.durationInMillis || 0), 0);
            const NewhoverTime = newStartOffset + verifyMilliSeconds("00:00:30") - totalAdBreakDuration;

            let obj = {
                assetId: value["id"],
                assetType: "AD",
                adInsertionPoint: NewhoverTime,
                adInsertionPointFormat: millisecondsToHuman(NewhoverTime),
                duration: value["duration"],
                durationInMillis: value["duration"],
                startAt: millisecondsToHuman(newStartOffset),
                startOffsetInMillis: newStartOffset,
                endAt: millisecondsToHuman(newStartOffset + verifyMilliSeconds("00:00:30") + value["duration"]),
                endOffsetInMillis: newStartOffset + verifyMilliSeconds("00:00:30") + value["duration"],
                title: value["filename"],
                scheduledDate: getFormattedDate(),
                date: getFormattedDate(),
            };

            return { ...item, adBreaks: [...item.adBreaks, obj] };
        }
        return item;
    });

    addadsSqId(updatedGridList, millisecondsToHuman, setGridList);
};

export const millisecondsToHuman = (duration) => {
    // if(duration === 0) return '00:00:00';
    let milliseconds = parseInt((duration % 1000) / 100),
    seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)));

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds ;
}

const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;
export const verifyMilliSeconds = (duration) => {
    const len = duration?.toString()?.split(":");
    if(len?.length > 1) {
        return parseInt(toMilliseconds(len[0], len[1], len[2]))
    } else {
        return parseInt(duration);
    }
}

export const totalScheduleTimeFun = (
    gridList,
    singleTimeSlots,
    setTimeSlots,
    setTotalDuration,
    setTotalVideoDuration,
    setTotalAdDuration,
    setTotalPromosDuration
) => {
    let totalDuration = 0, videoDuration = 0, adsDuration = 0, promosDuration = 0, fillerDuration = 0;
    let totalVideos = 0, totalAds = 0, totalPromos = 0, totalFillers = 0;
    gridList?.forEach((data => {
        if(!data?.totalDurationInMillis) {
            data.totalDurationInMillis = data.durationInMillis;
        }
        totalDuration += verifyMilliSeconds(data?.totalDurationInMillis);
        videoDuration += verifyMilliSeconds(data?.durationInMillis);
        totalVideos += 1;

        data.adBreaks.forEach((adData) => {
            switch(adData.assetType?.toLowerCase()) {
                case "filler":
                fillerDuration += verifyMilliSeconds(adData?.durationInMillis);
                totalFillers += 1;
                break;
                case "promo":
                promosDuration += verifyMilliSeconds(adData?.durationInMillis);
                totalPromos += 1;
                break;
                default:
                adsDuration += verifyMilliSeconds(adData?.durationInMillis);
                totalAds += 1;
                break;
            }
        })
    }))

    // Handle time slot repetition based on video duration
    const hoursInADay = 24;
    const millisInAnHour = 60 * 60 * 1000;
    const millisInADay = hoursInADay * millisInAnHour;

    const fullDays = Math.floor((videoDuration + fillerDuration + adsDuration) / millisInADay);
    const remainingDurationMillis = (videoDuration + + fillerDuration + adsDuration) % millisInADay;

    const repeatedSlots = [];
    // Repeat for full days
    for (let i = 0; i < fullDays; i++) {
        repeatedSlots.push(...singleTimeSlots);
    }
    // Add remaining slots
    const remainingHours = Math.ceil(remainingDurationMillis / millisInAnHour);
    if (remainingHours > 0) {
        repeatedSlots.push(...singleTimeSlots.slice(0, Math.min(remainingHours * 2, singleTimeSlots.length)));
    }
    // Set time slots based on video duration
    setTimeSlots(repeatedSlots);
    
    setTotalDuration({
        duration: millisecondsToHuman(totalDuration),
        totalNum: totalVideos + totalFillers + totalPromos + totalAds,
    });
    setTotalVideoDuration({
        duration: millisecondsToHuman(videoDuration),
        totalNum: totalVideos,
    });
    setTotalAdDuration({
        duration: millisecondsToHuman(adsDuration + fillerDuration),
        totalNum: totalAds + totalFillers,
    });
    setTotalPromosDuration({
        duration: millisecondsToHuman(promosDuration),
        totalNum: totalPromos,
    });
    
}

// Function to generate time slots from 6:00 AM to 5:30 AM
export const generateTimeSlots = () => {
    let timeSlotsArray = [];
    let currentHour = 6; // Start at 6:00 AM
    let isPM = false;

    while (!(currentHour === 5.5 && !isPM)) {
        let hours = Math.floor(currentHour) % 12 || 12; // Convert to 12-hour format, default to 12 for 0
        let minutes = (currentHour % 1 === 0) ? '00' : '30'; // Set minutes to 00 or 30
        let period = isPM ? 'PM' : 'AM';

        // Format the time correctly
        let time = `${hours}:${minutes} ${period}`;
        timeSlotsArray?.push(time);

        // Increment by 30 minutes
        currentHour += 0.5;
        
        // Switch AM/PM after 12 PM and wrap after midnight
        if (currentHour >= 12 && currentHour < 24) isPM = true;
        else if (currentHour >= 24) {
            isPM = false;
            currentHour -= 24; // Reset to continue into AM hours
        }
    }
    timeSlotsArray?.push("5:30 AM");
    return timeSlotsArray;
};

export const calculateMinutes = (durationInMillis) => {
    const durationInMinutes = durationInMillis / 60000;
    return durationInMinutes;
}

export const getAssetInfo = (
    sequenceId,
    gridList,
    setAssetInfo,
    setSeqId
) => {
    const matchedAsset = gridList?.find(item => item.sequence === Number(sequenceId));
    // setAssetInfo(matchedAsset || null);
    // setSeqId(sequenceId);
    if (matchedAsset || sequenceId == 1) {
        setAssetInfo(matchedAsset);
        setSeqId(sequenceId);
    } else if (gridList?.length > 0) {
        const lastItem = gridList[gridList.length - 1];
        // const lastItem = gridList[0];
        setAssetInfo(lastItem);
        setSeqId(lastItem.sequence);
    } else {
        setAssetInfo(null);
        setSeqId(null); // Optional: Handle cases where gridList is empty
    }
}

export const setAdsTime = (
    e, 
    adSqId, 
    sequenceId,
    gridList,
    toast,
    setGridList
) => {
    let convertTime = verifyMilliSeconds(e);
    let PrevAdBreaksTotalDuration = 0;
    const updatedGridList = gridList.map(item => {
        if (item.sequence === sequenceId) {
            let totalDuration = 0;
    
            let updatedAdBreaks = item.adBreaks.map(ad => {
                if (item.durationInMillis < convertTime) {
                    toast.error("Ads cannot be set beyond the video's duration.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000
                    });
                    return ad;
                }
    
                if (ad.adSqId === adSqId) {
                    const isOverlapping = item.adBreaks.some(
                        a => a.adInsertionPoint === convertTime && a.adSqId !== ad.adSqId
                    );
    
                    if (isOverlapping) {
                        toast.error("Ad cannot overlap with an existing ad.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 3000
                        });
                        return ad;
                    }
    
                    ad = { ...ad, adInsertionPoint: convertTime, adInsertionPointFormat: e };
                }
    
                return ad;
            });
    
            // **Sort & Rearrange `adSqId`**
            updatedAdBreaks = updatedAdBreaks
                .sort((a, b) => a.adInsertionPoint - b.adInsertionPoint)
                .map((ad, index) => ({ ...ad, adSqId: index + 1 }));
    
            // **Recalculate Offsets**
            updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
                totalDuration += index === 0 ? 0 : updatedAdBreaks[index - 1]?.durationInMillis || 0;
                const startOffsetInMillis = ad.adInsertionPoint + totalDuration;
                const endOffsetInMillis = startOffsetInMillis + (ad.durationInMillis || 0);
    
                return {
                    ...ad,
                    startOffsetInMillis,
                    endOffsetInMillis,
                    startAt: millisecondsToHuman(startOffsetInMillis),
                    endAt: millisecondsToHuman(endOffsetInMillis),
                };
            });
    
            return { ...item, adBreaks: updatedAdBreaks };
        }
        return item;
    });        
    
    setGridList(updatedGridList);
}

export const selectedFiller = (
    id, 
    adSqId, 
    sequenceId,
    fillerList,
    gridList,
    setGridList
) => {
    const matchedFiller = fillerList.find(item => item.id === Number(id));
    const updatedGridList = gridList.map(item => {
        if (item.sequence === sequenceId) {
            return {
                ...item,
                adBreaks: item.adBreaks.map(ad => {
                    if (ad.adSqId === adSqId) {
                        const assetId = matchedFiller["id"];
                        const duration = matchedFiller["duration"];
                        const durationInMillis = matchedFiller["duration"];
                        const title = matchedFiller["title"];
                        const assetType = "FILLER";
                        return {
                            ...ad,
                            assetId,
                            duration,
                            durationInMillis,
                            title,
                            assetType,
                        };
                    }
                    return ad;
                })
            };
        }
        return item;
    });
    setGridList(updatedGridList);
}

export const searchProgram = (
    text,
    originalProgramList,
    setProgramList,
    setSearchedText
) => {
    if (text) {
        const filterPrograms = originalProgramList?.filter((data) => {
            return data?.metadata?.title?.toLowerCase().includes(text.toLowerCase());
        });
        setProgramList(filterPrograms);
    } else {
        // If text is empty or null, show the original program list
        setProgramList(originalProgramList);
    }
    setSearchedText(text);
};