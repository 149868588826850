import React, { useState, useEffect, useRef } from 'react';

// import "../../pages/asset-management/channeldetail/CmsChannelDetail.scss";
import './metastyle.scss';
import Multiselect from 'multiselect-react-dropdown';
import closeChip from '../../assets/images/close-chip.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow.svg';
import { Categories_list, Country_list, Genres_list, Language_list, MetadataAdd, Metadataupdate, Partner_list, Theme_list } from "../../api/api";
import { ContentGroup, image_url, ratings } from "../../service/Constant";
import Groupicon from "../../assets/svg/partitioncontent/blueVideoImg.svg";
import expand from "../../assets/svg/partitioncontent/open_in_full.svg";
import DynamicMultiSelect, { CustomMultiSelect } from '../multiselect/MultiSelect';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { posterimage } from '../../service/commonFunc';
import check from "../../assets/svg/partitioncontent/star_rate.svg";
import uncheck from "../../assets/svg/partitioncontent/starIcon.svg";



function MetaDataView({ videodata, closepartition, assetdetail, programVideos, databymeta }) {
    // Define state variables for input values
    // const metadata = videodata.metadata;
    const AssetPartnerDetail = JSON.parse(localStorage.getItem("AssetPartnerDetail"));
    const [metadata, setMetadata] = useState(videodata?.metadata);
    const state = useSelector((state) => state);
    const genre = state?.GenreList?.data;
    const language = state?.AudioLanguageList?.data;
    const Themes = state?.ThemesList?.data;
    const Category = state?.CategoriesList?.data;
    const Ratings = state?.MaturityList?.data;
    const Territorys = state?.CountryList?.data?.data;
    // Field Options State
    const [partnerOption, setPartnerOptions] = useState([]);
    const [territoryOption, setTerritoryOption] = useState([]);
    const [categoryOptions, setCategoryOption] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [themeList, setThemeList] = useState([]);
    const [ratingList, setRatingList] = useState([]);
    // const [fillerList, setFillerList] = useState([]);
    const [gernreList, setGenreList] = useState([]);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [selectedThemeIds, setSelectedThemeIds] = useState([]);
    const [selectedLanguageIds, setSelectedLanguageIds] = useState([]);
    const [selectedGenreIds, setSelectedGenreIds] = useState([]);
    const [selectedTerritoryIds, setSelectedTerritoryIds] = useState([]);
    useEffect(() => {

        if (videodata?.metadata) {
            const newMetadata = videodata?.metadata;

            setMetadata(newMetadata);
            setFeaturecheck(newMetadata?.featured == null ? false : newMetadata?.featured)
            setPartnerCode(newMetadata?.partnerCode);
            setPartnerName(newMetadata?.partnerName);
            // setSourceLocation(newMetadata?.sourceLocation);
            setProgramId(newMetadata?.programId);
            // setVodSource(newMetadata?.vodSource);
            setTitle(newMetadata?.title);
            setProgramType(newMetadata?.programType);
            setDuration(newMetadata?.duration);
            settranscodeduration(videodata?.duration)
            setShortSynopsis(newMetadata?.shortSynopsis);
            setActors(newMetadata?.actors);
            setDirectors(newMetadata?.directors);

            setOriginalLanguage(newMetadata?.originalLanguage);
            setOrigin(newMetadata?.origin);
            let rate = newMetadata?.ratings[0]?.id
            setRating(rate);
            setRatingsDescriptor(newMetadata?.ratingsDescriptor);
            // setTerritory(newMetadata?.territoryIds);
            const end = parseInt(newMetadata?.endDate, 10); // Convert string to number
            const timestamp = parseInt(newMetadata?.startDate, 10); // Convert string to number
            const startdate = !isNaN(timestamp) ? new Date(timestamp) : null;
            const enddate = !isNaN(end) ? new Date(end) : null;


            const redate = parseInt(newMetadata?.releaseDate, 10);
            const daterelease = !isNaN(redate) ? new Date(redate) : null;
            setReleaseDate(daterelease);
            setStartDate(startdate);
            setEndDate(enddate);
            // setCoverartFilename(newMetadata?.coverartFilename);
            // setHeroartFilename(newMetadata?.heroartFilename);
            // setAudioLanguages(newMetadata?.audioLanguages);

            setContentGroup(newMetadata?.contentGroup);

            setSelectedThemeIds(newMetadata?.themes.map((obj) => obj.id));
            setSelectedCategoryIds(newMetadata?.categories?.map((obj) => obj.id));
            setSelectedLanguageIds(newMetadata?.audioLanguages?.map((obj) => obj.id));
            setSelectedGenreIds(newMetadata?.genres?.map((obj) => obj.id));
            setSelectedTerritoryIds(newMetadata?.territories?.map(obj => obj.id))
        } else {
            setMetadata("");
            setPartnerCode("");
            setPartnerName("");
            // setSourceLocation(newMetadata?.sourceLocation);
            setProgramId("");
            // setVodSource(newMetadata?.vodSource);
            setTitle("");
            setProgramType("");
            setDuration("");
            settranscodeduration("");
            
            setShortSynopsis("");
            setActors("");
            setDirectors("");
            setReleaseDate();
            setOriginalLanguage("");
            setOrigin("");
            setRating([]);
            setRatingsDescriptor("");
            // setTerritory([]);
            setFeaturecheck(null)
            // const end = parseInt(newMetadata?.endDate, 10); // Convert string to number
            // const timestamp = parseInt(newMetadata?.startDate, 10); // Convert string to number
            // const startdate = !isNaN(timestamp) ? new Date(timestamp) : null;
            // const enddate = !isNaN(end) ? new Date(end) : null;



            setStartDate(null);
            setEndDate(null);
            // setCoverartFilename(newMetadata?.coverartFilename);
            // setHeroartFilename(newMetadata?.heroartFilename);
            // setAudioLanguages(newMetadata?.audioLanguages);

            setContentGroup();

            setSelectedThemeIds([]);
            setSelectedCategoryIds([]);
            setSelectedLanguageIds([]);
            setSelectedGenreIds([]);
            setSelectedTerritoryIds([])

        }


    }, [videodata]);
 

    const [updatebtn, setupdatebtn] = useState(false);
    const [partnerCode, setPartnerCode] = useState("");
    const [partnerName, setPartnerName] = useState("");
    const [programId, setProgramId] = useState("");
    const [title, setTitle] = useState("");
    const [programType, setProgramType] = useState("");
    const [duration, setDuration] = useState("");
    const [shortSynopsis, setShortSynopsis] = useState("");
    const [actors, setActors] = useState("");
    const [directors, setDirectors] = useState("");
    const [releaseDate, setReleaseDate] = useState("");
    const [originalLanguage, setOriginalLanguage] = useState();
    const [origin, setOrigin] = useState("");
    const [rating, setRating] = useState("");
    const [ratingsDescriptor, setRatingsDescriptor] = useState();
    // const [territory, setTerritory] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contentGroup, setContentGroup] = useState("");
    const [featurecheck, setFeaturecheck] = useState(false);
    const [transcodeduration, settranscodeduration] = useState("");
    const featuredchecks = () => {

        if (featurecheck) {
            setFeaturecheck(false)
        } else {
            setFeaturecheck(true)
        }

    };


    const handleDoneClick = () => {
        // Collect all the data
        const data = {
            title,
            programType,
            duration,
            shortSynopsis,
        };
        updatemetadata(videodata?.metadata?.id)
    };

    // Function to convert date
    const formatReleaseDate = () => {
        const date = new Date(releaseDate);
        return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
    };

    const updatemetadata = async (id) => {
        setupdatebtn(true)
        let data =
        {
            ...(videodata?.metadata == null && {
                "programId": videodata?.filename
            }),
            ...(videodata?.metadata == null && {
                "partnerId": AssetPartnerDetail?.id
            }),
            "title": title,
            "programType": programType,
            "duration": duration,
            "shortSynopsis": shortSynopsis,
            "actors": actors,
            "directors": directors,

            "originalLanguage": originalLanguage,
            "origin": origin,
            "ratingIds": rating == null ? [] : [rating],
            "ratingsDescriptor": ratingsDescriptor,
            "territoryIds": selectedTerritoryIds,
            "startDate": startDate == null ? "" : new Date(startDate).getTime(),
            "endDate": endDate == null ? "" : new Date(endDate).getTime(),
            // "releaseDate": releaseDate == null ? "" : new Date(releaseDate).getTime(),
            "releaseDate": releaseDate == null ? null : formatReleaseDate(),
            "audioLanguageIds": selectedLanguageIds,
            "themeIds": selectedThemeIds,
            "categoryIds": selectedCategoryIds,
            "genreIds": selectedGenreIds,
            "contentGroup": contentGroup,
            "featured": featurecheck,
        };

        if (title != "") {
            if (videodata?.metadata != null) {
                const metadata = await Metadataupdate(id, data);
                if (metadata?.data.status == true) {

                    // dispatch(AssetDetailAction(AssetDetail?.data))

                    toast.success("Metadata updated successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                    });
                } else {
                    toast.error("Something went wrong, please try after sometime", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                    });
                }
            } else {
                const addmetadata = await MetadataAdd(data);
                if (addmetadata?.data.status == true) {

                    // dispatch(AssetDetailAction(AssetDetail?.data))

                    toast.success("Metadata added successfully", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                    });
                } else {
                    toast.error("Something went wrong, please try after sometime", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                    });
                }
            }
            setupdatebtn(false)
            assetdetail();
        } else {
            toast.error("Title is required", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
            });
            setupdatebtn(false)
        }
    };

    //new code
    useEffect(() => {
        // getChannelThemeList();
        // getChannelCategoryList();
        // getLanguageList()
        getPartnerList()
        // getgenreList()
        // getCountriesList()
        setGenreList(genre)
        setLanguageList(language)
        setCategoryOption(Category)
        setThemeList(Themes)
        setRatingList(Ratings)
        setTerritoryOption(Territorys)
    }, [])






    // Get Terretory Option List
    // const getCountriesList = async () => {
    //     const countriesData = await Country_list();
    //     let countries = countriesData.map((item) => {
    //         return { territoryId: item.flag, niceName: item.name.common }
    //     });

    //     countries.sort((a, b) => {
    //         return a.niceName.localeCompare(b.niceName);
    //     });

    //     if (countries && countries?.length) {
    //         setTerritoryOption(countries);
    //     }
    // }

    // Get Partner Option List
    const getPartnerList = async () => {
        const partnerData = await Partner_list();
        if (partnerData?.data && partnerData?.status === true) {
            setPartnerOptions(partnerData.data);
        }
    };


    const getgenreList = async () => {
        const languageList = await Genres_list({ searchText: "", sortBy: "name", sortOrder: "asc" });
        if (languageList?.content && languageList?.content?.length) {
            setGenreList(languageList.content);
        }
    }

    // Get Language List
    const getLanguageList = async () => {
        const languageList = await Language_list();
        if (languageList?.content && languageList?.content?.length) {
            setLanguageList(languageList.content);
        }
    }

    // Get Channel Category List
    const getChannelCategoryList = async () => {
        const categoryList = await Categories_list({ searchText: "", sortBy: "name", sortOrder: "asc" });
        if (categoryList?.content && categoryList?.content?.length) {
            setCategoryOption(categoryList.content);
        }
    }

    // Get Theme List
    const getChannelThemeList = async () => {
        const themeList = await Theme_list({ searchText: "", sortBy: "name", sortOrder: "asc" });
        if (themeList?.content && themeList?.content?.length) {
            setThemeList(themeList.content);
        }
    }

    // Get Filler List
    //   const getFillerByPartner = async(id) => {
    //     const fillerList = await FillerList(id);
    //     if (fillerList?.data && fillerList?.data?.length) {
    //       setFillerList(fillerList.data);
    //     }
    //   }


    const validateValue = (value) => {
        if (value == '' || value == null || value.length === 0) {
            return 'Field is Required';
        } else {
            return ''
        }
    }



    return (
        <div className=" bg-light row">
            <div className="breadcrumbs">
                <ul>
                    <li>
                        <a href='/asset'>Asset Management</a>
                    </li>
                    <li>
                       
                        <a
                          href="/CmsChannelDetail"


                        >{AssetPartnerDetail?.name}</a>
                    </li>
                    <li className="active">MetaData</li>
                </ul>
            </div>

            <div className='col-10 '>
                <div className="video-container my-3">
                    <div className="video-title">{videodata?.metadata == null ? videodata?.filename : videodata?.metadata?.title}
                        <span className="small-grey-text">  {videodata?.metadata == null
                            ? null
                            : "(" + videodata?.filename + ")"}</span>



                    </div>
                    {featurecheck ? <button className="image-container" style={{ marginLeft: "auto" }} onClick={() => featuredchecks()}>
                        <img
                            src={check}
                            className="image"
                        />
                    </button> :
                        <button className="image-container" style={{ marginLeft: "auto" }} onClick={() => featuredchecks()}>
                            <img
                                src={uncheck}
                                className="image"
                            />
                        </button>}
                    <button className="image-container"  onClick={() => closepartition()}>
                        <img
                            src={expand}
                            className="image"
                        />
                    </button>


                </div>
                <div className='scroll-height '>  <div className="expand-content w-100 row p-3">
                    <div className="detail-row col-6">
                        <div className="detail-label">Title<span style={{ color: "red" }}>*</span></div>
                        <input
                            className="detail-inputs"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="detail-row  col-6">
                        <div className="detail-label">Program Type</div>
                        <input
                            className="detail-inputs"
                            value={programType}
                            onChange={(e) => setProgramType(e.target.value)}
                        />
                    </div>

                    <div className="detail-row  col-6">
                        <div className="detail-label">Duration</div>
                        <input
                            className="detail-inputs"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                        />
                    </div>
                    <div className="detail-row  col-6">
                        <div className="detail-label">Transcoded Duration</div>
                        <input
                        disabled
                            className="detail-inputs"
                            value={transcodeduration}
                        onChange={(e) => settranscodeduration(e.target.value)}
                        />
                    </div>

                    <div className="detail-row  col-6">
                        <div className="detail-label">Release Date</div>
                        <DatePicker
                            className="detail-inputs"
                            selected={releaseDate || null}
                            onChange={(date) => setReleaseDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable

                        />
                    </div>

                    <div className="detail-row  col-6">
                        <div className="detail-label">Content Group</div>
                        <select
                            className="detail-inputs"

                            name="Content"
                            value={contentGroup}
                            onChange={(e) => setContentGroup(e.target.value)}
                            required
                        >
                            <option value="">Select Content Group</option>
                            {
                                ContentGroup && ContentGroup?.map((item) => {
                                    return (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="detail-row  col-6">
                        <div className="detail-label">Start Date</div>

                        <DatePicker
                            className="detail-inputs"
                            selected={startDate || null}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable

                        />

                    </div>

                    <div className="detail-row  col-6">
                        <div className="detail-label">End Date</div>

                        <DatePicker
                            className="detail-inputs"
                            selected={endDate || null}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable
                            minDate={startDate} // Setting the minimum selectable date for the end date picker
                            disabled={!startDate} // Disable the end date picker if start date is not selected
                        />
                    </div>



                    <div className="detail-row  col-4">
                        <div className="detail-label">Genres</div>

                        <DynamicMultiSelect

                            options={gernreList}
                            displayValue="name"
                            selectedValues={selectedGenreIds} // Use your state directly here
                            placeholder="Select Genre"
                            setSelectedCategoryIds={setSelectedGenreIds}
                            selectedCategoryIds={selectedGenreIds}
                        />
                    </div>

                    <div className="detail-row  col-4">
                        <div className="detail-label">Themes</div>

                        <DynamicMultiSelect

                            options={themeList}
                            displayValue="name"
                            selectedValues={selectedThemeIds} // Use your state directly here
                            placeholder="Select Theme"
                            setSelectedCategoryIds={setSelectedThemeIds}
                            selectedCategoryIds={selectedThemeIds}
                        />

                    </div>

                    <div className="detail-row  col-4">
                        <div className="detail-label">Categories</div>


                        <DynamicMultiSelect

                            options={categoryOptions}
                            displayValue="name"
                            selectedValues={selectedCategoryIds} // Use your state directly here
                            placeholder="Select Categories"
                            setSelectedCategoryIds={setSelectedCategoryIds}
                            selectedCategoryIds={selectedCategoryIds}
                        />
                    </div>


                    <div className="detail-row  col-6">
                        <div className="detail-label">Original Language</div>

                        <select className="detail-inputs" name='theme' value={originalLanguage} onChange={(e) => setOriginalLanguage(e.target.value)} required>
                            <option value="">Select Language</option>
                            {
                                languageList && languageList?.map((item) => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>


                    <div className="detail-row  col-6">
                        <div className="detail-label">Audio Languages</div>


                        <DynamicMultiSelect

                            options={languageList}
                            displayValue="name"
                            selectedValues={languageList} // Use your state directly here
                            placeholder="Select Language"
                            setSelectedCategoryIds={setSelectedLanguageIds}
                            selectedCategoryIds={selectedLanguageIds}
                        />
                    </div>


                    <div className="detail-row  col-6">
                        <div className="detail-label">Rating</div>

                        <select
                            className="detail-inputs"

                            name="theme"
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                            required  >
                            <option value="">Select Rating</option>
                            {
                                ratingList && ratingList.map((item, index) => {
                                    return (
                                        <option key={item} value={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                        </select>

                    </div>
                    <div className="detail-row  col-6">
                        <div className="detail-label">Ratings Descriptor</div>
                        <input
                            className="detail-inputs"
                            value={ratingsDescriptor}
                            onChange={(e) => setRatingsDescriptor(e.target.value)}
                        />
                    </div>
                    <div className="detail-row  col-6">
                        <div className="detail-label">Origin</div>
                        <select
                            className="detail-inputs"

                            name="theme"
                            value={origin}
                            onChange={(e) => setOrigin(e.target.value)}
                            required
                        >
                            <option value="">Select Origin</option>
                            {territoryOption &&
                                territoryOption?.map((item) => {
                                    return (
                                        <option key={item.id} value={item.niceName}>
                                            {item.niceName}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>

                    <div className="detail-row  col-6">
                        <div className="detail-label">Territory</div>

                        <DynamicMultiSelect

                            options={territoryOption}
                            displayValue="niceName"
                            selectedValues={selectedTerritoryIds} // Use your state directly here
                            placeholder="Select Territory"
                            setSelectedCategoryIds={setSelectedTerritoryIds}
                            selectedCategoryIds={selectedTerritoryIds}
                        />

                    </div>
                    <div className="detail-row  col-6">
                        <div className="detail-label">Actors</div>
                        <input
                            className="detail-inputs"
                            value={actors}
                            onChange={(e) => setActors(e.target.value)}
                        />
                    </div>


                    <div className="detail-row  col-6">
                        <div className="detail-label">Directors</div>
                        <input
                            className="detail-inputs"
                            value={directors}
                            onChange={(e) => setDirectors(e.target.value)}
                        />
                    </div>



                    <div className="detail-row  col-12">
                        <div className="detail-label">Short Synopsis</div>
                        <textarea
                            className="detail-inputs"
                            value={shortSynopsis}
                            onChange={(e) => setShortSynopsis(e.target.value)}
                        />
                    </div>

                </div>
                </div>


            </div>
            <div className='col-2 '>
                <div className='right-bar'>
                    <div>
                        <div><h3 className='text-center right-head'> Videos </h3></div>
                        <div className='video-channel-list meta scroll-height'>
                            {programVideos &&
                                programVideos.map((data, index) => {
                                    let image = posterimage(data)

                                    return (

                                        videodata?.filename == data?.filename ? <div className="selected-block block m-auto my-2" onClick={() => databymeta(data)} >

                                            {image == "" ?
                                                <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                                <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image} alt="" /></div>}

                                            <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>

                                        </div> : null

                                    );
                                })}
                            {programVideos &&
                                programVideos.map((data, index) => {
                                    let image = posterimage(data)

                                    return (

                                        videodata?.filename == data?.filename ? null :

                                            <div className=" block m-auto my-2" onClick={() => databymeta(data)} >

                                                {image == "" ?
                                                    <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                                    <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image} alt="" /></div>}

                                                <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>

                                            </div>

                                    );
                                })}

                        </div>
                    </div>


                </div>



            </div>
            <div className='m-3 '>
                <button className=' default-odd' onClick={() => closepartition()}>Cancel</button>
                <button disabled={updatebtn} className=' default-even mx-3' onClick={() => handleDoneClick()}>{videodata?.metadata != null ? "Done" : "Add"}</button>


            </div>
        </div>
    );
}

export default MetaDataView;