export const TableBody = ({
    children,
    handleDragStarts,
    handleDragOvers,
    handleAdsAndProgramDrop
}) => {
    return (
        <div className="content-wrapper">
            <table className="scheduling-table tbl-50-per">
                <tbody
                    data-type="table-body"
                    role="none"
                    onDragStart={handleDragStarts}
                    onDragOver={handleDragOvers}
                    onDrop={(e) => handleAdsAndProgramDrop(e)}
                >   
                    <div className="row w-100">
                        {children}
                    </div>
                </tbody>
            </table>
        </div>
    )
}