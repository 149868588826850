export const HoverLine = ({
    linePosition,
    hoverTime
}) => {
    return (
        <div
            style={{
                position: "absolute",
                pointerEvents: "none",
                top: `${linePosition}px`,
                zIndex: "9999",
                left: "-8",
                width: "250px",
                height: "2px",
                backgroundColor: "blue",
                pointerEvents: "none"
            }}
        >
            <span
                style={{
                    position: "absolute",
                    zIndex: "99999999",
                    top: "-22px",
                    left: "0px",
                    backgroundColor: "white",
                    padding: "2px 5px",
                    fontSize: "12px",
                }}
            >
                {hoverTime} min
            </span>
        </div>
    )
}