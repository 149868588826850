import React from "react";
import TimePicker from "react-time-picker";

const AdBreaksTable = ({
    appTheme,
    categoryTab,
    handlecategoryTab,
    locationState,
    assetInfo,
    selectedFillers,
    fillerList,
    millisecondsToHuman,
    updateAdsTime,
    deleteAdEvent,
    deleteIcon,
    adAdsOnClick
}) => {
  return (
    <div className={`bottom-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
        <div className="tabs mt-4 p-2">
            <ul className={`channel-category-tab px-1 ${appTheme === "light" ? "border shadow-sm": ""}`}>
                <li className={categoryTab === "Ads" ? "active" : ""}>
                <a onClick={() => handlecategoryTab("Ads")}>Ads</a>
                </li>
                <li className={categoryTab === "Promos" ? "active" : ""}>
                <a onClick={() => handlecategoryTab("Promos")}>Promos</a>
                </li>
                <li className={categoryTab === "Secondary" ? "active" : ""}>
                <a disable onClick={() => {handlecategoryTab("Secondary");}}>Secondary</a>
                </li>
            </ul>
        </div>
        <div className={`ads-table mx-2 my-3 p-2 rounded ${appTheme === "light"? "shadow-sm border" : ""}`}>
            <table className={`table ${appTheme === "light"? "border table-striped table-hover" : ""}`}>
                <thead>
                    <tr>
                        <th>Name <span className="header-icon"></span></th>
                        <th>Start Time <span className="header-icon"></span></th>
                        <th>Duration</th>
                        <th>
                            {
                                locationState?.state?.permission === "edit" &&
                                "Actions"
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                { categoryTab === "Ads"?
                    assetInfo?.adBreaks?.some((data) => data?.assetType === "AD" || data?.assetType === "FILLER")?
                        assetInfo?.adBreaks
                        ?.filter((data) => data?.assetType === "AD" || data?.assetType === "FILLER")
                        .map((data, index) => {
                            return (
                                <tr key={data.id}>
                                    <td style={{width:"40%"}}>   
                                        <select value={data?.assetId} onChange={(e)=> {selectedFillers(e.target.value, data?.adSqId, assetInfo?.sequence)}}>
                                            {
                                                fillerList?.map((fillers) => {
                                                    return <option value={fillers?.id} >{fillers?.filename}</option>
                                                })
                                            }
                                        </select>
                                    </td>
                                    <td style={{width:"25%"}}>
                                        <TimePicker
                                            value={millisecondsToHuman(data?.adInsertionPoint)}
                                            hourPlaceholder="HH"
                                            minutePlaceholder="mm"
                                            secondPlaceholder="ss"
                                            maxDetail="second"
                                            // maxTime={parentData?.extendedProps.endAt}
                                            // minTime={parentData?.extendedProps.startAt}
                                            format="HH:mm:ss"
                                            disableClock={true}
                                            onChange={(e) => {
                                                updateAdsTime(e, data?.adSqId, assetInfo?.sequence);
                                            }}
                                        />
                                    </td>
                                    <td style={{width:"25%"}}>
                                        <div className="ads-duration">
                                            {millisecondsToHuman(data?.durationInMillis)}
                                        </div>
                                    </td>
                                    {/* <td>
                                        <TimePicker
                                            value={millisecondsToHuman(data?.durationInMillis)}
                                            hourPlaceholder="HH"
                                            minutePlaceholder="mm"
                                            secondPlaceholder="ss"
                                            maxDetail="second"
                                            // maxTime={parentData?.extendedProps.endAt}
                                            // minTime={parentData?.extendedProps.startAt}
                                            format="HH:mm:ss"
                                            disableClock={true}
                                            onChange={(e) => {
                                                setDurationTime(e, data?.adSqId, assetInfo?.sequence);
                                            }}
                                        />
                                    </td> */}
                                    <td style={{width:"10%"}}>
                                        {
                                            locationState?.state?.permission === "edit" &&
                                            <div className="table-action-group">
                                                <div className='icon-group'>
                                                    {/* <span className="table-icon me-1"><img src={editIcon} /></span> */}
                                                    <span className="table-icon" onClick={()=> {deleteAdEvent(data?.adSqId, assetInfo?.sequence)}}><img src={deleteIcon} /></span>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                        :
                        <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                            No ads to display.
                        </td>
                    :
                    categoryTab === "Promos"?
                    assetInfo?.adBreaks?.some((data) => data?.assetType === "PROMO")?
                        assetInfo?.adBreaks
                        ?.filter((data) => data?.assetType === "PROMO")
                        .map((data) => {
                            return (
                                <tr key={data.id}>
                                    <td>{data?.title}</td>
                                    <td>
                                        <TimePicker
                                            value={millisecondsToHuman(data?.startOffsetInMillis)}
                                            hourPlaceholder="HH"
                                            minutePlaceholder="mm"
                                            secondPlaceholder="ss"
                                            maxDetail="second"
                                            // maxTime={parentData?.extendedProps.endAt}
                                            // minTime={parentData?.extendedProps.startAt}
                                            format="HH:mm:ss"
                                            disableClock={true}
                                            onChange={(e) => {
                                                updateAdsTime(e, data?.adSqId, assetInfo?.sequence);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {millisecondsToHuman(data?.durationInMillis)}
                                    </td>
                                    <td>
                                        {
                                            locationState?.state?.permission === "edit" &&
                                            <div className="table-action-group">
                                                <div className='icon-group'>
                                                    {/* <span className="table-icon me-1"><img src={editIcon} /></span> */}
                                                    <span className="table-icon ms-3" onClick={()=> {deleteAdEvent(data?.adSqId, assetInfo?.sequence)}}><img src={deleteIcon} /></span>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                        :
                        <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                            No promos to display.
                        </td>
                    :
                    categoryTab === "Secondary"?
                    <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                        No Secondary to display.
                    </td>
                    :
                    ''
                }
                </tbody>
            </table>
        </div>
        {
            categoryTab === "Ads"?
            <span className="mt-3 ads-table-btn p-2" onClick={() => {adAdsOnClick(assetInfo?.sequence)}}>+ Ad Break</span>
            :
            ''
        }
    </div>
  );
};

export default AdBreaksTable;
