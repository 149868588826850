  import React,{useState,useRef,useEffect} from 'react'
  import * as ReactDOM from 'react-dom';
  import { createRoot } from 'react-dom/client';
  import './scheduling.scss';
  import stylesAds from './AdsPopup.module.scss';
  import { Calendar } from '@fullcalendar/core';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
  import copyOutline from "../../assets/images/solar_copy-outline.png";
  import { useLocation } from 'react-router-dom';
  import deleteicon from "../../assets/images/deleteicon.png";
  import { toast, ToastContainer } from "react-toastify";
  import AdsPopup from "./AdsPopup";
  import moment from 'moment';
  import {
    Schedules_list,
    Schedules_Datalist_Loop,
    ScheduleLoop_update
  } from "../../api/api";
  import TimePicker from 'react-time-picker';
  import editIcon from '../../assets/images/edit-outline.svg';
  import "react-toastify/dist/ReactToastify.css";
  import 'react-time-picker/dist/TimePicker.css';
  import 'react-datepicker/dist/react-datepicker.css';
  import 'react-datepicker/dist/react-datepicker-cssmodules.css'; // Use this CSS module
  import { add } from 'date-fns';

  function ScheduleLoop() {
    const calendarRef = useRef();
    const draggableRef = useRef(null);
    
    const [dataChage, setDataChange] = useState(false);
    const [position, setPosition]= useState(0);
    const timer = useRef(null);
    const [eventData, setEventData] = useState([]);
    const [adData, setAdData] = useState([]);
    const [parentData, setParentData] = useState();
    const [isZoomIn, setIsZoomIn] = useState(true);
    const [selectedDate, setSelectedDate] = useState(true);
    const [eventStartDate, setEventStartDate] = useState('');
    const [weekView, setweekView] = useState(new Date().toISOString().substring(0,10));
    const [eventStartTime, setEventStartTime] = useState('');
    const [eventEndDate, setEventEndDate] = useState('');
    const [eventEndTime, setEventEndTime] = useState('');
    const [udpateAds, setUpdateAds] = useState('')
    const [programList, setProgramList] = useState();
    const [fillerList, setFillerist] = useState();
    const [gridList, setGridist] = useState();
    const [firstFetch, setFirstFetch] = useState(false)
    const [promoList, setPromoList] = useState();
    const [masterData, setMasterData] = useState();
    const [PartnerData, setPartnerData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [focusTime, setFocusTime] = useState(0);
    const [copyData, setCopyData] = useState([]);
    const [validForm, setValidForm] = useState('invalid');
    const [activeData, setActiveData] = useState(null);

    const [firstData, setFirstData] = useState(true);
    const [singledata, setSingleData] = useState(null);
    const [validateData, setValidateData] = useState(null);
    const [data, setData] = useState([]);
    const [allowedEdit, setAllowedEdit] = useState(true);
    const [editData, setEditData] = useState(null);
    
    const [minTime, setMinTime] = useState(null);
    const [maxTime, setMaxTime] = useState(null);
    
    const [durationData, setDurationData] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const locationState = useLocation();
    const hasDarkTheme = document.getElementsByTagName('body')[0].classList.contains('dark-theme');
    let calendar;
    
    useEffect(() => {
      const ndata = eventData[editIndex-1];
    }, [singledata]);
    useEffect(() => {
      
        const customButtonsSetup = () => {
        if(locationState?.state?.type === 'APIDriven' || locationState?.state?.type === 'XLSInput') {
          return [
            'prev,title,next today',
            'zoomButton'
          ]
        } else {
          return [
            'prev,title,next today autoSchedule',
            'undoButton zoomButton trimButton updateButton'
          ]
        }
      }
    const customBtns = customButtonsSetup(); 

    const calendarEl = document.getElementById('calendar');
    let eventUpdate = false;
    calendar = new Calendar(calendarEl, {
      timeZone: 'UTC',
      plugins: [timeGridPlugin, interactionPlugin],    
      initialView: 'timeGridDay',
      eventOrder: "sequence",
      customButtons: {
        undoButton: {
          text: 'Undo',
          click: () => {
            fetchDate();
            setSingleData(null);
            setValidateData(null);
          }
        },
        zoomButton: {
          text: (isZoomIn) ? 'Zoom out' : 'Zoom in',
          click: () => {
          // setFirstData(true);
            const prevTop =  document.querySelector('[data-time="00:00:00"]').getBoundingClientRect().top;
            if(position !== 0) setPosition(prevTop)
            setIsZoomIn(!isZoomIn);
            setTimeout(() => {
              let top = document.querySelector('[data-time="00:00:00"]').getBoundingClientRect().top;
              const itemPosition = document.querySelector(".fc-scroller-liquid-absolute").scrollTop;
              if(isZoomIn) {
                top = (top * -1) + 140;
              } else{
                if(top < itemPosition) {
                  top = itemPosition + top - 290;
                } else {
                  top = itemPosition - top;
                }
              }
              document.querySelector(".fc-scroller-liquid-absolute").scrollTo(0,top);
            }, 100)
          }
        },
        autoSchedule: {
          text: 'Auto schedule',
          click: () => {
            console.log('Auto schedule')
          },
        },
        trimButton: {
          text: 'Trim',
          click: () => {
            setFirstData(true);
            let currentDate = false;
            const sortEvent = eventData.sort((a, b) => a.sequence - b.sequence);
            const reversedEvent = eventData;
            let adDatas = [];
            let newAdData = [];
            let td = 0;
            const scheduledDate = new Date(calendar.view.activeStart.toGMTString()).toISOString().slice(0, 10);
            let previousTime = '00:00:00';
            let timeDiffInMS = 0;
            const filteredData = reversedEvent.map((data, index) => {
            // if(data.scheduledDate === selectedDate) {
                if(eventData[index-1]) {
                  const currentStartTime = verifyMilliSeconds(data.startAt);
                  const prevData = eventData[index-1];
                  const prevEndTime = verifyMilliSeconds(prevData.endAt);
                  if(currentStartTime !== prevEndTime && (currentStartTime < prevEndTime + 1800000) && index !== 0) {
                  //  timeDiffInMS = prevEndTime - verifyMilliSeconds(data.startAt);
                    const durationMS = verifyMilliSeconds(data.totalDuration)
                    data.totalDuration = durationMS;
                    data.endAt = millisecondsToHuman(prevEndTime + data.totalDuration);
                    data.startAt = millisecondsToHuman(prevEndTime);
                  }
                }
              //   if(!currentDate) {
              //     currentDate = true;
              //   }
              // } else {
              //   currentDate = false;
              // }
              // bug below here
              //previousTime = data.endAt;
              // bug above here

            //  let timeDiff = 0;
          //   if(previousTime >= )
              const updateTime = verifyMilliSeconds(timeDiffInMS) || 0;
              td = td + updateTime;
              const pt = (previousTime === '0') ? 0 : verifyMilliSeconds(previousTime);
              const en = (pt === 0) ? millisecondsToHuman(verifyMilliSeconds(data.totalDuration)) : millisecondsToHuman(pt + verifyMilliSeconds(data.totalDuration));
              const st = (pt ===0) ? '00:00:00' : (pt ===0) ? '00:00:00' : millisecondsToHuman(pt).toString();
              data.adBreaks.forEach(dt => {
                const item = {
                  "title": dt.title,
                  'start': scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis)),
                  'end': scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis) + parseInt(dt.durationInMillis)),
                  'scheduledDate': scheduledDate,
                  'date': scheduledDate,
                  'duration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
                  'totalDuration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
                  "eventType": 'adData',
                  "className": "selected-event",
                  "color": '#FFBE83',
                  'startAt': millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis)),          
                  'endAt': millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis) + parseInt(dt.durationInMillis)),
                  'startOffsetInMillis': dt.startOffsetInMillis,
                  "durationInMillis": dt.durationInMillis || 0,
                  "endOffsetInMillis":  dt.endOffsetInMillis
                }
                adDatas.push(item)
                return item;
                
              });
              const pitem = {
                'adBreaks': data.adBreaks,
                'adBreaksCount': data.adBreaksCount,
                'start': scheduledDate +'T'+ st,
                'end': scheduledDate +'T'+ en,
                'endAt': en,
                'startAt': (pt ===0) ? '00:00:00' : millisecondsToHuman(pt),
                'id': data.id,
                'title': data.title,
                'scheduledDate': data.scheduledDate,
                'duration': data.duration,
                'totalDuration': millisecondsToHuman(verifyMilliSeconds(data.totalDuration)),
                'assetType': data.assetType.toUpperCase(),
                'assetId': parseInt(data.assetId),
                'sequence': data.sequence,
                'createdAt': data.createdAt,
              }
              
                if(previousTime !== '00:00:00') {
                  previousTime = pt + verifyMilliSeconds(data.totalDuration)
                } else {
                  previousTime = verifyMilliSeconds(data.totalDuration);
                }
              return pitem;
            });
          //  setAdData(adDatas);
            setAdData([...adDatas])
            setEventData(filteredData);
          }
        },
        updateButton: {
          text: 'Update',
          click: async () => {
            // const allData = calendarRef.current.currentData.dateProfile.activeRange;
            // const dates = getDatesBetween(allData.start, allData.end);
            // dates.pop();
            // debugger;
            // console.log("eventDataeventData",JSON.stringify(eventData))
            const filteredData = eventData.map((d, i) => {
              const data = d;
              data.adBreaks.forEach((adData) => {
                return {
                  "id": adData.id,
                  "assetType": adData.assetType?.toUpperCase(),
                  "startOffsetInMillis": adData.startOffsetInMillis,
                  "assetId": parseInt(adData.assetId),
                  "title": adData.title,
                  "durationInMillis": adData.durationInMillis,
                  "endOffsetInMillis": adData.endOffsetInMillis
                }
              });
              return {
                'adBreaks': data.adBreaks,
              //  'adBreaksCount': data.adBreaksCount,
              //  'endAt': data.endAt || d.endStr.split('T')[1].substring(0,8),
              //  'startAt': data.startAt || d.startStr.split('T')[1].substring(0,8),
              // 'id': data.id,
            //   'durationInMillis': verifyMilliSeconds(data.duration),
            //    'totalDurationInMillis': verifyMilliSeconds(data.totalDuration),
                'assetId': parseInt(data.assetId || data.id),
                'sequence': i+1,
                'assetType': data?.assetType?.toUpperCase()
              }
            })
            const transformedData = filteredData
                      
            const response = await ScheduleLoop_update(transformedData, locationState?.state?.channelId);
            if (response) {
              toast.success("Channel Updated successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
              });
              fetchDate();
            } else {
              toast.error("Channel Update failed", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
              });
            }
          }
        }
      },
      headerToolbar: {
        left: customBtns[0],
        right: customBtns[1],
      },
      editable: true,
      eventOverlap: false,
      events: [...eventData, ...adData],
      firstDay: new Date(weekView).getDay(),
      initialDate: new Date(weekView),
      eventDurationEditable: false, // Disable Resize
      eventStartEditable: true, // disable dreage drop
      dayHeaderContent: function(arg){
        let totalDuration = 0, videoDuration = 0, adsDuration = 0, promosDuration = 0, fillerDuration = 0;
        const argDate = new Date(arg.date.toGMTString()).toISOString().slice(0, 10);
        eventData.forEach((data) => {
          // if(data.scheduledDate === argDate) {          
            if(!data.totalDuration) {
              data.totalDuration = data.duration;
            }
            totalDuration += verifyMilliSeconds(data.totalDuration);
            videoDuration += verifyMilliSeconds(data.duration);
            
            data.adBreaks.forEach((adData) => {
              switch(adData.assetType?.toLowerCase()) {
                case "filler":
                  fillerDuration += verifyMilliSeconds(adData.durationInMillis);
                  break;
                case "promo":
                  promosDuration += verifyMilliSeconds(adData.durationInMillis);
                  break;
                default:
                  adsDuration += verifyMilliSeconds(adData.durationInMillis);
                  break;
              }
            })
          // }
        });

        const content = `<div class="custom-fc-header">
          <div class="fc-schedule-duration">
            <div class="flex">
              <div class="flex-left">Total Scheduled</div>
              <div class="flex-right">${millisecondsToHuman(totalDuration)}</div>
            </div>
            <div class="flex">
              <div class="flex-left">Video</div>
              <div class="flex-right">${millisecondsToHuman(videoDuration)}</div>
            </div>
            <div class="flex">
              <div class="flex-left">Ads</div>
              <div class="flex-right">${millisecondsToHuman(adsDuration)}</div>
            </div>
            <div class="flex">
              <div class="flex-left">Promos</div>
              <div class="flex-right">${millisecondsToHuman(promosDuration)}</div>
            </div>
            <div class="flex">
              <div class="flex-left">Fillers</div>
              <div class="flex-right">${millisecondsToHuman(fillerDuration)}</div>
            </div>
          </div>
          <div>

          </div>
        </div> `
        
        let dayTitle = document.createElement('div');
        dayTitle.innerHTML = content;
        return { domNodes: [
        //  placeTitle,
          dayTitle
        ]};
      },
      dateClick: function(info) {
        if(info?.dateStr) {
          setSelectedDate(info.dateStr.split("T")[0]);
        }
      },  
      allDaySlot: false,
      allDay: false,
      slotMinTime: "00:00",
      slotMaxTime: "96:00",
      // eventTimeFormat: {
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   hour12: false
      // },
      // slotLabelFormat: {
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   hour12: false
      // },
      slotLabelInterval: { days: 1 },
    // timeFormat: 'H(:mm)',
    // hour12: false,
      eventOverlap: true,
      slotDuration: (isZoomIn) ? '00:05:00' : '00:15:00',
      viewDidMount :(view) => {
        view.el.querySelector('.fc-timegrid-axis-frame').innerHTML ='Time';
      },
      datesSet: (async data => {
        const startDate = data.startStr.substring(0, 10);
        const activeDate = new Date(calendar.view.activeStart.toGMTString()).toISOString().slice(0, 10);
        //week change logic heres
        if(weekView !== activeDate || !firstFetch) {
          setFirstData(true)
          setFirstFetch(true);
        //  calendar.setOption('defaultDate', data.startStr)
          setweekView(startDate);
          // week changed without save so call update api if event changed
          if(timer.current) {
            setDataChange(false);
            timer.current = undefined;
            const updateBtn = document.querySelector('.fc-updateButton-button');
            if(updateBtn) {
              document.querySelector('.fc-updateButton-button').click();
            }
          }
          clearTimeout(timer.current);
          
          // const endDate = data.endStr.substring(0, 10);
          const endDate =new Date(data.end.setDate(data.end.getDate() - 1)).toISOString().substring(0,10);
          const gridData = await Schedules_Datalist_Loop(locationState?.state?.channelId);
          setGridist(gridData.scheduleItems);
          parseData(gridData);
        }
      }),
      // dayHeaderContent: ({ date }, b, c) => {
      //   return (
      //     <div style={{ display: "flex", flexDirection: "column" }}>
      //       <span>{`${date.getDate()}.${date.getMonth()}`}</span>
      //       <span>{date.getFullYear()}</span>
      //       <div
      //         style={{
      //           display: "flex",
      //           flexDirection: "column",
      //           border: "black 1px solid"
      //         }}
      //       >
      //         <span>8: 00</span>
      //         <span style={{ backgroundColor: "gray" }}>Activity</span>
      //       </div>
      //     </div>
      //   );
      // },
      // dayHeaderContent:({ date }, b, c) => {
      //   console.log(date);
      //   return `<b>${date.getDate()}.${date.getMonth()}</b>`;
      // },
      // dayHeaderFormat:(param) => {
      //   console.log(param);
      //   return '<b>'+param.date.day.toString()+'</b>';
      // },
      eventContent: function (info) {
        const eventContent = document.createElement('div');
        setSelectedDate(info.event?.startStr?.split("T")[0]);
        eventContent.classList.add('custom-event');
        if(info.event.extendedProps.eventType === 'adData' || info.event.extendedProps.eventType === 'promo' || info.event.extendedProps.eventType === 'filler' ) {
        return;
        }
        eventContent.setAttribute('data-index',eventData.length);
        const setDate = () => {
          setSelectedDate(info.event?.startStr?.split("T")[0]);
        }
        // Check if the event is split and mark the original event as hidden
        if (info.event.extendedProps.isSplit) {
          eventContent.style.display = 'none';
        }
        // Add event title and time information with error handling
        if(info.event.title === '+ Ads') {
          const time = document.createElement('div');
          time.innerText = info.event.duration;
          eventContent.appendChild(time);
        } else {
          const title = document.createElement('div');
          title.setAttribute('title', info.event.title)
          title.innerText = info.event.title;
          eventContent.appendChild(title);
        }
        
        let yesterDay = new Date();
        yesterDay = new Date(yesterDay.setDate(yesterDay.getDate() - 1)).toISOString().substring(0,10);
    
        const adButton = document.createElement('div');
        const deleteButton = document.createElement('button');
        if(info.event.title !== '+ Ads') {
          adButton.classList.add('ad-schedule');
          // if(!info.event.extendedProps?.adBreaks?.length) {
          //   return;
          // }
          adButton.textContent = info.event.extendedProps?.adBreaks?.length || 0;
        // if(new Date(weekView).getTime() >= new Date(yesterDay).getTime())  { 
        // if(info.event.startStr >= new Date().toISOString()) {
            deleteButton.classList.add('delete-btn-schedule');
            const eventClickMethod = () => {
              // Remove the event from the calendar
              info.event.remove();
              deleteButton.removeEventListener('click', eventClickMethod);
              eventContent.removeEventListener('click', setDate);
              // Mark the original event as hidden if it's split
              if (info.event.extendedProps.isSplit) {
                const originalEvent = calendarRef.current.getEventById(info.event.id);
                if (originalEvent) {
                  originalEvent.setExtendedProp('isSplit', true);
                }
              }
              // Remove the event from the eventData state by comparing start and end times
              const updatedEventData = eventData.filter((event) => {
                // if(event.start instanceof Date) {
                //   return (
                //     event.start.getTime() !== info.event.start.getTime() ||
                //     event?.end?.getTime() !== info?.event?.end?.getTime()
                //   );
                // } else {
                  return (
                    // event.start != new Date(info.event.start).toISOString().substring(0,19) ||
                    // event.end != new Date(info.event.end).toISOString().substring(0,19)
                    event?.id != parseInt(info.event._def.publicId)
                  );
                // }
              });         
              
              setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
              // Update the eventData state
              setEventData(updatedEventData);
              // Update the adData state
              const ads = getNoOverlap(info.event.start,info.event.end,adData);
              setAdData(ads);
              setSelectedDate(info.event.startAt)
            }
            deleteButton.addEventListener('click', eventClickMethod);
            
            eventContent.appendChild(deleteButton);
      //    }
            eventContent.addEventListener('click', setDate);
        
        // deleteButton.addEventListener('click', () => {
        //   // Remove the event from the calendar
        //   info.event.remove();

        //   // Mark the original event as hidden if it's split
        //   if (info.event.extendedProps.isSplit) {
        //     const originalEvent = calendarRef.current.getEventById(info.event.id);
        //     if (originalEvent) {
        //       originalEvent.setExtendedProp('isSplit', true);
        //     }
        //   }
        // // console.log("sdsd",eventData);
        //   // Remove the event from the eventData state by comparing start and end times
        //   const updatedEventData = eventData.filter((event) => {
        //     return (
        //       event.start.getTime() !== info.event.start.getTime() ||
        //       event.end.getTime() !== info.event.end.getTime()
        //     );
        //   });

        //   // Update the eventData state
        //   setEventData(updatedEventData);
        // });

          eventContent.appendChild(adButton);
        }
        return { domNodes: [eventContent] };
      },
      scrollTime : '00:00:00',
      eventClick: function(info) {
        info.jsEvent.preventDefault(); // don't let the browser navigate
        if(info.jsEvent.srcElement.className !== 'delete-btn-schedule' && info.event.extendedProps?.adBreaks?.length > 0) {
          setEditData(null);
          setValidateData(info.event._def)
          setSingleData(info.event._def.extendedProps.adBreaks);
          const index = eventData.findIndex(data => 
            data.startAt === info.event._def.extendedProps.startAt && 
            data.assetType === info.event._def.extendedProps.assetType
          );
          // correct bug here
          setParentData(index)
        //  setShowPopup(true);
        }
      },
      eventDrop: function(info) {
        
        // Add your event here
        if(!info.oldEvent) return;
      // let dur = info.draggedEl.getAttribute('data-duration');
        const eventType = info.event._def.extendedProps.assetType;
      // dur = verifyMilliSeconds(dur)// toMilliseconds(msDuration[0], msDuration[1], msDuration[2]);
        let st = String(new Date(info.event.start).toISOString().split('T')[1]).substring(0,8);
        let en = String(new Date(info.event.end).toISOString().split('T')[1]).substring(0,8);
        let newEvent = [];
        
        let newEn = en;
        if(!en || en === '00:00:00') {
          const stMil = verifyMilliSeconds(st);
          const durationMil = verifyMilliSeconds(info.oldEvent.duration);
          newEn = millisecondsToHuman(stMil + durationMil);
        }
        const index = eventData.findIndex(data => data.id === parseInt(info.oldEvent.id));
        if(index === -1) return;
        newEvent = {
          title: info.event.title,
          start: info.event.start,
          startAt: st,
          end: info.event.end,
          endAt: newEn,
          id: parseInt(info.event.id),
          assetId: parseInt(info.event._def.extendedProps.assetId),
          assetType: eventType?.toUpperCase(),
          duration: info.event._def.extendedProps.totalDuration,
          totalDuration: info.event._def.extendedProps.totalDuration,
          durationInMillis: info.event._def.extendedProps.durationInMillis,
          scheduledDate: new Date(info.event.start).toISOString().split('T')[0],
          adBreaksCount: info.oldEvent?.adBreaksCount || 0,
          adBreaks: info.event._def.extendedProps.adBreaks,
          color: info.oldEvent.color,
          added: true,
          sequence: index+1
        };
        // update adbreaks info
        const updatedEvent = [...eventData];
        updatedEvent[index] = newEvent;
        updatedEvent.sort((a, b) => new Date(a.start) - new Date(b.start));
        const adDatas = [];
        const scheduledDate = new Date(calendar.view.activeStart.toGMTString()).toISOString().slice(0, 10);
        updatedEvent.forEach((data, i) => {
          data.sequence = i+1;
        // const updateTime = verifyMilliSeconds(timeDiffInMS) || 0;
        // const en = (pt === 0) ? millisecondsToHuman(verifyMilliSeconds(data.totalDuration)) : millisecondsToHuman(pt + verifyMilliSeconds(data.totalDuration));
          const st = data.startAt;
          data.adBreaks.forEach(dt => {
            const item = {
              "title": dt.title,
              'start': scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis)),
              'end': scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis) + parseInt(dt.durationInMillis)),
              'scheduledDate': scheduledDate,
              'date': scheduledDate,
              'duration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
              'totalDuration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
              "eventType": 'adData',
              "className": "selected-event",
              "color": '#FFBE83',
              'startAt': millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis)),          
              'endAt': millisecondsToHuman(verifyMilliSeconds(st) + parseInt(dt.startOffsetInMillis) + parseInt(dt.durationInMillis)),
              'startOffsetInMillis': dt.startOffsetInMillis,
              "durationInMillis": dt.durationInMillis || 0,
              "endOffsetInMillis":  dt.endOffsetInMillis
            }
            adDatas.push(item)
            return item;
            
          });
        });
        setEventData([...updatedEvent]);
        setAdData(adDatas);
      },
      eventReceive: function (info) {
        let yesterDay = new Date();
        yesterDay = new Date(yesterDay.setDate(yesterDay.getDate() - 1)).toISOString().substring(0,10);
        const currentDate =  new Date(calendarRef.current.currentData.currentDate).toISOString().substring(0,10);
        //if event drop is in past return from it and undo added event
      // if(new Date(currentDate).getTime() < new Date(yesterDay).getTime())  {
        // if(info.event.startStr < new Date().toISOString()) {
        //   info.event.remove();
        //   toast.error("Can not modify past dates", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //     autoClose: 3000
        //   });
        //   return;
        // } 
        setDataChange(true);
        let dur = info.draggedEl.getAttribute('data-duration');
        const eventType = info.draggedEl.getAttribute('data-type');
        const eventId = info.draggedEl.getAttribute('data-id');
        const msDuration = dur.toString().split(":");
        dur = verifyMilliSeconds(dur)// toMilliseconds(msDuration[0], msDuration[1], msDuration[2]);
        let st = String(new Date(info.event.start).toISOString().split('T')[1]).substring(0,8);
        let en = String(new Date(info.event.end).toISOString().split('T')[1]).substring(0,8);
        let newEvent = [];
        //find event where to push data for ad type event only
        if(eventType === 'ad') {
          newEvent = isAnOverlapEvent(info.event.start,info.event.end, false);
          //  newEvent = [...updatedEvent];
          eventUpdate = true;
          if(newEvent.length>0) {
            const msDur = verifyMilliSeconds(newEvent[0].totalDuration);
          //  const msDur = (msTotalDuration.length>1) ? toMilliseconds(msTotalDuration[0], msTotalDuration[1], msTotalDuration[2]) : newEvent[0].totalDuration;
            newEvent[0].adBreaksCount += 1;
            newEvent[0].totalDuration = msDur + dur;
            const msStartDuration = newEvent[0].startAt.toString().split(":");
            const startDur = (msStartDuration.length>1) ? toMilliseconds(msStartDuration[0], msStartDuration[1], msStartDuration[2]) : msStartDuration;
            newEvent[0].endAt = millisecondsToHuman(startDur + newEvent[0].totalDuration);
            const newend = newEvent[0].end.split("T");
            newEvent[0].end = newend[0]+'T'+newEvent[0].endAt;
            const overlap = isAdOverlap(info.event.start, info.event.end, newEvent[0].adBreaks);
            if(overlap.length>0) {
              st = overlap[0].endAt;
              const stMil = verifyMilliSeconds(st);
              const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
              en = millisecondsToHuman(stMil + durationMil);
            }
            const newstart = newEvent[0].start.split("T");
            newEvent[0].adBreaks.push(
              {
                // "assetId": undefined,
                "assetId": parseInt(eventId),
                // "type": eventType,
                "assetType": eventType.toUpperCase(),
                "startAt": st,
                "endAt": en, 
                "scheduledDate": new Date(info.event.start).toISOString().split('T')[0],
                "date": new Date(info.event.start).toISOString().split('T')[0],
                "duration": dur,
                "durationInMillis": verifyMilliSeconds(dur),
                "title": 'Ad',
                // "startOffsetInMillis" : verifyMilliSeconds(st) - verifyMilliSeconds(newEvent[0].startAt),//verifyMilliSeconds(st),
                "startOffsetInMillis" : verifyMilliSeconds(st) - verifyMilliSeconds(newstart[1]),//verifyMilliSeconds(st),
                "durationInMillis": verifyMilliSeconds(dur),
                "endOffsetInMillis":  verifyMilliSeconds(en)
              }
            )

            // sort ads by time startOffsetInMillis

            // newEvent[0].adBreaks.sort((a, b) => {
            //   // sort by newEvent[0].adBreaks.sort((a, b)
            //   const aTime = a.startAt.split(':').map(Number);
            //   const bTime = b.startAt.split(':').map(Number);
            //   return aTime[0]*3600 + aTime[1]*60 + aTime[2] - (bTime[0]*3600 + bTime[1]*60 + bTime[2]);
            // });
          //  console.log(newEvent[0].adBreaks);
            newEvent[0].adBreaks.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis);
            //newEvent[0].adBreaks.sort((a, b) => b.startOffsetInMillis - a.startOffsetInMillis);
            const updatedDate = [...eventData];
            updatedDate[newEvent[1]] = newEvent[0];
            // updatedDate.splice(newEvent[1], 1);
            // updatedDate.push(newEvent[0])
            setEventData([...updatedDate]);
            setAdData([...adData,  {
              "title": "Event 1",
              "start": info.event.start,
              "end": info.event.end,
              "eventType": 'adData',
              "className": "selected-event",
              "color": '#FFBE83'
            },])
            setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
            return;
          } else {
            setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
            setEventData([...eventData]);
            setAdData([...adData])
            return;
          }
        } else {
          eventUpdate = true;
          // When a draggable event is dropped, add it as a custom event to eventData
          const overLappedEvent = isAnOverlapEvent(info.event.start,info.event.end, (eventType === 'filler' || eventType === 'promo') ? false : true);
          if(overLappedEvent.length > 0) {
            if((eventType === 'filler' || eventType === 'promo')) {
              overLappedEvent[0].adBreaksCount += 1;

              let newEn = en;            
              if(!en) {
                const stMil = verifyMilliSeconds(st);
                const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
                newEn = millisecondsToHuman(stMil + durationMil);
              }
              const eventId = info.draggedEl.getAttribute('data-id');
          //    overLappedEvent[0].adBreaksCount
              overLappedEvent[0].endAt = millisecondsToHuman(verifyMilliSeconds(overLappedEvent[0].endAt) +verifyMilliSeconds(info.draggedEl.getAttribute('data-duration')));
              const newend = overLappedEvent[0].end.split("T");
              overLappedEvent[0].end = newend[0]+'T'+overLappedEvent[0].endAt;
              
              const overlap = isAdOverlap(info.event.start, info.event.end, overLappedEvent[0].adBreaks);
              if(overlap.length>0) {
                if (overlap[0].endAt instanceof Date) {
                  overlap[0].endAt= new Date(overlap[0].endAt.toGMTString()).toISOString().split('T')[1].substring(0,8);
                }
                st = overlap[0].endAt;
                const stMil = verifyMilliSeconds(st);
                const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
                newEn = millisecondsToHuman(stMil + durationMil);
              } else {
                st = info.event.end;
                if (st instanceof Date) {
                  st = new Date(st.toGMTString()).toISOString().split('T')[1].substring(0,8);
                }
                const stMil = verifyMilliSeconds(st);
                const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
                newEn = millisecondsToHuman(stMil + durationMil);
              }
              overLappedEvent[0].adBreaks.push(
                {
                  "assetId": parseInt(eventId),
                  // "assetId": undefined,
                  // "type": eventType,
                  "assetType": eventType.toUpperCase(),
                  "title": info.event.title,
                  "startAt": st,
                  "endAt": newEn,
                  "scheduledDate": new Date(info.event.start).toISOString().split('T')[0],
                  "duration": verifyMilliSeconds(info.draggedEl.getAttribute('data-duration')),
                  "startOffsetInMillis" : verifyMilliSeconds(st) - verifyMilliSeconds(overLappedEvent[0].startAt),//verifyMilliSeconds(st),
                  "durationInMillis": dur,
                  "endOffsetInMillis":  verifyMilliSeconds(en)
                }
              );
            // overLappedEvent[0].adBreaks.sort((a, b) => b.startOffsetInMillis - a.startOffsetInMillis);
              overLappedEvent[0].adBreaks.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis);
            
              // overLappedEvent[0].adBreaks.sort((a, b) => {
              //   const aTime = a.startAt.split(':').map(Number);
              //   const bTime = b.startAt.split(':').map(Number);
              //   return aTime[0]*3600 + aTime[1]*60 + aTime[2] - (bTime[0]*3600 + bTime[1]*60 + bTime[2]);
              // });

              setAdData([...adData,   {
                "assetId": parseInt(eventId),
                // "type": eventType,
                "assetType": eventType.toUpperCase(),
                "title": info.event.title,
                "startAt": st,
                "endAt": newEn,
                "start": info.event.start,
                "end": info.event.end,
                "scheduledDate": new Date(info.event.start).toISOString().split('T')[0],
                "duration": verifyMilliSeconds(info.draggedEl.getAttribute('data-duration')),
                "className": "selected-event",
                "color": '#FFBE83'
              },])
          //   setAdData([...newAdData])
            //  console.log(newAdData)
              const updatedDate = [...eventData];
              updatedDate.splice(overLappedEvent[1], 1);
              updatedDate.push(overLappedEvent[0])
              
              setEventData([...updatedDate]);

            } else {
              newEvent = {
                title: info.event.title,
                start: info.event.start,
                startAt: st,
                end: info.event.end,
                endAt: en,
                assetId: parseInt(info.event._def.extendedProps.assetId),
                assetType: eventType.toUpperCase(),
                id: parseInt(info.event.id),
                duration: millisecondsToHuman(info.event._def.extendedProps.durationInMillis),
                totalDuration: dur,
                durationInMillis: info.event._def.extendedProps.durationInMillis,
                scheduledDate: new Date(info.event.start).toISOString().split('T')[0],
                adBreaksCount: info.event?.adBreaksCount || 0,
                adBreaks: [],
                color: (info.event.title === '+ Ads') ? '#FFBE83' : '#729BD2',
                createdAt: new Date().toISOString(),
              };
              newEvent.start = overLappedEvent[0].end;
              newEvent.startAt = overLappedEvent[0].endAt;
              const endTime = millisecondsToHuman(verifyMilliSeconds(newEvent.startAt) + verifyMilliSeconds(newEvent.duration));
              newEvent.endAt = endTime;
              newEvent.end = newEvent.scheduledDate +'T'+ newEvent.endAt;

              // Find all matching events based on assetId
              const matchingEvents = eventData.filter(event => event.assetId === newEvent.id);
              // Check if there are any matching events
              if (matchingEvents.length > 0) {
                // Find the latest event based on createdAt
                const latestEvent = matchingEvents.reduce((latest, event) => 
                  new Date(event.createdAt) > new Date(latest.createdAt) ? event : latest
                );
                // Merge the adBreaks arrays
                newEvent.adBreaks = [...latestEvent.adBreaks, ...newEvent.adBreaks];
              }

              // Replace or add newEvent in eventData
              const updatedEventData = eventData.map(event => 
                event.assetId === newEvent.id ? newEvent : event
              );

              // If no match was found, add the newEvent
              if (matchingEvents.length === 0) {
                updatedEventData.push(newEvent);
              }
              setEventData([...eventData, newEvent]);
            }
          } else {
            let newEn = en;
            if(!en || en === '00:00:00') {
              const stMil = verifyMilliSeconds(st);
              const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
              newEn = millisecondsToHuman(stMil + durationMil);
            }
            newEvent = {
              title: info.event.title,
              start: info.event.start,
              startAt: st,
              end: info.event.end,
              endAt: newEn,
              id: parseInt(info.event.id),
              assetId: parseInt(info.event._def.extendedProps.assetId),
              assetType: eventType.toUpperCase(),
              duration: dur,
              durationInMillis: info.event._def.extendedProps.durationInMillis,
              totalDuration: dur,
              scheduledDate: new Date(info.event.start).toISOString().split('T')[0],
              adBreaksCount: info.event?.adBreaksCount || 0,
              adBreaks: [],
              color: (info.event.title === '+ Ads') ? '#FFBE83' : '#729BD2',
              added: true,
              createdAt: new Date().toISOString(),
            };

            // Find all matching events based on assetId
            const matchingEvents = eventData.filter(event => event.assetId === newEvent.id);
            // Check if there are any matching events
            if (matchingEvents.length > 0) {
              // Find the latest event based on createdAt
              const latestEvent = matchingEvents.reduce((latest, event) => 
                new Date(event.createdAt) > new Date(latest.createdAt) ? event : latest
              );
              // Merge the adBreaks arrays
              newEvent.adBreaks = [...latestEvent.adBreaks, ...newEvent.adBreaks];
            }

            // Replace or add newEvent in eventData
            const updatedEventData = eventData.map(event => 
              event.assetId === newEvent.id ? newEvent : event
            );

            // If no match was found, add the newEvent
            if (matchingEvents.length === 0) {
              updatedEventData.push(newEvent);
            }
            setEventData([...eventData, newEvent]);
          }
        }
        setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
        calendarRef.current = calendar;
        
      }
      });
      calendar.render();
      draggableRef.current = new Draggable(
        document.getElementById('external-events'),
        {
          itemSelector: '.fc-event',
          eventData: function (eventEl) {
            
            const type = eventEl.getAttribute('data-type')
            const data = {
                id: Date.now(), // Assign a unique ID to the event
                title: eventEl.innerText,
                color: (type === 'ads') ? '#FFBE83' : '#1e5b83',
                type: type,
                assetType: type?.toUpperCase(),
                id: eventEl.getAttribute('data-id'),
                assetId: eventEl.getAttribute('data-id'),
                duration: eventEl.getAttribute('data-duration'),
                totalDuration: eventEl.getAttribute('data-mm-duration'),
                durationInMillis: verifyMilliSeconds(eventEl.getAttribute('data-duration')),
                scheduledDate: '',
                adBreaksCount: 0,
                adBreaks: []
              }
            return data;
          },
        }
      );

      calendarRef.current = calendar;

    // calendarRef.current.currentData.options.scrollTime = '08:00:00'
      //  console.log(calendar)
      
      if((!firstData && eventData) || eventUpdate) {
        document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop = focusTime;
        document.querySelector('.fc-trimButton-button').click();
      } else {      
        setTimeout(() => {
          setFirstData(false)
        }, 1000);
        document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop = focusTime;
      }
      const mainEvent = document.querySelectorAll('.fc-timegrid-event:not(.selected-event)');

      mainEvent.forEach((el, index) => {
        el.closest('div').classList.add('main-event');
        if(index % 2) {
          el.closest('div').classList.add('even')
        }
      });
      
      //console.log(document.querySelector('.selected-event').closest('div'));
    return () => {
      draggableRef.current.destroy();
    };
    
  }, [isZoomIn, eventData, focusTime, adData, copyData, weekView]);

  useEffect(() => {
    if (!(locationState?.state?.permission === "edit")) {
      let btn = document.querySelector(".fc-header-toolbar");
      btn.classList.add("scheduleBtnNone");
    }
  }, [])

    const overlapData = () => {
      isEditAdOverlap();
    }

    const isEditAdOverlap = () => {
      data.forEach((d, index) => {
          if(index === 0) {
              return;
          }
          const start = d.scheduledDate + 'T' + d.startAt;
          const end = d.scheduledDate + 'T' + d.endAt;
          data.forEach((indata, insideIndex) => {
              if(index === insideIndex) {
                  return;
              }
              const prevStart = indata.scheduledDate + 'T' + indata.startAt;
              const prevEnd = indata.scheduledDate + 'T' + indata.endAt;

              if (start >= prevStart && end <= prevEnd) {
                  data[index].startAt = indata.endAt;
                  data[index].endAt = setEndTimeAd(data[index].startAt , data[index].duration, true);
              }
              //end-time in between any of the events
              else if (end >= prevStart && end <= prevEnd) {
                  data[index].startAt = indata.endAt;
                  data[index].endAt = setEndTimeAd(data[index].startAt , data[index].duration, true);
              }
              //any of the events in between/on the start-time and end-time
              else if (start <= prevStart && end >= prevEnd) {
                  data[index].startAt = indata.endAt;
                  data[index].endAt = setEndTimeAd(data[index].startAt , data[index].duration, true);
              }
          })
      })
    }
    const setEndTimeAd = (time, duration, returnStart) => {
      if(!duration || !time) return;
      const msTimeString = time.toString().split(":");
      const msDurationArray = duration.toString().split(":");
      const msDuration = (msDurationArray.length > 1) ? (parseInt(msDurationArray[0])*60*60+parseInt(msDurationArray[1])*60+parseInt(msDurationArray[2]))*1000 : duration;
      const msTime = ((parseInt(msTimeString[0])*60*60+parseInt(msTimeString[1])*60+parseInt(msTimeString[2]))*1000) + msDuration;
      return (returnStart) ? millisecondsToHuman(msTime) : [millisecondsToHuman(msTime), millisecondsToHuman(msDuration)];
    }
    const isValidTime = (time) => {
      if(!time) return false;
      const msTimeString = validateData.extendedProps.endAt.toString().split(":");
      const msTime = ((parseInt(msTimeString[0])*60*60+parseInt(msTimeString[1])*60+parseInt(msTimeString[2]))*1000);
      const allowedTimeString = time.toString().split(":");
      const allowedTime = (parseInt(allowedTimeString[0])*60*60+parseInt(allowedTimeString[1])*60+parseInt(allowedTimeString[2]))*1000;
      return (allowedTime <= msTime) ? true : false;
    }
    const getTitle = (data) => {
      if(data.type === 'ads') {
          return data.type;
      } else {
          return data.title;
      }
    }

    const setFormData = (data,index, start, end) => {
      setEditData(start);
      setActiveData(data)
      setDurationData(millisecondsToHuman(data.durationInMillis))
      setEditIndex(index+1);
      setMinTime(start);
      setMaxTime(end);
      // fix bug work on max and min time validation
      // updateData(data);
    }
    const deleteEvent = (index) => {
      const tempData = [...eventData];
      tempData[parentData].adBreaks.splice(index,1)
      tempData[parentData].adBreaksCount -= 1;
    // data.splice(index,1);
      setEditData(null);
    // updateData(tempData[parentData].adBreaks);
      const adDatas = [];
      tempData.forEach((d) => {
        d.adBreaks.forEach(dt => {
          adDatas.push(dt);
        });
      });
      // check event delete bug here
      setEventData([...tempData]);
      setAdData([...adDatas]);
      
      // if(document.querySelector('.fc-trimButton-button')) {
      //   document.querySelector('.fc-trimButton-button').click();
      // }
      setSingleData(tempData[parentData].adBreaks);
    }

    const updateAds = (index, data) => {
      const tempData = [...eventData];
      tempData[parentData].adBreaks[index] = data;
    // data.splice(index,1);
    //  setEditData(null);
    // updateData(tempData[parentData].adBreaks);
      const adDatas = [];
      tempData.forEach((d) => {
        d.adBreaks.forEach(dt => {
          adDatas.push(dt);
        });
      });
      // check event delete bug here
      setEventData([...tempData]);
      setAdData([...adDatas]);
      
      // if(document.querySelector('.fc-trimButton-button')) {
      //   document.querySelector('.fc-trimButton-button').click();
      // }
    // setSingleData(tempData[parentData].adBreaks);
    }
  // time out update functinality
  useEffect(() => {
    if(dataChage && !timer?.current) {
      const timerConst = setTimeout(() => {
        const url = window.location.href;
        const isSchedulingDetail =url.includes('schedulingDetail');
        if(!isSchedulingDetail) {
          clearTimeout(timer.current);
          return;
        }
        if(document.querySelector('.fc-updateButton-button')) {
          document.querySelector('.fc-updateButton-button').click();
        }
        setDataChange(false);
      }, 300000);
      if(document.querySelector('.fc-updateButton-button')) {
        timer.current = timerConst;
      }
    }
  }, [dataChage])

  //  useEffect(() => {
  //   if(!calendarRef.current.currentData) return;
  //   calendarRef.current.currentData.calendarOptions.scrollTime.milliseconds = focusTime
  //   // 
  //    console.log(focusTime)
  // //calendar.scrollToTime(focusTime)
  //  }, [focusTime]);

  //  const scrollCalendar = (calendar, time) => {
  //  }

  const fetchDate = async () => {
    const startDate = new Date(calendarRef.current.currentData.currentDate).toISOString().substring(0,10);
    const endDate = new Date(calendarRef.current.currentData.viewApi.currentEnd).toISOString().slice(0, 10);
    const gridData = await Schedules_Datalist_Loop(locationState?.state?.channelId);
    setGridist(gridData.data);
    parseData(gridData);
  }
  const parseUpdateData = async (gridData) => {
    setGridist(gridData.data);
    parseData(gridData);
  }
  const verifyMilliSeconds = (duration) => {
    const len = duration?.toString()?.split(":");
    if(len?.length > 1) {
      return parseInt(toMilliseconds(len[0], len[1], len[2]))
    } else {
      return parseInt(duration);
    }
  }
  const millisecondsToHuman = (duration) => {
    if(duration === 0) return '00:00:00';
      let milliseconds = parseInt((duration % 1000) / 100),
          seconds = parseInt((duration / 1000) % 60),
          minutes = parseInt((duration / (1000 * 60)) % 60),
          hours = parseInt((duration / (1000 * 60 * 60)));

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds ;
  }

  const closePopup = () => {
    setShowPopup(false);
  // setSingleData(null); // Clear the single data
  // partnerListDetail();
  };
  const getDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(moment(new Date(currentDate)).format('YYYY-MM-DD'));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }
  const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;

  const isAnOverlapEvent = (eventStartDay, eventEndDay, iterate) => {
    // Events
    let events = eventData;
    const ds = new Date(eventStartDay);
    eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
    const de = new Date(eventEndDay);
    eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
    let iteratedData = [];
    for (let i = 0; i < events.length; i++) {
      const eventA = events[i];
      let newData = false;
      if(typeof eventA.start !== 'string') {
        eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
        eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
      }

      // function to normalize date and time of asset
      function normalizeDateTime(dateTime) {
        const [date, time] = dateTime.split('T');
        let [hours, minutes, seconds] = time.split(':');
        hours = parseInt(hours, 10);
        
        if (hours >= 24) {
          const newDate = new Date(date);
          newDate.setDate(newDate.getDate() + Math.floor(hours / 24)); // add the extra day(s)
          hours = hours % 24; // reset the hour within the 24-hour format
          
          return `${newDate.toISOString().split('T')[0]}T${String(hours).padStart(2, '0')}:${minutes}:${seconds}`;
        }
        
        return dateTime;
      }

      eventStartDay = normalizeDateTime(eventStartDay);
      eventEndDay = normalizeDateTime(eventEndDay);
      eventA.start = normalizeDateTime(eventA.start);
      eventA.end = normalizeDateTime(eventA.end);
      
      // start-time in between any of the events
      if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
        iteratedData = [eventA, i];
        newData = true;
      }
      //end-time in between any of the events
      if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
        iteratedData = [eventA,i];
        newData = true;
      }
      //any of the events in between/on the start-time and end-time
      if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
        iteratedData = [eventA,i];
        newData = true;
      }
      if(newData && iterate) {
        eventStartDay = eventA.start;
        eventEndDay = eventA.end;
      }
    }
    return iteratedData;
  }

  const isAdOverlap = (eventStartDay, eventEndDay, ads) => {
    // Events
    let events = eventData;
    const ds = new Date(eventStartDay);
    eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
    const de = new Date(eventEndDay);
    eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
    let iteratedData = [];
    for (let i = 0; i < events; i++) {
      const eventA = events[i];
      let newData = false;
      if(typeof eventA.start !== 'string') {
        if(!eventA.start) {
          eventA.start = eventA.scheduledDate + 'T' + eventA.startAt;
          eventA.end = eventA.scheduledDate + 'T' + eventA.endAt;
        } else {
          eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
          eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
        }
      }

      // start-time in between any of the events
      if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
        iteratedData = [eventA, i];
        newData = true;
      }
      //end-time in between any of the events
      if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
        iteratedData = [eventA,i];
        newData = true;
      }
      //any of the events in between/on the start-time and end-time
      if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
        iteratedData = [eventA,i];
        newData = true;
      }
      if(newData) {
        eventStartDay = eventA.start;
        eventEndDay = eventA.end;
      }
    }
    return iteratedData;
  }

  const getNoOverlap = (eventStartDay, eventEndDay, data) => {
    const ds = new Date(eventStartDay);
    eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
    const de = new Date(eventEndDay);
    eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
    let foundData = [];
    let events = data;

    for (let i = 0; i < events.length; i++) {
      const eventA = events[i];
      if(typeof eventA.start !== 'string') {
        eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
        eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
      }

      // start-time in between any of the events
      if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
        // return [eventA, i];
      }
      //end-time in between any of the events
      else if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
        //  return [eventA,i];
      }
      //any of the events in between/on the start-time and end-time
      else if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
        //  return [eventA,i];
      } else {
        foundData.push(eventA)
      }
    }
    return foundData;
  }

  /**
   * Parses the grid data and transforms it into a specific format to render inside grid
   * @param {Object} gridData - The grid data to be parsed.
   */
  const parseData = (gridData) => {
    let edataIn = []
  if(!gridData?.data?.length) {
    return;
  }
  let previousTime = '00:00:00'
  let adData = [];
  let startTime = calendarRef.current.view.activeStart;
  const scheduledDate = new Date(calendar.view.activeStart.toGMTString()).toISOString().slice(0, 10);
  const edata = gridData.data.map((data) => {
  //  const tempData = data.data.map(d => {
    // let tempAdData = [];
    
    // bug while pushing here
    data.adBreaks.forEach(dt => {
        adData.push({
          "title": dt.title,
          'start': scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(previousTime) + verifyMilliSeconds(dt.startOffsetInMillis)),
          'end': scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(previousTime) + verifyMilliSeconds(dt.startOffsetInMillis) + verifyMilliSeconds(dt.durationInMillis)),
          'scheduledDate': scheduledDate,
          'date': scheduledDate,
          'duration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
          'totalDuration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
          "eventType": 'adData',
          "className": "selected-event",
          "color": '#FFBE83',
          'startAt': millisecondsToHuman(verifyMilliSeconds(previousTime) + parseInt(data.startOffsetInMillis)),          
          'endAt': millisecondsToHuman(verifyMilliSeconds(previousTime) + parseInt(data.startOffsetInMillis) + parseInt(data.durationInMillis)),
          'startOffsetInMillis': dt.startOffsetInMillis,
          "durationInMillis": dt.durationInMillis || 0,
          "endOffsetInMillis":  dt.endOffsetInMillis
        })
      });
      const tempData =  {
        title: data.title,
        id: data.id || data.assetId,
        assetId: parseInt(data.assetId),
        assetType: data.assetType,
        createdAt: data.createdAt,
        start: scheduledDate +'T'+ millisecondsToHuman(verifyMilliSeconds(previousTime)),
        end: scheduledDate +'T'+ millisecondsToHuman(verifyMilliSeconds(previousTime) + verifyMilliSeconds(data.durationInMillis)),
        scheduledDate: scheduledDate,
        startAt: millisecondsToHuman(verifyMilliSeconds(previousTime)),
        duration: millisecondsToHuman(verifyMilliSeconds(data.durationInMillis)),
        durationInMillis: verifyMilliSeconds(data.durationInMillis),
        endAt: millisecondsToHuman(verifyMilliSeconds(previousTime) + verifyMilliSeconds(data.durationInMillis)),
        totalDuration: millisecondsToHuman(verifyMilliSeconds(data.totalDurationInMillis)),
        adBreaksCount: data.adBreaks.length,
        adBreaks: data.adBreaks,
        editable: true,      
        eventOverlap: false,
        sequence: data.sequence,
        eventDurationEditable: false
      }
      if(previousTime !== '00:00:00') {
        previousTime = verifyMilliSeconds(previousTime + data.durationInMillis)
      } else {
        previousTime = data.durationInMillis;
      }
  //  });
      edataIn.push(tempData)
    // return {
    //   title: data.awsProgramName,
    //   start: info.event.start,
    //   end: info.event.end,
    //   duration: info.event.duraiton,
    //   color: (info.event.title === '+ Ads') ? '#FFBE83' : '#729BD2',
    // }

  });
  setAdData([...adData])
  setEventData([...edataIn]);
  }


  const fetchSchedule = async () => {
    if(!locationState?.state?.channelId) {
      return;
    }
    const scheduleData = await Schedules_list(locationState?.state?.channelId, 'PROGRAM');
    setProgramList(scheduleData.data);

    const promoData = await Schedules_list(locationState?.state?.channelId, 'PROMO');
    setPromoList(promoData.data);

    const fillerData = await Schedules_list(locationState?.state?.channelId, 'FILLER');
    setFillerist(fillerData?.data);

    const date = new Date();
    const setDate = 5;
    const startDate = new Date().toISOString().slice(0, 10);
    const endDate = new Date(date.setDate(date.getDate() + setDate)).toISOString().slice(0, 10);
    const gridData = await Schedules_Datalist_Loop(locationState?.state?.channelId);
    setGridist(gridData.scheduleItems);
    //parseData(gridData);

    setMasterData([scheduleData.data, promoData.data, fillerData.data])
  }


    useEffect(() => {
      fetchSchedule();
    }, [locationState]);

    //expand collapse inside tree list
    const expandChild = (e) => {
      e.stopPropagation();
      e.currentTarget.classList.toggle('active')
      e.currentTarget?.childNodes?.[1]?.classList?.toggle("block");
    }

    //toogle treelist 
    const toogleList = (e) => {
      e.stopPropagation();
      document.querySelectorAll(".list-active").forEach( e => {
        e.classList.remove("list-active");
      });
      
      e.currentTarget.classList.toggle('list-active')
    }

    const updateData = (data) => {
      const updatedData = [...eventData];
      // const newData = [...singledata];
      // newData[editIndex-1].startAt = e;
      // newData[editIndex-1].endAt = setEndTimeAd(e , newData[editIndex-1].duration, true);
    //  const parentData = editIndex - 1;
      updatedData[parentData].adBreaks = data;
      let totalDuration = verifyMilliSeconds(updatedData[parentData].duration);
      // bug here fix it
      updatedData[parentData].adBreaks.forEach((data) => {
        totalDuration += verifyMilliSeconds(data.duration);
      })
      updatedData[parentData].adBreaksCount = data.length;
      const startAt = updatedData[parentData].startAt.toString().split(":");
      const currentStartTime = toMilliseconds(startAt[0], startAt[1], startAt[2]);
      updatedData[parentData].totalDuration = millisecondsToHuman(totalDuration);
      updatedData[parentData].durationInMillis = verifyMilliSeconds(data.durationInMillis);
      updatedData[parentData].endAt = millisecondsToHuman(currentStartTime + totalDuration);
      updatedData[parentData].end = updatedData[parentData].scheduledDate + 'T' + updatedData[parentData].endAt;
      let tempdata = [];
      updatedData.forEach((d) => {
        d.adBreaks.forEach(dt => {
          tempdata.push({
            // "start": dt.startAt,
            // "end": dt.endAt,
            // 'start': dt.scheduledDate + 'T'+dt.startAt,
            // 'end': dt.scheduledDate + 'T'+dt.endAt,
            // 'scheduledDate': dt.scheduledDate,
            // 'duration': dt.duration,
            // 'totalDuration': dt.totalDuration,
            // "eventType": 'adData',
            // "className": "selected-event",
            // "color": '#FFBE83'

            "title": dt.title,
            'start': d.scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(dt.startOffsetInMillis) + verifyMilliSeconds(d.startAt)),
            'end': d.scheduledDate + 'T'+ millisecondsToHuman(verifyMilliSeconds(dt.startOffsetInMillis) + verifyMilliSeconds(d.startAt)),
            'scheduledDate': d.scheduledDate,
            'date': d.scheduledDate,
          // 'duration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
          // 'totalDuration': millisecondsToHuman(verifyMilliSeconds(dt.durationInMillis)),
            'startOffsetInMillis': dt.startOffsetInMillis,
            "eventType": 'adData',
            "className": "selected-event",
            "color": '#FFBE83'
          })
        })

      })
      setAdData([...tempdata]);
      setEventData([...updatedData]);
      
    // info.view.getCurrentData().calendarOptions.events
    }

    const filterList = (evt) => {
      const filterPrograms = masterData?.[0]?.filter((data) => {
        return data.metadata?.title.toLowerCase().includes(evt.target.value.toLowerCase())
      });

      const filterFillers = masterData?.[1]?.filter((data) => {
        return data.filename.toLowerCase().includes(evt.target.value.toLowerCase())
      });

      const filterPromos = masterData?.[2]?.filter((data) => {
        return data.filename.toLowerCase().includes(evt.target.value.toLowerCase())
      });

      setProgramList(filterPrograms);
      setPromoList(filterPromos);
      setFillerist(filterFillers);
    }

    useEffect(() => {
      fetchSchedule();
    }, [locationState]);

    // alert(JSON.stringify(Schedules_Datalist_Loop))
      return (
          <div className='content-body SchedulingDetail SchedulingLoop'>
              <div className="dashboard-content flex schedule-loop-view">
                  <div className="left" id='external-events'>
                      <h3 className='title-header'>Scheduling</h3>
                      <div className='list-box'>
                          <h3>{locationState?.state?.channelName}</h3>
                          {locationState?.state?.type !== 'APIDriven' && locationState?.state?.type !== 'XLSInput' && (<>
                          <div>
                              <input type='text' className='search-program' placeholder='Search Program' onChange={(evt) => filterList(evt)}/>
                          </div>
                          <div className='program-list'>
                              <div className='program-header list-active' onClick={(evt) => toogleList(evt)}>Programs</div>

                              <ul className="treelist">
                                {programList && programList.map((schedule, index) => (
                                  <li key={index} className='fc-event' data-type="program" title={schedule?.metadata?.title} data-duration={schedule?.metadata?.duration} data-mm-duration={schedule?.duration} data-id={schedule?.id} onClick={(evt) => expandChild(evt)}>
                                    <span>{schedule?.metadata?.title}</span>
                                  </li>
                                ))}
                              </ul>
                              <div className='program-header' onClick={(evt) => toogleList(evt)}>Fillers</div>

                              <ul className="treelist">
                                {fillerList && fillerList.map((schedule, index) => (
                                  <li key={index} className='fc-event' data-type="filler" title={schedule?.filename} data-duration={millisecondsToHuman(verifyMilliSeconds(schedule?.duration))} data-id={schedule?.id} onClick={(evt) => expandChild(evt)}>
                                    <span>{schedule?.filename}</span>
                                  </li>
                                ))}
                              </ul>
                              <div className='program-header' onClick={(evt) => toogleList(evt)}>Promos</div>

                              <ul className="treelist">
                                {promoList && promoList.map((schedule, index) => (
                                  <li key={index} className='fc-event' data-type="promo" title={schedule?.filename} data-duration={millisecondsToHuman(verifyMilliSeconds(schedule.duration))} data-id={schedule.id} onClick={(evt) => expandChild(evt)}>
                                    <span>{schedule.filename}</span>
                                  </li>
                                ))}
                              </ul>
                            
                          </div>
                          <div className='schedule-butttons'>
                            <button className='fc-event schedule-ads' data-type="ad" data-duration='00:00:30'>
                              + Ads
                            </button>
                          </div></>)}
                      </div>
                  </div>
                  <div className="middle">
                    <div id='calendar'></div>
                  </div>
                  {validateData && <div className="right">
                    <div className='list-box list-box-right'>
                      {/* {!validateData && <h2 className='title-header h2'>Select Program to update schedule</h2>} */}
                      <h3 className='title-header'>
                        {validateData?.title}
                      </h3>
                      <h5>
                        Schedule: {validateData?.extendedProps.scheduledDate} at {validateData?.extendedProps.startAt} | Duration: {millisecondsToHuman(verifyMilliSeconds(validateData?.extendedProps.totalDuration))}
                      </h5>
                      
                      {editData && <div>
                        {/* <div className='edit-title-ad'>
                            Change start time of Ad {editIndex}
                        </div> */}
                        <div className={stylesAds.adsTimeEditor}>
                            <div className={stylesAds.adsTimeFirstChild}>
                                <label className={stylesAds.adsLabel} title={getTitle(activeData)}>Start Time of {getTitle(activeData)}</label>
                                <TimePicker 
                                    value={editData}
                                    hourPlaceholder="HH"
                                    minutePlaceholder="mm"
                                    secondPlaceholder="ss"
                                    maxDetail="second"
                                    // maxTime={validateData?.extendedProps.endAt}
                                    // minTime={validateData?.extendedProps.startAt}
                                    format="HH:mm:ss"
                                    onChange={(e) => {                                    
                                        if(!isValidTime(e)) {
                                            setValidForm('invalid');                                          
                                            return;
                                        }
                                        const newData = [...singledata];                                    
                                        newData[editIndex-1].startAt = e;
                                        newData[editIndex-1].startOffsetInMillis = verifyMilliSeconds(e);
                                        const endTime = millisecondsToHuman(parseInt(newData[editIndex-1].startOffsetInMillis + parseInt(newData[editIndex-1].durationInMillis)))
                                        //(e , newData[editIndex-1].duration, true);  
                                        newData[editIndex-1].endAt = setEndTimeAd(e , endTime, true);
                                        setSingleData(newData);
                                        setEditData(e);
                                        setValidForm('valid');
                                        overlapData();
                                        updateAds(editIndex-1, newData[editIndex-1]);
                                    }}
                                    disableClock={true}
                                />
                            </div>
                            {(activeData?.assetType === 'ad' || activeData?.assetType === 'AD') && 
                                <div>
                                    <label className={stylesAds.adsLabel}>Duration</label>
                                    <TimePicker 
                                        value={durationData}
                                        hourPlaceholder="HH"
                                        minutePlaceholder="mm"
                                        secondPlaceholder="ss"
                                        maxDetail="second"
                                        maxTime={validateData?.extendedProps.endAt}
                                        minTime="00:00:01"
                                        format="HH:mm:ss"
                                        onChange={(e) => {
                                          console.log('working add', e);
                                            const newData = [...singledata];
                                            const ar = setEndTimeAd(newData[editIndex-1].startAt, e, false);
                                            newData[editIndex-1].endAt = ar[0]
                                            newData[editIndex-1].durationInMillis = verifyMilliSeconds(e);
                                            setSingleData(newData);
                                            setDurationData(e);
                                            setValidForm('valid');
                                            overlapData();
                                            updateAds(editIndex-1, newData[editIndex-1]);
                                        }}
                                        disableClock={true}
                                    />
                                </div>
                            }
                          </div>
                      </div>}
                      <div className="table">
                            <table className="striped-table">
                                <thead>
                                    <th>Subject</th>
                                    <th>Start Offset</th>
                                    <th>Duration </th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>
                                  
                                {singledata && singledata.length > 0 && singledata.map((data, index) => (
                                            <tr key={index}>
                                                <td title={getTitle(data)}><p className='text-nowrap'>{getTitle(data)}</p></td>
                                                <td>{millisecondsToHuman(data.startOffsetInMillis)}</td>
                                                <td>{millisecondsToHuman(data.durationInMillis)}</td>
                                                {/* <td onClick={()=> {
                                                    setFormData(data,index, millisecondsToHuman(verifyMilliSeconds(validateData.extendedProps.startAt) + parseInt(data.startOffsetInMillis)), millisecondsToHuman(verifyMilliSeconds(validateData.extendedProps.startAt) + parseInt(data.startOffsetInMillis + parseInt(data.durationInMillis))))
                                                }} className={`${stylesAds.editButton} ${hasDarkTheme ? stylesAds.btnInvert : ''}`}>
                                                    {allowedEdit && <img src={editIcon}/>}
                                                </td> */}
                                                <td onClick={()=> {
                                                    setFormData(data,index, millisecondsToHuman(data.startOffsetInMillis), millisecondsToHuman(verifyMilliSeconds(validateData.extendedProps.startAt) + parseInt(data.startOffsetInMillis + parseInt(data.durationInMillis))))
                                                }} className={`${stylesAds.editButton} ${hasDarkTheme ? stylesAds.btnInvert : ''}`}>
                                                    {allowedEdit && <img src={editIcon}/>}
                                                </td>
                                                <td onClick={()=> {
                                                    deleteEvent(index)
                                                }}
                                                className={`${stylesAds.editButton} ${hasDarkTheme ? stylesAds.btnInvert : ''}`}>
                                                    <img src={deleteicon} />
                                                </td>
                                            </tr>
                                    ))
                                }    
                                </tbody>
                            </table>
                        </div>
                    </div>
                  </div> }
                  <ToastContainer />
                  <AdsPopup
                    show={showPopup}
                    handleClose={closePopup}
                    data={singledata}
                    parentData={validateData}
                    updateData={updateData}
                  />
              </div>
          </div>
      )
  }
  export default ScheduleLoop