export const TableDetails = ({
    appTheme,
    item,
    millisecondsToHuman
}) => {
    return (
        <table className={`table table-striped table-hover ${appTheme === "light"? "border":""}`}>
            <thead>
                <tr>
                    <th>Total scheduled</th>
                    <th className="text-center">Number</th>
                    <th className="text-center">HH:MM:SS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Total Scheduled</td>
                    <td className="text-center">{item?.scheduleItems.length + item?.scheduleItems?.reduce((total, item) => total + (item?.adBreaksCount || 0), 0)}</td>
                    <td className="text-center">{millisecondsToHuman(item?.scheduleItems?.reduce((total, data) => total + (data?.totalDuration || 0), 0))}</td>
                </tr>
                <tr>
                    <td>Videos</td>
                    <td className="text-center">{item?.scheduleItems.length}</td>
                    <td className="text-center">{millisecondsToHuman(item?.scheduleItems?.reduce((total, data) => total + (data?.duration || 0), 0))}</td>
                </tr>
                <tr>
                    <td>Ads</td>
                    <td className="text-center">{item?.scheduleItems.reduce((total, item) => total + (item.adBreaksCount || 0), 0)}</td>
                    <td className="text-center">{millisecondsToHuman(item?.scheduleItems?.flatMap(item => item.adBreaks || []).reduce((total, adBreak) => total + (adBreak.duration || 0), 0))}</td>
                </tr>
                <tr>
                    <td>Promos</td>
                    <td className="text-center">0</td>
                    <td className="text-center">00:00:00</td>
                </tr>
            </tbody>
        </table>
    )
}