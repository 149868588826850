import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import "../../../assets/css/style.scss";
import featuredIcon from "../../../assets/images/featured-star.png";
import defaultIcon from "../../../assets/images/default-star.png";
import { StoreFrontFeaturedChannelListAction } from "../../../Redux/slices";
import { featureChannelList, updateFeatureList } from "../../../api/api";
import "../index.scss";
import DuplicatePopup from "../DuplicatePopup";
import UILoader from "../../../component/Loader/UILoader";
import { image_url } from "../../../service/Constant";
import updownarrowIcon from "../../../assets/images/up-down-arrow.svg";
import dragableIcon from "../../../assets/images/dragable-icon.png";

function FeatureFiltering({ deviceType, platforms, permission }) {
  const draggedItem = useRef();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("All");
  const { featuredChannelList } = useSelector(
    (state) => state.StoreFrontReducer
  );
  const [featuredChannels, setFeaturedChannels] = useState([]);
  const [changed, setChanged] = useState(false);
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [duplicatePlatform, setDuplicatePlatform] = useState([]);
  const calendar = useRef();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getFeatureChannelList();
  }, [deviceType]);
  useEffect(() => {
    if (featuredChannelList?.length) {
      const sortedList = [...featuredChannelList].sort((a, b) => {
        if (a.featuredType === "FEATURED" && b.featuredType !== "FEATURED") {
          return -1;
        }
        if (a.featuredType !== "FEATURED" && b.featuredType === "FEATURED") {
          return 1;
        }
        // if (a.featuredType === b.featuredType) {
        //   return a.channel.name.localeCompare(b.channel.name);
        // }
        return 0;
      });
      setFeaturedChannels(sortedList);
    }
  }, [featuredChannelList]);

  const onTabClick = (e, tab) => {
    setSelectedTab(tab);
    // let arr = [];
    // if (tab == "All") {
    //   arr = featuredChannelList?.filter((item) => {
    //     return item.deviceType == deviceType?.toUpperCase();
    //   });
    // } else {
    //   arr = featuredChannelList?.filter((item) => {
    //     return (
    //       item.featuredType == tab.toUpperCase() &&
    //       item.deviceType == deviceType?.toUpperCase()
    //     );
    //   });
    // }
    let arr = featuredChannelList?.filter((item) => {
      return item.deviceType == deviceType?.toUpperCase();
    });
    setFeaturedChannels([...arr]);
  };

  const getFeatureChannelList = async () => {
    const result = await featureChannelList(deviceType?.toUpperCase());
    setLoader(false);
    if (result?.data?.length) {
      let data = result.data.filter((item) => {
        return item.deviceType == deviceType?.toUpperCase();
      });
      dispatch(StoreFrontFeaturedChannelListAction(data));
    }
  };

  const toggleStatus = async (event, item) => {
    const data = featuredChannels.map((row) =>
      row.id === item.id
        ? { ...row, active: !row.active, isUpdated: true }
        : row
    );
    setFeaturedChannels(data);
    if (!changed) {
      setChanged(true);
    }
  };

  const cancelChanges = (event) => {
    setFeaturedChannels([...featuredChannelList]);
  };

  const applyChanges = async (event) => {
    const device = deviceType?.toUpperCase();
    const payload = {
      sequenceList: [],
      deviceType: "",
      duplicateOnDevices: [],
    };
    payload.deviceType = device;
    payload.duplicateOnDevices = [...duplicatePlatform];
    featuredChannels?.forEach((item) => {
      // if (item.isUpdated) {
      let obj = {
        id: item?.id,
        position: item.position,
        isActive: item.active,
        featuredType: item.featuredType,
        newRelease: item.newRelease,
      };
      if (item.startDate) obj.startDate = item.startDate;
      if (item.endDate) obj.endDate = item.endDate;
      payload.sequenceList.push(obj);
      // }
    });
    setLoader(true);
    const result = await updateFeatureList(payload);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setChanged(false);
      getFeatureChannelList();
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setLoader(false);
  };

  const updateDate = (type, date, item) => {
    const d = moment(date).toISOString();
    let arr = [...featuredChannels];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) {
        let data = { ...arr[i] };
        if (type == "start") data.startDate = d;
        if (type == "end") data.endDate = d;
        arr[i] = data;
        break;
      }
    }
    setFeaturedChannels([...arr]);
    setChanged(true);
  };

  const applyForOthers = () => {
    setDuplicatePopup(true);
  };

  const featuredClick = (e, item) => {
    let featuredType = "";
    if (item.featuredType == "DEFAULT") {
      featuredType = "FEATURED";
    } else {
      featuredType = "DEFAULT";
    }
    let arr = [...featuredChannels];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) {
        let data = { ...arr[i] };
        data.featuredType = featuredType;
        arr[i] = data;
        break;
      }
    }
    setFeaturedChannels([...arr]);
    setChanged(true);
  };

  const newReleaseClick = (e, item) => {
    let newRelease = "";
    if (item.newRelease === true) {
      newRelease = false;
    } else {
      newRelease = true;
    }
    let arr = [...featuredChannels];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) {
        let data = { ...arr[i] };
        data.newRelease = newRelease;
        arr[i] = data;
        break;
      }
    }
    setFeaturedChannels([...arr]);
    setChanged(true);
  }

  const handleDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    if (!id) {
      return;
    }
    let targetItem = document.querySelector(`[data-custom='${id}']`);
    if (targetItem !== draggedItem.current) {
      if (
        event.clientY >
        targetItem.getBoundingClientRect().top + targetItem.offsetHeight / 2
      ) {
        targetItem.parentNode.insertBefore(
          draggedItem.current,
          targetItem.nextSibling
        );
      } else {
        targetItem.parentNode.insertBefore(draggedItem.current, targetItem);
      }
    }
    let targetPosition = { ...draggedItem.current.dataset };
    let destinationPosition = { ...targetItem.dataset };
    draggedItem.current = null;
    ArrangeData(targetPosition, destinationPosition);
  };
  const ArrangeData = (tar, des) => {
    let targetIndex = 0,
      destinationIndex = 0;
    featuredChannels.forEach((item, index) => {
      if (tar.custom == item.id) {
        targetIndex = index;
      }
      if (des.custom == item.id) {
        destinationIndex = index;
      }
    });
    let arr = [...featuredChannels];
    const [movedItem] = arr.splice(targetIndex, 1);
    arr.splice(destinationIndex, 0, movedItem);
    // Adjust positions
    arr = arr.map((item, index) => {
        return {
            ...item,
            position: index + 1
        };
    });
    setFeaturedChannels([...arr]);
    setChanged(true);
  }

  return (
    <>
      <div className="page-content">
        {/* <div className="options-list-wrapper">
          {["All", "Featured", "Default"].map((tab) => (
            <div
              key={tab}
              role="none"
              className={`options-list ${tab == selectedTab ? "active" : ""}`}
              onClick={(e) => onTabClick(e, tab)}
            >
              {tab}
            </div>
          ))}
        </div> */}
        <div
          className={`content-wrapper w_90`}
          style={{ height: "380px", top: "0px" }}
        >
          <table role="none" className="store-front-table">
            <thead>
              <tr>
                <th>
                  <img src={updownarrowIcon} />
                </th>
                <th className="w-40">Channel Name</th>
                <th className="w-10">Type</th>
                {/* {selectedTab == "Featured" || selectedTab == "Default" ? ( */}
                <th className="w-40">Date Range</th>
                {/* ) : null} */}
                <th className="w-30">New Release</th>
                <th className="w-20">Status</th>
                {/* <th className="w-10"></th> */}
              </tr>
            </thead>
            <tbody
              className={featuredChannels.length == 0 ? "no-content" : ""}
              role="none"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {featuredChannels?.map((item, index) => {
                return (
                  <tr 
                    className="dragabble"
                    draggable={permission === "edit"? true : false}
                    key={item.id}
                    data-custom={item.id}
                    data-position={item.position}
                    data-index={index}
                  >
                    <td data-id={item.id}>
                      <div className="icon-group pe-none" data-id={item.id}>
                        <span className="table-icon" data-id={item.id}>
                          <img
                            className="dragabble-icon"
                            src={dragableIcon}
                            alt="dragableIcon"
                            data-id={item.id}
                          />
                        </span>
                      </div>
                    </td>
                    <td className="w-40" data-id={item?.id}>
                      <div className="icon-group feature pe-none">
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={`${image_url}${item.channel?.channelLogo[0]?.thumbnailSourcePath}`}
                        />
                        {item?.channel?.name}
                      </div>
                    </td>
                    <td className="w-10" data-id={item?.id}>
                      <div
                        className="icon-group feature"
                        onClick={(e) => featuredClick(e, item)}
                      >
                        <img
                          src={
                            item.featuredType == "DEFAULT"
                              ? defaultIcon
                              : featuredIcon
                          }
                          alt="viewIcon"
                        />
                        <span className="table-text">{item.featuredType}</span>
                      </div>
                    </td>
                    {/* {selectedTab == "Featured" || selectedTab == "Default" ? ( */}
                    <td className="w-40" data-id={item?.id}>
                      <div className="date-range">
                        <DatePicker
                          ref={(ref) => (calendar.current = ref)}
                          // selected={item?.startDate}
                          onChange={(date) => {
                            updateDate("start", date, item);
                          }}
                          dateFormat="DD/MM/YYYY"
                          customInput={
                            <span
                              className={`from-date ${
                                !item.startDate ? "empty" : ""
                              }`}
                              calendar={calendar.current}
                            >
                              {(item.startDate &&
                                moment(item.startDate).format("DD/MM/YYYY")) ||
                                "DD/MM/YYYY"}
                            </span>
                          }
                        />
                        <span className="to">TO</span>
                        <DatePicker
                          ref={(ref) => (calendar.current = ref)}
                          // selected={item?.endDate}
                          onChange={(date) => {
                            updateDate("end", date, item);
                          }}
                          dateFormat="DD/MM/YYYY"
                          customInput={
                            <span
                              className={`to-date ${
                                !item.endDate ? "empty" : ""
                              }`}
                              calendar={calendar.current}
                            >
                              {(item.endDate &&
                                moment(item.endDate).format("DD/MM/YYYY")) ||
                                "DD/MM/YYYY"}
                            </span>
                          }
                        />
                        <span className="calander-icon"></span>
                      </div>
                    </td>
                    {/* ) : null} */}
                    <td className="w-30" data-id={item?.id}>
                      <div
                        className="icon-group feature"
                        onClick={(e) => newReleaseClick(e, item)}
                      >
                        <img
                          src={
                            item?.newRelease === false
                            ? defaultIcon
                            : featuredIcon
                          }
                          alt="viewIcon"
                        />
                        <span className="table-text">{item?.newRelease === true? `YES` : `NO`}</span>
                      </div>
                    </td>
                    <td className="col5" data-id={item?.id}>
                      <div className="table-action-group">
                        {
                          permission === "edit"?
                          <>
                            <div className="switch-check">
                              <input
                                type="checkbox"
                                checked={item?.active}
                                value={item?.active}
                                onChange={(event) => toggleStatus(event, item)}
                                id={item.id}
                              />
                              <label htmlFor={item.id}></label>
                            </div>
                            <label className="action-label">
                              {item?.active ? "Enabled" : "Disabled"}
                            </label>
                          </>
                          : permission === "read"?
                          <>
                            <div className="switch-check">
                              <input
                                type="checkbox"
                                checked={item?.active}
                                value={item?.active}
                                id={item.id}
                                disabled
                              />
                              <label htmlFor={item.id}></label>
                            </div>
                            <label className="action-label">
                              {item?.active ? "Enabled" : "Disabled"}
                            </label>
                          </>
                          :
                          ''
                        }
                      </div>
                    </td>
                    {/* <td>
                      <div className="icon-group" data-id={item.id}>
                        <span className="table-icon" data-id={item.id}>
                          <img
                            src={viewIcon}
                            alt="viewIcon"
                            data-id={item.id}
                          />
                        </span>
                        <span className="table-icon" data-id={item.id}>
                          <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            data-id={item.id}
                          />
                        </span>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {
          permission === "edit"?
          <div className="button-wrapper">
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
              onClick={cancelChanges}
            >
              <div className="button-text">Cancel</div>
            </div>
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
            >
              <div className="button-text" onClick={applyChanges}>
                Apply
                <span className="arrow" onClick={applyForOthers}>
                  &gt;
                </span>
              </div>
            </div>
            {duplicatePopup ? (
              <DuplicatePopup
                platforms={platforms}
                deviceType={deviceType}
                cancel={(e) => {
                  setDuplicatePopup(false);
                }}
                apply={(data) => {
                  setDuplicatePlatform([...data]);
                  setDuplicatePopup(false);
                }}
              />
            ) : null}
          </div>
          :
          ''
        }
      </div>
      {loader ? <UILoader /> : null}
    </>
  );
}

export default FeatureFiltering;
