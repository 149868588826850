import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../assets/css/style.scss";
import LoginHeader from "../../component/Login-header";
import welcome from "../../assets/images/login-bg.png";
import realtv from "../../assets/images/real-tv.png";
import lock_outline from "../../assets/images/lock_outline.svg";
import mail_outline from "../../assets/images/mail_outline.svg";
import hide_text from "../../assets/images/hide-text.png";
import axios from "axios";
import { postData } from '../../api/apiMethod';
import { UUID_CODE, deviceType } from "../../utility/deviceid";
import CryptoJS from "crypto-js";
import { commonHeaders, encryptionKey as encryptionKeys } from "../../service/Constant";
import { Login_URL } from "../../service/API_URL";
import RunnBackground from '../../assets/svg/login/backgroundRunn.svg'
import MailIcon from '../../assets/svg/login/mailIcon.svg'
import PasswordIcon from '../../assets/svg/login/passwordIcon.svg'
import { image_url } from "../../service/Constant";
import logoInvert from "../../assets/images/logo-invert.png";
import logo from "../../assets/images/logo.png";

export const logout = () => {
  localStorage.clear();
};

export default function Login() {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const encryptionKey = encryptionKeys;
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem("tenantInfo");
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.superTenant);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "username" && value && !isEmailValid(value)) {
      setErrors({ ...errors, username: "Invalid email format" });
    } else {
      setErrors({ ...errors, username: "", password: "" });
    }

    if (name === "password") {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      setTimeout(() => {
        if (!passwordRegex.test(value)) {
          setErrors({
            ...errors,
            password: "Password must be at least 8 characters, contain at least one letter, and one symbol (@$!%*#?&)",
          });
        } else {
          setErrors({ ...errors, password: "" });
        }
      }, 1000);
    }
  };

  useEffect(() => {
    accessStore(); 
  }, []);

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    const storedPassword = sessionStorage.getItem("tok");
    if (storedEmail && storedPassword) {
      let decryptedBytes = CryptoJS.AES.decrypt(storedPassword, encryptionKey);
      let decrypted = decryptedBytes?.toString(CryptoJS.enc.Utf8);
      setFormData({
        username: storedEmail,
        password: decrypted,
      });
    }
  }, []);

  const isSubmitDisabled = errors.username || errors.password || !formData.username || !formData.password;

  const handleMouseDown = () => setShowPassword(true);
  const handleMouseUp = () => setShowPassword(false);
  const handleRememberMeChange = () => setRememberMe(!rememberMe);

  const LoginAPI = async () => {
    let URL = Login_URL;
    const data = {
      email: formData.username,
      password: formData.password,
      deviceInfo: { deviceId: UUID_CODE(), deviceType },
    };

    try {
      const response = await postData(URL, data, { headers: commonHeaders });
      if (response.status === 200 && response.data.success === true) {
        localStorage.setItem("accessToken", response.data?.data?.accessToken);
        localStorage.setItem("refreshToken", response.data?.data?.refreshToken);
        localStorage.setItem("userdetail", JSON.stringify(response.data?.data?.user));

        if (rememberMe) {
          sessionStorage.setItem("email", formData.username);
          const encrypted = CryptoJS.AES.encrypt(formData.password, encryptionKey).toString();
          sessionStorage.setItem("tok", encrypted);
        } else {
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("tok");
        }

        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("tok");
        setErrors({ ...errors, password: "Password is incorrect. Please try again." });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("tok");
      setErrors({ ...errors, password: "Something went wrong, please try after sometime" });
    }
  };

  return (
    <>
    
      <LoginHeader />
      <img src={RunnBackground} alt="Background" className="bottom-background" />
      <div className="main"
        style={{
          backgroundColor: '#131313', 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center", 
          height: "100vh", 
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}>
        <div className="login-block" style={{ position: "relative", zIndex: 1 }}>
          <div className="login-form" style={{ backgroundColor: '#1A1A1A' }}>
            <div className="title" style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
              <div 
                className="logo" 
                style={{
                  backgroundImage: `url(${
                    storeFrontAccess?.darkThemeLogoImagePath ?
                    image_url + storeFrontAccess?.darkThemeLogoImagePath :
                    logo
                  })`, 
                  width: '335px', 
                  height: '80px', 
                  backgroundSize: 'contain', 
                  backgroundRepeat: 'no-repeat', 
                  backgroundPosition: 'center',
                }} 
              />
            </div>

            <div className={errors.username ? "form-group error-msg" : "form-group"}>
              <label className="form-label" style={{ color: '#BFBFBF' }}>Email address</label>
              <div className="input-group input-group-sm " style={{ backgroundColor:'#FFFFFF14',    border: '1px solid #444648', }}>
                <div className="input-group-prepend" >
                  <img src={MailIcon} alt="Mail Icon" />
                </div>
                <input
                  name="username"
                  id="username"
                  type="text"
                  placeholder="Enter email address"
                  className="form-control "
                  value={formData.username}
                  onChange={handleChange}
                  autoComplete={!rememberMe ? "on" : "off"}
                  style={{
                    outline: "none", 
                    boxShadow: "none", 
                 
                  }}
                  
                />
              </div>
              {errors.username && <span className="error">{errors.username}</span>}
            </div>

            <div className="form-group">
              <label className="form-label" style={{ color: '#BFBFBF' }}>Password</label>
              <div className="input-group input-group-sm" style={{ backgroundColor:'#FFFFFF14' }}>
                <div className="input-group-prepend">
                  <img src={PasswordIcon} alt="Password Icon" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  className="form-control"
                  value={formData.password}
                  onChange={handleChange}
                  autoComplete="off"
                  style={{
                    outline: "none", 
                    boxShadow: "none", 
                 
                  }}
                 
                />
                <div
                  className="input-group-append"
                  style={{
                    cursor: "pointer",
                    zIndex: "99",
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={() => setShowPassword(false)}
                >
                  <img src={hide_text} alt="Hide Text" />
                </div>
              </div>
              {errors.password && <span className="error">{errors.password}</span>}
            </div>

            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    className="checkbox-input"
                  />
                  <label htmlFor="rememberMe" className="checkbox-label">
                    Remember me
                  </label>
                </div>

                <div className="col-sm-6 text-right">
                  <a 
                    className="forgot" 
                    href="/forget-password"
                    style={{
                      color: '#D22F26',
                      textDecoration: "none",
                      fontSize: "14px",
                      fontWeight: "500",
                      transition: "color 0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => e.target.style.color = '#e05042'} // Slightly darker red on hover
                    onMouseLeave={(e) => e.target.style.color = '#ff6f61'} // Reset to red
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="btn btn-block"
                onClick={LoginAPI}
                disabled={isSubmitDisabled}
                style={{ width: '100%' , backgroundColor:'#D22F26', height:56 }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
