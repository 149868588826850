export const handleProgramDelete = (
    event, 
    uuid,
    panelDate,
    gridList,
    setGridList,
    setShowPopup,
) => {
    event.stopPropagation();
    // Update gridList by removing the item with the matching uuid
    const updatedGridList = gridList.map((schedule) => {
        const originalLength = schedule.scheduleItems.length;
        const updatedScheduleItems = schedule.scheduleItems
            .filter((item) => item.uuid !== uuid) // Remove the item with the matching uuid
            .map((item, index) => ({
                ...item,
                sequence: index + 1, // Reset sequence starting from 1
            }));

        return {
            ...schedule,
            scheduleItems: updatedScheduleItems,
            isEdit: originalLength !== updatedScheduleItems.length ? true : schedule.isEdit
        };
    });
    setGridList(updatedGridList);
    setShowPopup(false);
};

// old deleteEvent without isEdit functioanlity
// export const deleteEvent = (
//     seqId,
//     sequenceId,
//     panelDate,
//     gridList,
//     setGridList,
//     setAssetInfo,
// ) => {
//     const updatedGridList = gridList.map(item => {
//         if (item.date === panelDate) { // Match the panelData (date) with gridList
//             return {
//                 ...item,
//                 scheduleItems: item.scheduleItems.map(schedule => {
//                     if (schedule.sequence === sequenceId) { // Match the sequence inside scheduleItems
//                         // Filter out the matching adSqId
//                         const updatedAdBreaks = schedule.adBreaks
//                             .filter(ad => ad.adSqId !== seqId) // Remove the ad with matching adSqId
//                             .map((ad, index) => ({
//                                 ...ad,
//                                 adSqId: index + 1 // Rearrange adSqId
//                             }));

//                         // Recalculate total duration, startAt, and endAt
//                         let totalAdBreakDuration = updatedAdBreaks.reduce((sum, ad) => sum + (ad.duration || 0), 0);
//                         const totalDuration = totalAdBreakDuration + (schedule?.duration || 0);
//                         const endTime = schedule?.startAt + (schedule?.duration || 0) + totalAdBreakDuration;
//                         const adBreaksCount = updatedAdBreaks.length;

//                         return {
//                             ...schedule,
//                             adBreaks: updatedAdBreaks,
//                             totalDuration: totalDuration,
//                             adBreaksCount: adBreaksCount,
//                             startAt: schedule?.startAt,
//                             endAt: endTime,
//                         };
//                     }
//                     return schedule;
//                 })
//             };
//         }
//         return item;
//     });

//     // Update the gridList with the new data
//     setGridList(updatedGridList);
//     // Find the updated matching schedule to pass the panelDate and set asset info
//     const updatedMatchingSchedule = updatedGridList.find(
//         item => item.date === panelDate
//     );
//     const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
//         item => item.sequence === sequenceId
//     );
//     if (matchedAsset) {
//         setAssetInfo({ ...matchedAsset, panelDate: panelDate });
//     }
// };

export const deleteEvent = (
    seqId,
    sequenceId,
    panelDate,
    gridList,
    setGridList,
    setAssetInfo,
) => {
    const updatedGridList = gridList.map(item => {
        if (item.date === panelDate) { // Match the panelData (date) with gridList
            let isGridEdited = false; // Track if this grid is edited
    
            const updatedScheduleItems = item.scheduleItems.map(schedule => {
                if (schedule.sequence === sequenceId) { // Match the sequence inside scheduleItems
                    // Filter out the matching adSqId
                    const updatedAdBreaks = schedule.adBreaks
                        .filter(ad => ad.adSqId !== seqId) // Remove the ad with matching adSqId
                        .map((ad, index) => ({
                            ...ad,
                            adSqId: index + 1 // Rearrange adSqId
                        }));
    
                    // If adBreaks updated, mark grid as edited
                    if (updatedAdBreaks.length !== schedule.adBreaks.length) {
                        isGridEdited = true;
                    }
    
                    // Recalculate total duration, startAt, and endAt
                    let totalAdBreakDuration = updatedAdBreaks.reduce((sum, ad) => sum + (ad.duration || 0), 0);
                    const totalDuration = totalAdBreakDuration + (schedule?.duration || 0);
                    const endTime = schedule?.startAt + (schedule?.duration || 0) + totalAdBreakDuration;
                    const adBreaksCount = updatedAdBreaks.length;
    
                    return {
                        ...schedule,
                        adBreaks: updatedAdBreaks,
                        totalDuration: totalDuration,
                        adBreaksCount: adBreaksCount,
                        startAt: schedule?.startAt,
                        endAt: endTime,
                    };
                }
                return schedule;
            });
    
            return {
                ...item,
                scheduleItems: updatedScheduleItems,
                isEdit: isGridEdited ? true : item.isEdit, // Set isEdit only if edited
            };
        }
        return item;
    });    

    // Update the gridList with the new data
    setGridList(updatedGridList);
    // Find the updated matching schedule to pass the panelDate and set asset info
    const updatedMatchingSchedule = updatedGridList.find(
        item => item.date === panelDate
    );
    const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
        item => item.sequence === sequenceId
    );
    if (matchedAsset) {
        setAssetInfo({ ...matchedAsset, panelDate: panelDate });
    }
};