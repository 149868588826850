import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import styles from "./AddPartnerpopup.module.scss";
import { Partner_create, Partner_update } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPartnerpopup = ({ show, handleClose, isEditing, partnerData, permission }) => {
  const [partnerName, setPartnerName] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [validForm, setValidForm] = useState("invalid");
  const [startTime, setStartTime] = useState("00:01");
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState("23:59");

  const {
    appThemeReducer: { appTheme },
  } = useSelector((state) => state);

  const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (isEditing) {
      setPartnerName(partnerData.name);
      setPartnerId(partnerData.code);
      setStartDate(new Date(partnerData.startDate));
      setEndDate(new Date(partnerData.endDate));

      const starttime = convertTimestampToTime(partnerData.startDate);
      const endTime = convertTimestampToTime(partnerData.endDate);
      setStartTime(starttime);
      setEndTime(endTime);
    } else {
      setPartnerName("");
      setPartnerId("");
      setStartDate(null);
      setEndDate(null);
      setStartTime("00:01");
      setEndTime("23:59");
    }
  }, [isEditing, partnerData]);

  useEffect(() => {
    formValidate();
  }, [partnerName, partnerId, startDate, endDate]);

  const formValidate = () => {
    if (
      partnerName &&
      partnerId &&
      startDate &&
      endDate &&
      startTime &&
      endTime
    ) {
      const stime = new Date(`${startDate?.toDateString()} ${startTime}`).getTime();
      const etime = new Date(`${endDate?.toDateString()} ${endTime}`).getTime();
      if (stime > etime) {
        setValidForm("invalid");
        return;
      }
      setValidForm("valid");
    } else {
      setValidForm("invalid");
    }
  };

  const datareset = () => {
    setPartnerName("");
    setPartnerId("");
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
  };

  const addPartner = async () => {
    if (!validForm) {
      return;
    }
    if (isEditing) {
      const updatedPartnerData = {
        name: partnerName,
        startDate: new Date(`${startDate?.toDateString()} ${startTime}`).getTime(),
        endDate: new Date(`${endDate?.toDateString()} ${endTime}`).getTime(),
      };

      if (partnerName && partnerId && startDate && endDate) {
        if (partnerId.length == 8) {
          if (startDate < endDate) {
            await Partner_update(updatedPartnerData, partnerData.id);
            datareset();
            handleClose();

            toast.success("Content Partner updated successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          } else {
            toast.error("End date cannot be greater than Start date", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        } else {
          toast.error("Partner Code should be limited to 8 letters", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      } else {
        toast.error("Please fill-in all required fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    } else {
      const newPartnerData = {
        code: partnerId,
        name: partnerName,
        startDate: new Date(`${startDate.toDateString()} ${startTime}`).getTime(),
        endDate: new Date(`${endDate.toDateString()} ${endTime}`).getTime(),
      };

      if (partnerName && partnerId && startDate && endDate) {
        if (partnerId.length == 8) {
          if (startDate < endDate) {
            await Partner_create(newPartnerData);

            toast.success("Content Partner added successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
            datareset();
            handleClose();
          } else {
            toast.error("End date cannot be greater than Start date", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        } else {
          toast.error("Partner Code should be limited to 8 letters", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      } else {
        toast.error("Please fill-in all required fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <Modal
      show={show}
      className={`${styles.partnermodal} ${appTheme}`}
      onHide={handleClose} style={{ padding: "120px" }}
     
    >
      <div style={{ padding: "40px" ,}}>
      <Modal.Header closeButton  >
        <Modal.Title style={{ marginTop: '' }}>
          {permission === "edit" ? (isEditing ? "Edit Partner" : "Add New Content Partner") : "View Partner"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form >
  <Form.Group>
    <Form.Label style={{color:'#E9E9E9'}}>
      Partner Name <span style={{ color: "red" }}>*</span>
    </Form.Label>
    <Form.Control
      placeholder="Enter partner’s name"
      type="text"
      value={partnerName}
      onChange={(e) => setPartnerName(e.target.value)}
      disabled={permission !== "edit"}
      style={{
        border: appTheme === "dark" ? '1px solid white' : '1px solid black',
        color: appTheme === "dark" ? '#ffffff' : '#000000',
        backgroundColor: appTheme === "dark" ? '#333' : '#ffffff',
      }}
    />
  </Form.Group>
   {/* <Form.Group>
    <Form.Label style={{color:'#E9E9E9'}}>
      Partner Code   <span style={{ color: "red" }}>*</span>
    </Form.Label>
    <Form.Control
      placeholder="Enter Partner’s code"
      type="text"
      value={partnerId}
      onChange={(e) => setPartnerId(e.target.value)}
      maxLength={8}
      minLength={8}
      disabled={isEditing || permission !== "edit"}
      style={{
        border: appTheme === "dark" ? '1px solid white' : '1px solid black',
        color: appTheme === "dark" ? '#ffffff' : '#000000',
        backgroundColor: appTheme === "dark" ? '#333' : '#ffffff',
      }}
    />
  </Form.Group> */}


<Form.Group>
  <Form.Label style={{ color: "#E9E9E9" }}>
    Partner Code <span style={{ color: "red" }}>*</span>
  </Form.Label>
  <Form.Control
    placeholder="Enter Partner’s code"
    type="text"
    value={partnerId}
    onChange={(e) => {
      const regex = /^[a-zA-Z0-9]*$/; // Allow alphanumeric characters only
      if (regex.test(e.target.value)) {
        setPartnerId(e.target.value);
      } else {
        toast.error("Only alphanumeric characters are allowed in Partner Code", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    }}
    maxLength={8}
    minLength={8}
    disabled={isEditing || permission !== "edit"}
    style={{
      border: appTheme === "dark" ? "1px solid white" : "1px solid black",
      color: appTheme === "dark" ? "#ffffff" : "#000000",
      backgroundColor: appTheme === "dark" ? "#333" : "#ffffff",
    }}
  />
</Form.Group>

  <Form.Group>
  <Form.Label style={{color:'#E9E9E9'}}>
    Start Date <span style={{ color: "red" }}>*</span>
  </Form.Label>
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
    <DatePicker
      className={styles.partnerDatepicker}
      selected={startDate || null}
      onChange={(date) => setStartDate(date)}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/mm/yyyy"
      isClearable
      disabled={permission !== "edit"}
      style={{
        border: appTheme === "dark" ? '1px solid white' : '1px solid black',
        color: appTheme === "dark" ? '#000000' : '#000000',
        backgroundColor: appTheme === "dark" ? '#333' : '#ffffff',
      }}
    />
    <Form.Control
      placeholder="HH:MM"
      className={styles.startTimePicker }
      type="time"
      value={startTime || "00:01"}
      onChange={(e) => setStartTime(e.target.value)}
      disabled={permission !== "edit"}
      style={{
        border: appTheme === "dark" ? '1px solid white' : '1px solid black',
        color: appTheme === "dark" ? '#ffffff' : '#000000',
        backgroundColor: appTheme === "dark" ? '#333' : '#333',
      }}
    />
  </div>
</Form.Group>
{/* <Form.Group>
  <Form.Label>
    End Date <span style={{ color: "red" }}>*</span>
  </Form.Label>
  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
  <DatePicker
      className={styles.partnerDatepicker}
      selected={startDate || null}
      onChange={(date) => setStartDate(date)}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/mm/yyyy"
      isClearable
      disabled={permission !== "edit"}
      style={{
        border: appTheme === "dark" ? '1px solid white' : '1px solid black',
        color: appTheme === "dark" ? '#000000' : '#000000',
        backgroundColor: appTheme === "dark" ? '#333' : '#333',
      }}
    />
    <Form.Control
      placeholder="HH:MM"
      className={styles.startTimePicker}
      type="time"
      value={endTime || "23:59"}
      onChange={(e) => setEndTime(e.target.value)}
      disabled={permission !== "edit"}
      style={{
        border: appTheme === "dark" ? '1px solid white' : '1px solid black',
        color: appTheme === "dark" ? '#ffffff' : '#000000',
        backgroundColor: appTheme === "dark" ? '#333' : '#ffffff',
      }}
    />
  </div>
</Form.Group> */}
 <Form.Group>
              <Form.Label>End Date <span style={{ color: "red" }}>*</span></Form.Label>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <DatePicker
                  className={styles.partnerDatepicker}
                  selected={endDate || null}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  isClearable
                  disabled={permission !== "edit"}
                />
                <Form.Control
                  placeholder="HH:MM"
                  className={styles.startTimePicker}
                  type="time"
                  value={endTime || "23:59"}
                  onChange={(e) => setEndTime(e.target.value)}
                  disabled={permission !== "edit"}
                />
              </div>
            </Form.Group>
  
</Form>

      </Modal.Body>
      {permission === "edit" && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} style={{backgroundColor:'#FAEAE9',border:'#FAEAE9'}}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={addPartner}
              disabled={validForm === "invalid"}
            >
              {isEditing ? "Update" : "Add"}
            </Button>
          </Modal.Footer>
        )}
      <ToastContainer />
      </div>
     
    </Modal>
  );
};

export default AddPartnerpopup;
