import adMarkers from "../../../../../../../assets/images/adMarkers.svg";

export const AdMarker = ({
    data,
    calculateMinutes,
    zoomInOut
}) => {
    if (!data?.adBreaks?.length) return null;
    return data.adBreaks.map((item) => {
        if (item?.type === "ad" || item?.type === "filler" || item?.type === "promo") {
          const style = {
            position: "absolute",
            zIndex: item?.adSqId,
            top: `${calculateMinutes(item?.startOffsetInMillis) * zoomInOut}px`,
            height: "0px",
            left: "-0.5vw"
          };
    
          const imgStyle = {
            position: "absolute",
            top: "-4px",
            ...(item?.type === "promo" && {
              filter: "invert(30%) sepia(90%) saturate(400%) hue-rotate(75deg) brightness(90%) contrast(90%)"
            })
          };
    
          return (
            <span key={item?.adSqId} style={style}>
              <img src={adMarkers} width={35} style={imgStyle} />
            </span>
          );
        }
        return null;
    });
}