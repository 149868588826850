import { useState } from "react";
import { ChannelTab } from "./ChannelTab";

export const ChannelListing = ({
    programList, 
    promoList, 
    fillerList, 
    locationState, 
    channelDragStart, 
    handelProgramDoubleClick,
    draggedItem,
}) => {
    const [selectedTab, setSelectedTab] = useState("Programs");

    return (
        <div className="channel-listing-cseq">
            <div className="list-wrapper" onDragStart={(e) => channelDragStart(e, draggedItem)}>
                {[
                    { tab: "Programs", list: programList, dataType: "programs" },
                    { tab: "Promos", list: promoList, dataType: "promos" },
                    { tab: "Fillers", list: fillerList, dataType: "fillers" },
                ].map(({ tab, list, dataType }, index) => (
                    <ChannelTab
                        key={index}
                        tab={tab}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        list={list}
                        dataType={dataType}
                        locationState={locationState}
                        handleDoubleClick={handelProgramDoubleClick}
                    />
                ))}
            </div>
        </div>
    );
};