import zoomInIcon from "../../../../../../assets/images/zoom-in.svg";
import zoomOutIcon from "../../../../../../assets/images/zoom-out.svg";

export const ZoomableSchedule = ({
    handleZoomIn,
    handleZoomOut,
    timeSlots,
    zoomInOut
}) => {
    return (
        <div className="border-middle-container rounded border-0">
            <div className="middle-up row justify-content-center">
                <div class="d-flex flex-column align-items-center zoom col-2">
                    <a class="btn btn-secondary btn-circle" onClick={handleZoomIn}>
                        <img src={zoomInIcon} width={30}/>
                    </a>
                    <div class="vertical-line my-2"></div>
                    <a class="btn btn-secondary btn-circle" onClick={handleZoomOut}>
                        <img src={zoomOutIcon} width={30}/>
                    </a>
                </div>
            </div>
            <div className="middle-down mt-3">
                <div className="content-wrapper" style={{height: 'auto', overflow: "hidden"}}>
                    <table className="scheduling-table tbl-50-per">
                        <tbody>
                            <div className="row w-100">
                                {/* Render timeSlots in a separate loop */}
                                <div className="time-slots-container col-2 p-2 text-center" style={{height: `${(30*timeSlots.length*zoomInOut)}px`}}>
                                    {timeSlots?.map((slot, index) => (
                                        <div 
                                            key={index}
                                            style={{ width: "100px", height: `${30 * zoomInOut}px` }}
                                            className="time-slot"
                                            {...(slot === "05:30" ? { id: "targetOnTime" } : {})}
                                        >
                                            <p>{slot}</p>  {/* Customize this to display relevant time slot info */}
                                        </div>
                                    ))}
                                </div>
                                {/* Render gridList as you already have it */}
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}