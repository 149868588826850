export const ScheduleDetails = ({children}) => {
    return (
        <div className="middle-up row">
            <div className="schedule-details col-12">
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
};