import React, { useState } from "react";
import uploadicon from "../../assets/images/uploadicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import archiveicon from "../../assets/images/archiveicon.png";
import Groupicon from "../../assets/svg/Action/videoIcon.svg";
import option from "../../assets/svg/Assetmanagemant/optionIcon.svg";
import GroupIconblue from "../../assets/svg/Assetmanagemant/blueIcon.svg"



function VideoBlock({ onUploadPromos, data, onVideoClick, onArchiveClick, onUploadPosterClick, onDeleteClick, image_url, assetSignledata, openplayercomp, permission }) {

  let selectedImageUrl = '';

  const posters = data?.posters || [];

  // Check for Mobile -> Hero
  const mobileHero = posters.find(
    poster => poster.deviceType === 'MOBILE' && poster.coverImagePath != null
  );

  if (mobileHero) {
    selectedImageUrl = image_url + mobileHero.coverImagePath;
  } else {
    // If Mobile -> Hero not available, find any other Hero
    const otherHero = posters.find(poster => poster.coverImagePath != null);

    if (otherHero) {
      selectedImageUrl = image_url + otherHero.coverImagePath;
    } else {
      // If no Hero, check for Mobile -> Cover
      const mobileCover = posters.find(
        poster => poster.deviceType === 'MOBILE' && poster?.heroImagePath != null
      );

      if (mobileCover) {
        selectedImageUrl = image_url + mobileCover.heroImagePath;
      } else {
        // If no Mobile -> Cover, select any other Cover
        const otherCover = posters.find(poster => poster?.heroImagePath != null);

        if (otherCover) {
          selectedImageUrl = image_url + otherCover.heroImagePath;
        }
        // If no Mobile -> Cover or any other Cover found, selectedImageUrl remains empty
      }
    }
  }
  let clickTimeout;

  const handleDoubleClick = (data) => {
    // Add your double-click functionality here
    // For example:
    openplayercomp(data)
  };

  function handleClick(data, e) {
    if (!clickTimeout) {
      // This executes on single click

      clickTimeout = setTimeout(() => {
        clickTimeout = null;
        onVideoClick(data, false, e);
      }, 200); // Adjust the delay as needed
    } else {
      // This executes on double click
      clearTimeout(clickTimeout);
      clickTimeout = null;
      handleDoubleClick(data);
    }
  }



  return (
    <div
      style={{
        background: data?.isPlaceHolder ? `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #280B3F, #A985ED, #280B3F) border-box`
          : `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #0F3B47, #36D2FF, #0F3B47) border-box`,
        borderRadius: "6px",
        border: "1px solid transparent",
        padding: "5px",
        transition: " all 0.3s ",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.background = `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box`
      }}
      onMouseLeave={(e) => {
        // Revert only the background to the original state
        e.currentTarget.style.background = data?.isPlaceHolder
          ? `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #280B3F, #A985ED, #280B3F) border-box`
          : `linear-gradient(#0D0D0D, #0D0D0D) padding-box, linear-gradient(to right, #0F3B47, #36D2FF, #0F3B47) border-box`;
      }}
      className={`block ${data?.id == assetSignledata?.id ? "active" : ""} ${data?.active === false ? 'border border-danger' : ''}`} onClick={data?.active !== false ? (e) => handleClick(data, e) : null}

    >
      {data.posters?.length === 0 || selectedImageUrl === "" ? (
        <div className="img-block" style={{ background: data?.isPlaceHolder ? "#280B3F" : "#0B2A33", border: data?.isPlaceHolder ? "#D8C2FF" : "#D8C2FF", }}>
          {/* <img src={Groupicon} alt="" /> */}
          <img
            src={data?.isPlaceHolder ? Groupicon : GroupIconblue}
            alt={data?.isPlaceHolder ? "Icon for #280B3F" : "Icon for #0B2A33"}
          />
        </div>
      ) : (
        <div className="img-block " 
        style={{ overflow: "hidden",
         padding: data?.isPlaceHolder ? "0" : "10px",
          }}>
 <img src={data?.isPlaceHolder ? selectedImageUrl : GroupIconblue} />
        </div>
      )}
      <div className="text-block">
        <label>
          {data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}

        </label>
      </div>
      {
        permission === "edit" ?
          <div className="dropdown">
            <a className="dropdown-button">
              <img src={option} />
            </a>
            <div className="dropdown-content">
              <a
                className="delete-button"
                onClick={(e) => onUploadPosterClick(data, true, e)}
              >
                <span className="img-box">
                  <img src={uploadicon} />
                </span>
                Upload Poster
              </a>

              <a
                className="delete-button"
                onClick={() => document.getElementById("fileInputs").click()}
              >
                <input
                  type="file"
                  id="fileInputs"
                  style={{ display: "none" }}
                  accept=".mp4,.mov"
                  multiple
                  onChange={(e) => onUploadPromos(e, data.id)}
                />
                <span className="img-box">
                  <img src={uploadicon} />
                </span>
                Upload Promo
              </a>
              <a className="delete-button"
                onClick={(e) => onArchiveClick(e)}
              >
                <span className="img-box">
                  <img src={archiveicon} />
                </span>
                Archive
              </a>
              <a className="delete-button" onClick={(e) => onDeleteClick(data.id, e)}>
                <span className="img-box">
                  <img src={deleteicon} />
                </span>
                Delete
              </a>
            </div>
          </div>
          :
          ''
      }
    </div>
  );
}

export default VideoBlock;
