export const TimeSlots = ({timeSlots, zoomInOut}) => {
    return (
        <div className="time-slots-container col-2 p-2 text-center">
            {timeSlots?.map((slot, index) => (
                <div 
                    key={index}
                    style={{ width: "100px", height: `${30 * zoomInOut}px` }}
                    className="time-slot"
                >
                    <p>{slot}</p>  {/* Customize this to display relevant time slot info */}
                </div>
            ))}
        </div>
    )
}