export const addadsSqId = (
    data, 
    panelDate,
    setGridList,
    millisecondsToHuman,
) => {
    // Find the object matching the provided panelDate
    const panelData = data.find((item) => item.date === panelDate);
    
    if (!panelData) {
        console.error(`No data found for the panelDate: ${panelDate}`);
        return;
    }

    // Extract scheduleItems for the matched date
    const { scheduleItems } = panelData;

    // Process each program in the scheduleItems
    const updatedScheduleItems = scheduleItems.map((program) => {
        // Calculate total duration from adBreaks
        const totalAdBreakDuration =
            program?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0) || 0;

        // Sort the adBreaks based on startOffsetInMillis or startAt and reassign adSqId
        let totalDuration = 0;
        let updatedAdBreaks = program?.adBreaks
            ?.sort((a, b) => (a.startOffsetInMillis || 0) - (b.startOffsetInMillis || 0))
            .map((ad, index) => ({
                ...ad,
                adSqId: index + 1, // Assign incremental adSqId
            }));
            updatedAdBreaks = updatedAdBreaks.map((ad, index) => {
                totalDuration += index === 0 ? 0 : updatedAdBreaks[index - 1]?.duration || 0;
                const startOffsetInMillis = ad.adInsertionPoint + totalDuration;
    
                return {
                    ...ad,
                    startOffsetInMillis,
                    startAt: millisecondsToHuman(startOffsetInMillis),
                };
            });

        // Update program with adjusted totalDuration and endAt
        return {
            ...program,
            adBreaks: updatedAdBreaks,
            totalDuration: (program?.duration || 0) + totalAdBreakDuration,
            endAt: (program?.startAt || 0) + (program?.duration || 0) + totalAdBreakDuration, // Adjust endAt based on startAt
        };
    });

    // Update the data by creating a new object instead of mutating panelData
    const updatedData = data.map((item) =>
        item.date === panelDate ? { ...item, scheduleItems: updatedScheduleItems } : item
    );

    // Adjust schedule items' timing across all items
    const adjustDataTime = updatedData.map((item, key, arr) => {
        const previousItem = arr[key - 1];
    
        return {
            ...item,
            scheduleItems: item?.scheduleItems.map((item2, key2, scheduleArr) => {
                let updatedItem = { ...item2 };
    
                if (previousItem) {
                    const lastIndexValue = previousItem?.scheduleItems?.[previousItem?.scheduleItems.length - 1];
    
                    // Adjust first item in scheduleItems based on the last item of the previous schedule
                    if (key2 === 0 && lastIndexValue?.endAt > updatedItem.startAt) {
                        updatedItem.startAt = lastIndexValue.endAt;
                        updatedItem.endAt = lastIndexValue.endAt + updatedItem.totalDuration;
                    }
                }
    
                // Adjust subsequent items based on the previous item's endAt in the same schedule
                if (key2 > 0) {
                    const prevScheduleItem = scheduleArr[key2 - 1];
    
                    if (prevScheduleItem && prevScheduleItem.endAt > updatedItem.startAt) {
                        updatedItem.startAt = prevScheduleItem.endAt;
                        updatedItem.endAt = prevScheduleItem.endAt + updatedItem.totalDuration;
                    }
                }
    
                // **Update the previous item's endAt in the array dynamically**
                scheduleArr[key2] = updatedItem;
    
                return updatedItem;
            }),
        };
    });

    // Update the data in your state
    setGridList(adjustDataTime); // Now, the state is properly updated
};