export const DraggableItem = ({
    children,
    handleDragOvers2,
    data,
    calculateMinutes,
    millisecondsSinceMidnightUTC,
    zoomInOut
}) => {
    return (
        <div 
            className="d-flex px-2" 
            onDragOver={(e) => handleDragOvers2(e, data?.uuid)}
            style={{top: `${calculateMinutes(millisecondsSinceMidnightUTC(data?.startAt))*zoomInOut}px`,position: "absolute", zIndex: `${data?.sequence}` }}
        >
            <div
                style={{height: `${calculateMinutes(data?.totalDuration)*zoomInOut}px`, width: "36px", position:"relative"}}
            >
                {children}
            </div>
        </div>
    )
}