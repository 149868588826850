export const DraggableTableRow = ({
    toGetAssetInfo,
    data,
    locationState,
    index,
    calculateMinutes,
    zoomInOut,
    assetInfo,
    millisecondsToHuman,
    programDelete,
    deleteIcon
}) => {
    return (
        <tr
            className="dragabble"
            onClick={() => toGetAssetInfo(data?.sequence)}
            draggable={locationState?.state?.permission === "edit"}
            data-type="draggable-list"
            key={index}
            data-value={JSON.stringify(data)}
            data-id={data?.sequence}
            style={{
                height: `${calculateMinutes(data?.durationInMillis + data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0) * zoomInOut}px`,
                width: "95%",
                overflow: "hidden",
                border: assetInfo?.sequence === data?.sequence ? "1px solid transparent" : "",
                borderImage: assetInfo?.sequence === data?.sequence ? "linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80) 1" : ""
            }}
            title={data?.title}
        >
            <td 
                data-id={data?.sequence} 
                style={{ 
                    background: index % 4 === 0 ? "#FFC374" : index % 4 === 1 ? "#4AD2C9" : index % 4 === 2 ? "#8E73EF" : "#536EFF", 
                    border: assetInfo?.sequence === data?.sequence ? "4px solid rgba(0, 0, 0, 0.9)" : ""
                }}
            >
                <div className="table-content2" style={{ background: "rgba(255, 255, 255, 0.65)", pointerEvents: "none"}}>
                    <div className="title-schedule-container"> 
                        <p className="px-2 fw-bold m-0 program-title" style={{ color: "#212529" }}>
                            {data?.title}
                        </p>
                        <p className="px-2 fw-bold schedule-time" style={{ color: "#212529" }}>
                            {`${millisecondsToHuman(data?.scheduleStartTime)} - ${millisecondsToHuman(data?.scheduleEndTime)}`}
                        </p>
                    </div>
                    <div className="position-relative">
                        <a className="p-2 table-icon position-absolute" onClick={() => programDelete(index)} style={{ filter: "invert(0)", pointerEvents: "auto"}}>
                            <img src={deleteIcon} width={18} alt="Delete" />
                        </a>
                        <a className="p-2 table-adbreaks-count position-absolute" style={{ filter: "invert(0)", pointerEvents: "auto"}}>
                            <span>{data?.adBreaks.length}</span>
                        </a>
                    </div>
                </div>
            </td>
        </tr>
    );
}