import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import UploadIcon from '../../assets/svg/contact/uil_upload.svg';
import TextareaAutosize from '../../component/TextareaAutosize';
import axiosInstance from '../../service/axiosPublicInstance';
import { CONTACT_SUPPORT_URL, PRIORITIES_URL, SECTIONS_URL } from "../../service/API_URL";
import MailIcon from '../../assets/svg/contact/MailIcon.svg'
import DropdownIcon  from  '../../assets/svg/playlists/downArrow.svg'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    section: "",
    issue: "",
    priority: "",
    attachment: null,
  });
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [attachmentPreview, setAttachmentPreview] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [sections, setSections] = useState([]);

  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  useEffect(() => {
    if (sections) {
      console.log("sections", sections)
    }


  }, [sections])

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [prioritiesResponse, sectionsResponse] = await Promise.all([
          axiosInstance.get(PRIORITIES_URL, { headers: { 'X-TENANT-ID': 'runn' } }),
          axiosInstance.get(SECTIONS_URL, { headers: { 'X-TENANT-ID': 'runn' } })
        ]);


        setPriorities(Array.isArray(prioritiesResponse?.data?.data) ? prioritiesResponse?.data?.data : []);
        console.log("Array.isArray(prioritiesResponse.data) ? prioritiesResponse?.data : []", prioritiesResponse?.data?.data)
        setSections(Array.isArray(sectionsResponse?.data?.data) ? sectionsResponse?.data?.data : []);
        console.log("Array.isArray(sectionsResponse.data) ? sectionsResponse?.data : []", sectionsResponse?.data?.data)
      } catch (error) {
        console.error("Error fetching priorities or sections:", error);
        setError("Failed to fetch priorities or sections. Please try again later.");
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        const isValidType = file.type === "image/jpeg" || file.type === "image/png";
        const isValidSize = file.size >= 1 * 1024 * 1024 && file.size <= 10 * 1024 * 1024;

        if (!isValidType || !isValidSize) {
          setError("Please upload a JPEG or PNG image between 1MB and 10MB.");
          setIsFileAttached(false);
          setAttachmentPreview("");
          return;
        } else {
          setError("");
          setIsFileAttached(true);
          
          if (attachmentPreview) {
            URL.revokeObjectURL(attachmentPreview);
          }

        }
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      attachment: null,
    }));
    setIsFileAttached(false);
    setAttachmentPreview("");
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("section", formData.section);
    formDataToSend.append("message", formData.issue);
    formDataToSend.append("priority", formData.priority);
 

    if (formData.attachment) {
      formDataToSend.append("attachment", formData.attachment);
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post(CONTACT_SUPPORT_URL, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-TENANT-ID': 'runn',
        },
      });

      console.log("Form submitted successfully", response.data);
      setFormData({
        email: "",
        section: "",
        issue: "",
        priority: "",
        attachment: null,
      });
      setIsFileAttached(false);
      setAttachmentPreview("");
      setError("");
    } catch (error) {
      console.error("Error submitting form:", error.response ? error.response.data : error.message);
      setError("There was an error submitting your form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="" style={{ backgroundColor: '#1A1A1A' }}>
      <div className="popup-content">
        <h2 className="heading-text">Contact Us</h2>
        <form onSubmit={handleSubmit} className="contact-form">
         
          <div className="form-subtext">
            <label htmlFor="email" style={{ fontWeight: 400, color: "#BFBFBF" }}>
              Email Address
            </label>
            <div className="form-input" style={{ position: 'relative' , width:'416px', height:'48px' }}>
              <img
                src={MailIcon}
                alt="Mail Icon"
                style={{
                  position: 'absolute',
                  left: '21px',
                  top: '49%',
                  transform: 'translateY(-50%)',
                  height: '15px',
                  pointerEvents: 'none',
                }}
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
                className="custom-input"
                style={{
                  paddingLeft: '55px',
                 
                  boxSizing: 'border-box',
                  color: '#999999',
                  borderRadius: '6px',
                  backgroundColor: '#FFFFFF14',
                  height:'48px'

                }}
              />
            </div>
          </div>



          <div className="form-group-contact  mt-5">
            <div className="form-item form-lable">
              <label htmlFor="section" style={{ fontWeight: 400, color: "#BFBFBF" }}>Section</label>
              <select
              style={{width:'188px' }}
                id="section"
                name="section"
                value={formData.section}
                onChange={handleChange}
                required
                className="custom-input-email"
              >
                <option value="" >Select Section</option>
                {sections?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}

              </select>
            </div>

            <div className="form-item">
              <label htmlFor="priority" style={{ fontWeight: 400, color: "#BFBFBF" }}>Priority</label>
              <select
                id="priority"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                required
                className="custom-input-email"
                style={{width:'188px' , height:'48px'}}
              >
                <option value="" style={{ color: '#999999' }}>Select Priority</option>


                {priorities?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}


              </select>
            </div>
          </div>

          <div className="mt-5">
            <label htmlFor="issue" style={{ fontWeight: 400, color: "#BFBFBF" }}>Issue</label>
            <TextareaAutosize
              name="issue"
              value={formData.issue}
              onChange={handleChange}
              placeholder="Describe the issue"
              minRows={2}
              // maxRows={3}
              // className="resize-none  "
              // required
            //   style={{
            //  height:'48px'
            //   }}
            />
          </div>

          <div className="form-subtext">
            <label htmlFor="attachment" style={{ fontWeight: 400, color: "#BFBFBF" }}>Attachment</label>
            <div className="upload-dashline">
              <div className="file-upload">
                {isFileAttached ? (
                  <div className="attachment-container">
                    <img
                      src={attachmentPreview}
                      alt="Attachment Preview"
                      className="thumbnail-preview"
                    />
                    <button type="button" className="cross-icon-button" onClick={handleRemove}>
                      <span className="cross-icon">✖</span>
                    </button>
                  </div>
                ) : (
                  <>
                    <span className="upload-icon">
                      <img src={UploadIcon} alt="Upload Icon" />
                    </span>
                    <span className="upload-text">Image (1MB to 10MB & JPEG/PNG)</span>
                  </>
                )}
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  required
                />
                {!isFileAttached && (
                  <label className="browse-button" htmlFor="attachment">Browse</label>
                )}
              </div>
            
            </div>
            {error && <p className="error-message" style={{ color: '#CA4F11' }}>{error}</p>}
          </div>

          <div className="mt-5">
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs
