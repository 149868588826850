import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../../assets/css/style.scss"
import viewIcon from '../../assets/images/carbon_view.svg';
import editIcon from '../../assets/images/edit-outline.svg';
import deleteIcon from '../../assets/images/delete_forever.png';
import rightArrow from "../../assets/images/rightvector.png";
import leftArrow from "../../assets/images/Vector.png";
import SearchBar from '../../component/search-bar/searchBar';
import snacky from "../../assets/images/snacky.png"
import "./MasterSettings.scss";
import AddEditSettingspopup from "../../component/popup/master-settings/AddEditSettingspopup";
import Loader from "../../component/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sort from "../../assets/images/sort.png";
import desc from "../../assets/images/desc.png";
import filter_alt from "../../assets/images/sortarrow.png";
import img_thumb from "../../assets/images/image-thum.png";
import {
  Theme_list,
  Theme_delete,
  Categories_list,
  Genres_list,
  MatuarityRatings_ListData_list,
  Languages_list,
  Devices_list,
  Categories_delete,
  Genres_delete,
  Languages_delete,
  MatuarityRatings_delete,
  Devices_delete,
  Devicetype,
  setLangPriority,
  Avatar_List,
  Avatar_delete,
} from "../../api/api";
import { MatuarityRatings_ListData_URL, cloudFront_URL } from "../../service/API_URL"
import { Fragment } from 'react';
import CategoriesSetting from '../../component/popup/master-settings/CategoriesSetting';
import ErrorPopup from '../../component/popup/ErrorPopup';
import { capitalizeFirstLetter } from '../../service/commonFunc';
import { permissionDetails } from '../../utility/localStoageData';


function MasterSettings() {
  const navigate = useNavigate();

  const goToCreateChannel = () => {
    navigate('/channelCreation');
  };

  const [showPopup, setShowPopup] = useState(false);
  const [createEdit, setCreateEdit] = useState("create");
  const [viewonly, setviewonly] = useState(false);
  const [singledata, setSingleData] = useState(null);
  const [ShowLoader, setShowLoader] = useState(true);
  const [ThemeData, setThemeData] = useState([]);
  const [CategoriesData, setCategoriesData] = useState([]);
  const [AvatarData, setAvatarData] = useState([]);
  const [GenresData, setGenresData] = useState([]);
  const [DevicesData, setDevices] = useState([]);
  const [MatuarityRatings, setMatuarityRatings] = useState([]);
  const [Languages, setLanguages] = useState([]);
  const [FormValid, setFormValid] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState('Themes');
  const [pageJump, setPageJump] = useState(0);
  const [tabContent, setTabContent] = useState({
    Themes: [],
    Categories: [],
    Genres: [],
    Languages: [],
    MatuarityRatings: [],
    Devices: [],
    Avatar: []
  });
  const [totalnosetting, setTotalnosetting] = useState(0);
  const [permission, setpermission] = useState(null);
  const [identifier, setIdentifier] = useState("");
  const locationState = useLocation();
  //add partner pop
  const openCreatePopup = () => {
    setCreateEdit("create");
    setShowPopup(true);
    setviewonly(false);
  };

  const openEditPopup = (data) => {
    setCreateEdit("edit");
    setSingleData(data);
    setShowPopup(true);
    setviewonly(false);
  };
  const openviewPopup = (data) => {
    setviewonly(true);
    setCreateEdit("edit");
    setSingleData(data);
    setShowPopup(true);
  };
  const [errorbox, setErrorbox] = useState(false);
  const [errorMssage, setErrorMssage] = useState("");
  const [deletedata, setDeletedata] = useState(null);
  const deletepopup = (data) => {

    setErrorMssage("Do you want to delete ?")
    setErrorbox(true)
    setDeletedata(data)

  }
  const errorboxclose = () => {
    setErrorbox(false)
    setErrorMssage("")


  }
  const deletesetting = async (data) => {
    let deleted = false;
    let settingname=""
    setErrorbox(false)
    switch (activeTab) {
      case "Themes":
        deleted = await Theme_delete(data.id);
        settingname ="theme"
        break;
      case "Categories":
        deleted = await Categories_delete(data.id);
        settingname ="category"
        break;
      case "Genres":
        deleted = await Genres_delete(data.id);
        settingname ="genre"
        break;
      case "Languages":
        deleted = await Languages_delete(data.id);
        settingname ="language"
        break;
      case "Maturity Ratings":
        deleted = await MatuarityRatings_delete(data.id);
        settingname ="maturity rating"
        break;
      case "Devices":
        deleted = await Devices_delete(data.id);
        settingname ="device"
        break;
      case "Avatar":
        deleted = await Avatar_delete(data.id);
        settingname = "avatar"
        break;
      // Add cases for other tabs as needed

      default:
        // Handle default case or unknown tab
        break;
    }
    if (deleted.status == 200) {
      handleTabChange(activeTab)
      // const settingname = activeTab.split(' ').slice(0, -1).join(' ');
      toast.success(deleted.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
      });
      // theme_ListDetail();
    } else {
      toast.error(deleted.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
      });
    }

  };
  const closePopup = () => {
    setShowPopup(false);
    setCreateEdit("create"); // Reset to create mode
    setSingleData(null); // Clear the single data
  };

  const [sortOrders, setSortOrders] = useState({});

const getdevice= async()=>{
const device = await Devicetype()

}

const getPermission = () => {
  let findEdit = permissionDetails();
  if (findEdit.includes("MASTER_SETTINGS_EDIT")) setpermission("edit");
  else if (findEdit.includes("MASTER_SETTINGS_READ")) setpermission("read");
  else setpermission(null);
}
const accessStore = async () => {
  const tenantLocalData = localStorage.getItem('tenantInfo');
  const tenantLocalDataParse = await JSON.parse(tenantLocalData);
  setIdentifier(tenantLocalDataParse?.code);
};

  useEffect( () => {
    accessStore()
    getPermission();
    handleTabChange("Themes");
    getdevice()
  }, []);
  useEffect(() => {
    const initialSortOrders = {
      name: 'asc', // Default sort order for 'duration' column
      // name: 'name', // Default sort order for 'filename' column
    };
    setSortOrders(initialSortOrders);
  }, []);


  // const theme_ListDetail = async () => {
  //   setShowLoader(true);
  //   const theme_ListData = await Theme_list();

  //   if (theme_ListData) {
  //     setShowLoader(false);
  //     setThemeData(theme_ListData.content);
  //   }
  // };

  const filterchanges = (tabName, field) => {
    let currentSortOrder = 'asc'; // Default sort order
    // Check if the field was previously sorted and toggle the sort order
    if (sortOrders[field]) {
      currentSortOrder = sortOrders[field] == 'desc' ? 'asc' : 'desc';
    }

    const updatedSortOrders = { ...sortOrders, [field]: currentSortOrder };
    setSortOrders(updatedSortOrders);
    handleTabChange(tabName, field, currentSortOrder)
  }
  const handleTabChange = async (tabName, field, currentSortOrder) => {
    let pageNo = 0;
    if (tabName === activeTab) {
      pageNo = pageJump;
    }
    setActiveTab(tabName);
    if (!tabContent[tabName] || tabContent[tabName].length == 0) {
      await fetchTabData(tabName, field, currentSortOrder, pageNo);
    }
  };
  
  // Fetch data based on the selected tab
  const fetchTabData = async (tabName, field, currentSortOrder, pageNo) => {
    
    setShowLoader(true);
    let tabData = [];
    const data = {
      searchText: "",
      ...(field !== "" ? { sortBy: field } : {}),
      ...(currentSortOrder !== "" ? { sortOrder: currentSortOrder } : {}),
      pageSize: pageSize,
      pageNumber:pageNo,
    }
    // Simulated API calls, replace with your actual API calls
    switch (tabName) {
      case 'Themes':
        const theme_ListData = await Theme_list(data);
        if (theme_ListData) {
          setShowLoader(false);
          setThemeData(theme_ListData?.content);
          setTotalnosetting(theme_ListData);
          setCurrentPage(theme_ListData?.number);
          setTotalPages(theme_ListData?.totalPages);
          setPageJump(theme_ListData?.number);
        }
        break;
      
      case 'Categories':
        const Categories_ListData = await Categories_list(data);
        if (Categories_ListData) {
          setShowLoader(false);
          setCategoriesData(Categories_ListData?.content);
          setCurrentPage(Categories_ListData?.number);
          setTotalnosetting(Categories_ListData);
          setTotalPages(Categories_ListData?.totalPages);
          setPageJump(Categories_ListData?.number);
        }
        break;

      case 'Genres':
        const Genres_ListData = await Genres_list(data);
        if (Genres_ListData) {
          setShowLoader(false);
          setGenresData(Genres_ListData?.content);
          setCurrentPage(Genres_ListData?.number);
          setTotalnosetting(Genres_ListData);
          setTotalPages(Genres_ListData?.totalPages);
          setPageJump(Genres_ListData?.number);
        }
        break;

      case 'Languages':
        const Languages_ListData = await Languages_list(data);
        if (Languages_ListData) {
          setShowLoader(false);
          setLanguages(Languages_ListData?.content);
          setCurrentPage(Languages_ListData?.number);
          setTotalnosetting(Languages_ListData);
          setTotalPages(Languages_ListData?.totalPages);
          setPageJump(Languages_ListData?.number);
        }
        break;

      case 'Maturity Ratings':
        const MatuarityRatings_ListData = await MatuarityRatings_ListData_list(data);
        if (MatuarityRatings_ListData) {
          setShowLoader(false);
          setMatuarityRatings(MatuarityRatings_ListData?.content);
          setCurrentPage(MatuarityRatings_ListData?.number);
          setTotalnosetting(MatuarityRatings_ListData);
          setTotalPages(MatuarityRatings_ListData?.totalPages);
          setPageJump(MatuarityRatings_ListData?.number);
        }
        break;

      case 'Devices':
        const Devices_ListData = await Devices_list(data);
        if (Devices_ListData) {
          setShowLoader(false);
          setDevices(Devices_ListData?.content);
          setCurrentPage(Devices_ListData?.number);
          setTotalnosetting(Devices_ListData);
          setTotalPages(Devices_ListData?.totalPages);
          setPageJump(Devices_ListData?.number);
        }
        break;

      case 'Avatar':
        const Avatar_ListData = await Avatar_List(data);
        if (Avatar_ListData) {
          setShowLoader(false);
          setAvatarData(Avatar_ListData?.content);
          setCurrentPage(Avatar_ListData?.number);
          setTotalnosetting(Avatar_ListData);
          setTotalPages(Avatar_ListData?.totalPages);
          setPageJump(Avatar_ListData?.number);
        }
        break;

      default:
        break;
    }

    if (tabData) {
      setShowLoader(false);
      setTabContent({ ...tabContent, [tabName]: tabData.content });
    }
  };


  //pagination
  const itemsPerPage = 10;
  const startIndex = (currentPage) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  // const currentThemeData = ThemeData.slice(startIndex, endIndex);
  // const currentCategoryData = CategoriesData.slice(startIndex, endIndex);
  // const currentGenresDataData = GenresData.slice(startIndex, endIndex);
  // const currentLanguagesData = Languages.slice(startIndex, endIndex);
  // const currentMatuarityRatingsData = MatuarityRatings.slice(startIndex, endIndex);
  // const currentDevicesDataData = DevicesData.slice(startIndex, endIndex);
  // const currentAvatarData = AvatarData.slice(startIndex, endIndex);
  
  const handlePageChange = (pageNumber) => {
    // setCurrentPage(pageNumber);
  };

  const fetchpaginationData = async (tabName, currentPage2) => {
    setShowLoader(true);
    const data = {
      pageSize: pageSize,
      pageNumber:currentPage2,
      searchText: "",
    }
    if (tabName === "Themes") {
      const theme_ListData = await Theme_list(data);
      if (theme_ListData) {
        setShowLoader(false);
        setThemeData(theme_ListData?.content);
        setTotalnosetting(theme_ListData);
        setCurrentPage(theme_ListData?.number);
        setTotalPages(theme_ListData?.totalPages);
        setPageJump(theme_ListData?.number);
      }
    } else if (tabName === "Categories") {
      const Categories_ListData = await Categories_list(data);
      if (Categories_ListData) {
        setShowLoader(false);
        setCategoriesData(Categories_ListData?.content);
        setCurrentPage(Categories_ListData?.number);
        setTotalnosetting(Categories_ListData);
        setTotalPages(Categories_ListData?.totalPages);
        setPageJump(Categories_ListData?.number);
      }
    } else if (tabName === "Genres") {
      const Genres_ListData = await Genres_list(data);
      if (Genres_ListData) {
        setShowLoader(false);
        setGenresData(Genres_ListData?.content);
        setCurrentPage(Genres_ListData?.number);
        setTotalnosetting(Genres_ListData);
        setTotalPages(Genres_ListData?.totalPages);
        setPageJump(Genres_ListData?.number);
      }
    } else if (tabName === "Languages") {
      const Languages_ListData = await Languages_list(data);
      if (Languages_ListData) {
        setShowLoader(false);
        setLanguages(Languages_ListData?.content);
        setCurrentPage(Languages_ListData?.number);
        setTotalnosetting(Languages_ListData);
        setTotalPages(Languages_ListData?.totalPages);
        setPageJump(Languages_ListData?.number);
      }
    } else if (tabName === "Maturity Ratings") {
      const MatuarityRatings_ListData = await MatuarityRatings_ListData_list(data);
      if (MatuarityRatings_ListData) {
        setShowLoader(false);
        setMatuarityRatings(MatuarityRatings_ListData?.content);
        setCurrentPage(MatuarityRatings_ListData?.number);
        setTotalnosetting(MatuarityRatings_ListData);
        setTotalPages(MatuarityRatings_ListData?.totalPages);
        setPageJump(MatuarityRatings_ListData?.number);
      }
    } else if (tabName === "Devices") {
      const Devices_ListData = await Devices_list(data);
      if (Devices_ListData) {
        setShowLoader(false);
        setDevices(Devices_ListData?.content);
        setCurrentPage(Devices_ListData?.number);
        setTotalnosetting(Devices_ListData);
        setTotalPages(Devices_ListData?.totalPages);
        setPageJump(Devices_ListData?.number);
      }
    } else if (tabName === "Avatar") {
      const Avatar_ListData = await Avatar_List(data);
      if (Avatar_ListData) {
        setShowLoader(false);
        setAvatarData(Avatar_ListData?.content);
        setCurrentPage(Avatar_ListData?.number);
        setTotalnosetting(Avatar_ListData);
        setTotalPages(Avatar_ListData?.totalPages);
        setPageJump(Avatar_ListData?.number);
      }
    } 
  }

  const handleNext = () => {
    if (currentPage < (totalPages - 1)) {
      fetchpaginationData(activeTab, currentPage + 1);
    }
  }

  const handlePrev = () => {
    if (currentPage > 0) {
      fetchpaginationData(activeTab, currentPage - 1);
    }
  }

  const setPage = (e) => {
    if (e.target.value > 0 && e.target.value <= totalPages) {
      setPageJump(e.target.value - 1);
    }
  }

  const handleKeyPress = (event) => {
    event.preventDefault();
    fetchpaginationData(activeTab, pageJump);
  }

  const setPriority = async(event, id) => {
    const data = event.target.value;
    const response = await setLangPriority(data, id);
    if (response.status == 200) {
      // handleTabChange(activeTab);
      toast.success(response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
      });
    }
  }

  return (
    permission !== null?
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <h3>Master Settings</h3>
          </div>
          <div className="right-side">
            {
              permission === "edit"?
              <div className='channel-setup-button'>
                <button className='btn btn-primary' onClick={openCreatePopup}>Add New</button>
              </div>
              :
              ''
            }
          </div>
        </div>

        {ShowLoader ?
          <div className='tableLoader'>
            <Loader></Loader>
          </div> :
          <Fragment>
            <div className='setting-genre'>
              {['Themes', 'Categories', 'Genres', 'Languages', 'Maturity Ratings', 'Avatar', 'Devices'].map(tab => (
                (identifier === "pktflm" || identifier === "nhstdz") && tab === "Avatar" ?
                ''
                :
                <span
                  key={tab}
                  className={activeTab == tab ? 'active' : ''}
                  onClick={() => {
                    handleTabChange(tab); 
                  }}
                >
                  {tab}
                </span>
              ))}
            </div>
            <div className='master-setting-table'>
              {activeTab === "Themes" && (
                <>
                  <table className='master-setting-list'>
                    {
                    
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name


                              <a
                                className="filter-icon "
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  filterchanges(activeTab, "name");
                                }}
                              >

                                {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                                {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                                {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                              </a>



                            </th>
                            <th>Description</th>
                            <th>Images</th>
                            <th></th>
                          </tr>
                        </thead>
                   
                    }
                    <tbody>
                      {ThemeData.length != 0 ? (
                        ThemeData.map((data, index) => {
                          const mobileObject = data?.images.find(obj => obj.size === "Mobile");
                          // If mobileObject exists, use it; otherwise, take the first object
                          const selectedObject = mobileObject || (data?.images.length !== 0 && data.images[0]);

                          const uniqueSizes = [...new Set(data.images.map(img => img.size ))];
                          
                        
                          // Sort the unique sizes array
                          const desiredOrder = ['Mobile', 'Tablet', 'TV', 'Web'];

                          const orderMap = {};
                          desiredOrder.forEach((value, index) => {
                            orderMap[value] = index;
                          });
                          
                          const sortedDevices = uniqueSizes.sort((a, b) => {
                            return orderMap[a] - orderMap[b];
                          });

                        
                          return (<tr key={data.id}>
                            <td>


                              <img
                                src={selectedObject ? (cloudFront_URL + (selectedObject ? selectedObject.sourcePath : data.images[0]?.sourcePath)):img_thumb}
                             
                                className='theme-image'
                                alt={data.name}
                              />

                              {/* <img src={cloudFront_URL + data.images[0]?.sourcePath} className='theme-image' alt={data.name} /> */}
                            </td>
                            <td><b>{data.name}</b></td>
                            <td>{data.description}</td>
                            <td>
                              {
                                sortedDevices.map((img) =>
                                  <span className='img-icon'> {img }</span>
                                )
                              }
                            </td>
                            <td>
                              <div className='icon-group'>
                                <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                                {
                                  permission === "edit"?
                                  <>
                                    <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                    <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                  </>
                                  :
                                  ''
                                }
                              </div>
                            </td>
                          </tr>)
                        })
                      ) : ""
                      }
                    </tbody>
                  </table>

                  {/* <div className="table-pagination">
              <div className="pagination-count">
                <div className="count">
                  {`${startIndex + 1}-${Math.min(endIndex, totalnosetting.numberOfElements)} of ${totalnosetting.numberOfElements}`}
                </div>
                <div className="pagination-arrow">
                  <a
                    className="prev"
                    onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
             <img src={leftArrow} alt="Previous" />
                  </a>
                  <a
                    className="next"
                    onClick={() =>
                      endIndex < totalnosetting.numberOfElements && handlePageChange(currentPage + 1)
                    }
                    disabled={endIndex >= totalnosetting.numberOfElements}
                  >
                    <img src={rightArrow} alt="Next" />
                  </a>
                </div>
              </div>
            </div> */}
                </>

              )}
              {activeTab === "Categories" && (
                <>
                  <table className='master-setting-list'>
                    {
                    
                        <thead>
                          <tr>
                            <th></th>
                            <th>Name

                              <a
                                className="filter-icon "
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  filterchanges(activeTab, "name");
                                }}
                              >

                                {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                                {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                                {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                              </a>



                            </th>
                            <th>Description</th>
                            <th>Images</th>
                            <th></th>
                          </tr>
                        </thead>
                    
                    }
                    <tbody>
                      {CategoriesData.length != 0 ? (
                        CategoriesData.map((data, index) => {
                          const iconSizes = new Set(data.iconImages.map((img) => img.size));
                          const posterSizes = new Set(data.posterImages.map((img) => img.size));
                          const allSizes = [...iconSizes, ...posterSizes];

                          // Filtering to get unique sizes
                          const uniqueSizes = [...new Set(allSizes)];

                          const mobileObject = data.iconImages.find(obj => obj.size === "Mobile");
                          const selectedObject = mobileObject || data.posterImages.find(obj => obj.size === "Mobile") || (data.iconImages.length !== 0 ? data.iconImages[0] : data.posterImages[0]);
                             // Sort the unique sizes array
                             const desiredOrder = ['Mobile', 'Tablet', 'TV', 'Web'];

                             const orderMap = {};
                             desiredOrder.forEach((value, index) => {
                               orderMap[value] = index;
                             });
                             
                             const sortedDevices = uniqueSizes.sort((a, b) => {
                               return orderMap[a] - orderMap[b];
                             });

                          return (
                            <tr key={data.id}>
                              <td>
                                <img
                                src={selectedObject ? (cloudFront_URL + (selectedObject ? selectedObject.sourcePath : data.posterImages[0]?.sourcePath)):img_thumb}
                                  className='theme-image'
                                  alt={data.name}
                                />                              </td>
                              <td><b>{data.name}</b></td>
                              <td>{data.description}</td>
                              <td>



                                {sortedDevices.map((size, idx) => (
                                  <span className='img-icon' key={`unique-${idx}`}>
                                    {size}
                                  </span>
                                ))}


                              </td>
                              <td>
                                <div className='icon-group'>
                                  <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                                  {
                                    permission === "edit"?
                                    <>
                                      <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                      <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                    </>
                                    :
                                    ''
                                  }
                                </div>
                              </td>
                            </tr>

                          );
                        })
                      ) : null
                      }
                    </tbody>
                  </table>
                  {/* <div className="table-pagination">
              <div className="pagination-count">
                <div className="count">
                  {`${startIndex + 1}-${Math.min(endIndex, totalnosetting.numberOfElements)} of ${totalnosetting.numberOfElements}`}
                </div>
                <div className="pagination-arrow">
                  <a
                    className="prev"
                    onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
             <img src={leftArrow} alt="Previous" />
                  </a>
                  <a
                    className="next"
                    onClick={() =>
                      endIndex < totalnosetting.numberOfElements && handlePageChange(currentPage + 1)
                    }
                    disabled={endIndex >= totalnosetting.numberOfElements}
                  >
                    <img src={rightArrow} alt="Next" />
                  </a>
                </div>
              </div>
            </div> */}
                </>
              )}
              {activeTab === "Genres" && (
                <table className='master-setting-list'>
                  {

                    <thead>
                      <tr>
                        <th></th>
                        <th>Name

                          <a
                            className="filter-icon "
                            onClick={(e) => {
                              // e.stopPropagation();
                              filterchanges(activeTab, "name");
                            }}
                          >

                            {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                            {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                            {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                          </a>
                        </th>
                        <th>Description</th>
                        <th>Images</th>
                        <th></th>
                      </tr>
                    </thead>

                  }
                  <tbody>
                    {GenresData.length != 0 ? (
                      GenresData.map((data, index) => {
                        const iconSizes = new Set(data.iconImages.map((img) => img.size));
                        const posterSizes = new Set(data.posterImages.map((img) => img.size));
                        const allSizes = [...iconSizes, ...posterSizes];

                        const mobileObject = data.iconImages.find(obj => obj.size === "Mobile");
                        const selectedObject = mobileObject || data.posterImages.find(obj => obj.size === "Mobile") || (data.iconImages.length !== 0 ? data.iconImages[0] : data.posterImages[0]);
                        const uniqueSizes = [...new Set(allSizes)];

                        const desiredOrder = ['Mobile', 'Tablet', 'TV', 'Web'];

                        const orderMap = {};
                        desiredOrder.forEach((value, index) => {
                          orderMap[value] = index;
                        });
                        
                        const sortedDevices = uniqueSizes.sort((a, b) => {
                          return orderMap[a] - orderMap[b];
                        });
                        return (
                          <tr key={data.id}>
                            <td>
                              <img
                                src={selectedObject ? (cloudFront_URL + (selectedObject ? selectedObject.sourcePath : data.posterImages[0]?.sourcePath)):img_thumb}
                                className='theme-image'
                                alt={data.name}
                              />                             </td>
                            <td><b>{data.name}</b></td>
                            <td>{data.description}</td>
                            <td>
                              {sortedDevices.map((size, idx) => (
                                <span className='img-icon' key={`unique-${idx}`}>
                                  {size }
                                </span>
                              ))}
                            </td>
                            <td>
                              <div className='icon-group'>
                                <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                                {
                                  permission === "edit"?
                                  <>
                                    <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                    <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                  </>
                                  :
                                  ''
                                }
                              </div>
                            </td>
                          </tr>


                        )
                      })
                    ) : null
                    }
                  </tbody>
                </table>
              )}
              {activeTab === "Languages" && (
                <table className='master-setting-list'>
                  {
               
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name
                            <a
                              className="filter-icon "
                              onClick={(e) => {
                                // e.stopPropagation();
                                filterchanges(activeTab, "name");
                              }}
                            >

                              {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                              {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                              {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                            </a>
                          </th>
                          <th>Description</th>
                          <th>Images</th>
                          <th>Priority</th>
                          <th></th>
                        </tr>
                      </thead>
            
                  }
                  <tbody>
                    {Languages.length != 0 ? (
                      Languages.map((data, index) => {
                        const iconSizes = new Set(data.iconImages.map((img) => img.size));
                        const posterSizes = new Set(data.posterImages.map((img) => img.size));
                        const allSizes = [...iconSizes, ...posterSizes];
                        const mobileObject = data.iconImages.find(obj => obj.size === "Mobile");
                        const selectedObject = mobileObject || data.posterImages.find(obj => obj.size === "Mobile") || (data.iconImages.length !== 0 ? data.iconImages[0] : data.posterImages[0]);

                        const uniqueSizes = [...new Set(allSizes)];
                        const desiredOrder = ['Mobile', 'Tablet', 'TV', 'Web'];

                        const orderMap = {};
                        desiredOrder.forEach((value, index) => {
                          orderMap[value] = index;
                        });
                        const sortedDevices = uniqueSizes.sort((a, b) => {
                          return orderMap[a] - orderMap[b];
                        });
                        return (
                          <tr key={data.id}>
                            <td>


                              <img
                                src={selectedObject ? (cloudFront_URL + (selectedObject ? selectedObject.sourcePath : data.posterImages[0]?.sourcePath)):img_thumb}
                                className='theme-image'
                                alt={data.name}
                              />




                            </td>
                            <td><b>{data.name}</b></td>
                            <td>{data.description}</td>
                            <td>
                              {sortedDevices.map((size, idx) => (
                                <span className='img-icon' key={`unique-${idx}`}>
                                  {size}
                                </span>
                              ))}
                            </td>
                            <td>
                            <div className='form-col '>
                              <div className='form-field'>
                                <select
                                  defaultValue={data.priority}
                                  onChange={(event)=> {
                                    setPriority(event, data.id);
                                  }}
                                >
                                  <option value="PRIMARY">PRIMARY</option>
                                  <option value="SECONDARY">SECONDARY</option>
                                  <option value="UPCOMING">UPCOMING</option>
                                  <option value="NONE">NONE</option>
                                </select>
                              </div>
                            </div>
                            </td>
                            <td>
                              <div className='icon-group'>
                                <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                                {
                                  permission === "edit"?
                                  <>
                                    <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                    <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                  </>
                                  :
                                  ''
                                }
                              </div>
                            </td>
                          </tr>)
                      }
                      )
                    ) : ""
                    }
                  </tbody>
                </table>
              )}

              {activeTab === "Maturity Ratings" && (
                <table className='master-setting-list'>

                  {
                  
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name
                            <a
                              className="filter-icon "
                              onClick={(e) => {
                                // e.stopPropagation();
                                filterchanges(activeTab, "name");
                              }}
                            >

                              {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                              {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                              {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                            </a>
                          </th>
                          <th>Description</th>
                          {/* <th>Images</th> */}
                          <th></th>
                        </tr>
                      </thead>
              
                  }
                  <tbody>
                    {MatuarityRatings.length != 0 ? (
                      MatuarityRatings.map((data, index) =>
                        <tr key={data.id}>
                          <td>
                            {/* <img src={viewIcon} className='theme-image' alt={data.name} /> */}
                          </td>
                          <td><b>{data.name}</b></td>
                          <td>{data.description}</td>
                          {/* <td>
                            {
                              data.iconImages.map((img) =>
                                <span className='img-icon'>{img?.size}</span>
                              )
                            }
                          </td> */}
                          <td>
                            <div className='icon-group'>
                              <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                              {
                                permission === "edit"?
                                <>
                                  <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                  <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                </>
                                :
                                ''
                              }
                            </div>
                          </td>
                        </tr>
                      )
                    ) : ""
                    }
                  </tbody>
                </table>
              )}
              {activeTab === "Devices" && (
                <table className='master-setting-list'>
                  {
                  
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name
                            <a
                              className="filter-icon "
                              onClick={(e) => {
                                // e.stopPropagation();
                                filterchanges(activeTab, "name");
                              }}
                            >

                              {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                              {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                              {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                            </a>
                          </th>
                          <th>Description</th>
                          {/* <th>Images</th> */}
                          <th></th>
                        </tr>
                      </thead>
                 
                  }
                  <tbody>
                    {DevicesData.length != 0 ? (
                      DevicesData.map((data, index) =>
                        <tr key={data.id}>
                          <td>
                            {/* <img src={cloudFront_URL + (data.iconImages?.length != 0 ? data.iconImages[0]?.sourcePath : data.posterImages[0]?.sourcePath)} className='theme-image' alt={data.name} /> */}
                          </td>
                          <td><b>{data.name}</b></td>
                          <td>{data.description}</td>
                          {/* <td>
                            {
                              data.iconImages.map((img) =>
                                <span className='img-icon'>{img?.size}</span>
                              )
                            }
                          </td> */}
                          <td>
                            <div className='icon-group'>
                              <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                              {
                                permission === "edit"?
                                <>
                                  <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                  <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                </>
                                :
                                ''
                              }
                            </div>
                          </td>
                        </tr>
                      )
                    ) : ""
                    }
                  </tbody>
                </table>
              )}
              
              {activeTab === "Avatar" && identifier !== "pktflm" && (
                <table className='master-setting-list'>
                  {
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Name
                          <a
                            className="filter-icon "
                            onClick={(e) => {
                              // e.stopPropagation();
                              filterchanges(activeTab, "name");
                            }}
                          >

                            {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                            {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                            {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                          </a>
                        </th>
                        <th>
                          User Group
                          <a
                            className="filter-icon "
                            onClick={(e) => {
                              // e.stopPropagation();
                              filterchanges(activeTab, "userGroup");
                            }}
                          >

                            {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                            {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                            {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                          </a>
                        </th>
                        <th>
                          Peer Group
                          <a
                            className="filter-icon "
                            onClick={(e) => {
                              // e.stopPropagation();
                              filterchanges(activeTab, "peerGroup");
                            }}
                          >

                            {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                            {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                            {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                          </a>
                        </th>
                        <th>
                          Gender
                          <a
                            className="filter-icon "
                            onClick={(e) => {
                              // e.stopPropagation();
                              filterchanges(activeTab, "gender");
                            }}
                          >

                            {sortOrders["name"] == "asc" && <img src={filter_alt} alt="Ascending" />}
                            {sortOrders["name"] == "desc" && <img src={desc} alt="Descending" />}
                            {!sortOrders["name"] && <img src={sort} alt="No Sorting" />}
                          </a>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                  }
                  <tbody>
                    {
                      AvatarData.length != 0 ?
                      (
                        AvatarData?.map((data) => {
                          return (
                            <tr>
                              <td>
                              <img
                                src={data?.sourcePath ? (cloudFront_URL + data?.sourcePath):img_thumb}
                                className='theme-image'
                                alt={data.name}
                              />
                              </td>
                              <td>{data?.name}</td>
                              <td>{data?.userGroup?.name}</td>
                              <td>{data?.peerGroup?.ageGroup} ({data?.peerGroup?.name})</td>
                              <td>{data?.gender?.name}</td>
                              <td>
                                <div className='icon-group'>
                                  <span className="table-icon" onClick={() => openviewPopup(data)} ><img src={viewIcon} /></span>
                                  {
                                    permission === "edit"?
                                    <>
                                      <span className="table-icon" onClick={() => openEditPopup(data)}><img src={editIcon} /></span>
                                      <span className="table-icon" onClick={() => deletepopup(data)}><img src={deleteIcon} /></span>
                                    </>
                                    :
                                    ''
                                  }
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      )
                      :
                      ''
                    }
                  </tbody>
                </table>
              )}

              {totalnosetting?.numberOfElements != "0" ?
                <div className="table-pagination">
                  <div className="pagination-count">
                    <div className="count">
                      Page: {currentPage + 1} of {totalPages}
                    </div>
                    <div className="count">
                      {`${startIndex + 1}-${Math.min(startIndex + totalnosetting.numberOfElements)} of ${totalnosetting.totalElements}`}
                    </div>
                    <div className="pagination-arrow">
                      <a
                        // className="prev"
                        className={`prev ${currentPage == 0 ? 'disable' : ''}`}
                        onClick={handlePrev}
                        // disabled={currentPage == 1}
                      >
                        <img src={leftArrow} alt="Previous" />
                      </a>
                        <div className="text-center">
                          <form onSubmit={handleKeyPress}>
                            <input 
                              type='text' 
                              defaultValue={currentPage + 1} 
                              onChange={setPage} 
                            />
                          </form>
                        </div>
                      <a
                        // className="next"
                        className={`next ${currentPage == (totalPages - 1) ? 'disable' : ''}`}
                        onClick={handleNext}
                        // disabled={`${currentPage == (totalPages - 1) ? 'true' : 'false'}`}
                      >
                        <img src={rightArrow} alt="Next" />
                      </a>
                    </div>
                  </div>
                </div> : null
              }
            </div>
          </Fragment>
        }
        <ToastContainer />

        {showPopup &&
          <AddEditSettingspopup
            TabChange={handleTabChange}
            tab={activeTab}
            show={showPopup}
            handleClose={closePopup}
            isEditing={createEdit === "edit"} // Pass true if editing, false if creating
            gridData={singledata}
            onlyview={viewonly}
          />}
        {errorbox && <ErrorPopup btntext="Delete" message={errorMssage} closebox={() => errorboxclose()} overrides={() => deletesetting(deletedata)} />}

      </div>
    </div>
    :
    ''
  )
}

export default MasterSettings;