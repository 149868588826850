
import React, { useState } from 'react';
import "./Drawer.scss";
import ContactUs from "../../pages/contact";
import CloseIcon from "../../assets/svg/contact/cross.svg"

export default function Drawer({ isOpen, onClose }) {
  return (
    <div>

      {isOpen && <div className="overlay" onClick={onClose}></div>}


      <div className={`drawer ${isOpen ? "open" : ""}`}>

        <div className="drawer-content">
          <button className="close-btn" onClick={onClose}>
            <img src={CloseIcon} alt="close" className='mb-1' />
          </button>
          <ContactUs />
        </div>
      </div>
    </div>
  );
}
