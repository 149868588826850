export const ScheduleDetails = ({
    appTheme,
    totalDuration,
    totalVideoDuration,
    totalAdDuration,
    totalPromosDuration
}) => {
    return (
        <div className="schedule-details col-10">
            <div>
                <table className={`table table-striped table-hover ${appTheme === "light"? "border":""}`}>
                    <thead>
                        <tr>
                            <th>Total scheduled</th>
                            <th className="text-center">Number</th>
                            <th className="text-center">HH:MM:SS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Total Scheduled</td>
                            <td className="text-center">{totalDuration?.totalNum}</td>
                            <td className="text-center">{totalDuration?.duration}</td>
                        </tr>
                        <tr>
                            <td>Videos</td>
                            <td className="text-center">{totalVideoDuration?.totalNum}</td>
                            <td className="text-center">{totalVideoDuration?.duration}</td>
                        </tr>
                        <tr>
                            <td>Ads</td>
                            <td className="text-center">{totalAdDuration?.totalNum}</td>
                            <td className="text-center">{totalAdDuration?.duration}</td>
                        </tr>
                        <tr>
                            <td>Promos</td>
                            <td className="text-center">{totalPromosDuration?.totalNum}</td>
                            <td className="text-center">{totalPromosDuration?.duration}</td>
                        </tr>
                        <tr>
                            <td>Secondary</td>
                            <td className="text-center">0</td>
                            <td className="text-center">00:00:00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}