import revertIcon from "../../../../../../assets/images/revert.svg";
import trim_svg from "../../../../../../assets/images/image-trim.svg";
import arrow_dropdown from "../../../../../../assets/images/down-arrow.svg";

export const ActionToolbar = ({
    locationState,
    trim_programs_space,
    SaveAndUpdate,
    saveBtnValue,
    setSaveBtnValue,
}) => {
    return (
        <div className="right-side d-flex">
            <a className='btn btn-revert mx-1 p-1 bg-secondary'><img src={revertIcon} width={35}/></a>
            <a className='btn btn-revert mx-1 p-1 bg-secondary' onClick={()=>trim_programs_space()}><img src={trim_svg} width={35}/></a>
            {
                locationState?.state?.permission === "edit" &&
                <>
                    <div className="btn-group mx-1">
                        <button type="button" className="btn btn-primary p-2" onClick={() => SaveAndUpdate(saveBtnValue)}>Save & Publish</button>
                        {/* <button type="button" className="btn btn-primary p-2" onClick={() => SaveAndUpdate(saveBtnValue)}>{saveBtnValue}</button>
                        <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split pe-1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={arrow_dropdown} style={{ filter: "invert(1)" }} />
                        </button>
                        <ul className="dropdown-menu" style={{transform: "translate(78.5px, 46.25px)"}}>
                            {
                                saveBtnValue === "Save & Publish"?
                                <li onClick={() => setSaveBtnValue("Save")}><a className="dropdown-item" >Save</a></li>
                                :
                                <li onClick={() => setSaveBtnValue("Save & Publish")}><a className="dropdown-item" >Save & Publish</a></li>
                            }
                        </ul> */}
                    </div>
                </>
            }
            <button className='btn btn-danger mx-1'>Auto Schedule</button>
        </div>
    )
}