import revertIcon from "../../../../assets/images/revert.svg";

export const HeaderSection = ({ locationState, updateLoopData }) => {
    return (
        <>
            <div className="left-side">
                <h3>{locationState?.state?.channelName}</h3>
            </div>
            <div className="right-side d-flex">
                <a className='btn btn-revert mx-2 p-1 bg-secondary'><img src={revertIcon} width={35}/></a>
                {
                    locationState?.state?.permission === "edit" &&
                    <button className='btn btn-primary mx-2' onClick={updateLoopData}>Update</button>
                }
                <button className='btn btn-danger mx-2'>Auto Schedule</button>
            </div>
        </>
    )
}