import { useState } from "react";

export const Left = ({children}) => {
    return (
        <div className="left">
            <div>
                <h3 className='schedule-title-header title-header mb-3'>Scheduling</h3>
                <div className='list-box'>
                    {children}
                </div>
            </div>
        </div>
    )
}