import { addadsSqId } from "./utilityCommonMethods";

export const fetchSchedule = async (
    setLoaderStatus,
    locationState,
    Schedules_list,
    setProgramList,
    setOriginalProgramList,
    setFillerist,
    setPromoList,
    Schedules_Datalist_Loop,
    millisecondsToHuman,
    setAssetInfo,
    setGridList
) => {
    setLoaderStatus(true);
    if(!locationState?.state?.channelId) {
        return;
    }
    const scheduleData = await Schedules_list(locationState?.state?.channelId, 'PROGRAM');
    setProgramList(scheduleData?.data);
    setOriginalProgramList(scheduleData?.data);

    const fillerData = await Schedules_list(locationState?.state?.channelId, 'FILLER');
    setFillerist(fillerData?.data);

    const promoData = await Schedules_list(locationState?.state?.channelId, 'PROMO');
    setPromoList(promoData?.data);

    const gridData = await Schedules_Datalist_Loop(locationState?.state?.channelId);
    const updatedAdBreaks = gridData?.data?.map((items) => {
        let totalPrevDuration = 0; // Tracks sum of all previous durations
    
        const updatedBreaks = items?.adBreaks?.map((ad, index) => {
            const updatedAd = {
                ...ad,
                adInsertionPoint: ad.startOffsetInMillis - totalPrevDuration,
                adInsertionPointFormat: millisecondsToHuman(ad.startOffsetInMillis - totalPrevDuration),
                startAt: millisecondsToHuman(ad.startOffsetInMillis),
            };
            totalPrevDuration += ad.durationInMillis; // Add current duration to total
            return updatedAd;
        });
    
        return {
            ...items,
            adBreaks: updatedBreaks
        };
    });
    
    addadsSqId(updatedAdBreaks, millisecondsToHuman, setGridList)
    setAssetInfo(updatedAdBreaks[0]);
    setLoaderStatus(false);
}

export const update_schedule = async (
    setLoaderStatus,
    ScheduleLoop_update,
    gridList,
    locationState,
    toast,
    fetchLoopData
) => {
    setLoaderStatus(true);
    const response = await ScheduleLoop_update(gridList, locationState?.state?.channelId);
    if (response) {
        toast.success("Channel Updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
        });
        fetchLoopData();
        setLoaderStatus(false);
    } else {
        toast.error("Channel Update failed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
        });
        setLoaderStatus(false);
    }
}